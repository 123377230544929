import AbstractDocument from "@/services/firebase/firestore/documents/AbstractDocument";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import {Expose} from "class-transformer";

export default class DocumentPurchaseOperationTimers extends AbstractDocument {
    @Expose({name: 'statusEmpty'})
    private _statusEmpty: number | null = null;
    @Expose({name: 'statusAccepted'})
    private _statusAccepted: number | null = null;
    @Expose({name: 'statusCashed'})
    private _statusCashed: number | null = null;
    @Expose({name: 'statusCreated'})
    private _statusCreated: number | null = null;
    protected _type: DocumentTypes = DocumentTypes.PURCHASE_OPERATION_TIMERS;

    constructor() {
        super();
    }

    get statusEmpty(): number | null {
        return this._statusEmpty;
    }

    get statusCreated(): number | null {
        return this._statusCreated;
    }

    get statusCashed(): number | null {
        return this._statusCashed;
    }

    get statusAccepted(): number | null {
        return this._statusAccepted;
    }
}