const dialByLocales: Map<string, string> = new Map();
dialByLocales.set("+93", "AF");
dialByLocales.set("+7", "KZ");
dialByLocales.set("+7", "RU");
dialByLocales.set("+49", "DE");
dialByLocales.set("+358", "AX")
dialByLocales.set("+355", "AL")
dialByLocales.set("+213", "DZ")
dialByLocales.set("+1684", "AS")
dialByLocales.set("+376", "AD")
dialByLocales.set("+244", "AO")
dialByLocales.set("+1264", "AI")
dialByLocales.set("+672", "AQ")
dialByLocales.set("+1268", "AG")
dialByLocales.set("+54", "AR")
dialByLocales.set("+374", "AM")
dialByLocales.set("+297", "AW")
dialByLocales.set("+61", "AU")
dialByLocales.set("+43", "AT")
dialByLocales.set("+994", "AZ")
dialByLocales.set("+1242", "BS")
dialByLocales.set("+973", "BH")
dialByLocales.set("+880", "BD")
dialByLocales.set("+1246", "BB")
dialByLocales.set("+375", "BY")
dialByLocales.set("+32", "BE")
dialByLocales.set("+501", "BZ")
dialByLocales.set("+229", "BJ")
dialByLocales.set("+1441", "BM")
dialByLocales.set("+975", "BT")
dialByLocales.set("+591", "BO")
dialByLocales.set("+387", "BA")
dialByLocales.set("+267", "BW")
dialByLocales.set("+47", "BV")
dialByLocales.set("+55", "BR")
dialByLocales.set("+246", "IO")
dialByLocales.set("+673", "BN")
dialByLocales.set("+359", "BG")
dialByLocales.set("+226", "BF")
dialByLocales.set("+257", "BI")
dialByLocales.set("+855", "KH")
dialByLocales.set("+237", "CM")
dialByLocales.set("+1", "CA")
dialByLocales.set("+238", "CV")
dialByLocales.set("+345", "KY")
dialByLocales.set("+236", "CF")
dialByLocales.set("+235", "TD")
dialByLocales.set("+56", "CL")
dialByLocales.set("+86", "CN")
dialByLocales.set("+61", "CX")
dialByLocales.set("+61", "CC")
dialByLocales.set("+57", "CO")
dialByLocales.set("+269", "KM")
dialByLocales.set("+242", "CG")
dialByLocales.set("+243", "CD")
dialByLocales.set("+682", "CK")
dialByLocales.set("+506", "CR")
dialByLocales.set("+225", "CI")
dialByLocales.set("+385", "HR")
dialByLocales.set("+53", "CU")
dialByLocales.set("+357", "CY")
dialByLocales.set("+420", "CZ")
dialByLocales.set("+45", "DK")
dialByLocales.set("+253", "DJ")
dialByLocales.set("+1767", "DM")
dialByLocales.set("+1849", "DO")
dialByLocales.set("+593", "EC")
dialByLocales.set("+20", "EG")
dialByLocales.set("+503", "SV")
dialByLocales.set("+240", "GQ")
dialByLocales.set("+291", "ER")
dialByLocales.set("+372", "EE")
dialByLocales.set("+251", "ET")
dialByLocales.set("+500", "FK")
dialByLocales.set("+298", "FO")
dialByLocales.set("+679", "FJ")
dialByLocales.set("+358", "FI")
dialByLocales.set("+33", "FR")
dialByLocales.set("+594", "GF")
dialByLocales.set("+689", "PF")
dialByLocales.set("+262", "TF")
dialByLocales.set("+241", "GA")
dialByLocales.set("+220", "GM")
dialByLocales.set("+995", "GE")
dialByLocales.set("+233", "GH")
dialByLocales.set("+350", "GI")
dialByLocales.set("+30", "GR")
dialByLocales.set("+299", "GL")
dialByLocales.set("+1473", "GD")
dialByLocales.set("+590", "GP")
dialByLocales.set("+1671", "GU")
dialByLocales.set("+502", "GT")
dialByLocales.set("+44", "GG")
dialByLocales.set("+224", "GN")
dialByLocales.set("+245", "GW")
dialByLocales.set("+592", "GY")
dialByLocales.set("+509", "HT")
dialByLocales.set("+672", "HM")
dialByLocales.set("+379", "VA")
dialByLocales.set("+504", "HN")
dialByLocales.set("+852", "HK")
dialByLocales.set("+36", "HU")
dialByLocales.set("+354", "IS")
dialByLocales.set("+91", "IN")
dialByLocales.set("+62", "ID")
dialByLocales.set("+98", "IR")
dialByLocales.set("+964", "IQ")
dialByLocales.set("+353", "IE")
dialByLocales.set("+44", "IM")
dialByLocales.set("+972", "IL")
dialByLocales.set("+39", "IT")
dialByLocales.set("+1876", "JM")
dialByLocales.set("+81", "JP")
dialByLocales.set("+44", "JE")
dialByLocales.set("+962", "JO")
dialByLocales.set("+254", "KE")
dialByLocales.set("+686", "KI")
dialByLocales.set("+850", "KP")
dialByLocales.set("+82", "KR")
dialByLocales.set("+383", "XK")
dialByLocales.set("+965", "KW")
dialByLocales.set("+996", "KG")
dialByLocales.set("+856", "LA")
dialByLocales.set("+371", "LV")
dialByLocales.set("+961", "LB")
dialByLocales.set("+266", "LS")
dialByLocales.set("+231", "LR")
dialByLocales.set("+218", "LY")
dialByLocales.set("+423", "LI")
dialByLocales.set("+370", "LT")
dialByLocales.set("+352", "LU")
dialByLocales.set("+853", "MO")
dialByLocales.set("+389", "MK")
dialByLocales.set("+261", "MG")
dialByLocales.set("+265", "MW")
dialByLocales.set("+60", "MY")
dialByLocales.set("+960", "MV")
dialByLocales.set("+223", "ML")
dialByLocales.set("+356", "MT")
dialByLocales.set("+692", "MH")
dialByLocales.set("+596", "MQ")
dialByLocales.set("+222", "MR")
dialByLocales.set("+230", "MU")
dialByLocales.set("+262", "YT")
dialByLocales.set("+52", "MX")
dialByLocales.set("+691", "FM")
dialByLocales.set("+373", "MD")
dialByLocales.set("+377", "MC")
dialByLocales.set("+976", "MN")
dialByLocales.set("+382", "ME")
dialByLocales.set("+1664", "MS")
dialByLocales.set("+212", "MA")
dialByLocales.set("+258", "MZ")
dialByLocales.set("+95", "MM")
dialByLocales.set("+264", "NA")
dialByLocales.set("+674", "NR")
dialByLocales.set("+977", "NP")
dialByLocales.set("+31", "NL")
dialByLocales.set("+599", "AN")
dialByLocales.set("+687", "NC")
dialByLocales.set("+64", "NZ")
dialByLocales.set("+505", "NI")
dialByLocales.set("+227", "NE")
dialByLocales.set("+234", "NG")
dialByLocales.set("+683", "NU")
dialByLocales.set("+672", "NF")
dialByLocales.set("+1670", "MP")
dialByLocales.set("+47", "NO")
dialByLocales.set("+968", "OM")
dialByLocales.set("+92", "PK")
dialByLocales.set("+680", "PW")
dialByLocales.set("+970", "PS")
dialByLocales.set("+507", "PA")
dialByLocales.set("+675", "PG")
dialByLocales.set("+595", "PY")
dialByLocales.set("+51", "PE")
dialByLocales.set("+63", "PH")
dialByLocales.set("+64", "PN")
dialByLocales.set("+48", "PL")
dialByLocales.set("+351", "PT")
dialByLocales.set("+1939", "PR")
dialByLocales.set("+974", "QA")
dialByLocales.set("+40", "RO")
dialByLocales.set("+250", "RW")
dialByLocales.set("+262", "RE")
dialByLocales.set("+590", "BL")
dialByLocales.set("+290", "SH")
dialByLocales.set("+1869", "KN")
dialByLocales.set("+1758", "LC")
dialByLocales.set("+590", "MF")
dialByLocales.set("+508", "PM")
dialByLocales.set("+1784", "VC")
dialByLocales.set("+685", "WS")
dialByLocales.set("+378", "SM")
dialByLocales.set("+239", "ST")
dialByLocales.set("+966", "SA")
dialByLocales.set("+221", "SN")
dialByLocales.set("+381", "RS")
dialByLocales.set("+248", "SC")
dialByLocales.set("+232", "SL")
dialByLocales.set("+65", "SG")
dialByLocales.set("+421", "SK")
dialByLocales.set("+386", "SI")
dialByLocales.set("+677", "SB")
dialByLocales.set("+252", "SO")
dialByLocales.set("+27", "ZA")
dialByLocales.set("+211", "SS")
dialByLocales.set("+500", "GS")
dialByLocales.set("+34", "ES")
dialByLocales.set("+94", "LK")
dialByLocales.set("+249", "SD")
dialByLocales.set("+597", "SR")
dialByLocales.set("+47", "SJ")
dialByLocales.set("+268", "SZ")
dialByLocales.set("+46", "SE")
dialByLocales.set("+41", "CH")
dialByLocales.set("+963", "SY")
dialByLocales.set("+886", "TW")
dialByLocales.set("+992", "TJ")
dialByLocales.set("+255", "TZ")
dialByLocales.set("+66", "TH")
dialByLocales.set("+670", "TL")
dialByLocales.set("+228", "TG")
dialByLocales.set("+690", "TK")
dialByLocales.set("+676", "TO")
dialByLocales.set("+1868", "TT")
dialByLocales.set("+216", "TN")
dialByLocales.set("+90", "TR")
dialByLocales.set("+993", "TM")
dialByLocales.set("+1649", "TC")
dialByLocales.set("+688", "TV")
dialByLocales.set("+256", "UG")
dialByLocales.set("+380", "UA")
dialByLocales.set("+971", "AE")
dialByLocales.set("+44", "GB")
dialByLocales.set("+1", "US")
dialByLocales.set("+598", "UY")
dialByLocales.set("+998", "UZ")
dialByLocales.set("+678", "VU")
dialByLocales.set("+58", "VE")
dialByLocales.set("+84", "VN")
dialByLocales.set("+1284", "VG")
dialByLocales.set("+1340", "VI")
dialByLocales.set("+681", "WF")
dialByLocales.set("+967", "YE")
dialByLocales.set("+260", "ZM")
dialByLocales.set("+263", "ZW")

export default dialByLocales;
