<template>
    <f7-page ref="self" :page-content="false" class="settings-page">
        <f7-navbar class="settings-page__navbar">
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="CLOSE">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                {{ $t('g.settings.page.title') }}
            </f7-nav-title>
            <f7-nav-right></f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <p class="list-title">{{ $t('settings.my-profile') }}</p>
            <f7-list class="list-outline list-strong list-dividers">
                <f7-list-item :title="$t('g.settings.page.links.phone')" :after="account.contact.phone"></f7-list-item>
                <f7-list-item :title="$t('g.settings.page.links.name')" link="/settings/change-short-name">
                    <template #after>
                        <template v-if="account.shortName && account.shortName.length > 0">
                            {{ account.shortName }}
                        </template>
                        <template v-else>
                            <i class="icon i-svg-warning"></i>
                        </template>
                    </template>
                </f7-list-item>
                <f7-list-item :title="$t('settings.user-id')" :after="account.id"/>
                <f7-list-item :title="$t('g.settings.page.links.language')" link="/settings/lang"
                              :after="detectCountryName(client.lang)"></f7-list-item>
                <f7-list-item :title="$t('settings.set-password')" link="#"
                              @click="openSetupPasswordPage"></f7-list-item>
                <f7-list-item :title="$t('settings.remove-account')" link="/remove-account"></f7-list-item>
            </f7-list>

            <f7-list class="list-outline list-strong list-dividers">
                <f7-list-item
                    :title="$t('settings.requisites')"
                    @click="toRequisitesViewEvent"
                >
                    <template #after>
                        <template v-if="isRequisitesLoading">
                            <f7-preloader/>
                        </template>
                        <template v-else>
                            <i class="icon arrow-svg"/>
                        </template>
                    </template>
                </f7-list-item>
            </f7-list>

            <f7-list class="logout-list no-margin-bottom list-outline list-strong list-dividers">
                <f7-list-item @click="logout">
                    <template #title>
                        <i class="icon i-svg-exit svg-filter-red1"></i>
                        <span>{{ $t('g.settings.page.logout') }}</span>
                    </template>
                </f7-list-item>
            </f7-list>

            <!--            <f7-list class="separate-list no-margin-bottom list-outline list-strong list-dividers">-->
            <!--                <f7-list-item @click="removeAccountEvent">-->
            <!--                    <template #title>-->
            <!--                        <span>{{ $t('settings.remove-account') }}</span>-->
            <!--                    </template>-->
            <!--                </f7-list-item>-->
            <!--            </f7-list>-->
            <div class="version-wrapper">
                <p class="version" @click="techController.openTechPage()">{{ $t('g.version') }} {{ version }}</p>
            </div>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n'
import {f7} from 'framework7-vue';
import {computed} from "@vue/runtime-core";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import Account from '@/entities/Account';
// @ts-ignore
import store from "@target/core/store/store";
import {ref} from "vue";
import GlobalRequisitesController from "@/views/requisites-group/GlobalRequisitesController";
import ErrorsService from "@/services/errors-service/ErrorsService";
import ViewTechController from "@/views/v2/view-tech/ViewTechController";

const {routerService} = AppController.getInstance();
const {t} = useI18n({useScope: 'global'});
const account = computed((): Account => store.getters.account.value);
const client = store.getters.client.value;
const version = import.meta.env.VITE_VERSION;
const requisitePs = GlobalRequisitesController.getInstance();
const isRequisitesLoading = ref(false);

const techController = ViewTechController.of();

const openSetupPasswordPage = () => {
    f7.views.current.router.navigate('/settings/set-password', {
        openIn: 'popup'
    });
}

const toRequisitesViewEvent = async () => {
    try {
        isRequisitesLoading.value = true;
        await routerService.toRequisitesPage();
    } catch (e: any) {
        console.error(e)
        f7.dialog.alert(t("g.settings.page.alert.first"), t("g.settings.page.alert.second"));
    } finally {
        isRequisitesLoading.value = false;
    }
}
const detectCountryName = (systemName: string) => {
    switch (systemName) {
        case 'ru':
            return t("g.settings.selectLanguage.languages.ru");
        case 'en':
            return t("g.settings.selectLanguage.languages.en");
    }
    return systemName;
}
//TODO not used
const updateCurrency = async (e: any) => {
    try {
        f7.preloader.show();
        await store.dispatch('patchClientData', {currency: e.target.value});
        await AppController.getInstance().auth(localStorage.getItem('token'));
        await store.dispatch('fetchAccount', null);
        await store.dispatch('fetchPaymentSystems', null);
        await store.dispatch('fetchCurrencies', null);
        await store.dispatch('fetchMyPayments', {refresh: true});
        if (account.value.settings.isBanned) {
            f7.view.main.router.back('/message');
        } else {
            f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
        }
    } catch (e: any) {
        f7.dialog.alert(t("g.settings.page.alert.first"), t("g.settings.page.alert.second"));
    } finally {
        f7.preloader.hide();
    }
}
const CLOSE = () => {
    f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
}
const logout = () => {
    f7.dialog.create({
        title: t('g.confirm-logout'),
        buttons: [
            {
                text: t('g.no'),
            },
            {
                text: t('g.yes'),
                onClick: async () => {
                    try {
                        f7.preloader.show();
                        await AppController.getInstance().logout(true);
                    } catch (e: any) {
                        ErrorsService.of().handle(e);
                    } finally {
                        f7.preloader.hide();
                    }
                }
            }
        ]
    }).open();
}
</script>

<style lang="scss" src="@/scss/views/settings-view.scss"></style>
<style lang="scss">
.settings-page__navbar {
    --f7-navbar-bg-color: #FFF;
}
</style>
