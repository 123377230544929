<template>
    <div class="payment-card">
        <div class="payment-card__body">
            <transition name="fade" mode="out-in">
                <template v-if="requisites">
                    <div class="available-content">
                        <payment-card-payment-system-operation
                            v-if="payment.psystemObj"
                            :paymentSystem="payment.psystemObj"
                            :isPhone="payment.requisites?.addressType === 'phone'"
                        />
                        <div class="address" @click="copy(requisites.address!)">
                            <template v-if="requisites.addressType === BuyingRequisiteAddressTypes.PHONE">
                                <p class="title">{{ $t('light-payment.requisites.with-phone') }}</p>
                            </template>
                            <template v-else-if="requisites.addressType === BuyingRequisiteAddressTypes.CARD">
                                <p class="title">{{ $t('light-payment.requisites.with-card') }}</p>
                            </template>
                            <p class="text">{{ payment!.requisites!.address }}
                                <i class="display-block copy-svg"></i>
                            </p>
                        </div>
                        <div class="agent">
                            <p class="title">{{ $t('light-payment.requisites.recipient') }}</p>
                            <p class="text">{{ payment!.requisites!.name }}</p>
                        </div>
                    </div>
                </template>
            </transition>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, PropType, toRef} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {f7} from "framework7-vue";
import {BuyingRequisiteAddressTypes} from "@enums/BuyingRequisiteAddressTypes";
import {ToRef} from "@vue/runtime-core";
import {useI18n} from "vue-i18n";
import PaymentCardPaymentSystemOperation
    from "@/views/payment/components/atomic/payment-card/PaymentCardPaymentSystemOperation.vue";

const props = defineProps({
    payment: {
        type: Object as PropType<PurchaseOperation>,
        required: true
    }
});

const {t} = useI18n({useScope: 'global'});
const payment: ToRef<PurchaseOperation> = toRef(props, 'payment');
const requisites = computed(() => payment.value!.requisites);


function copy(value: string) {
    const aux = document.createElement("input");
    aux.setAttribute("value", value);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);

    f7.toast.create({
        text: t('g.copied'),
        position: 'top',
        closeTimeout: 1000,
    }).open();
}
</script>

<style lang="scss">
.payment-card {
    width: 100%;
    //height: 197px;
    border-radius: 18px;
    box-sizing: border-box;
    padding: 20px;
    background-color: var(--grey9);
    border: 0.5px solid var(--grey8);
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .amount {
            display: flex;
            align-items: center;
            gap: 8px;

            p {
                @include text(24px, 700, var(--brown1), 28.8px);
            }

            i {
                cursor: pointer;
            }
        }
    }

    &__body {
        flex: 1;
        display: flex;
        flex-direction: column;

        .available-content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            flex: 1;
        }

        .address {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .title {
                @include text(16px, 400, var(--grey2), 19.2px);
            }

            .text {
                display: inline-flex;
                align-items: center;
                gap: 8px;
                @include text(20px, 500, var(--brown1), 24px);

                i {
                    margin-top: 2px;
                    cursor: pointer;
                }
            }
        }

        .payment-system-operation-sbp {
            @include text(20px, 500, var(--brown1), 24px);
        }

        .agent {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .title {
                @include text(16px, 400, var(--grey2), 19.2px);
            }

            .text {
                @include text(16px, 500, var(--brown1), 24px);
            }
        }
    }
}
</style>