<template>
    <div class="payment__status onappeal">
        <div class="status-image"></div>
        <PaymentStatusText
            :title="$t('payment.statuses.onappeal.title')"
            :text="$t('payment.statuses.onappeal.subtitle')"
        />
        <f7-button @click="toChat" class="ghost-type">{{ $t('g.to-chat') }}</f7-button>
        <PaymentViewRequisites :payment="payment"/>
    </div>
</template>

<script setup lang="ts">
import PaymentStatusText from "../atomic/PaymentStatusText.vue";
import PaymentViewRequisites from "@/views/payment/components/PaymentViewRequisites.vue";
import {PropType, toRef} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
const emits = defineEmits(['toChat']);

const props = defineProps({
    payment: {
        type: Object as PropType<PurchaseOperation>,
        required: true
    },
});

const payment = toRef(props, 'payment');

function toChat() {
    emits('toChat');
}

</script>

<style src="./index.scss"></style>
<style lang="scss">
.payment__status {
    &.onappeal {
        display: flex;
        align-items: center;
        margin-top: 56px;
        flex-direction: column;

        .status-image {
            width: 100px;
            height: 100px;
            background-image: url("data:image/svg+xml,%3Csvg width='101' height='100' viewBox='0 0 101 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4736_83875)'%3E%3Cpath d='M72.1319 17.1523C63.4676 17.1523 55.8181 22.647 51.1944 31.0322C46.5707 22.647 38.9227 17.1523 30.2569 17.1523C16.1241 17.1523 4.66666 31.7359 4.66666 49.7218C4.66666 67.7077 16.1241 82.2912 30.2569 82.2912C38.9213 82.2912 46.5707 76.7966 51.1944 68.4114C55.8181 76.7951 63.4661 82.2912 72.1319 82.2912C86.2648 82.2912 97.7222 67.7077 97.7222 49.7218C97.7222 31.7359 86.2648 17.1523 72.1319 17.1523ZM30.2569 72.9857C21.4312 72.9857 13.9722 62.3279 13.9722 49.7218C13.9722 37.1157 21.4298 26.4579 30.2569 26.4579C39.0841 26.4579 46.5417 37.1157 46.5417 49.7218C46.5417 62.3279 39.0827 72.9857 30.2569 72.9857ZM72.1319 72.9857C63.3048 72.9857 55.8472 62.3323 55.8472 49.7218C55.8472 37.1113 63.3048 26.4579 72.1319 26.4579C80.9591 26.4579 88.4167 37.1157 88.4167 49.7218C88.4167 62.3279 80.9577 72.9857 72.1319 72.9857Z' fill='%23007AFF'/%3E%3Cpath opacity='0.4' d='M18.6253 45.0703C16.895 45.0703 15.5428 45.5065 14.2284 46.219C14.1004 47.3676 13.9725 48.5308 13.9725 49.7231C13.9725 54.8746 15.2612 59.6466 17.3341 63.552C17.7601 63.6087 18.1833 63.6814 18.6253 63.6814C23.7651 63.6814 27.9308 59.5157 27.9308 54.3759C27.9308 49.2433 23.7579 45.0703 18.6253 45.0703ZM60.5003 45.0703C58.9009 45.0703 57.4178 45.511 56.1034 46.2222C55.9784 47.3676 55.8475 48.5308 55.8475 49.7231C55.8475 54.8746 57.1362 59.6466 59.2091 63.552C59.6424 63.6087 59.9332 63.6814 60.5003 63.6814C65.6401 63.6814 69.8058 59.5157 69.8058 54.3759C69.8058 49.2433 65.6329 45.0703 60.5003 45.0703Z' fill='%23007AFF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4736_83875'%3E%3Crect width='93.0556' height='74.4444' fill='white' transform='translate(4.66666 12.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        .button {
            padding-left: 32px;
            padding-right: 32px;
        }
    }
}
</style>