// @ts-ignore
import store from "@target/core/store/store";
import {ToRef} from "vue";
import Account from "@/entities/Account";

export default class AccountService {
    private static _instance: AccountService | null = null;

    public static of() {
        if (AccountService._instance === null) AccountService._instance = new AccountService();
        return AccountService._instance;
    }

    public getAccount(): ToRef<Account | undefined> {
        return store.getters.account;
    }

    public getAccountProjectSlug(): ToRef<Account | undefined> {
        return (store.getters.account?.value as Account)?.project.slug;
    }
}