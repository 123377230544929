<template>
    <div class="balance-block">
        <div class="header-row">
            <p class="usd" v-format-number="`${account.balance!.amount}`">{{ account.balance!.amount }} $</p>
            <p class="user-currency" @click="infoClick">
                ~<span v-format-number="account.balance!.amountC">{{ account.balance!.amountC }} {{
                    account.currency.abbr
                }}</span>
                <i class="icon i-svg-question"></i>
            </p>
        </div>
        <div class="actions-row">
            <f7-button
                class="flex-1 smoke-type"
                preloader
                :loading="btnWithdrawalLoading"
                :disabled="btnWithdrawalLoading || nextbtnLoading"
                @click="openSheetModalForWithdrawal"
            >{{ $t("g.payments.withdrawal") }}
            </f7-button>
            <f7-button
                class="flex-1 smoke-type"
                @click="openSheetModalForNewPayment"
                preloader
                :loading="nextbtnLoading"
                :disabled="nextbtnLoading || btnWithdrawalLoading"
            >{{ $t("g.payments.newPayment") }}
            </f7-button>
        </div>
    </div>
</template>

<script lang="ts" setup>
// @ts-ignore
import store from "@target/core/store/store";
import {computed, defineAsyncComponent, ref} from "vue";
import {useStore} from 'framework7-vue';
import Account from "@/entities/Account";

const nextbtnLoading = ref(false);
const btnWithdrawalLoading = ref(false);
const account: Account = useStore("account");
const lanpayUser = useStore('client');

const emits = defineEmits(['infoClick', 'openSheetModalForWithdrawal', 'openSheetModalForNewPayment']);

const infoClick = () => {
    emits('infoClick');
}
const openSheetModalForWithdrawal = () => {
    btnWithdrawalLoading.value = true;
    emits('openSheetModalForWithdrawal', () => {
        btnWithdrawalLoading.value = false;
    })
};
const openSheetModalForNewPayment = () => {
    nextbtnLoading.value = true;
    emits('openSheetModalForNewPayment', () => {
        nextbtnLoading.value = false;
    })
}
</script>

<style lang="scss" scoped>
.balance-block {
    margin-top: 40px;

    .header-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;

        .user-currency {
            display: inline-flex;
            align-items: center;
            color: var(--grey2);

            span {
                @include text(16px, 400, var(--grey2), 19.2px);

                & + i {
                    margin-left: 4px;
                }
            }

            i {
                cursor: pointer;
            }
        }

        .usd {
            @include text(44px, 500, var(--brown1), 52.8px);
        }
    }

    .actions-row {
        display: flex;
        margin: 32px 16px 0px;
        gap: 12px;

        .button {

        }
    }
}
</style>
