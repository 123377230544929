export default class FirebaseFcmToken {
    public type: string;
    public token: string;

    public constructor(
        token: string
    ) {
        this.type = "fcm";
        this.token = token;
    }
}