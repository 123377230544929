import Payment from "@/entities/Payment";
import {P_WITHDRAWAL_STATUS} from "@/entities/enums/PWithdrawalStatus";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

class GPController {
    public getStatusMM(payment: Payment): number {
        let updatedAt = dayjs(payment.updatedAt).utc();
        let ss = 0;
        // Set time for calculate view time
        switch (payment.status) {
            case 'CREATED':
                ss = 60000; // 1 min
                break;
            case 'ACCEPTED':
                ss = 60000; // 1 min
                break;
            case 'CASHED':
                ss = 60000; // 1 min
                break;
            default:
        }

        updatedAt = updatedAt.add(ss, "millisecond");
        let result = updatedAt.diff(dayjs().utc(), "millisecond");

        if (result <= 0) {
            result = 0;
            // @ts-ignore
            // Sentry.withScope((scope) => {
            //     scope.setLevel("info");
            //     scope.setContext('Details', {
            //         payment: payment,
            //         vars: {
            //             endUTC: updatedAt.format('YYYY-MM-DD HH.mm'),
            //             nowUTC: dayjs().utc().format('YYYY-MM-DD HH.mm'),
            //             now: dayjs().format('YYYY-MM-DD HH.mm'),
            //             result: result
            //         },
            //     });
            //     // @ts-ignore
            //     Sentry.captureMessage('Time was 0');
            // });
        }
        return result;
    }
}

export default new GPController();
