import {OpenIn} from "@/views/requisites-group/RequisitesController";
import {ref, ToRef} from "vue";
import RequisiteRepository from "@/repositories/requisite/RequisiteRepository";
import Requisite from "@models/requisite/Requisite";
import {data, value} from "dom7";
import requisite from "@components/requisites/Requisite.vue";
import RequisitePsystem from "@models/requisite/RequisitePsystem";
import RequisitePsystemsMap from "@/views/requisites-group/requisite/types/RequisitePsystemsMap";
import {RequisitePsystemTargetType} from "@enums/RequisitePsystemTargetType";
import {PageOpenIn} from "@enums/PageOpenIn";
import PaymentSystemOperation from "@models/operations/components/PaymentSystemOperation";

export default class RequisitePageController {
    private static _instance: RequisitePageController | null = null;
    public _requisite: ToRef<Requisite | null> = ref(null);
    private _openIn: PageOpenIn = PageOpenIn.PAGE;
    private _psystemsMap: RequisitePsystemsMap = { p2p: null, wallet: null };
    public DEFAULT_PSYSTEM_TARGET: RequisitePsystemTargetType = RequisitePsystemTargetType.P2P;

    private _requisiteRepository: RequisiteRepository;

    constructor(requisiteRepository: RequisiteRepository) {
        this._requisiteRepository = requisiteRepository;
    }

    public static of(): RequisitePageController {
        if (RequisitePageController._instance === null) {
            RequisitePageController._instance = new RequisitePageController(
                new RequisiteRepository()
            );
        }
        return RequisitePageController._instance;
    }

    get openIn(): PageOpenIn {
        return this._openIn;
    }

    set openIn(value: PageOpenIn) {
        this._openIn = value;
    }

    get requisite(): ToRef<Requisite | null> {
        return this._requisite;
    }

    // set requisite(value: ToRef<Requisite | null>) {
    //     this._requisite = value;
    // }

    async init(requisite: Requisite | null, pageOpenIn: PageOpenIn) {
        this._openIn = pageOpenIn;
        this._requisite.value = requisite;
        await this.fetchAndSetPsystemsDefaults();

        if (requisite === null) this.initRequisite();
    }

    private initRequisite() {
        const psystem = this._psystemsMap[this.DEFAULT_PSYSTEM_TARGET];
        this._requisite.value = Requisite.build(
            new PaymentSystemOperation(
                psystem!.id,
                psystem!.name,
                psystem!.targetType,
                psystem!.sysName,
                psystem!.imgPath,
                [1]
            )
        )
    }

    private async fetchAndSetPsystems(psystemTargetType: RequisitePsystemTargetType) {
        const data = await this._requisiteRepository.getAllPsystems(psystemTargetType);
        this._psystemsMap[psystemTargetType] = data[0];
    }

    private async fetchAndSetPsystemsDefaults() {
        const data = await this._requisiteRepository.getAllPsystemsDefault();
        this._psystemsMap.p2p = data[0];
        this._psystemsMap.wallet = data[1];
    }

    public getPsystemByTargetType(psystemTargetType: RequisitePsystemTargetType) {
        return this._psystemsMap[psystemTargetType];
    }

    async save() {
        await this._requisiteRepository.patch(this._requisite.value!);
    }

    async create() {
        await this._requisiteRepository.create(this._requisite.value!);
    }
    //
    // private async fetchAndSetRequisites() {
    //     this._requisites.value = await this._requisiteRepository.getAll();
    // }
    async delete(requisite: Requisite) {
        await this._requisiteRepository.delete(requisite);
    }
}