import { FirebaseService } from "@/services/firebase/FirebaseService";
import kodmobiApiService from "@/targets/main/services/project/kodmobiApiService";
import ProjectApiService from "@/targets/main/services/project/projectApiService";
import MainApiService from "@/services/api-service/MainApiService";
import {f7} from "framework7-vue";

export default class ViewTechController {
    private static instance?: ViewTechController;
    private techCount: number = 0;
    private token: string | null = null;
    private rt: string | null = null;
    private csp: string | null = null;
    private url: string | null = null;
    private userAgent: string | null = null;
    private version: string | null = null;
    private isFirebaseInitialized: string | null = null;
    private kodmobiUrl: String | null = null;
    private projectUrl: String | null = null;
    private mainUrl: string | null = null;

    public readSettings() {
        this.token = localStorage.getItem("ltoken");
        this.rt = localStorage.getItem("lrefreshToken");
        this.csp = localStorage.getItem("csp");
        this.url = localStorage.getItem("url");
        this.userAgent = window.navigator.userAgent;
        this.version = import.meta.env.VITE_VERSION;
        this.isFirebaseInitialized = FirebaseService.of().isInitialized() ? "Подключен" : "Недоступен";
        this.kodmobiUrl = kodmobiApiService.getInstance().url;
        this.projectUrl = ProjectApiService.getInstance().getConfigDomain()
        this.mainUrl = MainApiService.getInstance().getConfigDomain();

        return this;
    }

    public getSettings() {
        return {
            token: this.token,
            rt: this.rt,
            csp: this.csp,
            url: this.url,
            userAgent: this.userAgent,
            version: this.version,
            isFirebaseInitialized: this.isFirebaseInitialized,
            kodmobiUrl: this.kodmobiUrl,
            projectUrl: this.projectUrl,
            mainUrl: this.mainUrl
        }
    }

    public static of() {
        if (typeof ViewTechController.instance === 'undefined') ViewTechController.instance = new ViewTechController();

        return ViewTechController.instance;
    }

    public openTechPage() {
        this.techCount++;

        if (this.techCount === 6) {
            f7.dialog.prompt("", "Password", (password: string) => {
                if (password === '666') {
                    f7.view.current.router.navigate('/settings/tech');
                }
            })
            this.techCount = 0;
        }
    }
}
