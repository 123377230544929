// @ts-ignore
import store from '@target/core/store/store';
import {f7, useStore} from 'framework7-vue';
import {computed, Ref, ref} from "vue";
import Country from "@/entities/Country";
import PaymentSystem from "@/entities/PaymentSystem";
import {autobind} from "@/decorators/autobind";
import Currency from "@/entities/Currency";
import ViewController from "@/interfaces/ViewController";
import {catchErrors} from "@/decorators/catch-errors";
import {ServerResponseError} from "@/interfaces/errors";
import Payment from "@/entities/Payment";
import i18n from "@/langs/i18n"
import PaymentWithdrawal from "@/entities/PaymentWithdrawal";
import humps from "lodash-humps-ts";
import AppController from "@/targets/main/components/App/ts/AppController";
import ChatDataController from "@/controllers/ChatDataController";

declare const window: Window & typeof globalThis & { Pusher: any, Echo: any }

export interface CountrySmartSelect extends Country {
    selected: boolean;
}

export interface PaymentSystemSmartSelect extends PaymentSystem {
    selected: boolean;
}

export default class PaymentsViewController implements ViewController {
    private static instance?: PaymentsViewController;
    readonly countries: Ref<Array<CountrySmartSelect>>;
    readonly paymentSystems: Ref<Array<PaymentSystemSmartSelect>>;
    // amount: Ref<string>;
    defaultAmounts: Ref<Array<number>>;
    btnContinueLoading: Ref<boolean>;
    init: Ref<boolean>;

    static getInstance() {
        if (typeof this.instance === 'undefined') {
            this.instance = new PaymentsViewController();
        }
        return this.instance;
    }

    private constructor() {
        this.init = ref(false);
        this.countries = ref([
            {id: 1, title: 'Россия', value: 'ru', selected: true},
            {id: 2, title: 'Казахстан', value: 'kz', selected: false},
            {id: 3, title: 'Азербайджан', value: 'az', selected: false}
        ]);
        // this.paymentSystems = ref([...store.getters.paymentSystems.value.map((el: PaymentSystemSmartSelect, key: number) => {
        //     if (key === 0) el.selected = true;
        //     else el.selected = false;
        //     return el;
        // })]);
        this.defaultAmounts = ref([]);
        this.btnContinueLoading = ref(false);

        this.configure();
    }

    public updateInit() {
        this.init.value = true;
    }

    public getMinAmount(): number {
        return Math.ceil(this.getSelectedPaymentSystem().minAmount);
    }

    set setAmount(s: String) {
        // @ts-ignore
        // this.amount.value = s;
    }

    private async configure() {
        // await store.dispatch('setPayment', undefined);
        this.setDefaultAmounts();
        // window.Echo.channel(localStorage.getItem('ltoken')).stopListening('.PaymentActivated');
        // window.Echo.channel(localStorage.getItem('ltoken')).stopListening('.PaymentRejected');
        // window.Echo.channel(localStorage.getItem('ltoken')).stopListening('.PaymentDeclined');
    }

    private async syncSocket() {
        // window.Echo.channel(localStorage.getItem('ltoken'))
        //     .listenToAll(async (event: any, data: any) => {
        //         switch (event) {
        //             case '.SaleCanceled':
        //                 await store.dispatch('updatePayments', null);
        //                 break;
        //             case '.SaleAccepted':
        //                 await store.dispatch('updatePayments', null);
        //                 break;
        //             case '.SaleCashed':
        //                 await store.dispatch('updatePayments', null);
        //                 break;
        //         }
        //     });
    }

    public unSyncSocket() {
        // window.Echo.channel(localStorage.getItem('ltoken')).stopListeningToAll();
    }

    setDefaultAmounts() {
        // const result: number[] = [];
        // const defaultValues = [500, 1000, 3000];

        // if (this.paymentSystems.value.length > 0) {
        //     const selectedPaymentSystem = this.paymentSystems.value.find(el => el.selected);
        //     defaultValues.map(el => {
        //         if (selectedPaymentSystem!.maxAmount > el) {
        //             result.push(el);
        //         }
        //     })
        // }
        // this.defaultAmounts.value = defaultValues;
    }

    getSelectedPaymentSystem(): PaymentSystem {
        return this.paymentSystems.value.find((el: PaymentSystemSmartSelect) => el.selected)!;
    }

    @autobind
    async updateAccountData() {
        try {
            const routesWithUpdateAccount = ['/withdrawal', '/payment', '/settings'];
            if (routesWithUpdateAccount.includes(f7.views.current.router.previousRoute.path)) {
                await store.dispatch('fetchAccount', null);
            }
        } catch (e) {
            await AppController.getInstance().logout();
            throw e;
        }
    }

    async AppFunctions() {
        try {
            if (store.getters['account'].value === undefined) {
                await store.dispatch('fetchClientProfile', null);
            }
            await store.dispatch('fetchAccount', null);
            await store.dispatch('fetchMyPayments', {refresh: true});
        } catch (e) {
            console.log(e)
            await AppController.getInstance().logout();
        }
    }

    @autobind
    @catchErrors
    async updateNeedsData() {
        await store.dispatch('fetchAccount', null);
        await store.dispatch('fetchPaymentSystems', null);
        await store.dispatch('fetchCurrencies', null);
    }

    @autobind
    async updateMyPayments() {
        await store.dispatch('fetchMyPayments', {refresh: true});
    }

    @autobind
    async updateMyAccount() {
        await store.dispatch('fetchAccount', null);
    }

    // @autobind
    // @catchErrors
    // async next() {
    //     const amount = parseInt(this.amount.value.replace(/\s/g, ''));
    //     // console.log(amount)
    //     try {
    //         if (amount < this.getMinAmount()) {
    //             f7.dialog.alert(`Минимальная сумма ${this.getMinAmount()} `, 'Упс!');
    //             return;
    //         }
    //         if (amount > this.getSelectedPaymentSystem().maxAmount) {
    //             f7.dialog.alert(`Максимальная сумма ${this.getSelectedPaymentSystem().maxAmount} `, `Упс!`);
    //             return;
    //         }
    //         this.btnContinueLoading.value = true;
    //         const payload: {
    //             amount: number,
    //             psystem_id: number,
    //             currency_id: number,
    //             account_ids?: number[]
    //         } = {
    //             amount: amount,
    //             psystem_id: this.paymentSystems.value.find(el => el.selected)!.id,
    //             currency_id: (store.getters.currencies.value[0] as Currency).id,
    //         }
    //         if (import.meta.env.VITE_DEV_AGENTS_IDS) {
    //             payload.account_ids = (import.meta.env.VITE_DEV_AGENTS_IDS as string)?.split(',').map(el => parseInt(el));
    //         }
    //         await store.dispatch('createPaymentRequest', payload);
    //         f7.view.main.router.navigate('/find-agent');
    //     } catch (e: any) {
    //         if (typeof e.type !== 'undefined') {
    //             switch (e.type) {
    //                 case 'SERVER_RESPONSE_ERROR':
    //                     throw new ServerResponseError(e.message, e.code);
    //                 // case 'AGENT_NOT_FOUND_ERROR':
    //                 //     throw new AgentNotFoundError(e.message);
    //                 default:
    //                     throw new Error(e.message);
    //             }
    //         }
    //     } finally {
    //         this.btnContinueLoading.value = false;
    //     }
    // }

    destructor() {
        PaymentsViewController.instance = undefined;
    }
}
