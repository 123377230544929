<template>
    <f7-page class="payment-income_page" name="payment" :page-content="false" @page:beforeout="">
        <PaymentIncomeViewNavbar @click="CLOSE" :payment="payment"/>
        <f7-page-content ptr @ptr:refresh="eventPtrRefresh">
            <PaymentIncomeStatusConfirmed :payment="payment"/>
        </f7-page-content>

        <div class="bottom-block" ref="bottom-block">
            <div class="row-actions">
                <f7-button fill class="primary" @click="CLOSE">{{ $t("payment.close") }}</f7-button>
            </div>
        </div>
    </f7-page>
</template>

<script lang="ts">
import PaymentViewController from "./ts/PaymentViewController";
import {computed, Ref} from "vue";
// @ts-ignore
import store from "@target/core/store/store";
import {f7, useStore} from "framework7-vue";
import Account from "@/entities/Account";
import dayjs from "dayjs";
import PaymentIncomeViewNavbar from "@/views/payment-income/components/PaymentIncomeViewNavbar.vue";
import PaymentIncomeOperation from "@models/operations/PaymentIncomeOperation";
import PaymentIncomeStatusConfirmed from "@/views/payment-income/components/PaymentIncomeStatusConfirmed.vue";

export default {
    components: {PaymentIncomeStatusConfirmed, PaymentIncomeViewNavbar},
    props: {
        f7router: Object,
        fromHistory: Boolean,
    },
    setup(props: any) {
        const vc = PaymentViewController.getInstance();
        vc.configurateTextOnView();
        const {title, subtitle, hint, fileToUploadPath} = vc;
        const payment: Ref<PaymentIncomeOperation> = useStore('paymentIncome');
        const amount = 100; //parseInt(payment.value!.amount!.toString());
        const account = computed((): Account => store.getters.account.value);
        return {
            fileToUploadPath,
            hint,
            title,
            subtitle,
            amount,
            payment,
            vc,
            account
        }
    },
    data() {
        return {
            messageText: '',
            placeholder: '',
            dayjs
        }
    },
    mounted() {
    },
    methods: {
        commentReformat(text: string) {
            return text;
        },
        async eventPtrRefresh(done: Function) {
            await store.dispatch('checkTransactionStatus', null);
            setTimeout(() => {
                done();
            }, 700);
        },
        CLOSE() {
            f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
        },
        copy(text: any, toastText: string) {
            text = text.toString();
            const aux = document.createElement("input");
            aux.setAttribute("value", text);
            document.body.appendChild(aux);
            aux.select();
            document.execCommand("copy");
            document.body.removeChild(aux);

            f7.toast.create({
                text: toastText,
                position: 'top',
                closeTimeout: 1000,
            }).open();
        },
        convertDatetime(dateString: string) {
            return dayjs(dateString).format('DD.MM.YYYY HH:mm');
        },
    },
    unmounted() {

    }
}
</script>

<style lang="scss">
.payment-income_page {
    .page-content {
        background: #FFF;
        padding-top: calc(var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) + var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) + var(--f7-page-content-extra-padding-top, 0px)) !important;
        --f7-page-content-extra-padding-bottom: 94px;
    }

    &::-webkit-scrollbar {
        width: 0;
    }

    .row-actions {
        width: 100%;
    }
}
</style>

