import {ref, ToRef} from "vue";
import RequisiteRepository from "@/repositories/requisite/RequisiteRepository";
import Requisite from "@models/requisite/Requisite";
import {PageOpenIn} from "@enums/PageOpenIn";

export default class RequisitesPageController {
    private static _instance: RequisitesPageController | null = null;
    private _openIn: PageOpenIn = PageOpenIn.PAGE;
    public forceUpdateRequisite: boolean = false;
    private _requisites: ToRef<Requisite[]> = ref([]);
    private _requisiteRepository: RequisiteRepository;

    constructor(requisiteRepository: RequisiteRepository) {
        this._requisiteRepository = requisiteRepository;
    }

    public static of(): RequisitesPageController {
        if (RequisitesPageController._instance === null) {
            RequisitesPageController._instance = new RequisitesPageController(
                new RequisiteRepository()
            );
        }
        return RequisitesPageController._instance;
    }

    get openIn() {
        return this._openIn;
    }

    set openIn(value: PageOpenIn) {
        this._openIn = value;
    }

    get requisites(): ToRef<Requisite[]> {
        return this._requisites;
    }

    async init(pageOpenIn: PageOpenIn) {
        if (this._requisites.value.length === 0) {
            await this.fetchAndSetRequisites();
        }
        this._openIn = pageOpenIn;
    }

    public async fetchAndSetRequisites() {
        this._requisites.value = await this._requisiteRepository.getAll();
    }

    public destroy() {
        this._requisites.value = [];
        RequisitesPageController._instance = null;
    }
}