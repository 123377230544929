<template>
    <div class="payment__status expired" v-if="payment">
        <template v-if="payment.account">
            <slot name="timer"></slot>
            <PaymentStatusText
                :title="$t('light-payment.status.expired.title')"
                :text="$t('light-payment.status.expired.text')"
                :advice="$t('light-payment.status.expired.advice')"
            />
            <f7-button
                :disabled="actionBtnLoading"
                :loading="actionBtnLoading"
                preloader
                class="theme-type"
                @click="submit"
            >
                {{ $t('payment.statuses.expired.actions.recreate') }}
            </f7-button>
        </template>
        <template v-else>
            <SlothBlock
                :title="$t('payment.statuses.expired.no-agent.title')"
                :subtitle="$t('payment.statuses.expired.no-agent.subtitle')"
                :type="SLOTH_TYPE.QUESTION"
            ></SlothBlock>
            <f7-button
                :disabled="actionBtnLoading"
                :loading="actionBtnLoading"
                preloader
                class="theme-type"
                @click="submit"
            >
                {{ $t('payment.statuses.expired.no-agent.actions.recreate') }}
            </f7-button>
        </template>
    </div>
</template>

<script setup lang="ts">
import {computed, PropType, ref, toRef, UnwrapRef, watch} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {ReactiveBuyingOperation} from "@/services/operations/AbstractBuyingService";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import SlothBlock from "@/components/messages/SlothBlock.vue";
import PaymentStatusText from "../atomic/PaymentStatusText.vue";

enum TIMER_STATUS_TYPE {
    DEFAULT = "DEFAULT",
    WARNING = "WARNING",
    ALERT = "ALERT"
}

const setTimerColors = {
    DEFAULT: "#1890FF",
    WARNING: "#E69700",
    ALERT: "#FF3B30"
};

const props = defineProps({
    payment: {
        type: Object as PropType<UnwrapRef<PurchaseOperation>>,
        required: true
    },
    timeText: {
        type: Object as PropType<UnwrapRef<string | null>>,
        required: true
    },
    percent: {
        type: Object as PropType<UnwrapRef<number>>,
        required: true
    }
});
const emits = defineEmits(['submit']);
const payment: ReactiveBuyingOperation = toRef(props, 'payment');
const requisites = computed(() => payment.value?.requisites);
const percent = toRef(props, 'percent');
const timeText = toRef(props, 'timeText');
const buyingIsAvailable = ref(true);
const timerBorderColor = ref(setTimerColors[TIMER_STATUS_TYPE.DEFAULT]);
const timerTextColor = ref(setTimerColors[TIMER_STATUS_TYPE.DEFAULT]);
const actionBtnLoading = ref(false);

const submit = () => {
    actionBtnLoading.value = true;
    emits('submit', () => {
        actionBtnLoading.value = false;
    });
}

if (timeText.value) {
    detectBuyingIsAvailable(timeText.value);
}

watch(timeText, (value) => {
    if (value) {
        detectBuyingIsAvailable(value);
    }
});

function detectBuyingIsAvailable(value: string) {
    const minute = +value.split(':')[0];
    if (minute == 4) {
        timerBorderColor.value = setTimerColors[TIMER_STATUS_TYPE.WARNING];
        timerTextColor.value = setTimerColors[TIMER_STATUS_TYPE.WARNING];
    }
    if (minute == 1 || minute == 0) {
        timerBorderColor.value = setTimerColors[TIMER_STATUS_TYPE.ALERT];
        timerTextColor.value = setTimerColors[TIMER_STATUS_TYPE.ALERT];
    }
    if (minute <= 0) {
        buyingIsAvailable.value = false;
    }
}

</script>

<style src="./index.scss"></style>
<style lang="scss">
.payment__status {
    &.expired {
        display: flex;
        align-items: center;
        margin-top: 56px;
        flex-direction: column;

        .button {
            padding: 0 32px;
        }
    }
}
</style>