<template>
  <f7-page
      ref="self"
      :page-content="false"
      class="select-psystem-page"
      @page:beforein="PAGE_BEFORE_IN"
      @page:beforeout="PAGE_BEFORE_OUT"
  >
    <f7-navbar>
      <f7-nav-left>
        <a href="#" class="link icon-only back">
          <i class="icon-back icon"></i>
        </a>
      </f7-nav-left>
      <f7-nav-title>
        {{ $t('requisite-select-address-type.title') }}
      </f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <f7-page-content>
      <div class="address-type-content">
        <f7-list>
          <template v-for="addressType in localAddressTypes" :key="'address-type_' + addressType.id">
            <f7-list-item
                radio
                radio-icon="end"
                :title="addressType.name"
                name="addressType"
                :checked="addressType.selected"
                @change="change(addressType)"
            >
              <template #media>
                <i :class="addressType.icon" class="icon"></i>
              </template>
            </f7-list-item>
          </template>
        </f7-list>
      </div>
    </f7-page-content>
  </f7-page>
</template>

<script lang="ts">
import {computed, defineComponent, Ref, ref} from "@vue/runtime-core";
import GlobalRequisitesController from "../GlobalRequisitesController";
import {reactive} from "vue";
import {f7} from "framework7-vue";
import {useI18n} from 'vue-i18n'
import AddressType from "@/entities/AddressType";

interface LocalAddressType extends AddressType {
  selected: boolean
}

export default defineComponent({
  components: {},
  setup() {
    const ps = GlobalRequisitesController.getInstance();
    const {t} = useI18n({useScope: 'global'});
    const requisitePayload = ps.requisitePayload;
    const addressTypes: AddressType[] = [...ps.addressTypes.value];
    const localAddressTypes: LocalAddressType[] = addressTypes.map(el => {
      return {
        ...el,
        selected: el.id === requisitePayload.value.addressTypeId ? true : false
      }
    })
    const openIn = ps.openIn;

    return {
      localAddressTypes,
      openIn,
      t,
      ps,
    }
  },
  methods: {
    PAGE_BEFORE_IN() {},
    PAGE_BEFORE_OUT() {},
    back() {
      f7.views.current.router.back();
    },
    change(addressType: LocalAddressType) {
      this.ps.requisitePayload.value.addressTypeId = addressType.id;
      this.back();
    },
  },
});
</script>

<style lang="scss">
.select-psystem-page {
  &.without-navbar {
    --f7-navbar-height: 0px;
  }

  .page-content {
    padding-bottom: 16px;

    .title-wrapper {
      margin: 24px 16px;
      display: flex;
      align-items: center;
      gap: 16px;

      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: 0.337647px;
        color: #000000;
      }

      .icon-back-svg {
        cursor: pointer;
        width: 32px;
        height: 32px;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z' fill='white'/%3E%3Cpath d='M11.3262 16.1863C11.3337 16.4524 11.4314 16.6805 11.6343 16.8858L17.4818 22.6721C17.6546 22.8394 17.8576 22.9307 18.1056 22.9307C18.6092 22.9307 19 22.5353 19 22.0258C19 21.7825 18.9023 21.5544 18.7294 21.3795L13.4682 16.1863L18.7294 10.993C18.9023 10.8181 19 10.5976 19 10.3467C19 9.83723 18.6092 9.44184 18.1056 9.44184C17.8651 9.44184 17.6546 9.53308 17.4818 9.70036L11.6343 15.4943C11.4239 15.692 11.3262 15.9201 11.3262 16.1863Z' fill='%23818E95'/%3E%3C/svg%3E%0A");
      }
    }

    .address-type-content {

    }
  }
}
</style>
