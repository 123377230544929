import Operation from "@models/operations/Operation";
import {OperationType_N} from "@enums/OperationType_N";
import {plainToInstance} from "class-transformer";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {validateOrReject} from "class-validator";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
// import MainApiService from "@/services/api-service/MainApiService";

class MyPaymentsService {
    private _page: number;
    private _results: number;
    private _url: string;

    constructor() {
        this._page = 1;
        this._results = 10;
        this._url = `/api/mobile/client/operations`;
    }

    set page(page: number) {
        this._page = page;
    }

    get page() {
        return this._page;
    }

    set results(results: number) {
        this._results = results;
    }

    get results() {
        return this._results;
    }

    public async updateData() {
        const result = [];
        for (let i = 1; i <= this._page; i++) {
            const payload = {
                'page': i,
                'results': this._results,
            };
            // const requisites = await MainApiService.getInstance().get(this._url, payload);
            const requisites = await ServiceMainApi.of().get<{ data: any }>(this._url, payload);
            result.push(...requisites.data);
        }
        return result;
    }

    public async fetchData(config: { refresh: boolean }) {
        if (config.refresh) this.refreshPagination();

        const payload = {
            'page': this._page,
            'results': this._results,
        };

        // const response = await MainApiService.getInstance().get(this._url, payload);
        const response = await ServiceMainApi.of().get<{ data: any[], meta: any }>(this._url, payload);

        this._page = this._page + 1;

        const operations = await Promise.all(response.data.map(async (element: Operation) => {
            let operation = null;

            if (element.operationType === OperationType_N.purchase) {
                operation = plainToInstance(PurchaseOperation, element, {
                    excludeExtraneousValues: true,
                    excludePrefixes: ['_']
                });
                await validateOrReject(operation);
            } else {
                operation = element;
            }

            return operation;
        }));

        return {
            operations,
            meta: response.meta
        }
    }

    private refreshPagination() {
        this._page = 1;
        this._results = 10;
    }
}

export default MyPaymentsService;
