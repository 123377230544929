<template>
    <div class="withdrawal-view__content">
        <template v-if="withdrawal">
            <transition name="fade" mode="out-in">
                <template v-if="withdrawal.status === P_WITHDRAWAL_STATUS.CREATED">
                    <div class="wrapper-elements">
                        <WithdrawalStatusCreated
                            :withdrawal="withdrawal"
                            :timeText="timeText"
                            :percent="percent"
                            @cancelPayment="cancelFindAgent"
                        />
                    </div>
                </template>
                <template v-else-if="withdrawal.status === P_WITHDRAWAL_STATUS.ACCEPTED">
                    <div class="wrapper-elements">
                        <WithdrawalStatusAccepted
                            :withdrawal="withdrawal"
                            :timeText="timeText"
                            :percent="percent"
                        />
                    </div>
                </template>
                <template
                    v-else-if="withdrawal.status === P_WITHDRAWAL_STATUS.CANCELED || withdrawal.status === P_WITHDRAWAL_STATUS.EXPIRED">
                    <WithdrawalStatusCanceled :withdrawal="withdrawal"/>
                </template>
                <template v-else-if="withdrawal.status === P_WITHDRAWAL_STATUS.CONFIRMED">
                    <WithdrawalStatusConfirmend :withdrawal="withdrawal"/>
                </template>
                <template v-else-if="withdrawal.status === P_WITHDRAWAL_STATUS.ONAPPEAL">
                    <WithdrawalStatusOnappeal @toChat="toChat" :withdrawal="withdrawal" />
                </template>
                <template v-else-if="withdrawal.status === P_WITHDRAWAL_STATUS.CASHED">
                    <WithdrawalStatusCashed :withdrawal="withdrawal">
                        <template #timer>
                            <WithdrawalStatusTimer
                                :timeText="timeText"
                                :percent="percent"
                                :size="100"
                                :borderWidth="8"
                                :hiddenTimer="false"
                                :fontSize="28"
                                class="cashed"
                            />
                        </template>
                    </WithdrawalStatusCashed>
                </template>
            </transition>
        </template>
    </div>
</template>

<script setup lang="ts">
import {onMounted, PropType, toRef, UnwrapRef, watch} from "vue";
import PaymentStatusCreated from "./contents/PaymentStatusCreated.vue";
import PaymentStatusTimer from "./atomic/PaymentStatusTimer.vue";
import WithdrawalStatusTimer from "@/views/withdrawal-group/withdrawal/components/atomic/WithdrawalStatusTimer.vue";
import WithdrawalStatusCreated
    from "@/views/withdrawal-group/withdrawal/components/contents/WithdrawalStatusCreated.vue";
import PaymentStatusCanceled from "./contents/PaymentStatusCanceled.vue";
import PaymentStatusAccepted from "./contents/PaymentStatusAccepted.vue";
import PaymentStatusConfirmed from "./contents/PaymentStatusConfirmed.vue";
import PaymentStatusRejected from "./contents/PaymentStatusRejected.vue";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import PaymentTimerService, {
    ReactivePaymentPercent,
    ReactivePaymentTimeText
} from "@/services/operations/payment/PaymentTimerService";
import PaymentStatusCashed from "@/views/payment/components/contents/PaymentStatusCashed.vue";
import PaymentStatusOnappeal from "@/views/payment/components/contents/PaymentStatusOnappeal.vue";
import PaymentStatusExpired from "../components/contents/PaymentStatusExpired.vue";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import WithdrawalTimerService from "@/services/operations/withdrawal/WithdrawalTimerService";
import {P_WITHDRAWAL_STATUS} from "@/entities/enums/PWithdrawalStatus";
import WithdrawalStatusCanceled
    from "@/views/withdrawal-group/withdrawal/components/contents/WithdrawalStatusCanceled.vue";
import WithdrawalStatusAccepted
    from "@/views/withdrawal-group/withdrawal/components/contents/WithdrawalStatusAccepted.vue";
import WithdrawalStatusCashed from "@/views/withdrawal-group/withdrawal/components/contents/WithdrawalStatusCashed.vue";
import WithdrawalStatusConfirmend
    from "@/views/withdrawal-group/withdrawal/components/contents/WithdrawalStatusConfirmend.vue";
import WithdrawalStatusOnappeal
    from "@/views/withdrawal-group/withdrawal/components/contents/WithdrawalStatusOnappeal.vue";

const props = defineProps({
    withdrawal: {
        type: Object as PropType<WithdrawalOperation>,
        required: true
    },
    timerService: {
        type: Object as PropType<WithdrawalTimerService>,
        required: true
    }
});

const withdrawal = toRef(props, 'withdrawal');
const timerService: WithdrawalTimerService = props.timerService;
const timeText: ReactivePaymentTimeText = timerService.getTimeText;
const percent: ReactivePaymentPercent = timerService.getPercent;
const emits = defineEmits(['timerExpired', 'cancelPayment', 'toChat', 'recreatePayment']);
const recreatePayment = (callback: Function) => {
    emits('recreatePayment', callback);
}

function cancelFindAgent() {
    emits('cancelPayment');
}

const toChat = () => {
    emits('toChat');
}

onMounted(async () => {
    initTimerService();
});

watch(() => withdrawal.value!.status, () => {
    initTimerService();
});

watch(() => percent.value < 0, (statement) => {
    if(statement)
    emits('timerExpired');
});

const initTimerService = () => {
    if (withdrawal?.value?.status &&
        [
            P_WITHDRAWAL_STATUS.ACCEPTED,
            P_WITHDRAWAL_STATUS.CREATED,
            P_WITHDRAWAL_STATUS.ONAPPEAL,
            BuyingOperationStatus.CASHED,
        ].includes(withdrawal.value.status)) {
        timerService.start(withdrawal.value.status, withdrawal.value.updatedAt!);
    } else {
        timerService.stopIntervals();
    }
}
</script>

<style lang="scss">
.withdrawal-view__content {
    position: relative;

    .wrapper-elements {
        padding: 0 16px;
    }

    .cancel {
        margin-bottom: 16px;
    }
}
</style>