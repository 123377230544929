<template>
    <f7-nav-left>
        <a href="#" class="link action-icon" @click="closePage">
            <i class="icon i-svg-icon-close"></i>
        </a>
    </f7-nav-left>
    <f7-nav-title>
        <div class="display-flex flex-direction-column" v-if="withdrawal">
            <span class="id">{{ withdrawal.account }}</span>
            <div class="online-status" v-if="chatResource">
                <template v-if="chatResource!.agent.isOnline">
                    <i class="icon green"></i>
                    <p>Online</p>
                </template>
                <template v-else>
                    <i class="icon red"></i>
                    <p>Offline</p>
                </template>
            </div>
        </div>
    </f7-nav-title>
    <f7-nav-right>
        <div class="navbar-withdrawal-info" v-if="withdrawal">
            <p class="navbar-withdrawal-info__id">{{ withdrawal.id }}</p>
            <WithdrawalOperationStatus :payment="withdrawal" />
        </div>
    </f7-nav-right>
    <transition
        mode="out-in"
        :enter-active-class="withTransition ? 'slide-in__top-block' : ''"
        :leave-active-class="withTransition ? 'slide-out__top-block' : ''"
    >
        <f7-subnavbar @click="subnavbarEventClick" v-if="withdrawal && isAllowSubnavbar && showSubnavbar">
            <template v-if="withdrawal.status === BuyingOperationStatus.ONAPPEAL">
                {{ $t('g.finish-appeal') }}
            </template>
            <template v-else>
                {{ $t('g.call-admin') }}
            </template>
        </f7-subnavbar>
    </transition>
</template>

<script lang="ts" setup>
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import {computed, PropType, ref, toRef} from "vue";
import ChatResource from "@/entities/chat/ChatResource";
import Helper from "@/helpers/helper";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import {ToRef} from "@vue/runtime-core";
import {P_WITHDRAWAL_STATUS} from "@/entities/enums/PWithdrawalStatus";
import WithdrawalOperationStatus from "@components/atomic/operation-status/WithdrawalOperationStatus.vue";

const props = defineProps({
    withdrawal: {
        type: Object as PropType<WithdrawalOperation | null>,
        required: true
    },
    chatResource: {
        type: Object as PropType<ChatResource | null>,
        required: true
    }
});
const withdrawal = toRef(props, 'withdrawal');

const isAllowSubnavbar: ToRef<boolean> = computed(() => {
    return !!(withdrawal.value &&
        withdrawal.value?.status &&
        [
            P_WITHDRAWAL_STATUS.CASHED,
            P_WITHDRAWAL_STATUS.CONFIRMED,
            P_WITHDRAWAL_STATUS.ONAPPEAL,
        ].includes(withdrawal.value.status))
});
const showSubnavbar = ref(false);
const withTransition = ref(true);
const chatResource = toRef(props, 'chatResource');
const emits = defineEmits(['closePage', 'subnavbarEventClick']);
const closePage = () => {
    emits('closePage');
}
defineExpose({showSubnavbar})

const subnavbarEventClick = () => {
    emits('subnavbarEventClick');

}
</script>

<style lang="scss">
.chat-withdrawal-view__navbar {
    --f7-navbar-bg-color: #fff;
    --f7-subnavbar-height: 32px;
    --f7-subnavbar-bg-color: #fff;
    --f7-navbar-border-color: var(--grey4);

    &.with-subnavbar {
        &::after {
            transition: 0.5s background-color cubic-bezier(0.250, 0.460, 0.450, 0.940);
            content: '';
            position: absolute;
            background-color: var(--grey4);
            display: block;
            z-index: 15;
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 100%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
    }

    .online-status {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        @include text(12px, 400, #818E95, 14.4px);

        i {
            width: 6px;
            height: 6px;
            border-radius: 50%;

            &.green {
                background: #32BF61;
            }

            &.red {
                background: #818E95;
            }
        }
    }

    .right {
        min-width: 116px;
        justify-content: flex-end;
    }

    .subnavbar {
        --f7-subnavbar-border-color: var(--grey4);
        cursor: pointer;
        &:before {
            transition: 0.5s background-color cubic-bezier(0.250, 0.460, 0.450, 0.940);
            content: '';
            position: absolute;
            background-color: var(--grey4);
            display: block;
            z-index: 15;
            top: 0;
            right: 0;
            bottom: auto;
            left: auto;
            height: 1px;
            width: 100%;
            transform-origin: 50% 100%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }

        .subnavbar-inner {
            justify-content: center;
            width: 100%;
            @include text(12px, 500, var(--grey2), 14.4px);

            &.active-state {
                background-color: #00000026;
            }
        }
    }
}
</style>