<template>
    <div class="info-block">
        <i class="icon sloths-svg" :class="type"></i>
        <p class="title" v-if="title" v-html="title"></p>
        <p class="subtitle" v-if="subtitle" v-html="subtitle"></p>
        <slot name="button"></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import {ref, toRef} from "vue";
import {prop} from "dom7";
export default defineComponent({
    name: "InfoBlock.vue",
    props: [
        'type',
        'title',
        'subtitle',
        'btnEvent',
        'btnText',
        'btnClasses'
    ],
    setup(props: any) {
        const title = toRef(props, 'title');
        const subtitle = toRef(props, 'subtitle');
        return {
            loading: ref(false),
            title,
            subtitle,
            type: props.type,
            btnEvent: props.btnEvent,
            btnText: props.btnText,
            btnClasses: props.btnClasses
        }
    },
});
</script>

<style lang="scss">
.info-block {
    //margin-top: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
    .title {
        margin-top: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.41px;
        color: #818E95;
    }
    .subtitle {
        margin-top: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.41px;
        color: #ACB4BE;
        text-align: center;
    }
    .button {
        margin-top: 40px;
    }
}
</style>