import Account from "@/entities/Account";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {Expose, Type} from "class-transformer";
import LightPaymentPidResponseCredentials from "@models/responses/light-payment-pid/LightPaymentPidResponseCredentials";
import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";

export default class LightPaymentPidResponseError {
    @Expose({name: 'code'})
    private readonly _code: number;
    @Expose({name: 'data'})
    private readonly _data: any;

    public constructor(code: number, data: any) {
        this._code = code;
        this._data = data;
    }

    get code(): number {
        return this._code;
    }

    get data(): any {
        return this._data;
    }
}