<template>
    <f7-list dividers-ios strong-ios outline-ios class="withdrawal-requisites"
             v-if="withdrawal && withdrawal.requisites">
        <f7-list-item :header="$t('withdrawal.requisites.amount')">
            <template #title>
                <span v-format-number>{{ withdrawal.amount }} {{ withdrawal.currency.abbr }}</span>
            </template>
        </f7-list-item>
        <f7-list-item :header="$t('withdrawal.requisites.method')">
            <template #title>
                <div class="psystem-wrapper">
                    <img :src="withdrawal.psystem!.imgPath" alt="">
                    {{ withdrawal.psystem!.name }}
                </div>
            </template>
        </f7-list-item>
        <f7-list-item :header="$t('withdrawal.requisites.requisites')" @click="copy(withdrawal.requisites.address!)">
            <template #title>
                <div class="requisite-wrapper">
                    {{ withdrawal.requisites.address }}
                    <i class="display-block copy-svg"></i>
                </div>
            </template>
        </f7-list-item>
        <f7-list-item v-if="withdrawal.requisites.name && withdrawal.requisites.name.length > 0"
                      :header="$t('withdrawal.requisites.recipient')">
            <template #title>
                {{ withdrawal.requisites.name }}
            </template>
        </f7-list-item>
        <template v-if="withdrawal.requisites.comment && withdrawal.requisites.comment.length > 0">
            <f7-list-item :header="$t('withdrawal.requisites.comment')">
                <template #title>
                    {{ withdrawal.requisites.comment }}
                </template>
            </f7-list-item>
        </template>
        <f7-list-item :header="$t('withdrawal.requisites.createdAt')">
            <template #title>
                {{ withdrawal.getHumanCreateAt() }}
            </template>
        </f7-list-item>
        <f7-list-item :header="$t('withdrawal.requisites.id')">
            <template #title>
                {{ withdrawal.id }}
            </template>
        </f7-list-item>
    </f7-list>
</template>

<script setup lang="ts">
import {PropType, ToRef, toRef} from "vue";
import {f7} from "framework7-vue";
import {useI18n} from "vue-i18n";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";

const {t} = useI18n({useScope: 'global'});

const props = defineProps({
    withdrawal: {
        type: Object as PropType<WithdrawalOperation>,
        required: true
    },
});
const withdrawal: ToRef<WithdrawalOperation> = toRef(props, 'withdrawal');

function copy(value: string) {
    const aux = document.createElement("input");
    aux.setAttribute("value", value);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);

    f7.toast.create({
        text: t('g.copied'),
        position: 'top',
        closeTimeout: 1000,
    }).open();
}
</script>

<style lang="scss">
.withdrawal-requisites {
    width: 100%;
    --f7-list-item-padding-vertical: 12px;
    --f7-list-outline-border-color: var(--grey8);

    ul:before, ul:after {
        content: none !important;
    }

    .item-inner {
        padding-right: 16px;

        .item-title {
            @include text(16px, 500, var(--brown1), 19.2px);
            word-break: break-all;
            white-space: unset;

            .requisite-wrapper {
                display: flex;
                align-items: center;
                gap: 8px;

                i {
                    cursor: pointer;
                }
            }

            .psystem-wrapper {
                display: flex;
                align-items: center;
                gap: 4px;

                img {
                    height: 24px;
                    width: 24px;
                }

                line-height: 24px;
            }

            .item-header {
                @include text(12px, 400, var(--grey2), 14.4px);
                margin-bottom: 6px;
            }
        }
    }
}
</style>