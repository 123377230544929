import {Expose} from "class-transformer";

export default class Agent {
    @Expose({name: 'id'})
    private _id: number | null = null;

    @Expose({name: 'isOnline'})
    private _isOnline: boolean = false;

    @Expose({name: 'name'})
    private _name: string | null = null;

    @Expose({name: 'sex'})
    private _sex: 'male' | 'female' = 'male';

    get isOnline(): boolean {
        return this._isOnline;
    }
}