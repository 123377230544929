import {Expose} from "class-transformer";

export default class ProjectOperation {
    @Expose({name: 'name'})
    private _name: string | null = null;

    @Expose({name: 'slug'})
    private _slug: string | null = null;

    @Expose({name: 'logoUrl'})
    private _logoUrl: string | null = null;


    get name(): string | null {
        return this._name;
    }

    get slug(): string | null {
        return this._slug;
    }

    get logoUrl(): string | null {
        return this._logoUrl;
    }
}