<template>
    <f7-page class="tech-page" :page-content="false">
        <f7-navbar>
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="back">
                    <i class="icon-back icon svg-filter-blue1"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>{{ $t('g.tech.page.title') }}</f7-nav-title>
        </f7-navbar>
        <f7-page-content>
            <f7-block-title>{{ $t('g.tech.page.groups.network') }}</f7-block-title>
            <f7-list media-list dividers-ios strong-ios outline-ios no-chevron>
                <f7-list-item
                    v-if="techSettings.token"
                    title="t"
                    :text="techSettings.token"
                    @click="copy(techSettings.token)"
                    link="#"
                />
                <f7-list-item
                    v-if="techSettings.rt"
                    title="rt"
                    :text="techSettings.rt"
                    @click="copy(techSettings.rt)"
                    link="#"
                />
                <f7-list-item
                    v-if="techSettings.csp"
                    title="csp"
                    :text="techSettings.csp"
                    @click="copy(techSettings.csp)"
                    link="#"
                />
                <f7-list-item
                    v-if="techSettings.url"
                    title="url"
                    :text="techSettings.url"
                    @click="copy(techSettings.url)"
                    link="#"
                />
                <f7-list-item
                    v-if="techSettings.isFirebaseInitialized"
                    title="Firebase"
                    :text="techSettings.isFirebaseInitialized"
                    @click="copy(techSettings.isFirebaseInitialized)"
                    link="#"
                />
                <f7-list-item
                    v-if="techSettings.kodmobiUrl"
                    title="KodMobi URL"
                    :text="techSettings.kodmobiUrl"
                    @click="copy(techSettings.kodmobiUrl)"
                    link="#"
                />
                <f7-list-item
                    v-if="techSettings.projectUrl"
                    title="Project URL"
                    :text="techSettings.projectUrl"
                    @click="copy(techSettings.projectUrl)"
                    link="#"
                />
                <f7-list-item
                    v-if="techSettings.mainUrl"
                    title="Main URL"
                    :text="techSettings.mainUrl"
                    @click="copy(techSettings.mainUrl)"
                    link="#"
                />
            </f7-list>
            <f7-block-title>{{ $t('g.tech.page.groups.basic') }}</f7-block-title>
            <f7-list media-list dividers-ios strong-ios outline-ios no-chevron>
                <f7-list-item
                    v-if="techSettings.userAgent"
                    title="UserAgent"
                    :text="techSettings.userAgent.split(' ')[0]"
                    @click="copy(techSettings.userAgent)"
                    link="#"
                />
                <f7-list-item
                    v-if="techSettings.version"
                    title="Version"
                    :text="techSettings.version"
                    @click="copy(techSettings.version)"
                    link="#"
                />
            </f7-list>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts" setup>
import {useI18n} from "vue-i18n";
import {f7} from "framework7-vue";
import ViewTechController from "./ViewTechController";
import {copyWithToast} from "@/helpers/copy-clipboard";

const {t} = useI18n({useScope: 'global'});
const techSettings = ViewTechController.of().readSettings().getSettings();

function copy(value: string | String) {
    copyWithToast(value, t);
}

function back() {
    f7.view.main.router.back();
}
</script>

<style lang="scss">
.tech-page {
    .list .item-text {
        word-break: break-all;
    }
}
</style>
