<template>
    <f7-page class="find-agent-view" name="find-agent" :page-content="false" @page:beforein="vc.beforeIn">
        <f7-navbar>
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="vc.cancelPaymentWithBack">
                    <i class="icon icon-back"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                <div class="display-flex flex-direction-column">
                    <span class="id">{{ payment.amount }} {{ payment.currency.abbr }}</span>
                    <span class="date">{{ convertDatetime(payment.createdAt) }}</span>
                </div>
            </f7-nav-title>
        </f7-navbar>
        <f7-page-content>
            <div class="block">
                <f7-gauge
                    size="100"
                    border-width="4"
                    value-text-color="#007aff"
                    :value-text="timeText"
                    type="circle"
                    :value="percent"
                    value-font-size="31"
                    value-font-weight="400"
                    border-color="#007aff"
                ></f7-gauge>
                <span class="title">{{ $t("findAgent.title") }}</span>
                <span class="text">{{ $t("findAgent.text") }}</span>
                <span class="hint" v-html="$t('findAgent.hint')" />
            </div>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts">
import FindAgentController from "./ts/FindAgentController";
import {onMounted, computed} from "vue";
import store from "@target/core/store/store";
import Payment from "@/entities/Payment";

export default {
    setup() {
        const vc = FindAgentController.getInstance();
        // @ts-ignore
        window.controller = vc;

        const {timeText, percent} = vc;
        const payment = computed((): Payment => store.getters.payment.value);

        onMounted(() => {
            vc.viewDidLoad();
        })

        return {
            percent,
            timeText,
            payment,
            vc
        }
    },
    methods: {
        convertDatetime(dateString: string) {
            const date = new Date(dateString);
            const day = date.getDate();
            const dayString = day < 10 ? `0${day}` : day.toString();
            const month = date.getMonth();
            const monthString = month < 10 ? `0${month}` : month.toString();
            const year = date.getFullYear();
            const hour = date.getHours();
            const hourString = hour < 10 ? `0${hour}` : hour.toString();
            const minutes = date.getMinutes();
            const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();
            return `${dayString}.${monthString}.${year} ${hourString}:${minutesString}`;
        },
    },
    unmounted() {
        // @ts-ignore
        window.controller = undefined;
        this.vc.destructor();
    }
}
</script>

<style src="./index.scss" lang="scss"></style>
