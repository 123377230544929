<template>
    <f7-page
        class="view-payment-agents"
        :class="[{'view-like-popup': openIn === PageOpenIn.POPUP}]"
        ref="self"
        :page-content="false"
        @page:beforein="pageBeforeIn"
        @page:beforeout="pageBeforeOut"
    >
        <popup-navbar
            :title="popupTitle"
            v-if="openIn === PageOpenIn.POPUP"
            @on-left-click="toBack"
        >
        </popup-navbar>
        <f7-page-content>
            <transition name="fade" mode="out-in">
                <ViewPaymentCreateAgentsPaymentError
                    v-if="!paymentErrorWasShowed && paymentError && currency"
                    :payment-error="paymentError!"
                    :agent="agent"
                    :amount="amount"
                    :currency="currency"
                />
            </transition>
            <transition name="fade" mode="out-in">
                <template v-if="isAgentsLoading === false">
                    <template v-if="agents.length > 0 && currency">
                        <div class="view-payment-agents_agents-list">
                            <f7-block-title>{{ $t("payment-create-agents.agents") }}</f7-block-title>
                            <f7-list media-list dividers-ios strong-ios outline-ios no-chevron>
                                <f7-list-item
                                    v-if="!paymentError || (paymentError.code !== 10034 && paymentError.code !== 10033)"
                                    class="media-item_any-agent-button"
                                    :title="$t('payment-create-agents.select')"
                                    link="#"
                                    @click.stop="agentElementClick(null)"
                                />
                                <template v-for="agent in agents" :key="agent.id + ':' + agent.name">
                                    <f7-list-item
                                        :title="agent.name"
                                        link="#"
                                        @click.stop="agentElementClick(agent)"
                                    >
                                        <template #subtitle>
                                            <p>{{ $t("payment-create-agents.amountFrom") }}
                                                <span v-format-number="Math.ceil(agent.sumRanges.currency.min!)">{{
                                                        Math.ceil(agent.sumRanges.currency.min!)
                                                    }}</span> {{ currency.abbr }}
                                                {{ $t("payment-create-agents.amountTo") }}
                                                <span v-format-number="Math.floor(agent.sumRanges.currency.max!)">{{
                                                        Math.floor(agent.sumRanges.currency.max!)
                                                    }}</span> {{ currency.abbr }}
                                            </p>
                                        </template>
                                    </f7-list-item>
                                </template>
                            </f7-list>
                        </div>
                    </template>
                    <template v-else>
                        <SlothBlock
                            :title="$t('notFoundAgent.title')"
                            :subtitle="$t('notFoundAgent.text')"
                            :type="SLOTH_TYPE.RELAX"
                        >
                            <template #button>
                                <f7-button
                                    class="smoke-type view-payment-agents_refresh-btn"
                                    @click="fetchAgents(700)"
                                >
                                    {{ $t("payment-create-agents.update") }}
                                </f7-button>
                            </template>
                        </SlothBlock>
                    </template>
                </template>
                <template v-else>
                    <LoaderBlock/>
                </template>
            </transition>
        </f7-page-content>
    </f7-page>
</template>

<script setup lang="ts">
import {PageOpenIn} from "@enums/PageOpenIn";
import PopupNavbar from "@components/popup/popup-navbar/PopupNavbar.vue";
import {onMounted, ref, ToRef, watch} from "vue";
import RouterService from "@/services/RouterService";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import ErrorsService from "@/services/errors-service/ErrorsService";
import ViewPaymentCreateAgentsController from "@/views/payment-create-agents/ViewPaymentCreateAgentsController";
import LoaderBlock from "@components/atomic/loader-block/LoaderBlock.vue";
import Agent from "@models/agents/Agent";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import SlothBlock from "@components/messages/SlothBlock.vue";
import LightPaymentPidResponseError from "@models/responses/light-payment-pid/LightPaymentPidResponseError";
import ViewPaymentCreateAgentsPaymentError
    from "@/views/payment-create-agents/comps/ViewPaymentCreateAgentsPaymentError.vue";
import {useI18n} from "vue-i18n";
import NewPaymentController from "@/views/payment-create-config/NewPaymentController";

const routerService: RouterService = AppController.getInstance().routerService;
const openIn = ref(PageOpenIn.POPUP);
const {t} = useI18n({useScope: 'global'});
const popupTitle = ref(t("payment-create-agents.title"));
const isAgentsLoading = ref(true);
const controller = ViewPaymentCreateAgentsController.of();
const {agents, amount, agent, paymentError, paymentErrorWasShowed} = controller;
const vc = NewPaymentController.getInstance();
const {
    currency,
} = vc;

function toBack() {
    // if (paymentError.value?.code === 10034) return;
    routerService.toBack();
}

onMounted(() => {

});

function agentElementClick(agent: Agent | null) {
    controller.selectAgent(agent);
    routerService.toBack();
}

async function fetchAgents(delay: number = 0) {
    try {
        isAgentsLoading.value = true;
        await controller.fetchAndSetAgents();
    } catch (e: any) {
        ErrorsService.of().handle(e);
    } finally {
        setTimeout(() => {
            isAgentsLoading.value = false;
        }, delay);
    }
}

async function pageBeforeIn() {
    increaseHeightIfPopup();
    await fetchAgents();
}

function pageBeforeOut() {
    paymentErrorWasShowed.value = true;
    minimizeHeightIfPopup();
}

function increaseHeightIfPopup() {
    // checking is like popup
    const popupHTML = document.querySelector('.new-payment-popup');
    (popupHTML as HTMLDivElement).classList.add('height-100');
}

function minimizeHeightIfPopup() {
    // checking is like popup
    const popupHTML = document.querySelector('.new-payment-popup');
    (popupHTML as HTMLDivElement).classList.remove('height-100');
}
</script>

<style lang="scss">
.view-payment-agents {
    &.view-like-popup {
        padding-top: 24px;
    }

    &_refresh-btn {
        padding-left: 32px;
        padding-right: 32px;
    }

    .page-content {
        height: calc(100% - 35px);

        .info-block {
            margin-top: 56px;
        }

        .block-title {
            margin-top: 24px;

            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            color: #818E95;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .list {
        .media-item {
            &_any-agent-button {
                .item-content {
                    height: 56px;
                    padding: 0;

                    .item-inner {
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .item-title {
                            @include text(16px, 500, var(--blue1), 19);
                        }
                    }
                }
            }

            .item-title-row {
                & + .item-subtitle {
                    margin-top: 4px;

                }
            }

            .item-subtitle {
                @include text(14px, 400, var(--brown1));
            }
        }
    }
}
</style>