export const getTimeString = () => {
    const date = new Date();

    return {
        date: `${getDayString(date)}, ${getMonth(date)} ${date.getDate()}, `,
        time: `${getTime(date)}`
    };
}

function getDayString(date: Date) {
    let day = '';
    switch (date.getDay()) {
        case 1:
            day = 'Понедельник';
            break;
        case 2:
            day = 'Вторник';
            break;
        case 3:
            day = 'Среда';
            break;
        case 4:
            day = 'Четверг';
            break;
        case 5:
            day = 'Пятница';
            break;
        case 6:
            day = 'Суббота';
            break;
        case 7:
            day = 'Воскресенье';
            break;
    }
    return day;
}

function getMonth(date: Date) {
    const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декарь"
    ];
    return monthNames[date.getMonth()];
}

function getTime(date: Date) {
    const hours = date.getHours();
    const min = date.getMinutes();
    const hourString = (hours < 10) ? `0${hours}` : hours;
    const minString = (min < 10) ? `0${min}` : min;
    return `${hourString}:${minString}`;
}

export function getDateString(date: Date) {
    let day = date.getDate();
    let dayString = day < 10 ? `0${day}` : day;
    let month = date.getMonth() + 1;
    let monthString = month < 10 ? `0${month}` : month;
    let year = date.getFullYear();
    let hours = date.getHours();
    let hoursString = hours < 10 ? `0${hours}` : hours;
    let minutes = date.getMinutes();
    let minutesString = minutes < 10 ? `0${minutes}` : minutes;
    let seconds = date.getSeconds();
    let secondsString = seconds < 10 ? `0${seconds}` : seconds;

    return `${dayString}.${monthString}.${year} ${hoursString}:${minutesString}:${secondsString}`;
}
