import AbstractDocument from "@/services/firebase/firestore/documents/AbstractDocument";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import {Expose} from "class-transformer";

export default class DocumentOthers extends AbstractDocument {
    @Expose({name: 'toastHuntingAgentsShow'})
    private _toastHuntingAgentsShow: boolean | null = null;
    @Expose({name: 'projectApiUrl'})
    private _projectApiUrl: string | null = null;
    @Expose({name: 'mainApiUrl'})
    private _mainApiUrl: string | null = null;
    @Expose({name: 'kodmobiApiUrl'})
    private _kodmobiApiUrl: string | null = null;
    @Expose({name: 'kodmobiApiKey'})
    private _kodmobiApiKey: string | null = null;
    @Expose({name: 'pusherKey'})
    private _pusherKey: string | null = null;
    protected _type: DocumentTypes = DocumentTypes.OTHERS;

    constructor() {
        super();
    }

    get toastHuntingAgentsShow(): boolean | null {
        return this._toastHuntingAgentsShow;
    }

    get pusherKey(): string | null {
        return this._pusherKey;
    }

    get projectApiUrl(): string | null {
        return this._projectApiUrl;
    }

    get mainApiUrl(): string | null {
        return this._mainApiUrl;
    }

    get kodmobiApiKey(): string | null {
        return this._kodmobiApiKey;
    }

    get kodmobiApiUrl(): string | null {
        return this._kodmobiApiUrl;
    }
}