<template>
<f7-page :page-content="false" class="login-telegram-page page-full-height">
    <f7-navbar class="login-telegram-page-navbar">
        <f7-nav-left back-link=""></f7-nav-left>
        <f7-nav-title>
            <i class="icon sloth-logo-m-svg"/>
            <i class="icon logo-small-svg"/>
        </f7-nav-title>
        <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <f7-page-content>
        <f7-block>
            <div class="main-icon-wrapper">
                <i class="icon i-svg-telegram"></i>
                <p>Telegram</p>
            </div>

            <div class="help-message">
                <p :class="{ 'red': errorUpdatedTelegram }" v-html="helpMessage"></p>
            </div>

            <f7-button
                @click="SUBMIT"
                class="theme-type"
                :class="{ 'disabled': loading }"
                large
                fill
                preloader
                :loading="loading"
            >
                {{ $t("g.login.telegram.submit") }}
            </f7-button>
            <div
                class="check-status"
                @click="CHECK_STATUS"
                :class="{
                        'disabled': loadingStatus
                    }"
            >
                <template v-if="loadingStatus">
                    <f7-preloader color="white" :size="25"/>
                </template>
                <template v-else>
                    {{ $t("g.login.telegram.status") }}
                </template>
            </div>
            <div id="login-telegram-channel-page_cf-turnstile"></div>
        </f7-block>
    </f7-page-content>
    >
</f7-page>
</template>

<script lang="ts">
import {useI18n} from 'vue-i18n'
import LoginController from "../LoginController";
import {defineComponent, ref} from "@vue/runtime-core";
import {getDevice} from 'framework7';
// import { NetworkError } from "@/globals/errors/NetwortError"
// import { ServerError } from "@/targets/origin/ts/errors/ServerError";
import {f7} from "framework7-vue";
import {CaptchaActions} from "@/targets/main/views/login/types/CaptchaActions";
import {CaptchaPlace} from "@/targets/main/views/login/types/CaptchaPlace";

export default defineComponent({
    setup() {
        const {t} = useI18n({useScope: 'global'});
        const loading = ref(false);
        const loadingStatus = ref(false);
        const controller = LoginController.getInstance();
        const helpMessage = ref(t("g.login.telegram.help.first"));
        const errorUpdatedTelegram = ref(false);

        return {
            t,
            helpMessage,
            controller,
            loading,
            loadingStatus,
            errorUpdatedTelegram
        }
    },
    data: () => ({
        loading: false,
        controller: LoginController.getInstance(),
        channels: LoginController.getInstance().channels,
    }),
    methods: {
        async CHECK_STATUS() {
            try {
                this.loadingStatus = true;
                await this.controller.generateCaptchaIfEnabled(
                    CaptchaActions.SEND,
                    '#login-telegram-channel-page_cf-turnstile',
                    CaptchaPlace.LOGIN_CHANNEL_PAGE,
                )
                const response = await this.controller.requestManuallySendCode("telegram");
                if (!response.channel.isActive) {
                  this.errorUpdatedTelegram = true;
                  return
                }
                this.controller.setActiveChannelByName("telegram");
                f7.views.current.router.back();
            } catch (e: any) {
                this.helpMessage = e.error;
                this.errorUpdatedTelegram = true;
            } finally {
                this.loadingStatus = false;
            }
        },
        SUBMIT() {
            // LoginScreenController.getInstance().setChannels([], false, true);
            // const currentView = this.$f7.views.get(document.querySelector('#view-login-screen'));
            // currentView.router.back(currentView.router.history[0], { force: true });
            const channel = this.channels.find(el => el.type === 'telegram')!;
            const device = getDevice();

            const url = channel.link;
            if (getDevice().cordova) {
                // ts-ignore
                window.cordova.InAppBrowser.open(url, '_system');
            } else {
                try {
                    if (getDevice().ios) {
                        // @ts-ignore
                        window.webkit.messageHandlers.iosListener.postMessage(JSON.stringify({
                            "deeplink": url
                        }));
                    } else if (getDevice().android) {
                        // @ts-ignore
                        window.webInterface.onMessage(JSON.stringify({
                            "deeplink": url
                        }));
                    } else {
                        window.open(url, "_target");
                    }
                } catch (e) {
                    window.open(url, "_target");
                }
            }
        },
    }
});
</script>

<style lang="scss">
.login-telegram-page-navbar {
    --f7-navbar-inner-padding-left: 16px;
    --f7-navbar-inner-padding-right: 16px;

    .navbar-bg:after {
        content: none;
    }

    .left {
        span {
            color: var(--app-color);
            margin-left: 8px;
        }

        i:after {
            color: var(--app-color);
        }
    }

    .right {
        a {
            background: rgba(116, 116, 128, 0.08);
            border-radius: 50%;
            width: 30px !important;
            height: 30px;

            i {
                width: 12px;
                height: 12px;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.271767 10.3446C-0.0881408 10.7045 -0.0954858 11.3435 0.279112 11.7181C0.661056 12.0927 1.30008 12.0854 1.65264 11.7328L6.00092 7.38454L10.3419 11.7255C10.7091 12.0927 11.3408 12.0927 11.7154 11.7181C12.09 11.3362 12.09 10.7119 11.7227 10.3446L7.38179 6.00367L11.7227 1.6554C12.09 1.28815 12.0973 0.656471 11.7154 0.281873C11.3408 -0.0927245 10.7091 -0.0927245 10.3419 0.274528L6.00092 4.61546L1.65264 0.274528C1.30008 -0.0853795 0.653711 -0.10007 0.279112 0.281873C-0.0954858 0.656471 -0.0881408 1.30284 0.271767 1.6554L4.6127 6.00367L0.271767 10.3446Z' fill='%233C3C43' fill-opacity='0.6'/%3E%3C/svg%3E%0A");
            }
        }
    }
}

.login-telegram-page {
    .main-icon-wrapper {
        margin-top: 32px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: #000;
        display: flex;
        flex-direction: column;
        gap: 24px;

        i {
            border-radius: 12px;
            width: 48px;
            height: 48px;
        }

        //   .icon-telegram {
        //     width: 121px;
        //     height: 120px;
        //     background-image: url("data:image/svg+xml,%3Csvg width='121' height='120' viewBox='0 0 121 120' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_10948_191368)'%3E%3Crect x='0.5' width='120' height='120' rx='7' fill='%23039BE5'/%3E%3Cpath d='M23.6759 59.2505C30.3117 55.6094 37.7191 52.5705 44.6403 49.516C56.5473 44.5131 68.5016 39.5969 80.5766 35.02C82.9259 34.2401 87.1471 33.4774 87.5611 36.9456C87.3345 41.8547 86.4021 46.7351 85.7626 51.6156C84.1393 62.348 82.2632 73.0437 80.4337 83.7409C79.8034 87.304 75.3224 89.1486 72.4553 86.8683C65.5649 82.232 58.6216 77.6409 51.8192 72.8972C49.5909 70.6417 51.6572 67.4028 53.6473 65.7922C59.3224 60.2209 65.3409 55.4876 70.7195 49.6284C72.1703 46.1383 67.8836 49.0797 66.4696 49.9809C58.7003 55.3141 51.1212 60.973 42.93 65.6602C38.7459 67.9546 33.8693 65.9938 29.6871 64.7135C25.9372 63.167 20.4426 61.6087 23.6759 59.2505Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_10948_191368'%3E%3Crect x='0.5' width='120' height='120' rx='60' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        //   }
    }

    .help-message {
        margin-top: 16px;
        margin-bottom: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        p {
            @include text(15px, 400, var(--grey1), 18px);

            &.red {
                color: var(--red) !important;
            }
        }
    }

    .submit {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 17px 15px;
        height: 58px;
        background: var(--app-color);
        color: #FFFFFF;
        border-radius: 13px;
        text-transform: none;
        margin: 0px;
        width: 100%;

        &.active-state {
            opacity: 0.75;
        }
    }

    .check-status {
        @include text(17px, 500, var(--blue1), 21px);
        width: 100%;
        text-align: center;
        display: block;
        margin-top: 32px;
        cursor: pointer;

        .preloader-inner-line {
            background-color: var(--blue1);
        }

        & + #login-telegram-channel-page_cf-turnstile {
            margin-top: 24px;
        }

    }
}
</style>
