<template>
    <f7-page ref="self" :page-content="false" class="remove-account-page">
        <f7-navbar class="remove-account-page_navbar">
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="back">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                {{ $t('remove-account-page.navbar-title') }}
            </f7-nav-title>
            <f7-nav-right></f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <f7-block>
                <p class="title">{{ $t('remove-account-page.content.title') }}</p>
                <p class="text">{{ $t('remove-account-page.content.text1') }}</p>
                <ul>
                    <li class="text">{{ $t('remove-account-page.content.text2') }}</li>
                    <li class="text">{{ $t('remove-account-page.content.text3') }}</li>
                    <li class="text">{{ $t('remove-account-page.content.text4') }}</li>
                </ul>
                <button @click="removeAccountEvent">{{ $t('remove-account-page.content.submit') }}</button>
            </f7-block>
        </f7-page-content>
    </f7-page>
</template>

<script setup lang="ts">
import {f7} from "framework7-vue";
import {useI18n} from "vue-i18n";
// @ts-ignore
import store from "@target/core/store/store";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import ErrorsService from "@/services/errors-service/ErrorsService";

const {t} = useI18n({useScope: 'global'});
const back = () => {
    f7.view.main.router.back();
}
const removeAccountEvent = () => {
    f7.dialog.create({
        title: t('alerts.remove-account.title'),
        text: t('alerts.remove-account.text'),
        buttons: [
            {
                text: t('alerts.remove-account.no'),
            },
            {
                text: t('alerts.remove-account.yes'),
                onClick: async () => {
                    try {
                        f7.preloader.show();
                        await store.dispatch('removeAccount');
                        await AppController.getInstance().logout();
                    } catch (e: any) {
                        ErrorsService.of().handle(e);
                    } finally {
                        f7.preloader.hide();
                    }
                }
            }
        ]
    }).open();
}

</script>

<style lang="scss">
.remove-account-page {
    &_navbar {
        --f7-navbar-bg-color: #FFF;
    }
    --f7-block-margin-vertical: 24px;
    --f7-page-bg-color: #FFF;

    .title {
        @include text(20px, 700, var(--brown1), 24px);
        margin-bottom: 12px;
    }

    .text {
        @include text(14px, 400, var(--grey2), 16.8px);

        & + ul {
            margin-top: 8px;
        }
    }

    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 16px;

        & + button {
            margin-top: 32px;
        }

        li {
            list-style-type: none;

            &::before {
                content: "·";
                padding-right: 8px;
            }

            & + li {
                margin-top: 8px;
            }
        }
    }

    button {
        border: none;
        cursor: pointer;
        height: 44px;
        background: var(--red);
        border-radius: 8px;
        @include text(16px, 500, var(--white1), 19.2px);
    }
}
</style>
