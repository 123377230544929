<template>
<f7-popup class="channels-popup" swipe-to-close="to-bottom" @popup:open="POPUP_OPEN">
    <span class="tint"></span>
    <f7-page no-navbar :page-content="false">
        <f7-page-content>
            <div class="header-row">
                <p>{{ $t("g.login.channels.title") }}</p>
            </div>
            <div class="list links-list channels-row">
                <ul>
                    <li v-for="channel in channels" :key="channel.type">
                      <ChannelPopupListItem :channel="channel" @select="selectChannel"/>
                    </li>
                </ul>
            </div>
        </f7-page-content>
    </f7-page>
</f7-popup>
</template>
<script lang="ts">
// import { NetworkError } from "@/globals/errors/NetwortError";
// import { ServerError } from "@/globals/errors/ServerError";
import {defineComponent, ref} from "@vue/runtime-core";
import {f7} from "framework7-vue";
import LoginController, {Channel} from "../LoginController";
import TelegramPage from "../subpages/TelegramPage.vue";
import ChannelPopupListItem from "@/targets/main/views/login/components/atomic/ChannelPopupListItem.vue";

export default defineComponent({
    components: {
      ChannelPopupListItem,
        TelegramPage
    },
    emits: {
      selectChannel: (channel: Channel) => {
        return !!channel
      },
      close: (): void => {},
    },
    setup() {
        const controller = LoginController.getInstance();

        return {
            selectedChannel: controller.channel,
            channels: controller.channels,
            channel: controller.channel,
            controller,
        }
    },
    methods: {
        detectPopupHight() {
            const elHight = 64;
            const elsHight = this.channels.length * elHight;
            this.$el.style.height = `${28 + 32 + 32 + 16 + elsHight}px`;
            // // console.log(this.$el.style.height);
        },
        POPUP_OPEN() {
            this.detectPopupHight();
        },
        checkTelegram(channel: Channel) {
            if (channel.type === 'telegram' && !channel.isActive) {
                if (typeof f7.views.current.routes.find((r) => r.name === 'detect-telegram') === 'undefined') {
                    f7.views.current.routes.push({
                        name: 'detect-telegram',
                        path: '/auth/login/detect-telegram',
                        component: TelegramPage,
                    });
                }
                f7.views.current.router.navigate('/auth/login/detect-telegram');
                const popup = f7.popup.get(this.$el);
                popup.close();
                return false;
            }
            return true;
        },
        async selectChannel(channel: Channel) {
            if (!this.checkTelegram(channel)) {
              return;
            }

            this.$emit('selectChannel', channel)
            this.close()
        },
        close() {
            const popup = f7.popup.get(this.$el);
            popup.close();
            this.$emit('close');
        }
    }
})
;
</script>
<style lang="scss">
.popup {
    &.channels-popup {
        bottom: 0px !important;
        top: unset !important;
        border-top-left-radius: 16px !important;
        border-top-right-radius: 16px !important;
        padding-bottom: calc(0px + var(--f7-safe-area-bottom)) !important;
        max-height: 90vh !important;
        // height: fit-content !important;
        p {
            margin: 0;
        }

        .tint {
            width: 36px;
            height: 4px;
            position: absolute;
            top: 6px;
            z-index: 9999;
            background: var(--grey4);
            border-radius: 4px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%)
        }

        .page-content {
            overflow: hidden;
            background-color: var(--white1);
            padding: 32px 0px 32px 0px;

            .header-row {
                padding: 0px 16px 16px;
                @include text(24px, 700, var(--grey1), 28px);
            }

            .channels-row {
                margin: 0px;
                --f7-list-margin-vertical: 0px;

                ul {
                    &:before, &:after {
                        content: none;
                    }

                }
            }
        }
    }
}
</style>
