import {ServerResponseError} from "@/interfaces/errors";
import NoAgentsWithAmountData from "@models/errors/NoAgentsWithAmountData";

export default class NoAgentsWithAmountError extends ServerResponseError {
    private readonly _data: NoAgentsWithAmountData;
    protected readonly type: string = 'NO_AGENTS_WITH_AMOUNT_ERROR';

    constructor(message: string, code: number, data: NoAgentsWithAmountData) {
        super(message, code);

        this._data = data;

        Object.setPrototypeOf(this, NoAgentsWithAmountError.prototype);
    }

    get data() {
        return this._data;
    }
}