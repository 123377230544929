<template>
    <p class="payment-system-operation-sbp">
        (<i class="icon sbp-logo"></i>
        <p class="text">СБП</p>)
    </p>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
.payment-system-operation-sbp {
    display: flex;
    align-items: center;
    .sbp-logo {
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.37451 6.07129L5.92207 10.618V13.3889L3.37897 17.9267L3.37451 6.07129Z' fill='%235B57A2'/%3E%3Cpath d='M13.1519 8.96255L15.5376 7.502L20.4189 7.49902L13.1519 11.9461V8.96255Z' fill='%23D90751'/%3E%3Cpath d='M13.1388 6.04623L13.1521 12.0625L10.5986 10.4978V1.50098L13.1388 6.04623Z' fill='%23FAB718'/%3E%3Cpath d='M20.4192 7.49935L15.5379 7.50232L13.1388 6.04623L10.5986 1.50098L20.4192 7.49935Z' fill='%23ED6F26'/%3E%3Cpath d='M13.1521 17.9521V15.031L10.6001 13.4961V22.5003L13.1521 17.9521Z' fill='%2363B22F'/%3E%3Cpath d='M15.5318 16.5049L5.92207 10.618L3.37451 6.07129L20.4087 16.4989L15.5318 16.5049Z' fill='%231487C9'/%3E%3Cpath d='M10.6001 22.5004L13.1521 17.9521L15.5319 16.505L20.4088 16.499L10.6001 22.5004Z' fill='%23017F36'/%3E%3Cpath d='M3.37891 17.9274L10.6192 13.4967L8.18452 12.0049L5.92201 13.3896L3.37891 17.9274Z' fill='%23984995'/%3E%3C/svg%3E%0A");
    }
    .text {
        //margin-left: 4px;
        //@include text(20px, 500, var(--brown1), 24px);
    }
}
</style>