<template>
    <f7-page class="new-payment-psystems-view" @page:beforein="beforeIn" @page:beforeout="beforeOut">
        <div class="title-wrapper">
            <i class="icon icon-back-svg" @click="back"></i>
            <span class="title">{{ $t("newPayment.method") }}</span>
        </div>
        <div class="payments-wrapper">
            <div class="search__wrapper">
                <f7-list inset class="languages list-dividers list-strong">
                    <f7-list-input
                        type="text"
                        :placeholder="$t('newPayment.search')"
                        clear-button
                        @input="FILLTER_METHODS"
                    >
                    </f7-list-input>
                </f7-list>
            </div>
            <div v-for="paymentSystem in systems" :key="paymentSystem.id" class="psystem" @click="changePaymentSystem(paymentSystem)">
                <img :src="paymentSystem.imgPath">
                <span>{{paymentSystem.name}}</span>
                <i class="selected-svg icon" v-if="paymentSystem.selected"></i>
            </div>
        </div>
    </f7-page>
</template>

<script lang="ts">
import {f7} from "framework7-vue";
import PaymentsViewController from "./NewPaymentController";
import {computed, ref, Ref} from "vue";
import store from "@target/core/store/store";

export default {
    setup() {
        const vc = PaymentsViewController.getInstance();
        const {amount, paymentSystems, defaultAmounts, btnContinueLoading} = vc;
        const systems = ref(vc.paymentSystems.value);
        const selectedPaymentSystem = computed(() => vc.getSelectedPaymentSystem());
        const btnContinueDisabled = computed(() => (vc.amount as Ref).value.length === 0);
        const maxAmount = computed(() => {
            return vc.getSelectedPaymentSystem().maxAmount;
        });
        const currencySign = computed(() => store.getters['currencySign'].value);

        return {
            currencySign,
            vc,
            amount,
            paymentSystems,
            defaultAmounts,
            btnContinueLoading,
            selectedPaymentSystem,
            btnContinueDisabled,
            maxAmount,
            systems
        }
    },
    methods: {
        FILLTER_METHODS(e: Event) {
            const value = (e.target as HTMLInputElement).value;
            const regex = new RegExp(value, 'g');
            this.systems = this.paymentSystems.filter((sys: any) => sys.name.match(regex))
        },
        back() {
            f7.views.current.router.back();
        },
        beforeIn() {
            const popupHTML = document.querySelector('.new-payment-popup');
            (popupHTML as HTMLDivElement).classList.add('height-100');
        },
        beforeOut() {
            const popupHTML = document.querySelector('.new-payment-popup');
            (popupHTML as HTMLDivElement).classList.remove('height-100');
        },
        changePaymentSystem(psystem: number) {
            this.vc.selectPSystem(psystem);
            let min = this.vc.getMinAmount();

            // if (min < this.vc.selectedPaymentSystem.value.currencies[0].dSums[0]) {
            //     min = this.vc.selectedPaymentSystem.value.currencies[0].dSums[0];
            // }

            this.vc.setAmount = `${min} ${this.currencySign}`;
            this.back();
        }
    },
}
</script>

<style lang="scss">
.new-payment-psystems-view {
    .title-wrapper {
        margin: 24px 16px;
        display: flex;
        align-items: center;
        gap: 16px;

        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 120%;
            letter-spacing: 0.337647px;
            color: #000000;
        }
        .icon-back-svg {
            width: 32px;
            height: 32px;
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z' fill='white'/%3E%3Cpath d='M11.3262 16.1863C11.3337 16.4524 11.4314 16.6805 11.6343 16.8858L17.4818 22.6721C17.6546 22.8394 17.8576 22.9307 18.1056 22.9307C18.6092 22.9307 19 22.5353 19 22.0258C19 21.7825 18.9023 21.5544 18.7294 21.3795L13.4682 16.1863L18.7294 10.993C18.9023 10.8181 19 10.5976 19 10.3467C19 9.83723 18.6092 9.44184 18.1056 9.44184C17.8651 9.44184 17.6546 9.53308 17.4818 9.70036L11.6343 15.4943C11.4239 15.692 11.3262 15.9201 11.3262 16.1863Z' fill='%23818E95'/%3E%3C/svg%3E%0A");
        }
    }
    .payments-wrapper {
        position: relative;
        .search__wrapper {
            // padding: 1rem 0rem;
            .list {
                --f7-list-item-min-height: 2.375rem;
                --f7-input-bg-color: #EFEFF4;
                --f7-input-height: 2.375rem;
                // --f7-list-item-padding-horizontal: 0rem;
                margin: 0rem;
                .item-content {
                    padding-top: 1rem !important;
                    padding-bottom: 1rem;
                    .item-input-wrap {
                        input {
                            border-radius: 0.5rem;
                            padding: 0 1rem;
                        }
                        .input-clear-button {
                            right: 0.625rem;
                        }
                    }
                }
            }
        }

        &:after {
            content: "";
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 100%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
        &:before {
            content: "";
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: 0;
            right: auto;
            bottom: auto;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 0%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
        .psystem {
            height: 48px;
            background-color: #FFFFFF;
            display: flex;
            align-items: center;
            //width: calc(100% - 32px);
            padding: 0px 16px;


            &:active {
                background-color: var(--f7-list-link-pressed-bg-color);
            }

            img {
                width: 32px;
                height: 32px;
            }

            .selected-svg {
                width: 18px;
                height: 18px;
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.5537 11.2422L14.005 4.79084C14.3928 4.40305 15.0216 4.40305 15.4094 4.79084C15.7971 5.17863 15.7971 5.80736 15.4094 6.19515L7.5537 14.0508L2.99104 9.48814C2.60325 9.10035 2.60325 8.47162 2.99104 8.08383C3.37883 7.69604 4.00756 7.69604 4.39535 8.08383L7.5537 11.2422Z' fill='%23007AFF'/%3E%3C/svg%3E%0A");
            }

            span {
                margin-left: 16px;
                flex: 1;
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 22px;
                letter-spacing: -0.41px;
                color: #000000;
            }
        }

        .psystem + .psystem {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                background-color: var(--f7-list-border-color);
                display: block;
                z-index: 15;
                top: 0;
                right: auto;
                bottom: auto;
                left: 64px ;
                height: 1px;
                width: calc(100% - 64px);
                transform-origin: 50% 0%;
                transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
            }
        }
    }
}
</style>
