import MainApiService from "@/services/api-service/MainApiService";
import {plainToInstance} from "class-transformer";
import {validateOrReject} from "class-validator";
import {ToRef} from "@vue/runtime-core";
import {ref} from "vue";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import WithdrawalTimerService from "@/services/operations/withdrawal/WithdrawalTimerService";
import CreateWalletWithdrawalDto from "@/services/operations/withdrawal/dto/CreateWalletWithdrawalDto";
import WithdrawalRepository from "@/repositories/withdrawal/WithdrawalRepository";
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";

export default class WithdrawalService {
    private _withdrawal: ToRef<WithdrawalOperation | null> = ref(null);
    private _timerService: WithdrawalTimerService = new WithdrawalTimerService();
    private _isExternalCreated: ToRef<boolean> = ref(false);
    private _withdrawalRepository: WithdrawalRepository = new WithdrawalRepository();

    public withdrawalFactory(msid: string) {
        this._withdrawal.value = plainToInstance(WithdrawalOperation, new WithdrawalOperation(), {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        this._withdrawal.value.msid = msid;
    }

    public async create2P2Withdrawal(payload: createWithdrawalPayload) {
        // const response = await MainApiService.getInstance().post('/api/mobile/client/sales/create', payload);
        const response = await ServiceMainApi.of().post<{ data: any }>('/api/mobile/client/sales/create', payload);
        const withdrawal: WithdrawalOperation = plainToInstance(WithdrawalOperation, response.data, {
            // const withdrawal: WithdrawalOperation = plainToInstance(WithdrawalOperation, humps(WITHDRAWAL_CREATED_MOCK), {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        await validateOrReject(withdrawal);
        this._withdrawal.value = withdrawal;
    }

    public async createWithdrawal(createWalletWithdrawalDto: CreateWalletWithdrawalDto) {

        // const withdrawal: WithdrawalOperation = plainToInstance(WithdrawalOperation, response, {
        //     // const withdrawal: WithdrawalOperation = plainToInstance(WithdrawalOperation, humps(WITHDRAWAL_CREATED_MOCK), {
        //     excludeExtraneousValues: true,
        //     excludePrefixes: ['_']
        // });
        // await validateOrReject(withdrawal);
        // this._withdrawal.value = withdrawal;
    }

    public async createWalletWithdrawal(createWalletWithdrawalDto: CreateWalletWithdrawalDto): Promise<WithdrawalWalletOperation> {
        const response = await this._withdrawalRepository.createWallet(createWalletWithdrawalDto);
        return plainToInstance(WithdrawalWalletOperation, response, {
            excludeExtraneousValues: true
        }) as unknown as WithdrawalWalletOperation;
    }

    public async recreatePayment() {
        this.checkCreateWithdrawal();
        // const payload: createPaymentPayload = {
        //     amount: this._withdrawal.value!.amount!,
        //     psystem_id: this._withdrawal.value!.psystemObj?.id!,
        //     currency_id: this._withdrawal.value!.currency?.id!
        // }
        // await this.createPayment(payload);
    }

    public async cancelOldPayments() {
        await MainApiService.getInstance().patch('/api/mobile/client/payments/cancelOldCreated');
    }

    public async updateWithdrawal() {
        this.checkMSID();

        // const response = await MainApiService.getInstance().get(`/api/mobile/client/sales/get/${this._withdrawal.value!.msid}`);
        const response = await ServiceMainApi.of().get<{ data: any }>(`/api/mobile/client/sales/get/${this._withdrawal.value!.msid}`);
        const payment: WithdrawalOperation = plainToInstance(WithdrawalOperation, response.data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        await validateOrReject(payment);
        this._withdrawal.value = payment;
    }

    public async toAppealWithdrawal() {
        this.checkMSID();

        const response = await MainApiService.getInstance().patch(`/api/mobile/client/sales/appeal`, {
            msid: this._withdrawal.value!.msid
        });
        const payment: WithdrawalOperation = plainToInstance(WithdrawalOperation, response, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        await validateOrReject(payment);
        this._withdrawal.value = payment;
    }

    public async fetchWithdrawal(payload: fetchWithdrawalPayload) {
        // const response = await MainApiService.getInstance().get(`/api/mobile/client/sales/get/${payload.msid}`);
        const response = await ServiceMainApi.of().get<{ data: any }>(`/api/mobile/client/sales/get/${payload.msid}`);
        const withdrawal: WithdrawalOperation = plainToInstance(WithdrawalOperation, response.data, {
            // const withdrawal: WithdrawalOperation = plainToInstance(WithdrawalOperation, humps(WITHDRAWAL_CREATED_MOCK), {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        await validateOrReject(withdrawal);
        this._withdrawal.value = withdrawal;
    }

    public async cancelWithdrawal() {
        this.checkMSID();

        // await MainApiService.getInstance().patch(`/api/mobile/client/sales/cancel`, {
        //     msid: this._withdrawal.value!.msid
        // });
        await ServiceMainApi.of().patch(`/api/mobile/client/sales/cancel`, {
            msid: this._withdrawal.value!.msid
        });
    }

    public async confirmWithdrawal() {
        this.checkMSID();

        // const response: object = await MainApiService.getInstance().patch(`/api/mobile/client/sales/confirm`, {
        const response: object = await ServiceMainApi.of().patch(`/api/mobile/client/sales/confirm`, {
            msid: this._withdrawal.value!.msid
        });

        const payment: WithdrawalOperation = plainToInstance(WithdrawalOperation, response, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        await validateOrReject(payment);
        this._withdrawal.value = payment;
    }

    public reset() {
        this._withdrawal.value = null;
        this._timerService.stopIntervals();
    }

    set withdrawal(value: WithdrawalOperation | null) {
        this._withdrawal.value = value;
    }

    get withdrawal(): ToRef<WithdrawalOperation | null> {
        return this._withdrawal;
    }

    get timerService(): WithdrawalTimerService {
        return this._timerService;
    }

    get isExternalCreated(): ToRef<boolean> {
        return this._isExternalCreated;
    }

    set isExternalCreated(value: ToRef<boolean>) {
        this._isExternalCreated = value;
    }

    private checkMSID() {
        if (this._withdrawal.value === null) throw new Error(`[${this.constructor.name}]: payment is null`);
        if (this._withdrawal.value.msid === null) throw new Error(`[${this.constructor.name}]: payment msid is null`);
    }

    private checkCreateWithdrawal() {
        if (this._withdrawal.value === null) throw new Error(`[${this.constructor.name}]: payment is null`);
        if (this._withdrawal.value.msid === null) throw new Error(`[${this.constructor.name}]: payment msid is null`);
        // if (this._withdrawal.value.psystemObj === null) throw new Error(`[${this.constructor.name}]: payment psystemObj is null`);
        // if (this._withdrawal.value.psystemObj.id === null) throw new Error(`[${this.constructor.name}]: payment psystemObj.id is null`);
        if (this._withdrawal.value.currency === null) throw new Error(`[${this.constructor.name}]: payment currency is null`);
        if (this._withdrawal.value.currency.id === null) throw new Error(`[${this.constructor.name}]: payment currency.id is null`);
    }
}

export type createWithdrawalPayload = {
    amount: number,
    requisite_id: number
}

export type fetchWithdrawalPayload = {
    msid: string
}
