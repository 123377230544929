<template>
    <f7-block class="requisite-page_requisite-target-types mt-0 mb-6">
        <requisite-page-target-type :targetType="controller.values[0]" @on-select-type="handleSelectRequisiteType" />
        <requisite-page-target-type :targetType="controller.values[1]" @on-select-type="handleSelectRequisiteType" />
    </f7-block>
</template>

<script setup lang="ts">

import RequisitePageTargetType from "@/views/requisites-group/requisite/components/RequisitePageTargetType.vue";
import {useI18n} from "vue-i18n";
import RequisiteTargetTypes from "@/views/requisites-group/requisite/types/RequisiteTargetTypes";
import {computed, PropType, reactive} from "vue";
import RequisiteTargetTypesController from "@/views/requisites-group/requisite/types/RequisiteTargetTypesController";

const {t} = useI18n({useScope: 'global'});
const emits = defineEmits(["onSelectType"]);
const props = defineProps({
    controller: {
        type: Object as PropType<RequisiteTargetTypesController>,
        required: true
    }
});
const controller = props.controller;
function handleSelectRequisiteType(requisiteType: RequisiteTargetTypes) {
    emits("onSelectType", requisiteType);
}
</script>

<style scoped lang="scss">
.requisite-page_requisite-target-types {
    margin-top: 24px;
    display: flex;
    gap: 12px;
}
</style>