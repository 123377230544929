import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";
import {Expose, Type} from "class-transformer";

export default class NoAgentsWithAmountData {
    @Expose({name: 'ranges'})
    @Type(() => AgentsAmountRanges)
    private _ranges: AgentsAmountRanges | null = null;

    get ranges() {
        return this._ranges;
    }
}