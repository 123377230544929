import { createI18n } from 'vue-i18n'
import en from './en.json'
import ru from './ru.json'


const languages = {
    en,
    ru,
}
const messages = Object.assign(languages)

const i18n = createI18n({
    legacy: false,
    locale: 'ru',
    fallbackLocale: 'ru',
    globalInjection: true,
    messages,
})

// @ts-ignore
window.i18n = i18n

export default i18n;
