<template>
    <div class="view-payment-config_agent-button">
        <f7-block-title>
            <p>{{ $t("payment-create-config.agent") }}</p>
        </f7-block-title>
        <f7-list dividers-ios strong-ios outline-ios>
            <f7-list-item link="#" @click="eventClickByAgentElement">
                <template #title>
                    <p v-if="agent">{{ agent.name }}
                        <span class="view-payment-config_agent-button_agent-range">
                            (<span v-format-number="Math.ceil(agent.sumRanges.currency.min!)">{{
                                Math.ceil(agent.sumRanges.currency.min!)
                            }}</span> {{ currency.abbr }}
                            -
                            <span v-format-number="Math.floor(agent.sumRanges.currency.max!)">{{
                                    Math.floor(agent.sumRanges.currency.max!)
                                }}</span> {{ currency.abbr }})
                        </span>
                    </p>
                    <p v-else>{{ $t("payment-create-config.any-agent") }}</p>
                </template>
            </f7-list-item>
        </f7-list>
    </div>
</template>

<script setup lang="ts">
import {computed, PropType, ref, toRef, ToRef} from "vue";
import Agent from "@models/agents/Agent";
import Currency from "@/entities/Currency";

const emits = defineEmits(["toSelectAgent"]);
const props = defineProps({
    agent: {
        type: Object as PropType<Agent | null>,
        required: true
    },
    currency: {
        type: Object as PropType<Currency>,
        required: true
    },
});
const agent = toRef(props, 'agent');
const currency = toRef(props, 'currency');

function eventClickByAgentElement() {
    emits("toSelectAgent");
}
</script>


<style lang="scss">
.view-payment-config_agent-button {
    margin-top: 24px;
    --f7-block-margin-vertical: 0;
    --f7-list-item-padding-vertical: 15.5px !important;

    &_agent-range {
        color: var(--grey2);
    }

    .block-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            color: #818E95;
        }
    }

    .item-link {
        .item-title {
            @include text(16px, 500, var(--brown1), 19px);
        }
    }
}
</style>