import {createStore} from 'framework7/lite';
import StoreStateInterface from "@/interfaces/StoreStateInterface";
import MainApiService from "@/services/api-service/MainApiService";
import Message from "@/entities/Message";
import Account from "@/entities/Account";
import StoreGettersInterface from "@/interfaces/StoreGettertsInterface";
import PaymentSystem from "@/entities/PaymentSystem";
import Currency from "@/entities/Currency";
import Payment from "@/entities/Payment";
import {f7} from "framework7-vue";

import MyPaymentsService from "@/services/operations/MyPaymentsService";
import ProjectApiService from '@/targets/main/services/project/projectApiService';
import Rates from '@/entities/Rates';
import PaymentWithdrawal from '@/entities/PaymentWithdrawal';
import {P_WITHDRAWAL_STATUS} from '@/entities/enums/PWithdrawalStatus';
import PaymentIncome from "@/entities/PaymentIncome";
import humps from "lodash-humps-ts";
import PaymentIncomeOperation from "@models/operations/PaymentIncomeOperation";
import {plainToInstance} from "class-transformer";
import {validateOrReject} from "class-validator";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import * as Sentry from "@sentry/vue";

const state: StoreStateInterface = {
    client: undefined,
    account: undefined,
    paymentSystems: undefined,
    currencies: undefined,
    message: undefined,
    payment: undefined,
    paymentWithdrawal: undefined,
    paymentIncome: undefined,
    myPayments: undefined,
    rates: undefined
};

const myPaymentsService: MyPaymentsService = new MyPaymentsService();

const getters: StoreGettersInterface = {
    rates(context: any): Rates {
        return context.state.rates;
    },
    paymentSystems(context: any): Array<PaymentSystem> {
        return context.state.paymentSystems;
    },
    message(context: any): Message {
        return context.state.message;
    },
    account(context: any): Account {
        return context.state.account;
    },
    client(context: any): Account {
        return context.state.client;
    },
    currencies(context: any): Array<Currency> {
        return context.state.currencies;
    },
    currencySign(context: any): string {
        return context.state.account?.currency.abbr;
    },
    payment(context: any): Payment {
        return context.state.payment;
    },
    paymentWithdrawal(context: any): PaymentWithdrawal {
        return context.state.paymentWithdrawal;
    },
    paymentIncome(context: any): PaymentIncome {
        return context.state.paymentIncome;
    },
    myPayments(context: any) {
        return context.state.myPayments;
    },
};

const store = createStore({
    state: state,
    getters: getters,
    actions: {
        reset(context: any) {
            context.state.currencies = undefined;
            context.state.paymentSystems = undefined;
            context.state.account = undefined;
            context.state.client = undefined;
            context.state.myPayments = undefined;
        },
        resetPaymentSystems(context: any) {
            context.state.paymentSystems = undefined;
        },
        async fetchPaymentIncome(context: any, payment: PaymentIncome) {
            const operation: PaymentIncomeOperation = plainToInstance(PaymentIncomeOperation, payment, {
                excludeExtraneousValues: true,
                excludePrefixes: ['_']
            });
            await validateOrReject(operation);
            context.state.paymentIncome = operation;
        },
        manuallyUpdateMyPayments(context: any, payments: PaymentWithdrawal[] | Payment[]) {
            context.state.myPayments = [];
            context.state.myPayments = payments;
        },
        async checkTransactionStatus(context: any) {
            //TODO Используется в сокетах(важный метод) в будущем скорее всего нужно его вынести
            try {
                const payment = context.state.payment;
                if (typeof payment === 'undefined') return false;
                // const response = await MainApiService.getInstance().get(`/api/mobile/client/payments/get/${payment.msid}`);
                const response = await ServiceMainApi.of().get<{ data: any }>(`/api/mobile/client/payments/get/${payment.msid}`);
                const data = response.data;
                if (data.requisites !== null && data.account !== null) {
                    if (data.status === 'CONFIRMED') {
                        await context.dispatch('setPayment', data);
                        f7.view.main.router.navigate('/payment');
                    }
                    if (data.status === 'ACCEPTED') {
                        await context.dispatch('setPayment', data);
                        f7.view.main.router.navigate('/payment');
                    }
                    if (data.status === 'ACTIVATED') {
                        await context.dispatch('fetchPayment', null);
                        f7.view.main.router.navigate('/payment');
                    }
                    if (data.status === 'REJECTED') {
                        await context.dispatch('fetchPayment', null);
                    }
                } else {
                    await context.dispatch('fetchPayment', null);
                }
            } catch (e: any) {
                throw new Error(`Код ответа: ${e.code}\nСообщение: ${e.message}`);
            }
        },
        updateAccountBalance(context: any, data: any) {
            const balance = humps(data.balance);
            balance.amountC = Math.round(balance.amountC);
            const account = {...context.state.account};
            account.balance = balance;

            context.state.account = account;
        },

        // Account
        async removeAccount() {
            // await MainApiService.getInstance().delete('/api/mobile/client');
            await ServiceMainApi.of().delete('/api/mobile/client');
        },
        async fetchAccount(context: any) {
            // const response = await MainApiService.getInstance().get('/api/mobile/client');
            const response = await ServiceMainApi.of().get<{ data: Account }>('/api/mobile/client');
            const account = response.data;
            // @ts-ignore
            account.balance.amountC = Math.round(account.balance.amountC);
            context.state.account = account;
            const root = document.getElementsByTagName('html')[0];
            root.classList.add(account.project.slug);
            return account;
        },
        async fetchClientProfile(context: any) {
            const response: any = await ProjectApiService.getInstance().get('/api/user');
            context.state.client = response.data;
            return response.data;
        },
        async patchClientCurrency(context: any, payload: { currency: string }) {
            const response: any = await ProjectApiService.getInstance().get(`/api/user/currency/change`, payload);
            context.state.client = response.data;
        },
        async patchClientData(context: any, payload: any) {
            const resposnse: any = await ProjectApiService.getInstance().post(`/api/user/data/patch`, payload);
            context.state.client = resposnse.data;
        },
        async patchAccountData(context: any, payload: any) {
            // const response: any = await MainApiService.getInstance().patch("/api/mobile/client", payload);
            const response = await ServiceMainApi.of().patch<{ data: any }>("/api/mobile/client", payload);
            context.state.account = response.data;
        },
        async fetchRates(context: any) {
            // const response: any = await MainApiService.getInstance().get("/api/mobile/client/rates");
            const response = await ServiceMainApi.of().get<{ data: any }>("/api/mobile/client/rates");
            context.state.rates = response.data;
        },
        async fetchPayment(context: any, payload: { msid: string }) {
            if (payload === null) payload = context.state.payment;
            // const response = await MainApiService.getInstance().get(`/api/mobile/client/payments/get/${payload.msid}`);
            const response = await ServiceMainApi.of().get<{data: any}>(`/api/mobile/client/payments/get/${payload.msid}`);
            context.state.payment = response.data;
            return response.data;
        },
        //TODO возможно Deprecated, not used
        async checkSystemReadyToPay(context: any) {
            const response = await MainApiService.getInstance().get('/api/mobile/client/readyToPay');
            return response.data;
        },
        //TODO возможно Deprecated, not used
        async cancelPayments(context: any) {
            await MainApiService.getInstance().patch('/api/mobile/client/payments/cancelOldCreated');
        },
        async fetchPaymentSystems(context: any) {
            let response = await MainApiService.getInstance().get('/api/mobile/client/psystems');
            context.state.paymentSystems = response.data;
        },
        async fetchCurrencies(context: any) {
            const response = await MainApiService.getInstance().get('/api/mobile/client/currencies');
            context.state.currencies = response.data;
        },
        //TODO возможно Deprecated
        async updatePayments(context: any) {
            const config = {refresh: false, update: true};
            let response = await myPaymentsService.updateData();
            context.state.myPayments = response;
        },
        //TODO возможно Deprecated
        async updatePayment(context: any, payment: Payment) {
            if (payment === null) payment = context.state.payment;
            const p = context.state.myPayments.findIndex((el: Payment) => el.msid === payment.msid);
            if (p !== -1) {
                const newArr = context.state.myPayments;
                newArr[p] = payment;
                context.state.myPayments = newArr;
            }
            // // console.log(context.state.myPayments, payment)
        },
        async fetchMyPayments(context: any, config: { refresh: boolean }) {
            let response: any = await myPaymentsService.fetchData(config);

            if (config.refresh) {
                context.state.myPayments = response.operations;
                return response;
            }

            if (typeof context.state.myPayments === "undefined") {
                context.state.myPayments = [];
            } else {
                context.state.myPayments = [
                    ...context.state.myPayments,
                    ...response.operations
                ];
            }
            return response;
        },
        //TODO возможно Deprecated
        async createPaymentRequest(context: any, payload: any) {
            context.state.payment = await MainApiService.getInstance().post('/api/mobile/client/payments/create', payload);
        },
        //TODO возможно Deprecated
        async activatePromo(context: any, payload: object) {
            await MainApiService.getInstance().post('/api/mobile/client/payments/activate', payload);
        },
        async cancelPaymentRequest(context: any) {
            const payment: Payment = context.state.payment;
            await MainApiService.getInstance().patch(`/api/mobile/client/payments/cancel`, {msid: payment.msid});
        },
        async cancelPaymentRequestWithExpired(context: any) {
            const payment: Payment = context.state.payment;
            await MainApiService.getInstance().patch(`/api/mobile/client/payments/cancel`, {
                msid: payment.msid,
                expired: true
            });
        },
        async cancelPaymentWithdrawalRequest(context: any) {
            const paymentWithdrawal: any = context.state.paymentWithdrawal;
            await MainApiService.getInstance().patch(`/api/mobile/client/sales/cancel`, {msid: paymentWithdrawal.msid});
        },
        async confirmPaymentRequest(context: any) {
            const payment: Payment = context.state.payment;
            await MainApiService.getInstance().post(`/api/mobile/client/payments/confirm`, {msid: payment.msid});
        },
        setPayment(context: any, payment: Payment) {
            context.state.payment = payment;
        },
        setMessage(context: any, message: Message) {
            context.state.message = message;
        },

        // Withdrawal methods
        //TODO возможно Deprecated
        async createWithdrawalPaymentRequest(context: any, payload: PaymentWithdrawal) {
            context.state.paymentWithdrawal = await MainApiService.getInstance().post('/api/mobile/client/sales/create', payload);
        },
        setPaymentWithdrawal(context: any, payment: PaymentWithdrawal) {
            context.state.paymentWithdrawal = payment;
        },
        async checkWithdrawalStatus(context: any) {
            try {
                const payment = context.state.paymentWithdrawal;
                if (typeof payment === 'undefined') return false;
                const response = await MainApiService.getInstance().get(`/api/mobile/client/sales/get/${payment.msid}`);
                await context.dispatch('setPaymentWithdrawal', response.data);
            } catch (e: any) {
                throw new Error(`Код ответа: ${e.code}\nСообщение: ${e.message}`);
            }
        },
        //TODO возможно Deprecated
        async fetchPaymentWithdrwawal(context: any, payload: { msid: string }) {
            if (payload === null) payload = context.state.payment;
            const response = await MainApiService.getInstance().get(`/api/mobile/client/sales/get/${payload.msid}`);
            context.state.paymentWithdrawal = response.data;
            // // console.log('context: ', context.state.payment);
            return response.data;
        },
        async confirmPaymentWithdrawal(context: any, payload: { msid: string }) {
            const url = `/api/mobile/client/sales/confirm`;
            context.state.paymentWithdrawal = await MainApiService.getInstance().patch(url, {msid: payload.msid});
        },
        //TODO возможно Deprecated
        async openAppelationPaymentWithdrawal(context: any, payload: { msid: string }) {
            const url = `/api/mobile/client/sales/appeal`;
            context.state.paymentWithdrawal = await MainApiService.getInstance().patch(url, {msid: payload.msid});
            // console.log(context.state.paymentWithdrawal);
        },
        //TODO возможно Deprecated
        async checkWPithdrawalStatus(context: any) {
            try {
                const paymentWithdrawal = context.state.paymentWithdrawal as PaymentWithdrawal;
                if (typeof paymentWithdrawal === "undefined") return false;
                const response = await MainApiService.getInstance().get(`/api/mobile/client/sales/get/${paymentWithdrawal.msid}`);
                const data: PaymentWithdrawal = response.data;
                if (data.account !== null) {
                    if (data.status === P_WITHDRAWAL_STATUS.CONFIRMED) {
                        await context.dispatch('setPaymentWithdrawal', data);
                        f7.views.main.router.navigate('/withdrawal');
                    }
                    if (data.status === P_WITHDRAWAL_STATUS.ACCEPTED) {
                        await context.dispatch('setPaymentWithdrawal', data);
                        const popupEl: HTMLDivElement = document.querySelector('.withdrawal-popup')!;
                        if (popupEl) {
                            f7.popup.get(popupEl).close();
                        }
                        f7.views.main.router.navigate('/withdrawal');
                    }
                } else {
                    if (data.status === P_WITHDRAWAL_STATUS.CANCELED) {
                        // @ts-ignore
                        window.controller.destructor();
                        const popupEl: HTMLDivElement = document.querySelector('.withdrawal-popup')!;
                        f7.popup.get(popupEl).close();
                        console.log('strange#1')
                        const el: HTMLDivElement = document.querySelector('.no-agents-sheet')!;
                        const sheet = f7.sheet.create({el});
                        sheet.open();
                    }
                }
            } catch (e: any) {
                throw new Error(`Код ответа: ${e.code}\nСообщение: ${e.message}`);
            }
        },

        /* Mock data */
        setPaymentMock(context: any, payment: Payment) {
            context.state.payment = payment;
        },
        fetchAccountMock(context: any, account: Account) {
            context.state.account = account;
        },
        fetchPaymentSystemsMock(context: any, paymentSystems: PaymentSystem[]) {
            context.state.paymentSystems = paymentSystems;
        },
        fetchCurrenciesMock(context: any, currencies: Currency[]) {
            context.state.currencies = currencies;
        },
    },
})
export default store;
