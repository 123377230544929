<template>
  <f7-page
      ref="self"
      :page-content="false"
      class="login-code-page"
      @page:beforein="PAGE_BEFORE_IN"
      @page:afterin="PAGE_AFTER_IN"
  >
    <f7-navbar class="login-code-navbar">
      <f7-nav-left back-link></f7-nav-left>
      <f7-nav-title>
        <i class="icon sloth-logo-m-svg"/>
        <i class="icon logo-small-svg"/>
      </f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <f7-page-content>
      <f7-block>
        <PageHeadline :title="headline.title" :text="headline.text"/>
        <div class="form" :class="{'error': error}">
          <template v-if="kodmobiSettings?.code.letters">
            <code-form-input ref="symbols-row" @onCheckCode="checkCode"/>
          </template>
          <template v-else>
            <div class="form-input-wrapper" ref="form-input-wrapper">
              <div class="channel" @click="openChannelsPopup">
                <i class="icon" :class="`i-svg-${channel!.type}`"></i>
              </div>
              <template v-if="kodmobiSettings?.code.length === 6">
                <code-form-six-inputs
                    ref="symbols-row"
                    :error="error"
                    @setError="setError"
                    @onCheckCode="checkCode"
                />
              </template>
              <template v-else>
                <code-form-four-inputs
                    ref="symbols-row"
                    :error="error"
                    @setError="setError"
                    @onCheckCode="checkCode"
                />
              </template>
            </div>
          </template>

          <div class="error-block">
            <p class="error-message" v-if="error">{{ error.message }}</p>
          </div>
          <div class="action-block">
            <div v-if="!loading" class="action-links-wrapper">
              <p class="action-link" @click="openChannelsPopup">
                {{ $t("g.login.step2.button") }}
              </p>

              <p class="action-link small" :class="{'disabled': sessionTimerText}" @click="requestNewCode">
                {{ $t("g.login.step2.sendCodeAgain") }}
                <span v-if="sessionTimerText">({{sessionTimerText}})</span>
              </p>
            </div>

            <f7-preloader v-else/>
          </div>
        </div>

        <div id="login-enter-code-page_cf-turnstile"></div>
      </f7-block>
    </f7-page-content>

    <ChannelsPopup
        ref="channels-popup"
        @selectChannel="handleChannelSelect"
    />
  </f7-page>
</template>
<script lang="ts">
import {useI18n} from 'vue-i18n'
import {defineComponent, Ref, ref} from "@vue/runtime-core";
import LoginController, {Channel} from "../LoginController";
import PageHeadline from '@/components/atomic/page-headline/PageHeadline.vue';
import Animations from "@/helpers/animations";
import ChannelsPopup from "../components/ChannelsPopup.vue";
import AppController from "@target/components/App/ts/AppController";
import {f7} from "framework7-vue";
import anime from "animejs";
import {InputCodeErrorMessage} from "@/targets/main/views/login/types/InputCodeErrorMessage";
import CodeFormFourInputs from "@/targets/main/views/login/components/atomic/CodeFormFourInputs.vue";
import CodeFormSixInputs from "@/targets/main/views/login/components/atomic/CodeFormSixInputs.vue";
import {CaptchaActions} from "@/targets/main/views/login/types/CaptchaActions";
import {CaptchaPlace} from "@/targets/main/views/login/types/CaptchaPlace";
import CodeFormInput from "@/targets/main/views/login/components/atomic/CodeFormInput.vue";
import {computed} from "vue";
import TelegramPage from "@/targets/main/views/login/subpages/TelegramPage.vue";

export default defineComponent({
  components: {
    CodeFormInput,
    CodeFormSixInputs,
    CodeFormFourInputs,
    PageHeadline,
    ChannelsPopup
  },
  setup() {
    const {t} = useI18n({useScope: 'global'});
    const controller = LoginController.getInstance();
    const error: Ref<InputCodeErrorMessage | null> = ref(null);
    const phoneText = controller.result.value.formatInternational;
    const kodmobiSettings = controller.kodmobiSettings;
    const headline = ref({
      title: t("g.login.step2.headline.title"),
      text: t("g.login.step2.headline.text", {phone: phoneText, channel: controller.channel.value!.name})
    });
    const submitState = ref({
      loading: false,
      disable: true
    });
    let captchaId: any = null;

    const checkCodeLoading = ref(false)

    const loading = computed(() => {
      return checkCodeLoading.value || controller.isSendLoading.value
    })

    return {
      captchaId,
      kodmobiSettings,
      result: controller.result,
      t,
      phoneText,
      code: controller.code,
      channel: controller.channel,
      controller,
      error,
      submitState,
      loading,
      checkCodeLoading,
      headline,
      sessionTimerText: controller.sessionTimerText,
    }
  },
  watch: {
    'channel'(newValue: any) {
      this.headline = {
        title: this.t("g.login.step2.headline.title"),
        text: this.t("g.login.step2.headline.text", {phone: this.phoneText, channel: newValue.name})
      }
    },
  },
  unmounted() {
    if (AppController.getInstance().isAuth.value) {
      this.controller.destroy();
    }
  },
  methods: {
    extractCode() {
      // @ts-ignore
      const code = this.$refs['symbols-row']!.extractCode();
      this.controller.setCode(code);
    },
    showSuccess() {
      (() => {
        let target = (this.$refs['form-input-wrapper'] as HTMLDivElement);
        anime({
          targets: target,
          keyframes: [
            {
              scale: 1.2
            },
            {
              scale: 1
            },
          ],
          easing: 'easeInOutCubic',
          duration: 550,
        });
      })();
      const form = (this.$el as HTMLDivElement).querySelector('.form');
      form?.classList.add('success');
    },
    openChannelsPopup() {
      // @ts-ignore
      const popup = f7.popup.get(this.$refs['channels-popup'].$el);
      popup.open();
    },
    checkTelegram(channel: Channel) {
      if (channel.type === 'telegram' && !channel.isActive) {
        if (typeof f7.views.current.routes.find((r) => r.name === 'detect-telegram') === 'undefined') {
          f7.views.current.routes.push({
            name: 'detect-telegram',
            path: '/auth/login/detect-telegram',
            component: TelegramPage,
          });
        }
        f7.views.current.router.navigate('/auth/login/detect-telegram');
        return false;
      }
      return true;
    },
    async requestNewCode() {
      try {
        this.$refs['symbols-row']?.clearCode()
        this.setError(null)
        this.checkCodeLoading = true;
        await this.controller.generateCaptchaIfEnabled(
            CaptchaActions.CREATE,
            "#login-enter-code-page_cf-turnstile",
            CaptchaPlace.LOGIN_CODE_PAGE,
        )

        await this.controller.requestCreateSession();
        const firstActiveChannel = this.controller.getFirstActiveChannel()
        if (!this.checkTelegram(firstActiveChannel)) {
          return
        }

        await this.controller.generateCaptchaIfEnabled(
            CaptchaActions.SEND,
            '#login-enter-code-page_cf-turnstile',
            CaptchaPlace.LOGIN_CODE_PAGE,
        )

        await this.controller.requestManuallySendCode(firstActiveChannel.type);
      } catch (e) {
        this.handleKodmobiErrors(e)
        this.checkCodeLoading = false
      } finally {
        this.checkCodeLoading = false
      }
    },
    async checkCode() {
      try {
        this.setError(null)
        this.checkCodeLoading = true;
        this.extractCode();

        await this.controller.generateCaptchaIfEnabled(
            CaptchaActions.CHECK,
            "#login-enter-code-page_cf-turnstile",
            CaptchaPlace.LOGIN_CODE_PAGE,
        )
        await this.controller.requestCheckCode();

        this.controller.removeCaptchaIfEnabled(CaptchaPlace.LOGIN_CODE_PAGE)

        const {token} = await this.controller.requestVerifyToken();
        this.showSuccess();
        setTimeout(() => {
          AppController.getInstance().auth(token);
        }, 550);
      } catch (e: any) {
        this.handleKodmobiErrors(e);
        this.checkCodeLoading = false
      } finally {
        this.checkCodeLoading = false;
      }
    },
    async handleChannelSelect(channel: Channel) {
      try {
        this.setError(null)
        this.controller.isSendLoading.value = true

        this.controller.setChannel(channel);

        await this.controller.generateCaptchaIfEnabled(
            CaptchaActions.SEND,
            "#login-enter-code-page_cf-turnstile",
            CaptchaPlace.LOGIN_CODE_PAGE,
        )

        await this.controller.requestSendCode()
      } catch (e: any) {
        this.handleKodmobiErrors(e, channel);
      } finally {
        this.controller.isSendLoading.value = false
      }
    },
    handleKodmobiErrors(e: any, channel: Channel | undefined = undefined) {
      if (typeof e.type !== 'undefined' && e.type === 'KodMobiError') {
        if (e.sysMessage === 'ERROR_USER_NOT_AUTHORIZE_ON_CHANNEL' && channel) {
          setTimeout(() => {
            window.open(`${channel.link}`, '_blank');
          })
        }
      }

      if (e.sys_message === 'ERROR_SESSION_EXPIRED') {
        this.setError({ message: this.t('g.login.step2.errors.ERROR_SESSION_EXPIRED') })
        return;
      }

      if (e?.error) {
        this.setError({ message: e.error })
        return
      }

      // Unhandled error
      f7.dialog.alert(this.t('errors.70001'), 'Упс')
    },
    resetCode() {
      const parent = (this.$refs['symbols-row'] as any).rootEl;
      Array.from(parent.children as HTMLCollection).map(input => {
        (input as HTMLInputElement).value = ""
      });
      (parent.children[0] as HTMLInputElement).focus();
    },
    focusCode() {
      this.$nextTick(() => {
        this.resetCode();
        const parent = (this.$refs['symbols-row'] as any).rootEl;
        (parent.children[0] as HTMLInputElement).focus();
      })
    },
    PAGE_BEFORE_IN() {
      this.setError(null);
    },
    PAGE_AFTER_IN() {
      this.$nextTick(() => {
        const parent = (this.$refs['symbols-row'] as any).rootEl;
        (parent.children[0] as HTMLInputElement).focus();
        // this.controller.captchaController?.deleteCaptchaId(CaptchaPlace.LOGIN_CHANNEL_PAGE);
      })
    },
    INPUT_PHONE_FOCUS() {
    },
    INPUT_PHONE_KEYDOWN() {
    },
    setError(value: null | InputCodeErrorMessage) {
      this.error = value;
      if (value) {
        const page = this.$el;
        const inputWrapper = page.querySelector('.error-block');
        Animations.shakeAnimation(inputWrapper);
      }
    },
  }
});
</script>
<style lang="scss">
.login-code-navbar {
  --f7-navbar-inner-padding-left: 16px;
  --f7-navbar-inner-padding-right: 16px;

  .navbar-bg {
    &:after {
      content: none;
    }
  }

  .title {
    display: flex;
    align-items: center;
  }

  .right {

  }

}

.login-code-page {
  display: block;

  .block {
    margin: 0px;
    padding: 0px;

    .form {
      padding: 0px 24px;

      & + #login-enter-code-page_cf-turnstile {
        margin-top: 24px;
      }

      .form-input-wrapper {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        @include text(20px, 400, var(--grey1), 24px);
        position: relative;
        border: 1px solid var(--grey4);
        border-radius: 12px;
        width: fit-content;

        .channel {
          // position: absolute;
          // left: -1px;
          // top: -1px;
          display: flex;
          align-items: center;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;

          i {
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
            height: 100%;
            width: 52px;
          }
        }

        .symbols-row {
          display: flex;

          .empty {
            padding: 8px 12px;
            width: 24px;
          }

          input {
            padding: 8px 12px;
            @include text(28px, 700, var(--grey1));
            width: 24px;
            text-align: center;
            box-sizing: content-box;
          }

          hr {
            width: 1px;
            background-color: var(--grey4);
            border: none;
          }
        }
      }

      .error-block {
        margin-top: 8px;
        height: 12px;

        .error-message {
          text-align: center;
          @include text(12px, 400, var(--red), 14px);
        }

        & + .action-block {
          margin-top: 12px;
        }
      }

      .button {
        margin-top: 26px;
      }

      .action-block {
        text-align: center;

        .action-links-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .action-link {
            cursor: pointer;
            @include text(17px, 500, var(--blue1), 21px);

            &.disabled {
              cursor: default;
              @include text(17px, 500, var(--grey2), 21px)
            }

            &.small {
              @include text(15px, 500, var(--blue1), 21px);

              &.disabled {
                cursor: default;
                @include text(15px, 500, var(--grey2), 21px)
              }
            }


          }
        }
      }

      &.error {
        .form-input-wrapper {
          input {
            color: var(--red);
          }
        }
      }

      &.success {
        .form-input-wrapper {
          input {
            color: var(--green1);
          }
        }
      }
    }
  }
}
</style>
