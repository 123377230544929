import {f7} from "framework7-vue";
import i18n from "@/langs/i18n";
import {logEvent} from "firebase/analytics";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import {number} from "maz-ui";
import LogService from "@/services/log-service/LogService";
import AccountService from "@/services/account-service/AccountService";
import {FirebaseEvents} from "@/services/firebase/analytics/FirebaseEvents";
import FirebaseEventBuilder from "@/services/firebase/analytics/FirebaseEventBuilder";

export default class ErrorsService {
    private static _instance: ErrorsService | null = null;

    public static of() {
        if (this._instance === null) this._instance = new ErrorsService();
        return this._instance;
    }

    public handle(e: any, callback: Function | null = null) {
        if (e.code && i18n.global.te(`errors.${e.code}`)) {
            switch (e.code) {
                case 10078:
                    break;
                case 10030:
                    FirebaseService.of().analyticsEvent(
                        FirebaseEvents.PAYMENT_ERROR_NO_AGENTS,
                        FirebaseEventBuilder.of().build()
                    );
                    typeof callback === "function"
                        ? (
                            () => {
                                callback()
                            }
                        )()
                        : f7.dialog.alert(i18n.global.t(`errors.${e.code}`), i18n.global.t('g.errors.alert.default-title'));
                    break;
                case 10033:
                    LogService.of().log("ErrorService@handle", 10033);
                    FirebaseService.of().analyticsEvent(
                        FirebaseEvents.PAYMENT_ERROR_WRONG_RANGE,
                        FirebaseEventBuilder.of().build()
                    );
                    FirebaseService.of().emptyRangeMetricSuccess.reset();
                    FirebaseService.of().wrongRangeMetricSuccess.startObserve();
                    typeof callback === "function"
                        ? (
                            () => {
                                callback()
                            }
                        )()
                        : f7.dialog.alert(i18n.global.t(`errors.${e.code}`), i18n.global.t('g.errors.alert.default-title'));
                    break;
                case 10034:
                    LogService.of().log("ErrorService@handle", 10034);
                    FirebaseService.of().analyticsEvent(
                        FirebaseEvents.PAYMENT_ERROR_EMPTY_RANGE,
                        FirebaseEventBuilder.of().build()
                    );
                    FirebaseService.of().wrongRangeMetricSuccess.reset();
                    FirebaseService.of().emptyRangeMetricSuccess.startObserve();
                    typeof callback === "function"
                        ? (
                            () => {
                                callback()
                            }
                        )()
                        : f7.dialog.alert(i18n.global.t(`errors.${e.code}`), i18n.global.t('g.errors.alert.default-title'));
                    break;
                default:
                    f7.dialog.alert(i18n.global.t(`errors.${e.code}`), i18n.global.t('g.errors.alert.default-title'));
            }
        } else {
            f7.dialog.alert(i18n.global.t('g.errors.alert.default-text'), i18n.global.t('g.errors.alert.default-title'));
        }
    }
}