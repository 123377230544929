import {Expose} from "class-transformer";

export default class KodmobiCodeFormat {
    @Expose({name: 'length'})
    private readonly _length: number;
    @Expose({name: 'digits'})
    private readonly _digits: boolean;
    @Expose({name: 'letters'})
    private readonly _letters: boolean;

    constructor(length: number, digits: boolean, letters: boolean) {
        this._length = length;
        this._digits = digits;
        this._letters = letters;
    }

    get length(): number {
        return this._length;
    }

    get digits(): boolean {
        return this._digits;
    }

    get letters(): boolean {
        return this._letters;
    }
}