<template>
    <div class="message-error display-flex align-items-center justify-content-center flex-direction-column fade-in">
        <div class="picture ghost-svg"></div>
        <span class="title">{{ title }}</span>
        <span class="text">{{ text }}</span>
        <span class="extra" v-if="extra">{{ extra }}</span>
        <f7-button class="smoke-type" @click="reload">{{ $t('g.update') }}</f7-button>
    </div>
</template>

<script lang="ts">
import Message from "@/entities/Message";
import {defineComponent, PropType} from "vue";

export default defineComponent({
    props: {
        message: {
            type: Object as PropType<Message>,
            required: true
        }
    },
    setup(props) {
        const {title, text, extra} = props.message;
        return {
            title, text, extra
        }
    },
    methods: {
        reload() {
            location.reload();
        }
    }
})
</script>

<style lang="scss" src="./index.scss"></style>
