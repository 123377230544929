<template>
    <div class="withdrawal__status-timer">
        <transition name="fade" mode="out-in">
            <template v-if="percent > 0 || !hiddenTimer">
                <f7-gauge
                    :size="size"
                    :border-width="borderWidth"
                    border-bg-color="#D9D9D9"
                    :value-text="percent < 0 ? '0:00' : timeText"
                    type="circle"
                    :value="percent"
                    :value-font-size="fontSize"
                    value-font-weight="600"
                    :class="{'gauge_expired': percent <= 0}"
                    :border-color="borderColor"
                    :value-text-color="textColor"
                ></f7-gauge>
            </template>
            <template v-else>
                <div class="status-image"></div>
            </template>
        </transition>
    </div>
</template>

<script setup lang="ts">
import {computed, PropType, toRef, UnwrapRef, watch} from "vue";

const props = defineProps({
    hiddenTimer: {
        type: Object as PropType<Boolean>,
        default: true
    },
    borderColor: {
        type: Object as PropType<string>,
        default: "#1890FF"
    },
    textColor: {
        type: Object as PropType<string>,
        default: "#1890FF"
    },
    fontSize: {
        type: Object as PropType<number>,
        required: true
    },
    size: {
        type: Object as PropType<number>,
        required: true
    },
    borderWidth: {
        type: Object as PropType<number>,
        required: true
    },
    timeText: {
        type: Object as PropType<UnwrapRef<string | null>>,
        required: true
    },
    percent: {
        type: Object as PropType<UnwrapRef<number>>,
        required: true
    }
});
const percent = toRef(props, 'percent');
const timeText = toRef(props, 'timeText');
</script>

<style lang="scss">
.withdrawal__status-timer {
    .gauge {
        display: flex;
        &.gauge_expired {
            .gauge-value-text {
                fill: var(--red);
            }
        }
    }
}
</style>