import PurchaseOperation from "@models/operations/PurchaseOperation";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import {f7} from "framework7-vue";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import LogService from "@/services/log-service/LogService";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import DocumentOthers from "@/services/firebase/firestore/documents/DocumentOthers";

export default class HuntingAgentsService {
    private static instance?: HuntingAgentsService;
    private _toast: any = null;
    private _wasClosed: boolean = false;

    static of() {
        if (typeof HuntingAgentsService.instance === 'undefined') HuntingAgentsService.instance = new HuntingAgentsService();
        return HuntingAgentsService.instance;
    }

    public checkShowToast(payment: PurchaseOperation) {
        if (this._wasClosed) return;

        if (!FirebaseService.of().firestoreService) return;
        const doc = FirebaseService.of().firestoreService!.getDocument(DocumentTypes.OTHERS);
        if (!doc || !(doc as DocumentOthers).toastHuntingAgentsShow) return;

        if (payment.status && [
            BuyingOperationStatus.CREATED,
            BuyingOperationStatus.CASHED
        ].includes(payment.status)) {
            this._toast = f7.toast.create({
                closeTimeout: 99999999,
                // @ts-ignore
                el: document.querySelector('.hunting-agents-toast')!
            });
            this._toast.open();
        } else {
            this.hideToast();
        }
    }

    public hideToast() {
        if (this._toast) this._toast.close();
    }

    public forceHideToast() {
        this._wasClosed = true;
        this.hideToast();
    }
}