import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";
import {Expose, Type} from "class-transformer";
import PaymentSystemOperation from "@models/operations/components/PaymentSystemOperation";
import AgentSumRanges from "@models/agents/AgentSumRanges";

export default class Agent {
    @Expose({name: "id"})
    private readonly _id: number;
    @Expose({name: "isOnline"})
    private readonly _isOnline: boolean;
    @Expose({name: "name"})
    private readonly _name: string;
    @Expose({name: "sex"})
    private readonly _sex: string;
    @Expose({name: "sumRanges"})
    @Type(() => AgentSumRanges)
    private readonly _sumRanges: AgentSumRanges;

    public constructor(
        id: number,
        isOnline: boolean,
        name: string,
        sex: string,
        sumRanges: AgentSumRanges
    ) {
        this._id = id;
        this._isOnline = isOnline;
        this._name = name;
        this._sex = sex;
        this._sumRanges = sumRanges;
    }

    get sumRanges(): AgentSumRanges {
        return this._sumRanges;
    }

    get sex(): string {
        return this._sex;
    }

    get name(): string {
        return this._name;
    }

    get isOnline(): boolean {
        return this._isOnline;
    }

    get id(): number {
        return this._id;
    }
}