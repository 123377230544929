import Operation from "@models/operations/Operation";
import {Expose, Type} from "class-transformer";
import ProjectOperation from "@models/operations/components/ProjectOperation";
import Agent from "@models/operations/components/Agent";
import CurrencyOperation from "@models/operations/components/CurrencyOperation";
import RequisitesOperation from "@models/operations/components/RequisitesOperation";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import {OperationType_N} from "@enums/OperationType_N";
import {P_WITHDRAWAL_STATUS} from "@/entities/enums/PWithdrawalStatus";
import PaymentSystemOperation from "@models/operations/components/PaymentSystemOperation";

export default class WithdrawalOperation extends Operation {
    constructor() {
        super(OperationType_N.sale);
    }

    @Expose({name: 'id'})
    private _id: number | null = null;

    @Expose({name: 'msid'})
    private _msid: string | null = null;

    @Expose({name: 'project'})
    @Type(() => ProjectOperation)
    private _project: ProjectOperation | null = null;

    @Expose({name: 'agent'})
    @Type(() => Agent)
    private _agent: Agent | null = null;

    @Expose({name: 'psystem'})
    @Type(() => PaymentSystemOperation)
    private _psystem: PaymentSystemOperation | null = null;

    @Expose({name: 'amount'})
    private _amount: number | null = null;

    @Expose({name: 'amountUsd'})
    private _amountUsd: number | null = null;

    @Expose({name: 'currency'})
    @Type(() => CurrencyOperation)
    private _currency: CurrencyOperation | null = null;

    @Expose({name: 'account'})
    private _account: string | null = null;

    @Expose({name: 'requisites'})
    @Type(() => RequisitesOperation)
    private _requisites: RequisitesOperation | null = null;

    @Expose({name: 'status'})
    private _status: P_WITHDRAWAL_STATUS | null = null;

    @Expose({name: 'chat'})
    private _chat: any = null;

    @Expose({name: 'updatedAt'})
    private _updatedAt: string | null = null;

    get updatedAt(): string | null {
        return this._updatedAt;
    }

    get chat(): any {
        return this._chat;
    }

    get psystem(): PaymentSystemOperation | null {
        return this._psystem;
    }

    get status(): P_WITHDRAWAL_STATUS | null {
        return this._status;
    }

    get requisites(): RequisitesOperation | null {
        return this._requisites;
    }

    get account(): string | null {
        return this._account;
    }

    get currency(): CurrencyOperation | null {
        return this._currency;
    }

    get amountUsd(): number | null {
        return this._amountUsd;
    }

    get amount(): number | null {
        return this._amount;
    }

    get agent(): Agent | null {
        return this._agent;
    }

    get project(): ProjectOperation | null {
        return this._project;
    }

    get msid(): string | null {
        return this._msid;
    }

    set msid(value: string | null) {
        this._msid = value;
    }

    get id(): number | null {
        return this._id;
    }

    public resetChatUnreadCount() {
        this._chat.unread = 0;
    }

    public incrementChatUnreadCount() {
        this._chat.unread++;
    }
}

/**
 * {
 *     +"id": 12607,
 *     "operation_type": "sale",
 *     +"project": {
 *         "name": "BroMoney",
 *         "slug": "go-usdt",
 *         "logo_url": ""
 *     },
 *     +"msid": "5c6a3314-a744-43c4-9bc6-2597cec987c5",
 *     +"amount": 90,
 *     +"amount_usd": 1.01,
 *     +"currency": {
 *         "id": 1,
 *         "name": "RUB",
 *         "abbr": "₽",
 *         "desc": "Российский рубль"
 *     },
 *     "psystem": {
 *         "id": 6,
 *         "name": "Любой Банк",
 *         "sys_name": "anybank",
 *         "img_path": ""
 *     },
 *     +"account": null,
 *     +"agent": null,
 *     +"requisites": {
 *         "address": "213123123123123",
 *         "name": "13123123",
 *         "address_type": "phone",
 *         "comment": null
 *     },
 *     "requisites_a": null,
 *     +"status": "CREATED",
 *     +"chat": null,
 *     "is_appealable": false,
 *     "ends_in": "2024-01-16T14:28:26.000000Z",
 *     "accepted_at": null,
 *     "autoconfirm_at": null,
 *     +"created_at": "2024-01-16T14:26:26.000000Z",
 *     +"updated_at": "2024-01-16T14:26:26.000000Z"
 * }
 */