export enum FirebaseEvents {
    PAYMENT_ERROR_WRONG_RANGE = "payment.error.wrong_range",
    PAYMENT_ERROR_WRONG_RANGE_SUCCESS = "payment.error.wrong_range.success",
    PAYMENT_ERROR_EMPTY_RANGE = "payment.error.empty_range",
    PAYMENT_ERROR_EMPTY_RANGE_SUCCESS = "payment.error.empty_range.success",
    PAYMENT_ERROR_NO_AGENTS = "payment.error.no-agents",
    PAYMENT_CREATING_TRY = "payment.creating.try",
    PAYMENT_CREATING_SUCCESS = "payment.creating.success",
    PAYMENT_CREATING_ERROR = "payment.creating.error",
    PAYMENT_CANCEL = "payment.cancel",
    REQUEST_RETRY = "request.retry",
}