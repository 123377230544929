import {getDevice} from "framework7/shared/get-device";

export default class UtilsService {
    private static instance?: UtilsService;

    static of() {
        if (typeof UtilsService.instance === 'undefined') UtilsService.instance = new UtilsService();
        return UtilsService.instance;
    }

    async openTelegramSupportChat() {
        try {
            const url = "https://t.me/BroMoney_robot";
            if (getDevice().ios) {
                // @ts-ignore
                window.webkit.messageHandlers.iosListener.postMessage(JSON.stringify({
                    "deeplink": "https://t.me/BroMoney_robot"
                }));
            } else if (getDevice().android) {
                // @ts-ignore
                window.webInterface.onMessage(JSON.stringify({
                    "deeplink": "https://t.me/BroMoney_robot"
                }));
            } else {
                window.open(url, "_target");
            }
        } catch (e) {
            window.open('https://t.me/BroMoney_robot', "_target");
        }
    }
}