import AbstractDocument from "@/services/firebase/firestore/documents/AbstractDocument";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import {Expose} from "class-transformer";

export default class DocumentPurchase extends AbstractDocument {
    @Expose({name: 'isCanSelectAgent'})
    private _isCanSelectAgent: boolean | null = null;
    protected _type: DocumentTypes = DocumentTypes.PURCHASE;

    constructor() {
        super();
    }

    get isCanSelectAgent(): boolean | null {
        return this._isCanSelectAgent;
    }
}