import "reflect-metadata";
import {createApp} from 'vue';
// @ts-ignore
import Framework7 from 'framework7/lite-bundle'
// @ts-ignore
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle'
import 'framework7/css/bundle';

import '@/scss/app.scss';
import '@target/scss/app.scss';

import App from '@target/components/App/App.vue';
// @ts-ignore
import {color} from "@target/core/directives";
import i18n from "@/langs/i18n";
import disableBounce from "@/helpers/disable-bounce";

import * as Sentry from "@sentry/vue";

// @ts-ignore
Framework7.use(Framework7Vue);
const app = createApp(App);
app.use(i18n);
// main.use(pinia)

registerComponents(app);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
});


app.mount('#app');
app.directive(color.name, color.fn);

disableBounce(window);
if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function(str, newStr){
        if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            // @ts-ignore
            return this.replace(str, newStr);
        }
        // @ts-ignore
        return this.replace(new RegExp(str, 'g'), newStr);
    };
}