<template>
    <div class="list-item-with-title" ref="self">
        <f7-block-title>{{ title }}</f7-block-title>
        <f7-list dividers-ios strong-ios outline-ios>
            <f7-list-input
                :disabled="disabled"
                :type="textarea ? 'textarea' : 'text'"
                :placeholder="placeholder"
                v-model:value="text"
                :clear-button="!disabled"
                :rows="textarea ? 2 : 1"
                @update:value="updateValue"
            ></f7-list-input>
            <p ref="errorEl" v-if="error" class="list-item-with-title_error">{{ errorText }}</p>
            <p v-if="hint" class="list-item-with-title_hint">{{ hint }}</p>
        </f7-list>
    </div>
</template>

<script setup lang="ts">
import {PropType, ref, toRef} from "vue";
import {useI18n} from "vue-i18n";

const props = defineProps({
    title: {
        type: Object as PropType<string>,
        required: true
    },
    disabled: {
        type: Object as PropType<boolean>,
        required: false,
        default: false
    },
    text: {
        type: Object as PropType<string>,
        required: true
    },
    placeholder: {
        type: Object as PropType<string>,
        required: true
    },
    textarea: {
        type: Object as PropType<boolean>,
        required: false,
        default: false
    },
    required: {
        type: Object as PropType<boolean>,
        required: false,
        default: false
    },
    hint: {
        type: Object as PropType<string>,
        required: false
    }
});

function updateValue() {
    error.value = false;
}

const self = ref(null);
const errorEl = ref(null);
const text = defineModel<string>("text", {
    required: true
});
const {t} = useI18n({useScope: 'global'});
const title = props.title;
const hint = props.hint;
const textarea = props.textarea;
const disabled = props.disabled;
const error = ref(false);
const errorText = ref("");
const emits = defineEmits(["onItemClick"]);
defineExpose({error, text, errorEl, errorText})
</script>

<style lang="scss">
.list-item-with-title {
    margin-top: 32px;

    .block-title {
        margin-top: 0;
        text-transform: uppercase;
        @include text(12px, 400, var(--grey2), 14.4px);
    }

    .list {
        margin: 0;

        ul {
            & + .list-item-with-title_hint {
                margin-top: 6px;
            }
        }

    }

    &_error {
        padding: 6px 16px;
        @include text(12px, 400, var(--red1), 14.4px);
    }

    &_hint {
        padding: 0 16px;
        @include text(12px, 400, var(--grey2), 14.4px);
    }
}
</style>