import {OpenIn} from "@/views/requisites-group/RequisitesController";
import {ref, ToRef} from "vue";
import RequisiteRepository from "@/repositories/requisite/RequisiteRepository";
import Requisite from "@models/requisite/Requisite";
import {value} from "dom7";
import requisite from "@components/requisites/Requisite.vue";
import RequisitePageController from "@/views/requisites-group/requisite/RequisitePageController";
import RequisitePsystem from "@models/requisite/RequisitePsystem";
import {PageOpenIn} from "@enums/PageOpenIn";

export default class RequisitePsystemPageController {
    private static _instance: RequisitePsystemPageController | null = null;
    private _requisitePageController: RequisitePageController | null = null;
    private _requisiteRepository: RequisiteRepository;
    private _openIn: PageOpenIn = PageOpenIn.PAGE;
    private _psystems: ToRef<RequisitePsystem[]> = ref([]);

    constructor(requisiteRepository: RequisiteRepository) {
        this._requisiteRepository = requisiteRepository;
    }

    public static of(): RequisitePsystemPageController {
        if (RequisitePsystemPageController._instance === null) {
            RequisitePsystemPageController._instance = new RequisitePsystemPageController(
                new RequisiteRepository()
            );
        }
        return RequisitePsystemPageController._instance;
    }

    get openIn(): PageOpenIn {
        return this._openIn;
    }

    set openIn(value: PageOpenIn) {
        this._openIn = value;
    }

    get requisitePageController(): RequisitePageController | null {
        return this._requisitePageController;
    }

    get psystems(): ToRef<RequisitePsystem[]> {
        return this._psystems;
    }

    async init(requisitePageController: RequisitePageController, pageOpenIn: PageOpenIn) {
        this._requisitePageController = requisitePageController;
        await this.fetchAndSetPsystems();
        this._openIn = pageOpenIn;
    }

    private async fetchAndSetPsystems() {
        this._psystems.value = await this._requisiteRepository.getAllPsystems(
            this._requisitePageController!.requisite.value!.psystem.targetType
        );
    }
}