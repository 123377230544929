<template>
    <div class="withdrawal-cancel-operation-hint">
        <i class="icon svg-warning"></i>
        <p>{{ $t('withdrawal.statuses.accepted.hint') }}</p>
    </div>
</template>

<style lang="scss">
.withdrawal-cancel-operation-hint {
    display: flex;
    background: rgba(230, 151, 0, 0.1);
    border-radius: 18px;
    padding: 12px;
    margin-top: 24px;
    color: rgba(207, 117, 41, 1);
    gap: 12px;
    p {
        @include text(14px, 400, #CF7529, 16.8px);
    }
}
</style>