export default class LogService {
    private static instance: LogService | null = null;

    public static of() {
        if (this.instance === null) this.instance = new LogService()
        return this.instance
    }

    public log(where: string, value: any) {
        if (import.meta.env.DEV) console.log(where, value)
    }

    public error(where: string, value: any) {
        if (import.meta.env.DEV) console.error(where, value)
    }
}