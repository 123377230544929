import ViewController from "@/interfaces/ViewController";
// @ts-ignore
import store from '@target/core/store/store';
import { computed, ref, Ref } from "vue";
import { f7, useStore } from 'framework7-vue';
import i18n from "@/langs/i18n";
import PaymentWithdrawal from "@/entities/PaymentWithdrawal";
import humps from "lodash-humps-ts";
import { P_WITHDRAWAL_STATUS } from "@/entities/enums/PWithdrawalStatus";
import GPWithdrawalController from "@/controllers/GPWithdrawalController";

declare const window: Window & typeof globalThis & { Pusher: any, Echo: any }

const defaultValues = {
    time: 0,
}

export default class WithdrawalDataController implements ViewController {
    private static _instance?: WithdrawalDataController;
    timeText: Ref<string>;
    percent: Ref<number>;
    time: number;
    interval?: ReturnType<typeof setInterval>;
    intervalRestUpdatePayment?: ReturnType<typeof setInterval>;
    title: Ref<string>;
    subtitle: Ref<string>;
    hint: Ref<string>;

    static getInstance() {
        if (typeof this._instance === 'undefined') this._instance = new WithdrawalDataController();
        return this._instance;
    }

    constructor() {
        this.percent = ref(0);
        // const payment: PaymentWithdrawal = useStore('paymentWithdrawal').value;
        this.time = defaultValues.time;
        this.title = ref("");
        this.subtitle = ref("");
        this.hint = ref("");
        this.timeText = ref(this.getTimeText());
        this.syncSocket();
    }

    configure() {
        clearInterval(this.interval);
        const payment: PaymentWithdrawal = useStore('paymentWithdrawal').value;
        const statusesWithTimer = ['ACCEPTED', 'CASHED', 'CREATED'];
        if (statusesWithTimer.includes(payment.status)) {
            this.defineStatusLiveTime();
            this.startRestApiInterval();
            if (this.time > 0) {
                this.timeText.value = this.getTimeText();
                this.percent.value = 1;
                this.initInterval();
            }
        }
    }

    private async startRestApiInterval() {
        clearInterval(this.intervalRestUpdatePayment);
        this.intervalRestUpdatePayment = setInterval(async () => {
            await store.dispatch('checkWithdrawalStatus', null);
        }, 30000);
    }

    private defineStatusLiveTime() {
        const payment = store.getters['paymentWithdrawal'].value as PaymentWithdrawal | undefined;
        if (typeof payment !== "undefined") {
            this.time = GPWithdrawalController.getStatusMM(payment);
        }
    }

    async cancelPaymentWithClosePopup() {

    }

    public configurateTextOnView() {
        this.configure();
        const payment: PaymentWithdrawal = useStore('paymentWithdrawal').value;

        switch (payment.status) {
            case P_WITHDRAWAL_STATUS.CREATED:
                this.title.value = i18n.global.t("payment.controller.CREATED.withdrawal.title");
                this.subtitle.value = i18n.global.t("payment.controller.CREATED.withdrawal.subtitle");
                this.hint.value = i18n.global.t("payment.controller.CREATED.withdrawal.hint");
                break;
            case "ACCEPTED":
                //@ts-ignore
                this.title.value = i18n.global.t("payment.controller.ACCEPTED.withdrawal.title");
                this.subtitle.value = i18n.global.t("payment.controller.ACCEPTED.withdrawal.subtitle");
                this.hint.value = i18n.global.t("payment.controller.withdrawal");
                break;
            case "CANCELED":
                this.title.value = i18n.global.t("payment.controller.CANCELED.title");
                this.subtitle.value = i18n.global.t("payment.controller.CANCELED.subtitle");
                this.hint.value = i18n.global.t("payment.controller.withdrawal");
                break;
            case P_WITHDRAWAL_STATUS.ONAPPEAL:
                this.title.value = i18n.global.t("payment.controller.ONAPPEAL.title");
                this.subtitle.value = i18n.global.t("payment.controller.ONAPPEAL.subtitle");
                this.hint.value = i18n.global.t("payment.controller.withdrawal");
                break;
            case P_WITHDRAWAL_STATUS.CASHED:
                this.title.value = i18n.global.t("payment.controller.CASHED.withdrawal.title");
                this.subtitle.value = i18n.global.t("payment.controller.CASHED.withdrawal.subtitle");
                this.hint.value = i18n.global.t("payment.controller.CASHED.withdrawal.hint");
                break;
            case P_WITHDRAWAL_STATUS.CONFIRMED:
                this.title.value = i18n.global.t("payment.controller.CONFIRMED.title");
                this.subtitle.value = i18n.global.t("payment.controller.CONFIRMED.subtitle");
                this.hint.value = "";
                break;
            default:
        }
    };

    private syncSocket() {

    }

    private async initInterval() {
        const self = this;
        let timeInterval: null | number = null;
        this.interval = setInterval(async () => {
            // console.log('withdrawal interval');
            if (timeInterval === null) {
                timeInterval = Math.round(this.time / 1000);
            }
            this.time -= 1000;
            this.getTimeText();
            this.timeText.value = this.getTimeText();
            const z: number = +(1 / timeInterval).toFixed(5);
            this.percent.value = this.percent.value - z;

            if (this.time <= 0) {
                store.dispatch('checkWithdrawalStatus', null);
                clearInterval(this.intervalRestUpdatePayment);
                const payment: PaymentWithdrawal = useStore('paymentWithdrawal').value;
                if (payment.status === P_WITHDRAWAL_STATUS.ACCEPTED) {
                    clearInterval(this.interval);
                    timeInterval = null;
                    this.percent.value = 0;
                }
                if (payment.status === P_WITHDRAWAL_STATUS.CREATED) {
                    clearInterval(this.interval);
                    timeInterval = null;
                    this.percent.value = 0;
                    await store.dispatch('cancelPaymentWithdrawalRequest', null);
                }
                if (payment.status === P_WITHDRAWAL_STATUS.CASHED) {
                    this.hint.value = "";
                    clearInterval(this.interval);
                    timeInterval = null;
                    this.percent.value = 0;
                }
            }
        }, 1000);
    }

    getTimeText(): string {
        const ms = this.time / 1000;
        const min = Math.floor(ms / 60);
        const sec = Math.floor(ms % 60);
        const secString = sec < 10 ? `0${sec}` : sec;
        return `${min}:${secString}`;
    }

    destructor(): void {
        clearInterval(this.interval);
        clearInterval(this.intervalRestUpdatePayment);
        WithdrawalDataController._instance = undefined;
    }
}
