<template>
<f7-page ref="self" name="setup-password-page" class="setup-password-page" :page-content="false"
         @page:afterin="PAGE_AFTER_IN">
    <f7-navbar class="setup-password-page-navbar" no-hairline>
        <f7-nav-left @click="closePage">
            <a href="#" class="link icon-only"><i class="icon i-svg-close"></i></a>
        </f7-nav-left>
        <f7-nav-title>
            <i class="icon sloth-logo-m-svg"/>
            <i class="icon logo-small-svg"/>
        </f7-nav-title>
        <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <f7-page-content>
        <PageHeadline :title="headline.title" :text="headline.text"/>

        <div class="password-form" :class="{'error': error}">
            <div class="password-input-wrapper">
                <input ref="input-password" class="input-password" v-model="passwordInput"
                       :placeholder="t('set-password-page.form.input-password.placeholder')"
                       :type="passwordInputType" @keyup.enter="SUBMIT">
                <div class="item-media" @click="changePasswordInputType">
                    <i v-if="passwordInputType === 'password'" class="icon eye-closed-icon"></i>
                    <i v-else class="icon eye-opened-icon"></i>
                </div>
            </div>
            <div class="error-block">
                <p class="error-message" v-if="error">{{ error.message }}</p>
            </div>
            <f7-button @click="SUBMIT" class="theme-type" :loading="submitState.loading"
                       :disabled="submitState.disable" preloader>
                {{ $t('set-password-page.form.submit') }}
            </f7-button>
        </div>
    </f7-page-content>
</f7-page>
</template>

<script lang="ts">
import {f7} from "framework7-vue"
import {defineComponent, ref} from "@vue/runtime-core"
import {computed, Ref} from "@vue/reactivity";
import Animations from "@/helpers/animations";
import {useI18n} from "vue-i18n";
import PageHeadline from '@components/atomic/page-headline/PageHeadline.vue';
// @ts-ignore
import Api from "@target/services/project/projectApiService";

type ErrorMessage = {
    message: string
}

export default defineComponent({
    components: {
        PageHeadline
    },
    setup() {
        const passwordInput = ref('')
        const passwordInputType: 'password' | 'text' = ref('password')
        const submitBtnLoading = ref(false)
        const {t} = useI18n<{ message: MessageSchema }>({useScope: 'global'});
        const headline = {
            title: t('set-password-page.header.title'),
            text: t('set-password-page.header.subtitle')
        };
        const submitState = ref({
            loading: false,
            disable: passwordInput.value.length === 0 ? true : false
        });
        const error: Ref<ErrorMessage | null> = ref(null);

        return {
            passwordInputType,
            submitState,
            error,
            headline,
            t,
            passwordInput,
            submitBtnLoading
        };
    },
    watch: {
        'passwordInput'(newValue: string) {
            this.setError(null);
            if (newValue.length > 0) this.submitState.disable = false;
            else this.submitState.disable = true;
        }
    },
    methods: {
        changePasswordInputType() {
            this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
        },
        PAGE_AFTER_OUT() {
            setTimeout(() => {
                // UserEventsService.getInstance().next();
            }, 300);
        },
        PAGE_AFTER_IN() {
            // StatusBarController.toDarkContent()
        },
        setError(value: null | ErrorMessage) {
            this.error = value;
            if (value) {
                const page = this.$el;
                const inputWrapper = page.querySelector('.error-block');
                Animations.shakeAnimation(inputWrapper);
            }
        },
        async SUBMIT() {
            try {
                this.submitState.disable = true;
                this.submitState.loading = true;

                await Api.getInstance().post('/api/user/password', {
                    password: this.passwordInput
                });
                this.closePage()
            } catch (e: any) {
                f7.dialog.alert(e.message, this.t('g.errors.alert.default-title'));
            } finally {
                this.submitState.disable = false;
                this.submitState.loading = false;
            }
        },
        closePage() {
            // @ts-ignore
            f7.popup.get(this.$refs['self'].$el.closest('.popup-router-open-in')).close();
            // StatusBarController.toLightContent()
            // if (this.isPopup()) f7.popup.get(this.$refs['self'].$el.closest('.popup-router-open-in')).close()
            // else f7.views.current.router.back();
        }
    },
    unmounted() {

    },
});
</script>


<style lang="scss">
.setup-password-page-navbar {
    --f7-navbar-bg-color: var(--white2);
    .navbar-bg {
        &::after {
            content: none;
        }
    }
}

.setup-password-page {
    --f7-page-bg-color: var(--white2);

    .password-form {
        padding: 0px 24px;

        .password-input-wrapper {
            margin: 0px;
            display: flex;
            justify-content: space-between;
            @include text(20px, 400, var(--grey1), 24px);
            position: relative;

            &::after {
                content: '';
                position: absolute;
                background-color: var(--grey4);
                display: block;
                z-index: 15;
                top: auto;
                right: 0px;
                bottom: 0;
                left: auto;
                height: 1px;
                width: 100%;
                transform-origin: 50% 100%;
                transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
            }
            .item-media {
                display: flex;
                align-items: center;
                color: var(--grey3);
                cursor: pointer;
                flex: 1;
                margin-right: 8px;

                .icon {
                    width: 18px;
                    height: 18px;
                    filter: invert(84%) sepia(14%) saturate(188%) hue-rotate(173deg) brightness(84%) contrast(89%);
                }
            }
            .input-password {
                line-height: 20px;
                padding: 12px 8px 12px 16px;
                width: 100%;

                &::placeholder {
                    color: #ACB4BE;
                    //opacity: 0.3;
                }
            }
        }

        .error-block {
            margin-top: 8px;
            height: 12px;

            .error-message {
                text-align: center;
                @include text(12px, 400, var(--red), 14px);
            }
        }

        .button {
            margin-top: 26px;
        }

        &.error {
            .password-input-wrapper {
                input {
                    color: var(--red);
                }

                &::after,
                &::before {
                    background-color: var(--red);
                }
            }
        }

        & + .to-login-code {
            margin-top: 28px;
        }
    }
}
</style>
