import Pusher from "pusher-js";
import Echo from "laravel-echo";

const PUSHER_AUTH_ENDPOINT = import.meta.env.VITE_API_URL + "/broadcasting/auth";
const getPusherHost = () => {
    return import.meta.env.VITE_API_URL.replace('https://', '');
}
export default function initPusher(pusher: any): void {
    window.Pusher = Pusher;
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: pusher.key,
        cluster: 'mt1',
        forceTLS: true,
        encrypted: false,
        wsHost: pusher.host,
        wssHost: pusher.host,
        wsPort: 80,
        wssPort: 443,
        pongTimeout: 500,
        disableStats: true,
        authEndpoint: pusher.authEndpoint,
        auth: {
          headers: {
              "X-Token": localStorage.getItem('ltoken')
          },
        },
        enabledTransports: ['ws', 'wss']
    });
}
