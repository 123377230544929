import PaymentsView from '@target/views/payments/PaymentsView.vue';
import MessageView from '@target/views/message/MessageView.vue';
import FindAgentView from '@/views/find-agent/FindAgentView.vue';
import PaymentView from '@target/views/payment/PaymentView.vue';
import PaymentIncomePage from '@/views/payment-income/PaymentIncomePage.vue';


//import NewPaymentPopup from "@/views/new-payment/NewPaymentPopup.vue" ;
import NewPaymentPSystems from "@/views/new-payment/NewPaymentPSystems.vue" ;
import ChatView from "@/views/chat/ChatView.vue";
import ChatWithdrawView from "@/views/chat-withdrawal/ChatView.vue";
import LoginView from "@target/views/login/LoginPage.vue";
import SettingsView from "@target/views/settings/SettingsPage.vue";
import ChangeShortNamePage from "@target/views/settings/subpages/ChangeShortNamePage.vue";
import SelectCurrencyPage from "@target/views/settings/subpages/SelectCurrencyPage.vue";
import SelectLangPage from "@target/views/settings/subpages/SelectLangPage.vue";
import SetupPasswordPage from "@target/views/settings/subpages/SetupPasswordPage.vue";

// Creating new payment
import PopupPaymentCreate from "@/components/popup/popup-payment-create/NewPaymentPopup.vue";
import ViewPaymentCreateConfig from "@/views/payment-create-config/NewPaymentView.vue";

// Withdrawal popup
import WithdrawalPopup from "@/views/withdrawal-popup-group/popup/WithdrawalPopup.vue" ;
import WithdrawalDevView from "@/views/withdrawal-popup-group/WithdrawalDevView.vue" ;
import WithdrawalCreatePage from "@/views/withdrawal-popup-group/withdrawal-create/WithdrawalCreatePage.vue" ;
// import WithdrawalRequisitesPage from "@/views/withdrawal-popup-group/withdrawal-requisites/WithdrawalRequisitesPage.vue";
// Withdrawal flow
import WithdrawalData from "@/views/withdrawal-group/withdrawal/WithdrawalDataPage.vue" ;

// import RequisitesListPage from "@/views/requisites-group/requisites/RequisitesListPage.vue";
import RequisiteSelectAddressTypePage from "@/views/requisites-group/select-address-type/SelectAddressTypePage.vue";
import SelectPSystemPage from "@/views/requisites-group/select-psystem/SelectPSystemPage.vue";
import RequisitePage from "@/views/requisites-group/requisite/RequisitePage.vue";
import RemoveAccountView from "@/views/remove-account/RemoveAccountPage.vue";
import ViewTech from "@/views/v2/view-tech/ViewTech.vue";

export default [
    {
        path: '/remove-account',
        component: RemoveAccountView,
    },
    {
        path: '/settings/tech',
        component: ViewTech,
    },
    {
        path: '/withdrawal',
        component: WithdrawalData,
    },
    {
        path: '/settings',
        component: SettingsView,
    },
    {
        path: '/settings/set-password',
        component: SetupPasswordPage,
    },
    {
        path: '/settings/currency',
        component: SelectCurrencyPage,
    },
    // {
    //     path: '/settings/requisites',
    //     component: RequisitesListPage,
    // },
    {
        path: '/settings/requisite/select-address-type',
        component: RequisiteSelectAddressTypePage,
    },
    {
        path: '/settings/requisites/select-psystem',
        component: SelectPSystemPage,
    },
    {
        path: '/settings/requisites/requisite',
        component: RequisitePage,
    },
    {
        path: '/settings/lang',
        component: SelectLangPage,
    },
    {
        path: '/settings/change-short-name',
        component: ChangeShortNamePage,
    },
    {
        path: '/auth/login',
        component: LoginView,
    },
    {
        path: '/popup/payment/create',
        popup: {
            component: PopupPaymentCreate,
        },
    },
    {
        path: '/payment/create/config',
        component: ViewPaymentCreateConfig,
    },
    {
        path: '/popup/withdrawal',
        popup: {
            component: WithdrawalPopup,
        },
    },
    {
        path: '/popup/withdrawal/dev',
        component: WithdrawalDevView,
    },
    {
        path: '/popup/withdrawal/create',
        component: WithdrawalCreatePage,
    },
    {
        path: '/chat',
        component: ChatView,
    },
    {
        path: '/chat-withdrawal',
        component: ChatWithdrawView,
    },
    {
        path: '/popup/payment-systems',
        component: NewPaymentPSystems,
    },
    {
        path: '/payments',
        component: PaymentsView,
    },
    {
        path: '/payment/income',
        component: PaymentIncomePage,
    },
    {
        path: '/find-agent',
        component: FindAgentView,
    },
    {
        path: '/payment',
        component: PaymentView,
        options: {
            props: {
                fromHistory: false,
            },
        },
    },
    {
        path: '/message',
        component: MessageView,
    }
];
