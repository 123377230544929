<template>
    <transition
        mode="out-in"
        enter-active-class="slide-in__bottom-block"
        leave-active-class="slide-out__bottom-block"
    >
        <template v-if="payment">
            <template v-if="payment.status === BuyingOperationStatus.ACCEPTED">
                <div class="bottom-block">
                    <f7-button
                        class="theme-type"
                        :loading="confirmPaymentLoading"
                        :disabled="confirmPaymentLoading"
                        preloader
                        @click="confirmPayment"
                    >
                        {{ $t('light-payment.submit.paid') }}
                    </f7-button>
                </div>
            </template>
            <template v-else-if="
                payment.status === BuyingOperationStatus.CANCELED ||
                payment.status === BuyingOperationStatus.CONFIRMED ||
                payment.status === BuyingOperationStatus.REJECTED
            ">
                <div class="bottom-block">
                    <f7-button
                        class="theme-type"
                        @click="closeView"
                    >
                        {{ $t('g.close') }}
                    </f7-button>
                </div>
            </template>
        </template>
    </transition>
</template>

<script setup lang="ts">
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import {PropType, ref, toRef, UnwrapRef} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {f7} from "framework7-vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n({useScope: 'global'});

const props = defineProps({
    payment: {
        type: Object as PropType<PurchaseOperation>,
        required: true
    },
});
const payment = toRef(props, 'payment');
const confirmPaymentLoading = ref(false);
const emits = defineEmits(['closeView', 'openInfoPopup', 'confirmPayment']);

const confirmPayment = () => {
    confirmPaymentLoading.value = true;
    emits('confirmPayment', () => {
        confirmPaymentLoading.value = false;
    })
}
const closeView = () => {
    emits('closeView');
}
</script>

<style lang="scss">
.bottom-block {
    background-color: #FFFFFF;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px 16px calc(16px + var(--f7-safe-area-bottom));
    z-index: 100;
    display: flex;
    gap: 16px;

    a {
        flex: 2;

        //& :nth-child(1) {
        //    flex: 1 0 46px;
        //    background: #8E8E93;
        //}
    }

    &::before {
        content: '';
        position: absolute;
        background-color: var(--grey8);
        display: block;
        z-index: 15;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        transform-origin: 50% 100%;
        transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    }
}
</style>