import Operation from "@models/operations/Operation";
import {Expose, Type} from "class-transformer";
import ProjectOperation from "@models/operations/components/ProjectOperation";
import Agent from "@models/operations/components/Agent";
import CurrencyOperation from "@models/operations/components/CurrencyOperation";
import RequisitesOperation from "@models/operations/components/RequisitesOperation";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import {OperationType_N} from "@enums/OperationType_N";
import {P_WITHDRAWAL_STATUS} from "@/entities/enums/PWithdrawalStatus";
import PaymentSystemOperation from "@models/operations/components/PaymentSystemOperation";

export enum WithdrawalWalletOperationOperationStatus {
    PENDING = "pending",
    FAILED = "failed",
    CANCELED = "canceled",
    COMPLETED = "completed"
}

/**
 * {
 *     "id": 11,
 *     "operation_type": "client_transfer",
 *     "msid": "ef5fbf94-2bf3-47a2-bf1a-631e23c4e7d6",
 *     "amount": 1.12,
 *     "amount_fiat": 1.12,
 *     "fee": 0,
 *     "fee_fiat": 0.5,
 *     "amount_total": 1.12,
 *     "amount_fiat_total": 1.62,
 *     "currency": {
 *         "id": 14,
 *         "name": "USDT",
 *         "abbr": "USDT"
 *     },
 *     "currency_fiat": {
 *         "id": 2,
 *         "name": "USD",
 *         "abbr": "$"
 *     },
 *     "psystem": {
 *         "id": 200049,
 *         "target_type": "wallet",
 *         "name": "USDT BEP20",
 *         "sys_name": "usdt_bep20",
 *         "img_path": "https://api.lanpay.xyz/storage/psystems/thumb/jLZ8icL8k3Bh8Ha0Bo2hKjQjr4IcActMdpKXP5oK.png"
 *     },
 *     "pay_data": {
 *         "requisites": {
 *             "address": "0x2D818579B5a5133376671f03A6c1B99df67F5886",
 *             "name": null,
 *             "address_type": "usdt_bep20",
 *             "comment": null
 *         },
 *         "checkup_url": ""
 *     },
 *     "status": "PENDING",
 *     "created_at": "2024-06-05T12:56:29.000000Z",
 *     "completed_at": null
 * }
 */