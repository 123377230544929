<template>
    <div class="message-not-found-payment fade-in">
        <div class="display-flex align-items-center justify-content-center flex-direction-column">
            <span class="amount">{{ amount }} {{ currencySign }}</span>
            <span class="title">{{ title }}</span>
            <span class="text">{{ text }}</span>
        </div>

        <div class="form">
            <input accept="image/*" type="file" ref="input-screenshot" class="input-screenshot"
                   @change="vc.eventFileLoad">
            <div class="screenshot-area">
                <div v-if="screenFile === null" class="fade-in no-content" @click="$refs['input-screenshot'].click()">
                    <span><i class="display-block upload-svg"></i>Загрузить скриншот</span>
                </div>
                <div v-else class="fade-in file-content">
                    <div class="left-side display-inline-flex align-items-center">
                        <img :src="screenFilePath">
                        <span>{{ screenFileName }}</span>
                    </div>
                    <i class="display-block trash-svg" @click="removeFile"></i>
                </div>
            </div>
            <div class="actions">
                <f7-button class="cancel" @click="toPayments">Отмена</f7-button>
                <f7-button class="submit" preloader :loading="submitBtnLoading" :class="{'disabled': screenFile === null}" @click="vc.submit">Готово
                </f7-button>
            </div>
            <p class="hint">Если вы нажали кнопку «Я оплатил» по ошибке - нажмите «Отмена»</p>
        </div>
    </div>
</template>

<script lang="ts">
import Message from "@/entities/Message";
import {computed, defineComponent, PropType} from "vue";
import MessageNotFoundPaymentController from "./ts/MessageNotFoundPaymentController";
import {f7} from "framework7-vue";
import store from "@target/core/store/store";
import Payment from "@/entities/Payment";

export default defineComponent({
    props: {
        message: {
            type: Object as PropType<Message>,
            required: true
        }
    },
    setup(props) {
        const vc = MessageNotFoundPaymentController.getInstance();
        const {screenFile, screenFileName, screenFilePath, submitBtnLoading} = vc;
        const {title, text} = props.message;
        const payment: Payment = computed((): Payment => store.getters.payment.value).value;
        const amount = parseInt(payment.amount.toString());
        const currencySign = computed(() => store.getters['currencySign'].value);

        return {
            currencySign,
            amount,
            submitBtnLoading,
            screenFile,
            screenFileName,
            screenFilePath,
            vc,
            title,
            text
        }
    },
    methods: {
        toPayments() {
            f7.view.main.router.back('/', {force: true, clearPreviousHistory: true});
        },
        removeFile() {
            this.$refs['input-screenshot'].value = null;
            this.vc.resetScreenFile();
        }
    },
    unmounted() {
        this.vc.destructor();
    }
})
</script>

<style lang="scss" src="./index.scss"></style>
