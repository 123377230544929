<template>
    <div class="payment-view__content">
        <template v-if="payment">
            <transition name="fade" mode="out-in">
                <template v-if="payment.status === BuyingOperationStatus.CREATED">
                    <PaymentStatusCreated
                        @cancelPayment="cancelFindAgent"
                        :cancel="true"
                    >
                        <template #timer>
                            <PaymentStatusTimer
                                :timeText="timeText"
                                :percent="percent"
                                :size="100"
                                :borderWidth="8"
                                :hiddenTimer="false"
                                :fontSize="28"
                                class="created"
                            />
                        </template>
                    </PaymentStatusCreated>
                </template>
                <template v-else-if="payment.status === BuyingOperationStatus.ACCEPTED">
                    <div class="wrapper-elements">
                        <PaymentStatusAccepted
                            :payment="payment"
                            :timeText="timeText"
                            :percent="percent"
                            @cancelPayment="cancelFindAgent"
                        />
                    </div>
                </template>
                <template v-else-if="payment.status === BuyingOperationStatus.CASHED">
                    <PaymentStatusCashed :payment="payment" @uploadCheckImages="uploadCheckImages">
                        <template #timer>
                            <PaymentStatusTimer
                                :timeText="timeText"
                                :percent="percent"
                                :size="100"
                                :hidden-timer="false"
                                :borderWidth="8"
                                :fontSize="28"
                                class="cached"
                            />
                        </template>
                    </PaymentStatusCashed>
                </template>
                <template v-else-if="payment.status === BuyingOperationStatus.EXPIRED">
                    <PaymentStatusExpired
                        :payment="payment"
                        :timeText="timeText"
                        :percent="percent"
                        @submit="recreatePayment"
                    >
                        <template #timer>
                            <PaymentStatusTimer
                                :timeText="timeText"
                                :percent="percent"
                                :hiddenTimer="false"
                                :size="100"
                                :borderWidth="8"
                                :fontSize="28"
                                class="expired"
                            />
                        </template>
                    </PaymentStatusExpired>
                </template>
                <template v-else-if="payment.status === BuyingOperationStatus.CONFIRMED">
                    <PaymentStatusConfirmed :payment="payment"/>
                </template>
                <template v-else-if="payment.status === BuyingOperationStatus.CANCELED">
                    <PaymentStatusCanceled :payment="payment"/>
                </template>
                <template v-else-if="payment.status === BuyingOperationStatus.ONAPPEAL">
                    <PaymentStatusOnappeal :payment="payment" @toChat="toChat"/>
                </template>
                <template v-else-if="payment.status === BuyingOperationStatus.REJECTED">
                    <PaymentStatusRejected :payment="payment"/>
                </template>
            </transition>
        </template>
    </div>
</template>

<script setup lang="ts">
import {onMounted, PropType, toRef, UnwrapRef, watch} from "vue";
import PaymentStatusCreated from "./contents/PaymentStatusCreated.vue";
import PaymentStatusTimer from "./atomic/PaymentStatusTimer.vue";
import PaymentStatusCanceled from "./contents/PaymentStatusCanceled.vue";
import PaymentStatusAccepted from "./contents/PaymentStatusAccepted.vue";
import PaymentStatusConfirmed from "./contents/PaymentStatusConfirmed.vue";
import PaymentStatusRejected from "./contents/PaymentStatusRejected.vue";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import PaymentTimerService, {
    ReactivePaymentPercent,
    ReactivePaymentTimeText
} from "@/services/operations/payment/PaymentTimerService";
import PaymentStatusCashed from "@/views/payment/components/contents/PaymentStatusCashed.vue";
import PaymentStatusOnappeal from "@/views/payment/components/contents/PaymentStatusOnappeal.vue";
import PaymentStatusExpired from "../components/contents/PaymentStatusExpired.vue";
import PaymentCheckImage from "@models/operations/payment/PaymentCheckImage";

const props = defineProps({
    payment: {
        type: Object as PropType<PurchaseOperation>,
        required: true
    },
    timerService: {
        type: Object as PropType<PaymentTimerService>,
        required: true
    }
});

const payment = toRef(props, 'payment');
const timerService: PaymentTimerService = props.timerService;
const timeText: ReactivePaymentTimeText = timerService.getTimeText;
const percent: ReactivePaymentPercent = timerService.getPercent;
const emits = defineEmits(['timerExpired', 'cancelPayment', 'toChat', 'recreatePayment', 'uploadCheckImages']);

function uploadCheckImages(checkImages: PaymentCheckImage[], callback: Function, failedCallback: Function) {
    emits("uploadCheckImages", checkImages, callback, failedCallback)
}

const recreatePayment = (callback: Function) => {
    emits('recreatePayment', callback);
}

function cancelFindAgent() {
    emits('cancelPayment');
}

const toChat = () => {
    emits('toChat');
}

onMounted(async () => {
    initTimerService();
});

watch(() => payment.value!.status, () => {
    initTimerService();
});

watch(() => percent.value < 0, (statement) => {
    if(statement)
    emits('timerExpired');
});

const initTimerService = () => {
    if (payment?.value?.status &&
        [
            BuyingOperationStatus.ACCEPTED,
            BuyingOperationStatus.CREATED,
            BuyingOperationStatus.CASHED,
        ].includes(payment.value.status)) {
        timerService.start(payment.value.status, payment.value.updatedAt!);
    } else {
        timerService.stopIntervals();
    }
}
</script>

<style lang="scss">
.payment-view__content {
    position: relative;
    // height: calc(100% - 76px);
    overflow: auto;

    .wrapper-elements {
        padding: 0 16px;
    }

    .cancel {
        margin-bottom: 16px;
    }
}
</style>