<template>
    <div class="popup-navbar">
        <div class="popup-navbar_left" @click="handleLeftClick" v-if="showLeft">
            <i class="svg-arrow-left icon"></i>
        </div>
        <div class="popup-navbar_title">
            <p>{{ title }}</p>
        </div>
        <div class="popup-navbar_right">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import {PropType, toRef} from "vue";

const props = defineProps({
    title: {
        type: Object as PropType<string>,
        required: true
    },
    showLeft: {
        type: Object as PropType<boolean>,
        required: false,
        default: true
    }
});
const emits = defineEmits(["onLeftClick"]);
const title = props.title;
const showLeft = toRef(props, "showLeft")

function handleLeftClick() {
    emits("onLeftClick");
}
</script>

<style lang="scss">
.popup-navbar {
    height: 35px;
    display: flex;
    padding: 0 16px;

    &_left {
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: 8px;
    }

    &_title {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
            @include text(16px, 500, var(--brown1), 16px);
        }
    }

    &_right {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>