<template>
    <div class="operation-client-transfer-status" :class="operation.status.toLocaleLowerCase()">
        {{ Helper.getInstance().convertToHumanPaymentStatus(operation) }}
    </div>
</template>

<script setup lang="ts">
import {PropType, toRef, ToRef} from "vue";
import Helper from "../../../helpers/helper";
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";

const props = defineProps({
    operation: {
        type: Object as PropType<WithdrawalWalletOperation>,
        required: true
    }
});
const operation = toRef(props, 'operation');
</script>

<style lang="scss">
.operation-client-transfer-status {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    border-radius: 100px;
    padding: 3px 8px;
    background-color: #E6F2FF;
    color: var(--blue1);
    display: flex;
    align-items: center;

    &.completed {
        background-color: #eaf9f0;
        color: #32BF61 !important;
    }

    &.failed {
        color: #FF3B30;
        background-color: #ffebeb;
    }

    &.canceled {
        color: #FF3B30;
        background-color: #ffebeb;
    }
}
</style>
