<template>
<div class="payment" :class="'payment-' + operation.id" @click="handleClickItem">
    <div class="body">
        <div class="header-wrap">
            <div class="amount">
                <i class="icon round-arrows-svg"></i>
                <div class="wrapper-numbers">
                    <span class="main" v-format-number>{{ operation.amountTotal }} {{ operation.currency.abbr }}</span>
                    <span class="convert">{{ $t("withdrawal.wallet.operation-text") }} {{ operation.amountFiatTotal }} $</span>
                </div>
            </div>
            <div class="right-side">
                <OperationClientTransferStatus :operation="operation as WithdrawalWalletOperation" />
            </div>
        </div>
        <div class="details-wrap">
            <p class="number">{{ $t('g.request') }} {{ operation.id }} <span
                class="data">{{ convertDatetime(operation.createdAt) }}</span></p>
            <div class="wrapper-right">
            </div>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
import {useI18n} from 'vue-i18n'
// @ts-ignore
import store from "@target/core/store/store";
import {computed, PropType, Ref, toRef} from "vue";
import {f7, useStore} from "framework7-vue";
import Account from "@/entities/Account";
import PaymentIncome from "@/entities/PaymentIncome";
import dayjs from "dayjs";
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";
import {plainToInstance} from "class-transformer";
import {prop} from "dom7";
import OperationClientTransferStatus
    from "@components/operation-cards/client-transfer/OperationClientTransferStatus.vue";
import {OperationType_N} from "@enums/OperationType_N";
import CurrencyOperation from "@models/operations/components/CurrencyOperation";
import PaymentSystemOperation from "@models/operations/components/PaymentSystemOperation";
import {
    WithdrawalWalletOperationOperationStatus
} from "@models/operations/withdrawal/wallet/WithdrawalWalletOperationStatus";

const props = defineProps({
    operation: {
        type: Object as PropType<WithdrawalWalletOperation>,
        required: true,
    }
});
const emits = defineEmits(["onClickItem"]);
const {t} = useI18n({useScope: 'global'});
const operation = toRef(props, "operation");
const account: Ref<Account> = useStore("account");

function convertDatetime(dateString: string) {
    return dayjs(dateString).format('DD.MM.YYYY HH:mm');
}
function handleClickItem() {
    emits("onClickItem", operation.value);
}

function transformOperationToClass(operation: any) {
    return new WithdrawalWalletOperation(
        operation.id,
        operation.operationType,
        operation.msid,
        operation.amount,
        operation.amountFiat,
        operation.fee,
        operation.feeFiat,
        operation.amountTotal,
        operation.amountFiatTotal,
        operation.currency,
        operation.currencyFiat,
        operation.psystem,
        operation.payData,
        operation.status,
        operation.completedAt,
        operation.createdAt
    );
}

async function toPayment() {
    // try {
    //     // @ts-ignore
    //     const el = window.event.target.closest('.payment');
    //     el.classList.add("active-state");
    //     setTimeout(() => {
    //         el.classList.remove("active-state");
    //     }, 100);
    //     await store.dispatch("fetchPaymentIncome", payment.value);
    //     f7.views.current.router.navigate('/payment/income')
    // } catch (e) {
    //     console.log(e);
    // }
}
</script>
