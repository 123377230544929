import Operation from "@models/operations/Operation";
import {Expose, Type} from "class-transformer";
import ProjectOperation from "@models/operations/components/ProjectOperation";
import Agent from "@models/operations/components/Agent";
import CurrencyOperation from "@models/operations/components/CurrencyOperation";
import RequisitesOperation from "@models/operations/components/RequisitesOperation";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import {OperationType_N} from "@enums/OperationType_N";
import {P_WITHDRAWAL_STATUS} from "@/entities/enums/PWithdrawalStatus";
import PaymentSystemOperation from "@models/operations/components/PaymentSystemOperation";
import {
    WithdrawalWalletOperationOperationStatus
} from "@models/operations/withdrawal/wallet/WithdrawalWalletOperationStatus";
import {value} from "dom7";
import {id} from "framework7/shared/utils";
import {currency} from "maz-ui";
import Requisite from "@models/requisite/Requisite";

export default class WithdrawalWalletOperationPayData {
    @Expose({name: "requisites"})
    private _requisites: Requisite;

    @Expose({name: "checkupUrl"})
    private _checkupUrl: string;

    constructor(
        requisites: Requisite,
        checkupUrl: string
    ) {
        this._requisites = requisites;
        this._checkupUrl = checkupUrl;
    }

    get requisites(): Requisite {
        return this._requisites;
    }

    set requisites(value: Requisite) {
        this._requisites = value;
    }

    get checkupUrl(): string {
        return this._checkupUrl;
    }

    set checkupUrl(value: string) {
        this._checkupUrl = value;
    }
}