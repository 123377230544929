<template>
    <div class="simple-page-content-loader">
        <f7-preloader size="28"/>
    </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.simple-page-content-loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>