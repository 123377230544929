<template>
    <f7-page ref="self" :page-content="false" class="select-currency-page">
        <f7-navbar>
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="back">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                {{ $t("g.settings.selectCurrency.title") }}
            </f7-nav-title>
            <f7-nav-right></f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <f7-list class="languages list-dividers list-strong list-outline">
                <f7-list-item @change="updateCurrency" radio radio-icon="end" :title="$t('g.settings.selectCurrency.currencies.RUB')" value="RUB"
                    name="currency" :checked="client.currency == 'RUB'"></f7-list-item>
                <f7-list-item @change="updateCurrency" radio radio-icon="end" :title="$t('g.settings.selectCurrency.currencies.KZT')"
                    value="KZT" name="currency" :checked="client.currency == 'KZT'"></f7-list-item>
                <f7-list-item @change="updateCurrency" radio radio-icon="end" :title="$t('g.settings.selectCurrency.currencies.KGS')" value="KGS"
                    name="currency" :checked="client.currency == 'KGS'"></f7-list-item>
                <f7-list-item @change="updateCurrency" radio radio-icon="end" :title="$t('g.settings.selectCurrency.currencies.UZS')" value="UZS"
                    name="currency" :checked="client.currency == 'UZS'"></f7-list-item>
            </f7-list>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import { f7 } from 'framework7-vue';
import { computed, defineComponent, Ref, ref } from "@vue/runtime-core";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import Account from '@/entities/Account';
import store from "@target/core/store/store";

export default defineComponent({
    setup() {
        const { t } = useI18n({ useScope: 'global' });
        const account = computed((): Account => store.getters.account.value);
        const client = store.getters.client.value;
        // console.log(client);

        return {
            t,
            client,
            account
        }
    },
    computed: {},
    mounted() {

    },
    beforeMount() {
        // StatusBarController.toDarkContent();
    },
    methods: {
        async updateCurrency(e: any) {
            try {
                f7.preloader.show();
                // console.log(e.target.value);
                await store.dispatch('patchClientData', { currency: e.target.value });
                await AppController.getInstance().auth(localStorage.getItem('token'));
                await store.dispatch('fetchAccount', null);
                await store.dispatch('fetchPaymentSystems', null);
                await store.dispatch('fetchCurrencies', null);
                await store.dispatch('fetchRates');
                await store.dispatch('fetchMyPayments', { refresh: true });
                f7.view.main.router.back();
            } catch (e: any) {
                f7.dialog.alert(this.t("g.settings.selectCurrency.alert.first"), this.t("g.settings.selectCurrency.alert.second"));
            } finally {
                f7.preloader.hide();
            }
        },
        back() {
            f7.view.main.router.back();
        },
    },
    unmounted() {

    },
});
</script>

<style lang="scss">
.select-currency-page {
    .title-wrapper {
        align-items: center;
        margin: 27px 16px 5px;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        cursor: pointer;

        .title {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #818E95;
        }
    }
}
</style>
