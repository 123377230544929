import {ref, Ref} from "vue";
import {f7} from "framework7-vue";
import Payment from "@/entities/Payment";
import humps from "lodash-humps-ts";
// @ts-ignore
import store from "@target/core/store/store";
import {catchErrors} from "@/decorators/catch-errors";
import ViewController from "@/interfaces/ViewController";
import i18n from "@/langs/i18n"

declare const window: Window & typeof globalThis & { Pusher: any, Echo: any }

const defaultValues = {
    time: 60000,
}

export default class FindAgentController implements ViewController {
    private static instance?: FindAgentController;
    percent: Ref<number>;
    timeText: Ref<string>;
    time: number;
    interval?: ReturnType<typeof setInterval>;

    static getInstance(): FindAgentController {
        if (typeof this.instance === 'undefined') {
            this.instance = new FindAgentController();
        }
        return this.instance;
    }

    private constructor() {
        this.time = defaultValues.time;
        this.percent = ref(1);
        this.timeText = ref(this.getTimeText());
        this.configure();
    }

    private configure() {
        this.initInterval();
        this.syncSocket();
    }

    private initInterval() {
        const self = this;
        let timeInterval: null | number = null;
        this.interval = setInterval(() => {
            if (timeInterval === null) {
                timeInterval = Math.round(this.time / 1000);
            }
            this.time -= 1000;
            this.getTimeText();
            this.timeText.value = this.getTimeText();
            const z: number = +(1 / timeInterval).toFixed(5);
            this.percent.value = this.percent.value - z;

            if (this.time <= 0) {
                clearInterval(this.interval);
                timeInterval = null;

                f7.view.main.router.back('/', {force: true, clearPreviousHistory: true});
            }
        }, 1000);
    }

    @catchErrors
    private syncSocket() {
        const self = this;

        // window.Echo.channel(localStorage.getItem('ltoken'))
        //     .listen('.PaymentAccepted', async (data: { payment: object }) => {
        //         const payment: Payment = humps(data.payment);
        //         await store.dispatch('setPayment', payment);
        //         clearInterval(this.interval);
        //         f7.view.main.router.navigate('/payment');
        //     })
        //     .listen('.PaymentCanceled', async () => {
        //         self.destructor();
        //         f7.dialog.alert(i18n.global.t("findAgent.controller.noAgent.first"), i18n.global.t("findAgent.controller.noAgent.second"), () => {
        //             f7.view.main.router.back('/', {force: true, clearPreviousHistory: true});
        //         })
        //     });
    }

    @catchErrors
    public async cancelPaymentWithBack() {
        f7.dialog.create({
            title: i18n.global.t("findAgent.controller.cancelPayment.title"),
            text: i18n.global.t("findAgent.controller.cancelPayment.text"),
            buttons: [
                {
                    text: i18n.global.t("findAgent.controller.cancelPayment.no"),
                },
                {
                    text: i18n.global.t("findAgent.controller.cancelPayment.yes"),
                    onClick: async () => {
                        await store.dispatch('cancelPaymentRequest', null);
                        f7.view.main.router.back('/', {force: true, clearPreviousHistory: true});
                    }
                }
            ]
        }).open();
    }

    getTimeText(): string {
        const ms = this.time / 1000;
        const min = Math.floor(ms / 60);
        const sec = Math.floor(ms % 60);
        const secString = sec < 10 ? `0${sec}` : sec;

        return `${min}:${secString}`;
    }

    beforeIn() {

    }

    viewDidLoad() {
    }

    destructor() {
        clearInterval(this.interval);
        // window.Echo.channel(localStorage.getItem('ltoken')).stopListening('.PaymentAccepted');
        // window.Echo.channel(localStorage.getItem('ltoken')).stopListening('.PaymentCanceled');
        FindAgentController.instance = undefined;
    }
}
