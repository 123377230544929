import {getDevice} from "framework7/shared/get-device";
import LogService from "@/services/log-service/LogService";

export default class FirebaseNotificationCordovaPluginService {
    public fcmToken: string | null = null;

    public async initPushNotifications() {
        try {
            const self = this;
            if (getDevice().cordova) {
                if (getDevice().android) {
                    // @ts-ignore
                    cordova.plugins.diagnostic.isRemoteNotificationsEnabled(
                        async (enabled: boolean) => {
                            if (enabled) await self.retrieveAndSaveToken();
                        },
                    );
                } else {
                    // @ts-ignore
                    cordova.plugins.diagnostic.isRegisteredForRemoteNotifications((registered: boolean) => {
                        if (!registered) {
                            // @ts-ignore
                            cordova.plugins.diagnostic.isRemoteNotificationsEnabled(
                                async (enabled: boolean) => {
                                    if (enabled) await self.retrieveAndSaveToken();
                                    else self.requestAuthorization();
                                },
                            );
                        } else {
                            // @ts-ignore
                            cordova.plugins.diagnostic.isRemoteNotificationsEnabled(
                                async (enabled: boolean) => {
                                    if (enabled) await self.retrieveAndSaveToken();
                                },
                            );
                        }
                    });
                }
            }
        } catch (e: any) {}
    }

    private requestAuthorization() {
        const self = this;
        if (getDevice().ios) {
            // @ts-ignore
            cordova.plugins.diagnostic.requestRemoteNotificationsAuthorization({
                successCallback: function () {
                    self.retrieveAndSaveToken();
                },
                errorCallback: function (e: any) {
                    LogService.of().error("FirebaseNotificationCordovaPluginService@requestAuthorization", e.message);
                },
                types: [
                    // @ts-ignore
                    cordova.plugins.diagnostic.remoteNotificationType.ALERT,
                    // @ts-ignore
                    cordova.plugins.diagnostic.remoteNotificationType.SOUND,
                    // @ts-ignore
                    cordova.plugins.diagnostic.remoteNotificationType.BADGE
                ],
                omitRegistration: false
            });
        }
    }

    private async retrieveAndSaveToken() {
        try {
            // @ts-ignore
            this.fcmToken = await cordova.plugins.firebase.messaging.getToken();
        } catch (e: any) {
            LogService.of().error("FirebaseNotificationCordovaPluginService@checkAndSaveFcmToken", e.message);
        }
    }
}