<template>
    <div class="requisite-full-item" @click="handleItemClick">
        <div class="requisite-full-item_top-row">
            <div class="requisite-full-item_psystem-wrapper">
                <img :src="requisite.psystem.imgPath" alt="">
                <p class="requisite-full-item_psystem-name">{{ requisite.psystem.name }}</p>
            </div>
            <div class="requisite-full-item_icon-wrapper" @click.stop="handleClickEditItem">
                <i class="icon edit-svg"></i>
            </div>
        </div>
        <div class="requisite-full-item_middle-row">
            <p class="requisite-full-item_address">{{ requisite.address }}</p>
            <p v-if="requisite.name && requisite.name.length > 0" class="requisite-full-item_name">{{ requisite.name }}</p>
        </div>
        <div class="requisite-full-item_bottom-row" v-if="requisite.comment && requisite.comment.length > 0">
            <i class="icon i-svg-comment"></i>
            <p class="requisite-full-item_comment">{{ requisite.comment }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import {PropType, toRef} from "vue";
import Requisite from "@models/requisite/Requisite";
import {RequisiteAddressType} from "@enums/RequisiteAddressType";
import RouterService from "@/services/RouterService";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";

const {routerService} = AppController.getInstance();
const props = defineProps({
    requisite: {
        type: Object as PropType<Requisite>,
        required: true
    }
});
const emits = defineEmits(["onItemClick", "onEdit"]);
const requisite = toRef(props, "requisite");

function handleClickEditItem() {
    emits("onEdit", requisite.value);
}

function handleItemClick() {
    emits("onItemClick", requisite.value);
}

function handleRequisiteAddress(address: string, addressType: RequisiteAddressType) {
    switch (addressType) {
        case RequisiteAddressType.PHONE:
        case RequisiteAddressType.CARD:
            return "*".concat(address.slice(-4));
    }
}

function handleClickByRequisite(requisite: Requisite) {
    emits("onItemClick", requisite)
}
</script>

<style lang="scss">
.requisite-full-item {
    //display: flex;
    //flex-direction: column;
    padding: 16px;
    cursor: pointer;

    & + .requisite-full-item {
        border-top: 1px solid var(--f7-list-outline-border-color);
    }

    &_comment {
        @include text(14px, 400, var(--brown1), 16.8px);
    }

    &_address {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include text(16px, 700, var(--brown1), 19.2px);
    }

    &_name {
        text-transform: uppercase;
        @include text(12px, 400, var(--brown1), 14.4px);
    }

    &_icon-wrapper {
        cursor: pointer;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_psystem-name {
        @include text(16px, 500, var(--brown1), 16px);
    }

    &_psystem-wrapper {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    &_top-row {
        display: flex;
        justify-content: space-between;

        img {
            width: 24px;
        }
    }

    &_middle-row {
        display: flex;
        margin-top: 12px;
        gap: 2px;
        flex-direction: column;
    }

    &_bottom-row {
        margin-top: 12px;
        display: flex;
    }
}
</style>