import Requisite from "@models/requisite/Requisite";
import {plainToInstance} from "class-transformer";
import {RequisitePsystemTargetType} from "@enums/RequisitePsystemTargetType";
import RequisitePsystem from "@models/requisite/RequisitePsystem";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
// import MainApiService from "@/services/api-service/MainApiService";

export default class RequisiteRepository {
    public async getAll(): Promise<Requisite[]> {
        // const response = await MainApiService.getInstance().get('/api/mobile/client/requisites');
        const response = await ServiceMainApi.of().get<{ data: any }>('/api/mobile/client/requisites');
        const requisites: any = response.data;
        return plainToInstance(Requisite, requisites, {
            excludeExtraneousValues: true,
        }) as Requisite[];
    }

    public async getAllPsystems(target: RequisitePsystemTargetType): Promise<RequisitePsystem[]> {
        // const response = await MainApiService.getInstance().get('/api/mobile/client/ps/list', {
        //     target
        // });
        // const requisites: any = response.data;
        const { data: requisites } = await ServiceMainApi.of().get<{ data: any }>('/api/mobile/client/ps/list', { target });
        return plainToInstance(RequisitePsystem, requisites, {
            excludeExtraneousValues: true,
        }) as RequisitePsystem[];
    }

    public async getAllPsystemsDefault(): Promise<RequisitePsystem[]> {
        // const response = await MainApiService.getInstance().get('/api/mobile/client/ps/list?default');
        // const requisites: any = response.data;
        const { data: requisites } = await ServiceMainApi.of().get<{ data: any }>('/api/mobile/client/ps/list?default');
        return plainToInstance(RequisitePsystem, requisites, {
            excludeExtraneousValues: true,
        }) as RequisitePsystem[];
    }

    async patch(requisite: Requisite) {
        // const data: any = await MainApiService.getInstance().patch(`/api/mobile/client/requisites/${requisite.id}/update`, {
        await ServiceMainApi.of().patch(`/api/mobile/client/requisites/${requisite.id}/update`, {
            psystem_id: requisite.psystem.id,
            name: requisite.name,
            address: requisite.address,
            address_type_id: requisite.addressTypeId,
            comment: requisite.comment,
            is_default: requisite.isDefault
        });
    }

    async delete(requisite: Requisite) {
        // await MainApiService.getInstance().patch(`/api/mobile/client/requisites/${requisite.id}/draft`);
        await ServiceMainApi.of().patch(`/api/mobile/client/requisites/${requisite.id}/draft`);
    }

    async create(requisite: Requisite) {
        // await MainApiService.getInstance().post(`/api/mobile/client/requisites/create`, {
        await ServiceMainApi.of().post(`/api/mobile/client/requisites/create`, {
            psystem_id: requisite.psystem.id,
            name: requisite.name,
            address: requisite.address,
            address_type_id: requisite.addressTypeId,
            comment: requisite.comment,
            is_default: requisite.isDefault
        });
    }
}
