<template>
    <f7-list class="requisites-page_requisites-list" dividers-ios strong-ios outline-ios>
        <template v-for="requisite in requisites" :key="requisite.id">

            <f7-list-item v-if="!fullRequisite" class="requisites-page_requisites-item" link="#" @click="handleEditRequisite(requisite)">
                <template #media>
                    <img :src="requisite.psystem.imgPath" alt="">
                </template>
                <template #title>
                    {{ requisite.psystem.name }}
                    <span class="requisites-list_address">{{ handleRequisiteAddress(requisite.address, requisite.addressType) }}</span>
                </template>
            </f7-list-item>
            <requisites-page-requisites-list-item-full
                v-else
                :requisite="requisite"
                @on-edit="handleEditRequisite"
                @on-item-click="handleSelectRequisite"
            />

        </template>
    </f7-list>
</template>

<script setup lang="ts">
import {PropType, toRef} from "vue";
import Requisite from "@models/requisite/Requisite";
import {RequisiteAddressType} from "@enums/RequisiteAddressType";
import RouterService from "@/services/RouterService";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import RequisitesPageRequisitesListItemFull
    from "@/views/requisites-group/requisites/components/RequisitesPageRequisitesListItemFull.vue";

const { routerService } = AppController.getInstance();
const props = defineProps({
    requisites: {
        type: Object as PropType<Requisite[]>,
        required: true
    },
    fullRequisite: {
        type: Object as PropType<boolean>,
        default: false
    }
});
const emits = defineEmits(["onEditItem", "onSelectItem"]);
const requisites = toRef(props, "requisites");
const fullRequisite = props.fullRequisite;
function handleRequisiteAddress(address: string, addressType: RequisiteAddressType) {
    switch (addressType) {
        case RequisiteAddressType.PHONE:
        case RequisiteAddressType.CARD:
            return "*".concat(address.slice(-4));
    }
}
function handleSelectRequisite(requisite: Requisite) {
    emits("onSelectItem", requisite)
}
function handleEditRequisite(requisite: Requisite) {
    emits("onEditItem", requisite)
}
</script>

<style lang="scss">
.requisites-page_requisites-list {
    .requisites-page_requisites-item {
        --f7-list-item-media-margin: 8px;
        img {
            display: flex;
            align-items: center;
            width: 24px;
            height: 24px;
        }
        .requisites-list_address {
            @include text(16px, 400, var(--grey2), 19.2px);
        }
    }
}
</style>