import {Expose} from "class-transformer";

export default class RequisitesOperation {
    @Expose({name: 'address'})
    private _address: string | null = null;

    @Expose({name: 'name'})
    private _name: string | null = null;

    @Expose({name: 'comment'})
    private _comment: string | null = null;

    @Expose({name: 'addressType'})
    private _addressType: string | null = null;

    @Expose({name: 'addressHidden'})
    private _addressHidden?: boolean = undefined;

    get address(): string | null {
        return this._address;
    }

    get addressHidden(): boolean | undefined {
        return this._addressHidden;
    }

    get name(): string | null {
        return this._name;
    }

    get addressType(): string | null {
        return this._addressType;
    }

    get comment(): string | null {
        return this._comment;
    }
}