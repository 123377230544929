<template>
    <list-item-with-title
        :title="$t('requisite.psystem-name-input.title')"
        :media="requisite.psystem.imgPath!"
        v-model:text="requisite.psystem.name!"
        :loading="loading"
        @on-item-click="handleItemClick"
    />
    <list-input-with-title
        ref="addressEl"
        :title="$t('requisite.address-input.title')"
        :placeholder="$t('requisite.address-input.placeholder')"
        :required="true"
        v-model:text="requisite.address"
    />
    <list-input-with-title
        ref="nameEl"
        :title="$t('requisite.name-input.title')"
        v-model:text="requisite.name"
        :placeholder="$t('requisite.name-input.placeholder')"
        :required="true"
        :hint="$t('requisite.name-input.hint')"
    />
    <list-input-with-title
        :title="$t('requisite.comment-input.title')"
        :placeholder="$t('requisite.comment-input.placeholder')"
        v-model:text="requisite.comment"
        :textarea="true"
        :hint="$t('requisite.comment-input.hint')"
    />
</template>

<script setup lang="ts">

import ListItemWithTitle from "@components/atomic/list-item/ListItemWithTitle.vue";
import {PropType, ref, toRef} from "vue";
import Requisite from "@models/requisite/Requisite";
import ListInputWithTitle from "@components/atomic/list-item/ListInputWithTitle.vue";

const props = defineProps({
    requisite: {
        type: Object as PropType<Requisite>,
        required: true
    },
    loading: {
        type: Object as PropType<boolean>,
        required: false
    }
});
const addressEl = ref(null);
const nameEl = ref(null);
const requisite = defineModel<Requisite>("requisite", {
    required: true
});
const loading = toRef(props, "loading");
const emits = defineEmits(["toSelectPsystem"]);
defineExpose({addressEl, nameEl})
function handleItemClick() {
    emits("toSelectPsystem");
}
</script>

<style scoped lang="scss">

</style>