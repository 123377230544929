import Operation from "@models/operations/Operation";
import {OperationType_N} from "@enums/OperationType_N";
import {Expose, Type} from "class-transformer"
import ProjectOperation from "@models/operations/components/ProjectOperation";
import CurrencyOperation from "@models/operations/components/CurrencyOperation";

export default class PaymentIncomeOperation extends Operation {
    @Expose({name: 'id'})
    private _id: number | null = null;

    @Expose({name: 'project'})
    @Type(() => ProjectOperation)
    private _project: ProjectOperation | null = null;

    @Expose({name: 'extId'})
    private _extId: string | null = null;

    @Expose({name: 'amount'})
    private _amount: number | null = null;

    @Expose({name: 'fromAmount'})
    private _fromAmount: number | null = null;

    @Expose({name: 'fromRate'})
    private _fromRate: number | null = null;

    @Expose({name: 'fromRate'})
    private _currency: number | null = null;

    @Expose({name: 'fromCurrency'})
    @Type(() => CurrencyOperation)
    private _fromCurrency: CurrencyOperation | null = null;

    constructor() {
        super(OperationType_N.purchase);
    }

    get id(): number | null {
        return this._id;
    }

    get extId(): string | null {
        return this._extId;
    }

    get amount(): number | null {
        return this._amount;
    }

    get project(): ProjectOperation | null {
        return this._project;
    }

    get fromRate(): number | null {
        return this._fromRate;
    }

    get fromAmount(): number | null {
        return this._fromAmount;
    }

    get fromCurrency(): CurrencyOperation | null {
        return this._fromCurrency;
    }
}