<template>
    <f7-app v-bind="vc.config">
        <Transition name="fade" mode="out-in">
            <MainViews v-if="isAuth"/>
            <AuthViews v-else/>
        </Transition>
    </f7-app>
</template>

<script lang="ts">
// @ts-ignore
import {f7, f7ready} from 'framework7-vue';
import AppController from "./ts/AppController";
import AuthViews from "../views/LoginView.vue";
import MainViews from "../views/MainViews.vue";
import {getDevice} from "framework7/shared/get-device";
import cordovaApp from '../../core/cordova-app.js';
import {ref} from "vue";

export default {
    components: {
        AuthViews,
        MainViews
    },
    setup() {
        const vc = AppController.getInstance();
        vc.init();
        const initLoading = ref(false);
        return {
            initLoading,
            vc,
            isAuth: vc.isAuth
        }
    },

    mounted() {
        const self = this;

        f7ready(() => {
            if (getDevice().cordova) {
                if (getDevice().android) {
                    self.setTopOffsetAndroid();
                }
                cordovaApp.init(f7);
            }
        });
    },
    methods: {
        setTopOffsetAndroid() {
            // const rootEl = document.querySelector(':root') as HTMLElement;
            // rootEl.style.setProperty('--f7-safe-area-top', '4.40625vh');
        },
    }
}
</script>
