<template>
    <div class="withdrawal-status" :class="payment.status.toLocaleLowerCase()">
        {{ Helper.getInstance().convertToHumanPaymentStatus(payment) }}
    </div>
</template>

<script setup lang="ts">
import Helper from "@/helpers/helper";
import {PropType, toRef, ToRef} from "vue";
import Payment from "@/entities/Payment";
import PaymentWithdrawal from "@/entities/PaymentWithdrawal";
const props = defineProps({
    payment: {
        type: Object as PropType<PaymentWithdrawal>,
        required: true
    }
});
const payment: ToRef<PaymentWithdrawal> = toRef(props, 'payment');
</script>

<style lang="scss">
.withdrawal-status {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    border-radius: 100px;
    padding: 3px 8px;
    background-color: #E6F2FF;
    color: var(--blue1);

    &.cashed {
    }

    &.onappeal {
        color: #CF7529 !important;
        background-color: #FAEACC;
    }

    &.confirmed {
        background-color: #eaf9f0;
        color: #32BF61 !important;
    }

    &.created {
    }

    &.accepted {
    }

    &.expired {
        color: #FF3B30;
        background-color: #ffebeb;
    }


    &.canceled {
        background-color: #f3f4f5;
        color: var(--grey2) !important;
    }

    &.completed {
        background-color: #eaf9f0;
        color: #34C759 !important;
    }
}
</style>