<template>
<f7-popup
    v-model:opened="popupState.opened"
    class="popup-with-countries"
    swipe-to-close="to-bottom"
    swipe-handler=".header, .search-country"
>
    <div class="page">
        <div class="page-content">
            <div class="header">
                <span class="tint"></span>
                <p class="title">{{ $t('g.login.step1.popup.title') }}</p>
            </div>
            <div class="body">
                <div class="search-country">
                    <f7-input
                        type="text"
                        :placeholder="$t('g.login.step1.popup.search-placeholder')"
                        clear-button
                        v-model:value="searchQuery"
                    />
                </div>
                <f7-list class="countries" strong>
                    <template v-for="language in languagesList" :key="language.value">
                        <f7-list-item
                            class="country"
                            radio
                            radio-icon="end"
                            :value="language.value"
                            :checked="language.value == lang"
                            @change="selectLanguage"
                        >
                            <template #inner>
                                <div class="left-side">
                                    <div class="option-flag">
                                        <span>{{ language.flag }}</span>
                                    </div>
                                    <div class="option-name">
                                        <span>{{ language.name }}</span>
                                    </div>
                                </div>
                            </template>
                        </f7-list-item>
                    </template>
                </f7-list>
            </div>
        </div>
    </div>
</f7-popup>
</template>

<script setup lang="ts">
import {reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import {computed} from "@vue/runtime-core";
import LangService from "@/services/LangService";
import {f7} from "framework7-vue";
import {ModelValueSimple} from "@/components/maz/types";

let lang = computed(() => LangService.getInstance().get());
const {t} = useI18n({useScope: 'global'});

const popupState = ref({
    opened: false
})
const searchQuery = ref('');
defineExpose({
    popupState
})
const emits = defineEmits(['close'])
const languages = computed(() => [
    {
        "value": "ru",
        "name": t(`g.settings.selectLanguage.languages.ru`),
        "flag": "🇷🇺"
    },
    {
        "value": "en",
        "name": t(`g.settings.selectLanguage.languages.en`),
        "flag": "🇺🇸"
    }
]);

const languagesList = computed(() => {
    return searchQuery.value
            ? languages.value.filter((option) => {
                return (
                        searchInValue(option.name, searchQuery.value)
                )
            })
            : languages.value
});

const searchInValue = (value?: ModelValueSimple, query?: string) => {
    return query && value?.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase())
}

function selectLanguage(e: any) {
    f7.preloader.show();
    LangService.getInstance().set(e.target.value);
    setTimeout(() => {
        popupState.value.opened = false;
        f7.preloader.hide();
    }, 314);
    emits('close')
}
</script>
