import {computed, ref, Ref} from "vue";
import {f7, f7ready} from "framework7-vue";
import ViewController from "@/interfaces/ViewController";
import {catchErrors} from "@/decorators/catch-errors";
// @ts-ignore
import store from "@target/core/store/store";
import Message, {MessageType} from "@/entities/Message";
import Payment from "@/entities/Payment";
import {autobind} from "@/decorators/autobind";
import humps from "lodash-humps-ts";
import { nextTick } from "framework7/shared/utils";
import PaymentWithdrawal from "@/entities/PaymentWithdrawal";
import GPWithdrawalController from "@/controllers/GPWithdrawalController";
import GPController from "@/controllers/GPController";
import i18n from "@/langs/i18n";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import {FirebaseEvents} from "@/services/firebase/analytics/FirebaseEvents";
import FirebaseEventBuilder from "@/services/firebase/analytics/FirebaseEventBuilder";

declare const window: Window & typeof globalThis & { Pusher: any, Echo: any }

const defaultValues = {
    time: 0,
}

export default class PaymentViewController implements ViewController {
    private static instance?: PaymentViewController;
    timeText: Ref<string>;
    percent: Ref<number>;
    time: number;
    interval?: ReturnType<typeof setInterval>;
    intervalRestUpdatePayment?: ReturnType<typeof setInterval>;
    title: Ref<string>;
    subtitle: Ref<string>;
    hint: Ref<string>;
    fromHistory: boolean;
    screenFile: Ref<File | null> = ref(null);
    fileToUploadPath: Ref<string | null> = ref(null);

    constructor() {
        this.percent = ref(1);
        const payment: Payment = store.getters.payment.value;
        this.time = defaultValues.time;
        this.title = ref("");
        this.subtitle = ref("");
        this.hint = ref("");
        this.timeText = ref(this.getTimeText());
        this.fromHistory = false;
        this.syncSocket();
    }
    configure() {
        const payment: Payment = computed((): Payment => store.getters.payment.value).value;
        // console.log(store.getters.payment)
        if (payment) {
            this.configurateTextOnView();
            clearInterval(this.interval);

            const statusesWithTimer = ['ACCEPTED', 'CASHED', 'DISPUTED', 'CREATED'];
            if (statusesWithTimer.includes(payment.status)) {
                this.defineStatusLiveTime();
                this.startRestApiInterval();

                if (this.time > 0) {
                    this.timeText.value = this.getTimeText();
                    this.percent.value = 1;
                    this.initInterval();
                } else {
                    this.percent.value = 0;
                }
            }
        } else {
            console.log('payment not found')
        }
    }

    public removeImage() {
        this.screenFile.value = null;
        this.fileToUploadPath.value = null;
    }

    private defineStatusLiveTime() {
        const payment = store.getters['payment'].value as Payment | undefined;
        if (typeof payment !== "undefined") {
            this.time = GPController.getStatusMM(payment);
        }
    }

    private async startRestApiInterval() {
        clearInterval(this.intervalRestUpdatePayment);
        this.intervalRestUpdatePayment = setInterval(async () => {
            await store.dispatch('checkTransactionStatus', null);
        }, 15000);
    }

    public eventFileLoad(e: Event) {
        const file = (e.target as HTMLInputElement).files![0];
        const extension = file.type.split('/')[1];
        const fileSize = file.size / 1024 / 1024;

        if (fileSize > 10) {
            f7.dialog.alert(i18n.global.t("payment.controller.maxImgSize"), i18n.global.t("payment.controller.alert.second"));
            return false;
        }
        if (!['jpeg', 'jpg', 'png'].includes(extension)) {
            f7.dialog.alert(i18n.global.t("payment.controller.imgFormat"), i18n.global.t("payment.controller.alert.second"));
            return false;
        }

        this.screenFile.value = file;
        this.fileToUploadPath.value = URL.createObjectURL(file);
    }

    public setFromHistory(state: boolean) {
        this.fromHistory = state;

        if (state) {
            this.disableInterval();
        }
    }

    public disableInterval() {
        clearInterval(this.interval);
    }

    public configurateTextOnView() {
        const payment: Payment = store.getters.payment.value;
        // console.log(payment, 2)
        switch (payment.status) {
            case "CREATED":
                this.title.value = i18n.global.t("payment.controller.CREATED.title");
                this.subtitle.value = i18n.global.t("payment.controller.CREATED.subtitle");
                this.hint.value = i18n.global.t("payment.controller.CREATED.hint");
                break;
            case "CONFIRMED":
                this.title.value = i18n.global.t("payment.controller.ACTIVATED.title");
                this.subtitle.value = i18n.global.t("payment.controller.ACTIVATED.subtitle");
                this.hint.value = i18n.global.t("payment.controller.ACTIVATED.hint");
                break;
            case "ACCEPTED":
                this.title.value = i18n.global.t("payment.controller.ACCEPTED.title");
                this.subtitle.value = i18n.global.t("payment.controller.ACCEPTED.subtitle");
                break;
            case "CANCELED":
                this.title.value = i18n.global.t("payment.controller.CANCELED.title");
                this.subtitle.value = i18n.global.t("payment.controller.CANCELED.subtitle");
                break;
            case "DISPUTED":
                this.title.value = i18n.global.t("payment.controller.DISPUTED.title");
                this.subtitle.value = i18n.global.t("payment.controller.DISPUTED.subtitle");
                this.hint.value = i18n.global.t("payment.controller.DISPUTED.hint");
                break;
            case "DECLINED":
                this.title.value = i18n.global.t("payment.controller.DECLINED.title");
                this.subtitle.value = i18n.global.t("payment.controller.DECLINED.subtitle");
                this.hint.value = i18n.global.t("payment.controller.DECLINED.hint");
                break;
            case "CASHED":
                this.title.value = i18n.global.t("payment.controller.CASHED.title");
                this.subtitle.value = i18n.global.t("payment.controller.CASHED.subtitle");
                this.hint.value = i18n.global.t("payment.controller.CASHED.hint");
                break;
            case "REJECTED":
                this.title.value = i18n.global.t("payment.controller.REJECTED.title");
                this.subtitle.value = i18n.global.t("payment.controller.REJECTED.subtitle");
                this.hint.value = i18n.global.t("payment.controller.REJECTED.hint");
                break;
        }
        // console.log(this.title)
    };

    private syncSocket() {
        const self = this;

        // window.Echo.channel(localStorage.getItem('ltoken'))
        //     .listen('.PaymentConfirmed', async () => {
        //         await store.dispatch('fetchPayment', null);
        //         await store.dispatch('fetchAccount', null);
        //         // console.log('.PaymentConfirmed')
        //         f7.views.main.router.navigate('/payment', {transition: 'f7-fade'});
        //     })
        //     .listen('.PaymentRejected', async () => {
        //         console.log('.PaymentRejected')
        //         await store.dispatch('fetchPayment', null);
        //     })
        //     .listen('.PaymentDeclined', async (message: any) => {
        //         // console.log('.PaymentDeclined', message)
        //         await store.dispatch('fetchPayment', null);
        //     })
    }

    private initInterval() {
        const self = this;
        let timeInterval: null | number = null;
        this.interval = setInterval(() => {
            // console.log('payment interval');
            if (timeInterval === null) {
                timeInterval = Math.round(this.time / 1000);
            }
            this.time -= 1000;
            this.getTimeText();
            this.timeText.value = this.getTimeText();
            const z: number = +(1 / timeInterval).toFixed(5);
            this.percent.value = this.percent.value - z;

            // if (this.time <= 0) {
            //     const payment: Payment = store.getters.payment.value;
            //     store.dispatch('fetchPayment', null);
            //     if (payment.status === 'ACCEPTED') {
            //         clearInterval(this.interval);
            //         timeInterval = null;
            //         this.percent.value = 0;
            //     }
            //     if (payment.status === 'CASHED') {
            //         this.hint.value = i18n.global.t("payment.controller.statusCheckMessage")
            //         clearInterval(this.interval);
            //         timeInterval = null;
            //         this.percent.value = 0;
            //     }
            // }
        }, 1000);
    }

    getTimeText(): string {
        const ms = this.time / 1000;
        const min = Math.floor(ms / 60);
        const sec = Math.floor(ms % 60);
        const secString = sec < 10 ? `0${sec}` : sec;

        return `${min}:${secString}`;
    }

    static getInstance(): PaymentViewController {
        if (typeof this.instance === 'undefined') {
            this.instance = new PaymentViewController();
        }
        return this.instance;
    }

    destructor() {
        clearInterval(this.interval);
        clearInterval(this.intervalRestUpdatePayment);
        // console.log('PaymentViewController: destructor');
        // if (localStorage.getItem('ltoken')) {
        //     window.Echo.channel(localStorage.getItem('ltoken')).stopListening('.PaymentConfirmed');
        //     window.Echo.channel(localStorage.getItem('ltoken')).stopListening('.PaymentRejected');
        //     window.Echo.channel(localStorage.getItem('ltoken')).stopListening('.PaymentDeclined');
        // }
        PaymentViewController.instance = undefined;
        store.dispatch('setPayment', null);
    }

    public async cancelPayment() {
        const payment: Payment = store.getters.payment.value;
        f7.dialog.create({
            title: i18n.global.t("payment.controller.cancelPayment.title"),
            text: i18n.global.t("payment.controller.cancelPayment.text"),
            buttons: [
                {
                    text: i18n.global.t("payment.controller.cancelPayment.no"),
                },
                {
                    text: i18n.global.t("payment.controller.cancelPayment.yes"),
                    onClick: async () => {
                        FirebaseService.of().analyticsEvent(
                            FirebaseEvents.PAYMENT_CANCEL,
                            FirebaseEventBuilder.of().build()
                        );
                        await store.dispatch('cancelPaymentRequest', null);
                        await f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
                        // await store.dispatch('setPayment', undefined);
                    }
                }
            ]
        }).open();
    }

    public async cancelFindAgent() {
        f7.dialog.create({
            title: i18n.global.t("findAgent.cancel.title"),
            text: i18n.global.t("findAgent.cancel.text"),
            buttons: [
                {
                    text: i18n.global.t("findAgent.cancel.no"),
                },
                {
                    text: i18n.global.t("findAgent.cancel.yes"),
                    onClick: async () => {
                        f7.preloader.show();
                        await store.dispatch('cancelPaymentRequest', null);
                        // await store.dispatch('setPayment', undefined);
                        f7.preloader.hide();
                        f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
                    }
                }
            ]
        }).open();
    }

    @autobind
    @catchErrors
    public async cancelPaymentWithBack() {
        // f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
        f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
    }
}
