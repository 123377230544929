<template>
    <f7-page class="withdrawal-dev-view">
        <div class="display-flex flex-direction-column align-items-center">
            <div class="i-svg-sloth-welcome"></div>
            <p class="title">{{ $t("g.withdrawal.dev.title") }}</p>
            <p class="subtitle" v-html="$t('g.withdrawal.dev.subtitle')" />
        </div>
    </f7-page>
</template>

<style lang="scss">
.withdrawal-dev-view {
    padding-top: 56px;
    .title {
        text-align: center;
        margin-top: 16px;
        @include text(24px, 400, var(--grey2));
    }
    .subtitle {
        text-align: center;
        margin-top: 16px;
        @include text(16px, 400, #ACB4BE);
    }
}
</style>