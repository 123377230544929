import {Expose} from "class-transformer";
import {RequisitePsystemTargetType} from "@enums/RequisitePsystemTargetType";

export default class PaymentSystemOperation {
    @Expose({name: 'id'})
    private _id: number;

    @Expose({name: 'imgPath'})
    private _imgPath: string;

    @Expose({name: 'name'})
    private _name: string;

    @Expose({name: 'sysName'})
    private _sysName: string;

    @Expose({name: "targetType"})
    private _targetType: RequisitePsystemTargetType;

    @Expose({name: "addressTypes"})
    private _addressTypes: number[];

    constructor(
        id: number,
        name: string,
        targetType: RequisitePsystemTargetType,
        sysName: string,
        imgPath: string,
        addressTypes: number[]
    ) {
        this._id = id;
        this._name = name;
        this._targetType = targetType;
        this._sysName = sysName;
        this._imgPath = imgPath;
        this._addressTypes = addressTypes;
    }

    get id(): number | null {
        return this._id;
    }

    get imgPath(): string | null {
        return this._imgPath;
    }

    get name(): string | null {
        return this._name;
    }

    set id(value: number) {
        this._id = value;
    }
    set imgPath(value: string) {
        this._imgPath = value;
    }
    set name(value: string) {
        this._name = value;
    }
    get sysName(): string {
        return this._sysName;
    }

    set sysName(value: string) {
        this._sysName = value;
    }
    get targetType(): RequisitePsystemTargetType {
        return this._targetType;
    }

    set targetType(value: RequisitePsystemTargetType) {
        this._targetType = value;
    }
    get addressTypes(): number[] {
        return this._addressTypes;
    }

    set addressTypes(value: number[]) {
        this._addressTypes = value;
    }
}