import {autobind} from "@/decorators/autobind";
import {Attachment, ChatMessage} from "@/entities/chat/ChatPayload";
import ViewController from "@/interfaces/ViewController";
import i18n from "@/langs/i18n";
import MainApiService from "@/services/api-service/MainApiService";
import {f7} from "framework7-vue";
import {ToRef, ref} from "vue";
import {ChatAttachmentData} from "@/types/ChatAttachmentData";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";

export default class ChatViewController implements ViewController {
    private static _instance?: ChatViewController;
    public attachments: ToRef<ChatAttachmentData[]> = ref([]);

    static getInstance(): ChatViewController {
        if (typeof this._instance === "undefined") this._instance = new ChatViewController();
        return this._instance;
    }

    @autobind
    public async uploadFile(e: Event): Promise<boolean> {
        const file = (e.target as HTMLInputElement).files![0];

        if (!this.isFileSizeValid(file) || !this.isExtensionValid(file)) {
            return false;
        }

        this.attachFile(file);
        return true;
    }

    private isFileSizeValid(file: File): boolean {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 10) {
            //@ts-ignore
            f7.dialog.alert(i18n.global.t("g.errors.chat.max-size"), i18n.global.t("g.errors.alert.default-title"));
            return false;
        }
        return true;
    }

    private isExtensionValid(file: File): boolean {
        const extension = file.type.split('/')[1];
        if (!['jpeg', 'jpg', 'png', 'pdf'].includes(extension)) {
            f7.dialog.alert(i18n.global.t("g.errors.chat.invalid-format"), i18n.global.t("g.errors.alert.default-title"));
            return false;
        }
        return true;
    }

    private attachFile(file: File): void {
        this.attachments.value.push({
            file: file,
            path: URL.createObjectURL(file)
        });
        this.filterAttachmentsByTypes();
    }

    // private async switchFlowByFileType(file: File) {
    //     if (file.type.match('.*pdf.*')) {
    //         await this.sendTextMessage();
    //     } else {
    //         this.attachFile(file);
    //     }
    // }

    public filterAttachmentsByTypes() {
        const types = ['pdf'];
        const matchValues = types.toString().split(',').join('|');
        return this.attachments.value.filter((el: ChatAttachmentData) => !el.file.type.match(`(${matchValues})`))
    }

    public fileIsPDF(file: File): boolean {
        return !!file.type.match('.*pdf.*');
    }

    @autobind
    public removeAttachment() {
        this.attachments.value.pop();
    }

    public async sendTextMessage(payload: {
        msid: string,
        message: string,
        wait_files?: number
    }): Promise<ChatMessage> {
        // return await MainApiService.getInstance().post('/api/mobile/client/chat', payload);
         const { data } = await ServiceMainApi.of().post<{data: ChatMessage}>('/api/mobile/client/chat', payload);
         return data;
    }

    // public async sendFilePDF() {
    //
    // }

    public async sendFileMessage(payload: { chatId: number, messageId: number, file: File }): Promise<Attachment> {
        const formData = new FormData();
        formData.append('chat_id', payload.chatId.toString());
        formData.append('message_id', payload.messageId.toString());
        formData.append('file', payload.file);
        // return await MainApiService.getInstance().post('/api/mobile/client/chat/message/file', formData, true);
        const { data } = await ServiceMainApi.of().post<{ data: any }>('/api/mobile/client/chat/message/file', formData, true);
        return data;
    }

    destructor(): void {
        ChatViewController._instance = undefined;
    }
}
