import {Expose, Type} from "class-transformer";
import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";

export default class AgentSumRanges {
    @Expose({name: "currency"})
    @Type(() => AgentsAmountRanges)
    private readonly _currency: AgentsAmountRanges;
    @Expose({name: "origin"})
    @Type(() => AgentsAmountRanges)
    private readonly _origin: AgentsAmountRanges;

    public constructor(
        currency: AgentsAmountRanges,
        origin: AgentsAmountRanges,
    ) {
        this._currency = currency;
        this._origin = origin;
    }

    get origin(): AgentsAmountRanges {
        return this._origin;
    }
    get currency(): AgentsAmountRanges {
        return this._currency;
    }
}