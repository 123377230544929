<template>
    <div class="payment-meta" v-if="payment">
        <div class="payment-meta__block">
            <p class="payment-meta__text">{{ $t('g.operation.meta.createdAt') }}</p>
            <p class="payment-meta__text">{{ payment.getHumanCreateAt() }}</p>
        </div>
        <div class="payment-meta__block">
            <p class="payment-meta__text">{{ $t('g.operation.meta.sum') }}</p>
            <p class="payment-meta__text" v-format-number>{{ payment.amountUsd }} $</p>
        </div>
        <div class="payment-meta__block">
            <p class="payment-meta__text">{{ $t('g.operation.meta.number') }}</p>
            <p class="payment-meta__text">{{ payment.id }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">

import {PropType, toRef, UnwrapRef} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {ReactiveBuyingOperation} from "@/services/operations/AbstractBuyingService";

const props = defineProps({
    payment: {
        type: Object as PropType<UnwrapRef<PurchaseOperation>>,
        required: true
    },
});

const payment: ReactiveBuyingOperation = toRef(props, 'payment');

</script>

<style lang="scss">
.payment-meta {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    padding: 0;
    &__block {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: self-start;

        &:nth-child(2) {
            align-items: center;
        }
        &:nth-child(3) {
            align-items: self-end;
        }
    }
    &__text {
        @include text(10.5px, 400, #ACB4BE, 14.4px);

        @media (min-width: 375px) {
            @include text(11px, 400, #ACB4BE, 14.4px);
        }
        @media (min-width: 425px) {
            @include text(12px, 400, #ACB4BE, 14.4px);
        }
    }
}
</style>