<template>
    <div>
        <SlothBlock
            :type="SLOTH_TYPE.WORK"
            :title="$t('simple-requisites-view.empty-state.title')"
            :subtitle="$t('simple-requisites-view.empty-state.subtitle')"
        >
            <template #button>
                <f7-button
                    class="theme-type"
                    @click="submitEvent"
                >
                    {{ $t('simple-requisites-view.empty-state.submit') }}
                </f7-button>
            </template>
        </SlothBlock>
    </div>
</template>

<script setup lang="ts">
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import SlothBlock from "@components/messages/SlothBlock.vue";

const emits = defineEmits(['submit']);
const submitEvent = () => {
    emits('submit');
};
</script>

<style scoped lang="scss">

</style>