<template>
    <div class="requisites-page_requisites">
        <requisites-page-requisites-filters @on-update="handleFilterUpdate"/>
        <template v-if="requisites.length > 0">
            <requisites-page-requisites-list
                :requisites="requisites"
                @on-edit-item="handleEditItem"
                @on-select-item="handleSelectItem"
                :full-requisite="pageOpenIn === PageOpenIn.POPUP"
            />

            <requisites-page-hint />
        </template>
        <requisites-page-requisites-empty-state v-else />
    </div>
</template>

<script setup lang="ts">

import RequisitesPageRequisitesFilters
    from "@/views/requisites-group/requisites/components/RequisitesPageRequisitesFilters.vue";
import {RequisitesTypeFilter} from "@/views/requisites-group/requisites/types/RequisitesTypeFilter";
import RequisitesPageHint from "@/views/requisites-group/requisites/components/RequisitesPageHint.vue";
import RequisitesPageRequisitesList
    from "@/views/requisites-group/requisites/components/RequisitesPageRequisitesList.vue";
import {PropType, toRef} from "vue";
import Requisite from "@models/requisite/Requisite";
import {PageOpenIn} from "@enums/PageOpenIn";
import RequisitesPageRequisitesEmptyState
    from "@/views/requisites-group/requisites/components/RequisitesPageRequisitesEmptyState.vue";

const handleFilterUpdate = (filter: RequisitesTypeFilter) => {
    emits("onFilterEvent", filter);
}
const props = defineProps({
    requisites: {
        type: Object as PropType<Requisite[]>,
        required: true
    },
    pageOpenIn: {
        type: Object as PropType<PageOpenIn>,
        required: true
    }
});
const emits = defineEmits(["onEditItem", "handleSelectItem", "onFilterEvent"]);
const requisites = toRef(props, "requisites");
const pageOpenIn = props.pageOpenIn;
function handleEditItem(requisite: Requisite) {
    emits("onEditItem", requisite);
}
function handleSelectItem(requisite: Requisite) {
    emits("handleSelectItem", requisite);
}
const handleClickFilter = () => {

}
</script>

<style lang="scss">
.requisites-page_requisites {
    margin-top: 24px;
    .requisites-page_requisites-list {
        & + .requisites-page_hint {
            margin-top: 16px;
        }
    }
}
</style>