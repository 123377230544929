export default function disableBounce(global: Object) {
    let startY = 0;
    let enabled = false;
    let supportsPassiveOption = false;
    try {
        let opts = Object.defineProperty({}, "passive", {
            get: function () {
                supportsPassiveOption = true
            }
        });
        // @ts-ignore
        window.addEventListener("test", null, opts)
    } catch (e) {
    }
    // @ts-ignore
    let handleTouchmove = function (evt) {
        let el = evt.target;
        let zoom = window.innerWidth / window.document.documentElement.clientWidth;
        if (evt.touches.length > 1 || zoom !== 1) {
            return
        }
        while (el !== document.body && el !== document) {
            let style = window.getComputedStyle(el);
            if (!style) {
                break
            }
            if (el.nodeName === "INPUT" && el.getAttribute("type") === "range") {
                return
            }
            let scrolling = style.getPropertyValue("-webkit-overflow-scrolling");
            let overflowY = style.getPropertyValue("overflow-y");
            let height = parseInt(style.getPropertyValue("height"), 10);
            let isScrollable = scrolling === "touch" && (overflowY === "auto" || overflowY === "scroll");
            let canScroll = el.scrollHeight > el.offsetHeight;
            if (isScrollable && canScroll) {
                let curY = evt.touches ? evt.touches[0].screenY : evt.screenY;
                let isAtTop = startY <= curY && el.scrollTop === 0;
                let isAtBottom = startY >= curY && el.scrollHeight - el.scrollTop === height;
                if (isAtTop || isAtBottom) {
                    evt.preventDefault()
                }
                return
            }
            el = el.parentNode
        }
        evt.preventDefault()
    };
    // @ts-ignore
    let handleTouchstart = function (evt) {
        startY = evt.touches ? evt.touches[0].screenY : evt.screenY
    };
    let enable = function () {
        window.addEventListener("touchstart", handleTouchstart, supportsPassiveOption ? {passive: false} : false);
        window.addEventListener("touchmove", handleTouchmove, supportsPassiveOption ? {passive: false} : false);
        enabled = true
    };
    let disable = function () {
        window.removeEventListener("touchstart", handleTouchstart, false);
        window.removeEventListener("touchmove", handleTouchmove, false);
        enabled = false
    };
    let isEnabled = function () {
        return enabled
    };
    let testDiv = document.createElement("div");
    document.documentElement.appendChild(testDiv);
    // @ts-ignore
    testDiv.style.WebkitOverflowScrolling = "touch";
    // @ts-ignore
    let scrollSupport = "getComputedStyle" in window && window.getComputedStyle(testDiv)["-webkit-overflow-scrolling"] === "touch";
    document.documentElement.removeChild(testDiv);
    if (scrollSupport) {
        enable()
    }
    let iNoBounce = {enable: enable, disable: disable, isEnabled: isEnabled};
    // @ts-ignore
    if (typeof module !== "undefined" && module.exports) {
        // @ts-ignore
        module.exports = iNoBounce
    }
    // @ts-ignore
    if (typeof global.define === "function") {
        (function (define) {
            define("iNoBounce", [], function () {
                return iNoBounce
            })
            // @ts-ignore
        })(global.define)
    } else {
        // @ts-ignore
        global.iNoBounce = iNoBounce
    }
}
