<template>
    <f7-list dividers-ios strong-ios outline-ios class="payment-requisites" v-if="payment && payment.requisites">
        <f7-list-item :header="$t('payment.requisites.amount')">
            <template #title>
                <span v-format-number>{{ payment.amount }} {{ payment.currency.abbr }}</span>
            </template>
        </f7-list-item>
        <f7-list-item :header="$t('payment.requisites.method')">
            <template #title>
                <div class="psystem-wrapper">
                    <img :src="payment.psystemObj?.imgPath" alt="">
                    {{ payment.psystemObj.name }}

                    <template v-if="payment.requisites.addressType === 'phone'">
                        <payment-system-operation-s-b-p />
                    </template>
                </div>
            </template>
        </f7-list-item>
        <f7-list-item :header="$t('payment.requisites.requisites')" @click="copy(payment.requisites.address!)">
            <template #title>
                <div class="requisite-wrapper">
                    {{ payment.requisites.address }}
                    <i v-if="isShowRequisiteCopyIcon" class="display-block copy-svg"></i>
                </div>
            </template>
        </f7-list-item>
        <f7-list-item v-if="payment.requisites.name && payment.requisites.name.length > 0"
                      :header="$t('payment.requisites.recipient')">
            <template #title>
                {{ payment.requisites.name }}
            </template>
        </f7-list-item>
        <template v-if="payment.requisites.comment && payment.requisites.comment.length > 0">
            <f7-list-item :header="$t('payment.requisites.comment')">
                <template #title>
                    {{ payment.requisites.comment }}
                </template>
            </f7-list-item>
        </template>
        <f7-list-item :header="$t('payment.requisites.createdAt')">
            <template #title>
                {{ payment.getHumanCreateAt() }}
            </template>
        </f7-list-item>
        <f7-list-item :header="$t('payment.requisites.id')">
            <template #title>
                {{ payment.id }}
            </template>
        </f7-list-item>
    </f7-list>
</template>

<script setup lang="ts">
import {PropType, ToRef, toRef} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {f7} from "framework7-vue";
import {useI18n} from "vue-i18n";
import PaymentSystemOperationSBP from "@/views/payment/components/atomic/PaymentSystemOperationSBP.vue";
import {computed} from "@vue/reactivity";

const {t} = useI18n({useScope: 'global'});

const props = defineProps({
    payment: {
        type: Object as PropType<PurchaseOperation>,
        required: true
    },
});
const payment: ToRef<PurchaseOperation> = toRef(props, 'payment');
const isShowRequisiteCopyIcon = computed(() => {
    return !(payment.value?.requisites?.addressHidden !== undefined && payment.value?.requisites?.addressHidden);
});

function copy(value: string) {
    const aux = document.createElement("input");
    aux.setAttribute("value", value);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);

    f7.toast.create({
        text: t('g.copied'),
        position: 'top',
        closeTimeout: 1000,
    }).open();
}
</script>

<style lang="scss">
.payment-requisites {
    width: 100%;
    --f7-list-item-padding-vertical: 12px;
    --f7-list-outline-border-color: var(--grey8);

    ul:before, ul:after {
        content: none !important;
    }

    .item-inner {
        padding-right: 16px;

        .item-title {
            @include text(16px, 500, var(--brown1), 19.2px);
            word-break: break-all;
            white-space: unset;

            .requisite-wrapper {
                display: flex;
                align-items: center;
                gap: 8px;
                i {
                    cursor: pointer;
                }
            }

            .psystem-wrapper {
                display: flex;
                align-items: center;
                gap: 4px;

                img {
                    height: 24px;
                    width: 24px;
                }

                line-height: 24px;
            }

            .item-header {
                @include text(12px, 400, var(--grey2), 14.4px);
                margin-bottom: 6px;
            }
        }
    }
}
</style>