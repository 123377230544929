import PaymentWithdrawal from "@/entities/PaymentWithdrawal";
import humps from "lodash-humps-ts";
import {f7, useStore} from "framework7-vue";
import Payment from "@/entities/Payment";
// @ts-ignore
import store from '@target/core/store/store';
import initPusher from "@/services/pusher";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import ChatDataController from "@/controllers/ChatDataController";
import LightBuyingService from "@/services/light-buying/LightBuyingService";
import {plainToInstance} from "class-transformer";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {validateOrReject} from "class-validator";
import PaymentService from "@/services/operations/payment/PaymentService";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";
import WithdrawalWalletPageController from "@/views/withdrawal-group/wallet/wallet/WithdrawalWalletPageController";
import * as Sentry from "@sentry/vue";

export default class SocketService {
    private static instance: SocketService | null = null;
    private accountID: number = -1;
    private connected: boolean = false;
    private _gate: any = null;

    get gate() {
        return this._gate;
    }

    public static getInstance() {
        if (this.instance === null) this.instance = new SocketService()
        return this.instance
    }

    public init(accountID: number, pusher: any) {
        this.accountID = accountID;
        initPusher(pusher);
        this._gate = window.Echo.private(`client.app.${this.accountID}`);
        this.bindConnectionStates();

        ChatDataController.getInstance().socketConnect();
        return this;
    }

    public connect() {
        try {
            if (this.connected) return;
            this.checkLiveData();
            const paymentService: PaymentService = AppController.getInstance().paymentService;
            const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;

            this._gate
                .listen('.TransferPending', async (data: any) => {})
                .listen('.TransferFailed', async (data: any) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/payments':
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            break;
                        case '/withdrawal-wallet':
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            const operation = humps(data.payment);
                            if (operation.id === WithdrawalWalletPageController.of().withdrawal.value!.id) {
                                WithdrawalWalletPageController.of().withdrawal.value = humps(data.payment);
                            }
                            break;
                    }
                })
                .listen('.TransferCanceled', async (data: any) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/payments':
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            break;
                        case '/withdrawal-wallet':
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            const operation = humps(data.payment);
                            if (operation.id === WithdrawalWalletPageController.of().withdrawal.value!.id) {
                                WithdrawalWalletPageController.of().withdrawal.value = humps(data.payment);
                            }
                            break;
                    }
                })
                .listen('.TransferCompleted', async (data: any) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/payments':
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            break;
                        case '/withdrawal-wallet':
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            const operation = humps(data.payment);
                            if (operation.id === WithdrawalWalletPageController.of().withdrawal.value!.id) {
                                WithdrawalWalletPageController.of().withdrawal.value = humps(data.payment);
                            }
                            break;
                    }
                })
                .listen('.BalanceUpdated', async (data: any) => {
                    await store.dispatch('updateAccountBalance', data);
                })
                .listen('.SaleCanceled', async (data: any) => {
                    // const storePayment: PaymentWithdrawal = store.getters.paymentWithdrawal.value;
                    // const responsePayment = humps(data.payment) as PaymentWithdrawal;
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/chat-withdrawal':
                        case '/withdrawal':
                            const localWithdrawal: WithdrawalOperation | null = withdrawalService.withdrawal.value;
                            const serverWithdrawal: WithdrawalOperation = plainToInstance(WithdrawalOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverWithdrawal);
                            if (localWithdrawal?.id === serverWithdrawal.id) {
                                withdrawalService.withdrawal.value = serverWithdrawal;
                            }
                            break;
                        case '/payments':
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            break;
                    }
                })
                .listen('.SaleAccepted', async (data: any) => {
                    // const storePayment: PaymentWithdrawal = store.getters.paymentWithdrawal.value;
                    // const responsePayment = humps(data.payment) as PaymentWithdrawal;
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/withdrawal':
                        case '/chat-withdrawal':
                            const localWithdrawal: WithdrawalOperation | null = withdrawalService.withdrawal.value;
                            const serverWithdrawal: WithdrawalOperation = plainToInstance(WithdrawalOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverWithdrawal);
                            if (localWithdrawal?.id === serverWithdrawal.id) {
                                withdrawalService.withdrawal.value = serverWithdrawal;
                            }
                            break;
                        case '/payments':
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            break;
                    }
                })
                .listen('.SaleCashed', async (data: any) => {
                    // const storePayment: PaymentWithdrawal = store.getters.paymentWithdrawal.value;
                    const responsePayment = humps(data.payment) as PaymentWithdrawal;
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/chat-withdrawal':
                        case '/withdrawal':
                            const localWithdrawal: WithdrawalOperation | null = withdrawalService.withdrawal.value;
                            const serverWithdrawal: WithdrawalOperation = plainToInstance(WithdrawalOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverWithdrawal);
                            if (localWithdrawal?.id === serverWithdrawal.id) {
                                withdrawalService.withdrawal.value = serverWithdrawal;
                            }
                            break;
                        case '/payments':
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            break;
                    }
                })
                .listen('.PaymentAccepted', async (data: { payment: object }) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/payment/light':
                        case '/payment':
                            const localPayment: PurchaseOperation | null = paymentService.payment.value;
                            const serverPayment: PurchaseOperation = plainToInstance(PurchaseOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverPayment);
                            if (localPayment?.id === serverPayment.id) {
                                paymentService.payment.value = serverPayment;
                            }
                            break;
                        case '/payments':
                            await store.dispatch('fetchAccount', null);
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            break;
                    }
                })
                .listen('.PaymentCanceled', async (data: { payment: object }) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/payment/light':
                        case '/payment':
                            const localPayment: PurchaseOperation | null = paymentService.payment.value;
                            const serverPayment: PurchaseOperation = plainToInstance(PurchaseOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverPayment);
                            if (localPayment?.id === serverPayment.id) {
                                paymentService.payment.value = serverPayment;
                            }
                            break;
                        case '/payments':
                            await store.dispatch('fetchAccount', null);
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            break;
                    }
                })
                .listen('.PaymentExpired', async (data: { payment: object }) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/payments':
                            await store.dispatch('fetchAccount', null);
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            break;
                        case '/payment/light':
                        case '/payment':
                            const localPayment: PurchaseOperation | null = paymentService.payment.value;
                            const serverPayment: PurchaseOperation = plainToInstance(PurchaseOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverPayment);
                            if (localPayment?.id === serverPayment.id) {
                                paymentService.payment.value = serverPayment;
                            }
                            break;
                    }
                    await store.dispatch('fetchAccount', null);
                    await store.dispatch('fetchMyPayments', {refresh: true});
                })
                .listen('.PaymentConfirmed', async (data: { payment: object }) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/chat':
                        case '/payment/light':
                        case '/payment':
                            const localPayment: PurchaseOperation | null = paymentService.payment.value;
                            const serverPayment: PurchaseOperation = plainToInstance(PurchaseOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverPayment);
                            if (localPayment?.id === serverPayment.id) {
                                paymentService.payment.value = serverPayment;
                            }
                            break;
                        case '/payments':
                            await store.dispatch('fetchAccount', null);
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            break;
                    }
                })
                .listen('.PaymentRejected', async (data: { payment: object }) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/chat':
                        case '/payment/light':
                        case '/payment':
                            const localPayment: PurchaseOperation | null = paymentService.payment.value;
                            const serverPayment: PurchaseOperation = plainToInstance(PurchaseOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverPayment);
                            if (localPayment?.id === serverPayment.id) {
                                paymentService.payment.value = serverPayment;
                            }
                            break;
                        case '/payments':
                            await store.dispatch('fetchAccount', null);
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            break;
                    }
                })
                .listen('.PaymentDeclined', async (data: { payment: object }) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/chat':
                        case '/payment/light':
                        case '/payment':
                            const localPayment: PurchaseOperation | null = paymentService.payment.value;
                            const serverPayment: PurchaseOperation = plainToInstance(PurchaseOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverPayment);
                            if (localPayment?.id === serverPayment.id) {
                                paymentService.payment.value = serverPayment;
                            }
                            break;
                        case '/payments':
                            await store.dispatch('fetchAccount', null);
                            await store.dispatch('fetchMyPayments', {refresh: true});
                            break;
                    }
                })
            this.connected = true;
        } catch (e: any) {
            console.info(e)
        }
    }

    public disconnect() {
        try {
            this.checkLiveData();
            this.gate.stopListeningToAll();
            this.connected = false;
        } catch (e: any) {
        }
    }

    private checkLiveData() {
        if (this.accountID === -1) throw new Error("SocketService needs id of account");
        if (window.Echo == undefined) throw new Error("Echo isn't defined");
        if (window.Pusher == undefined) throw new Error("Pusher isn't defined");
    }

    private bindConnectionStates() {
        try {
            this.checkLiveData();
            const self = this;
            window.Echo.connector.pusher.connection.bind('state_change', async function (states: any) {
                if (states.current === 'connecting') {
                    const appController = AppController.getInstance();
                    appController.socketIsConnecting.value = true;
                }
                if (
                    (states.previous === "unavailable" && states.current === 'connecting') ||
                    (states.previous === "unavailable" && states.current === 'connected')
                ) {
                    await self.updateView();
                }
                if (states.current === 'failed') {
                    await self.updateView();
                }

                setTimeout(() => {
                    const appController = AppController.getInstance();
                    appController.socketIsConnecting.value = false;
                }, 2000);
                if (states.current === 'disconnected') {
                    const appController = AppController.getInstance();
                    appController.socketIsConnecting.value = false;
                }
            });
        } catch (e) {
            console.info(e)
        }
    }

    async updateView() {
        try {
            switch (f7.views.current.router.currentRoute.url) {
                case "/popup/new-payment":
                    await store.dispatch('fetchAccount', null);
                    await store.dispatch('checkTransactionStatus', null);
                    const payment1: PaymentWithdrawal = useStore('payment');
                    if (payment1) {
                        f7.view.main.router.navigate('/payment');
                    }
                    break;
                case "/popup/withdrawal/create":
                    await store.dispatch('fetchAccount', null);
                    await store.dispatch('checkWithdrawalStatus', null);
                    const payment2: PaymentWithdrawal = useStore('paymentWithdrawal');
                    if (payment2 && payment2.requisitesA) {
                        f7.view.main.router.navigate('/withdrawal');
                    }
                    break;
                case "/payments":
                    await store.dispatch('fetchAccount', null);
                    await store.dispatch('fetchMyPayments', {refresh: true});
                    break;
                case "/payment":
                    await store.dispatch('fetchAccount', null);
                    await store.dispatch('checkTransactionStatus', null);
                    break;
                case "/withdrawal":
                    await store.dispatch('fetchAccount', null);
                    await store.dispatch('checkWithdrawalStatus', null);
                    break;
            }
            AppController.getInstance().socketIsConnecting.value = false;
        } catch (e: any) {
            Sentry.captureMessage("UpdateView error: " + e.message);
            Sentry.captureException(e);
        }
    }
}