import { ServiceApiResponse, PayloadServiceApi } from "@/services/v2/service-api/types";
import {ServiceApiResponseError} from "@/services/v2/service-api/errors/ServiceApiResponseError";

export default class ServiceApi {
    private _url: string;
    private _requestInterceptors: Array<(config: RequestInit) => RequestInit | Promise<RequestInit>> = [];
    private _responseInterceptors: Array<{
        onFulfilled: (response: ServiceApiResponse<any>) => ServiceApiResponse<any> | Promise<ServiceApiResponse<any>>;
        onRejected?: (error: any) => any | Promise<any>;
    }> = [];

    constructor(url: string) {
        this._url = url;
    }

    public interceptors = {
        request: {
            use: (fn: (config: RequestInit) => RequestInit | Promise<RequestInit>) => {
                this._requestInterceptors.push(fn);
            }
        },
        response: {
            use: (
                onFulfilled: (response: ServiceApiResponse<any>) => ServiceApiResponse<any> | Promise<ServiceApiResponse<any>>,
                onRejected?: (error: any) => any | Promise<any> | ServiceApiResponse<any> | Promise<ServiceApiResponse<any>>
            ) => {
                this._responseInterceptors.push({ onFulfilled, onRejected });
            }
        }
    }

    public async get<T>(path: string, config: Omit<RequestInit, 'method'>, payload?: { [key: string]: string }): Promise<ServiceApiResponse<T>> {
        let url = this._url + path;

        if (payload !== undefined) {
            url += this.buildURLParams(payload);
        }

        const requestConfig: RequestInit = {
            method: "GET",
            ...config
        }

        return await this.request<T>(url, requestConfig);
    }

    async post<T>(path: string, config: Omit<RequestInit, 'method' | 'body'>, payload?: PayloadServiceApi| BodyInit | null | FormData): Promise<ServiceApiResponse<T>> {
        let url = this._url + path;

        const requestConfig: RequestInit = {
            method: "POST",
            body: payload instanceof FormData ? payload : JSON.stringify(payload),
            ...config
        }

        return await this.request<T>(url, requestConfig);
    }

    async patch<T>(path: string, config: Omit<RequestInit, 'method' | 'body'>, payload?: PayloadServiceApi): Promise<ServiceApiResponse<T>> {
        let url = this._url + path;

        const requestConfig: RequestInit = {
            method: "PATCH",
            body: JSON.stringify(payload),
            ...config
        }

        return await this.request<T>(url, requestConfig);
    }

    async delete<T>(path: string, config: Omit<RequestInit, 'method' | 'body'>, payload?: PayloadServiceApi): Promise<ServiceApiResponse<T>> {
        let url = this._url + path;

        const requestConfig: RequestInit = {
            method: "DELETE",
            body: JSON.stringify(payload),
            ...config
        }

        return await this.request<T>(url, requestConfig);
    }

    public async request<T = any>(url: string, config: RequestInit): Promise<ServiceApiResponse<T>> {
        config = await this.runRequestInterceptors(config);

        //The browser sets Content-type automatically for the types FormData | Blob | URLSearchParams | File
        if (
            config.body instanceof FormData
            || config.body instanceof Blob
            || config.body instanceof URLSearchParams
            || config.body instanceof File
        ) {
            delete (config.headers as Record<string, string>)["Content-Type"];
        }

        try {
            const originalResponse: Response = await fetch(url, config);
            const data = await originalResponse.json();
            let response: ServiceApiResponse<T> = { originalResponse, data };

            response = await this.runResponseOnFulfilledInterceptors<T>(response);

            if (!response.originalResponse.ok) {
                const error = new ServiceApiResponseError(originalResponse, { url, config }, data);

                response = await this.runResponseOnRejectedInterceptors(error);
            }

            return response;
        } catch (error) {
            throw error;
        }
    }

    private async runRequestInterceptors(config: RequestInit) {
        let _config = config;

        for (const interceptor of this._requestInterceptors) {
            _config = await interceptor(_config);
        }

        return _config;
    }

    private async runResponseOnFulfilledInterceptors<T>(response: ServiceApiResponse<T>) {
        let _response = response;

        for (const { onFulfilled } of this._responseInterceptors) {
            _response = await onFulfilled(_response);
        }

        return _response;
    }

    private async runResponseOnRejectedInterceptors(error: any): Promise<any> {
        let _error = error;

        for (const { onRejected } of this._responseInterceptors) {
            if (onRejected) {
                _error = await onRejected(_error);
            }
        }

        return _error;
    }

    public get url(): string | null {
        return this._url;
    }

    public set url(value: string) {
        this._url = value;
    }

    protected buildURLParams(payload: PayloadServiceApi): string {
        return Object.keys(payload).length ? '?' + new URLSearchParams(payload).toString() : '';
    }
}
