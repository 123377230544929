import KodmobiChallenge from "@models/kodmobi/KodmobiChallenge";
import KodmobiCodeFormat from "@models/kodmobi/KodmobiCodeFormat";
import {Expose, Type} from "class-transformer";

export default class KodmobiSettings {
    @Expose({name: 'challenge'})
    @Type(() => KodmobiChallenge)
    private readonly _challenge: KodmobiChallenge;

    @Expose({name: 'code'})
    @Type(() => KodmobiCodeFormat)
    private readonly _code: KodmobiCodeFormat;

    constructor() {
        this._challenge = new KodmobiChallenge(false, null);
        this._code = new KodmobiCodeFormat(4, true, false);
    }

    get challenge(): KodmobiChallenge {
        return this._challenge;
    }

    get code(): KodmobiCodeFormat {
        return this._code;
    }
}
