export default {
  countrySelector: {
    placeholder: 'Код',
    error: 'Выберите страну',
    searchPlaceholder: 'Поиск',
  },
  phoneInput: {
    placeholder: 'Телефон',
    example: 'Пример',
  },
}
