import MainApiService from "@/services/api-service/MainApiService";
import {plainToInstance} from "class-transformer";
import Agent from "@models/agents/Agent";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";

export default class AgentsRepository {
    public async getAgentsAvailableForPurchase(): Promise<Agent[]> {
        // const response = await MainApiService.getInstance().get('/api/mobile/client/payments/agents');
        const response = await ServiceMainApi.of().get<{ data: any }>('/api/mobile/client/payments/agents');
        return plainToInstance(Agent, response.data, {
            excludeExtraneousValues: true,
        }) as Agent[];
    }
}
