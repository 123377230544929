<template>
    <div class="payment-status" :class="payment.status.toLocaleLowerCase()">
        {{ Helper.getInstance().convertToHumanPaymentStatus(payment) }}
    </div>
</template>

<script setup lang="ts">
import Helper from "@/helpers/helper";
import {PropType, toRef, ToRef} from "vue";
import Payment from "@/entities/Payment";
const props = defineProps({
    payment: {
        type: Object as PropType<Payment>,
        required: true
    }
});
const payment: ToRef<Payment> = toRef(props, 'payment');
</script>

<style lang="scss">
.payment-status {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    background-color: #E6F2FF;
    color: var(--blue1);
    border-radius: 100px;
    padding: 3px 8px;

    &.completed {
        background-color: #eaf9f0;
        color: #34C759;
    }

    &.confirmed {
        background-color: #eaf9f0;
        color: #32BF61;
    }

    &.onappeal {
        color: #CF7529;
        background-color: #FAEACC;
    }

    &.activated {
        background-color: #eaf9f0;
        color: #32BF61;
    }

    &.canceled {
        background-color: #f3f4f5;
        color: var(--grey2);
    }

    &.rejected {
        color: #FF3B30;
        background-color: #ffebeb;
    }

    &.disputed {
        background-color: #E6F2FF;
    }

    &.declined {
        color: #FF3B30;
        background-color: #ffebeb;
    }

    &.expired {
        background-color: var(--pink1);
        color: var(--red1);
    }
}
</style>