import {value} from "dom7";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import LogService from "@/services/log-service/LogService";
import {FirebaseEvents} from "@/services/firebase/analytics/FirebaseEvents";
import FirebaseEventBuilder from "@/services/firebase/analytics/FirebaseEventBuilder";

export default class FirebaseEmptyRangeSuccessMetric {
    private _firebaseService: FirebaseService;
    private _observe: boolean = false;

    public constructor(firebaseService: FirebaseService) {
        this._firebaseService = firebaseService;
    }

    get observe(): boolean {
        return this._observe;
    }

    public startObserve() {
        this._observe = true;
        LogService.of().log("FirebaseEmptyRangeSuccessMetric@startObserve", null);
    }

    public checkAndSendAnalyticEvent() {
        if (this._observe) {
            FirebaseService.of().analyticsEvent(
                FirebaseEvents.PAYMENT_ERROR_EMPTY_RANGE_SUCCESS,
                FirebaseEventBuilder.of().build()
            );
            this.reset();
        }
    }

    public reset() {
        LogService.of().log("FirebaseEmptyRangeSuccessMetric@reset", null);
        this._observe = false;
    }
}