import {RequisitePsystemTargetType} from "@enums/RequisitePsystemTargetType";
import Requisite from "@models/requisite/Requisite";
import PaymentSystemOperation from "@models/operations/components/PaymentSystemOperation";

export default class CreateWalletWithdrawalDto {
    public amount: number;
    public requisite: Requisite;
    public psystem: PaymentSystemOperation;

    constructor(amount: number, requisite: Requisite, psystem: PaymentSystemOperation) {
        this.amount = amount;
        this.requisite = requisite;
        this.psystem = psystem;
    }
}