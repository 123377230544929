<template>
    <f7-list dividers-ios strong-ios outline-ios class="payment-income-requisites" v-if="payment">
        <div class="complex-item">
            <f7-list-item :header="$t('payment-income.requisites.amount')">
                <template #title>
                    <span v-format-number>{{ payment.fromAmount }} {{ payment.fromCurrency.abbr }}</span>
                </template>
            </f7-list-item>
            <f7-list-item :header="$t('payment-income.requisites.rate')">
                <template #title>
                    <span v-format-number>{{ payment.fromRate }} {{ payment.fromCurrency.abbr }}</span>
                </template>
            </f7-list-item>
        </div>
        <f7-list-item v-if="payment.project" :header="$t('payment-income.requisites.project')">
            <template #title>
                {{ payment.project.name }}
            </template>
        </f7-list-item>
        <f7-list-item :header="$t('payment.requisites.createdAt')">
            <template #title>
                {{ payment.getHumanCreateAt() }}
            </template>
        </f7-list-item>
        <f7-list-item :header="$t('payment.requisites.id')">
            <template #title>
                {{ payment.id }}
            </template>
        </f7-list-item>
    </f7-list>
</template>

<script setup lang="ts">
import {PropType, Ref, ToRef, toRef} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {f7} from "framework7-vue";
import {useI18n} from "vue-i18n";
import PaymentIncomeOperation from "@models/operations/PaymentIncomeOperation";

const {t} = useI18n({useScope: 'global'});

const props = defineProps({
    payment: {
        type: Object as PropType<PaymentIncomeOperation>,
        required: true
    },
});
const payment: Ref<PaymentIncomeOperation> = toRef(props, 'payment');

function copy(value: string) {
    const aux = document.createElement("input");
    aux.setAttribute("value", value);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);

    f7.toast.create({
        text: t('g.copied'),
        position: 'top',
        closeTimeout: 1000,
    }).open();
}
</script>

<style lang="scss">
.payment-income-requisites {
    width: 100%;
    --f7-list-item-padding-vertical: 12px;
    --f7-list-outline-border-color: var(--grey8);

    ul:before, ul:after {
        content: none !important;
    }

    .complex-item {
        position: relative;
        display: flex;

        li {
            list-style-type: none;

            &:nth-child(1) {
                flex: 1;
            }
        }

        .item-inner {
            &:after {
                content: none !important;
            }
        }

        &:after {
            content: '';
            position: absolute;
            background-color: var(--f7-list-item-border-color);
            display: block;
            z-index: 15;
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 100%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
    }

    .item-inner {
        padding-right: 16px;

        .item-title {
            @include text(16px, 500, var(--brown1), 19.2px);
            word-break: break-all;
            white-space: unset;

            .requisite-wrapper {
                display: flex;
                align-items: center;
                gap: 8px;

                i {
                    cursor: pointer;
                }
            }

            .psystem-wrapper {
                display: flex;
                align-items: center;
                gap: 4px;

                img {
                    height: 24px;
                    width: 24px;
                }

                line-height: 24px;
            }

            .item-header {
                @include text(12px, 400, var(--grey2), 14.4px);
                margin-bottom: 6px;
            }
        }
    }
}
</style>