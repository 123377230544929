export default class IFrameService {
    private static _instance: IFrameService | null = null;
    public isIFrameView: boolean = false;

    public static of() {
        if (!this._instance) this._instance = new IFrameService();
        return this._instance;
    }

    public init() {
        this.checkIsIFrameView();

        if (this.isIFrameView) {
            this.addDocumentClass();
        }
    }

    private checkIsIFrameView() {
        this.isIFrameView = window.location !== window.parent.location;
    }

    private addDocumentClass() {
        const html = document.querySelector("html")!;
        html.classList.add("opened-in-iframe");
    }
}