import kodmobiApiService from "@/targets/main/services/project/kodmobiApiService";
import {CaptchaActions} from "@/targets/main/views/login/types/CaptchaActions";
import {CaptchaPlace} from "@/targets/main/views/login/types/CaptchaPlace";

export default class CaptchaController {
    private _token: string | null = null;
    private _loginPhonePlaceCaptcha: string | null = null;
    private _loginCodePlaceCaptcha: string | null = null;
    private _loginChannelPlaceCaptcha: string | null = null;
    private _loginPasswordPlaceCaptcha: string | null = null;

    private findCaptcha(captchaPlace: CaptchaPlace): string | null {
        if (captchaPlace === CaptchaPlace.LOGIN_PHONE_PAGE) return this._loginPhonePlaceCaptcha;
        else if (captchaPlace === CaptchaPlace.LOGIN_CODE_PAGE) return this._loginCodePlaceCaptcha;
        else if (captchaPlace === CaptchaPlace.LOGIN_PASSWORD_PAGE) return this._loginPasswordPlaceCaptcha;
        else return this._loginChannelPlaceCaptcha;
    }

    private saveCaptchaId(captchaPlace: CaptchaPlace, newCaptchaId: string): void {
        if (captchaPlace === CaptchaPlace.LOGIN_PHONE_PAGE) this._loginPhonePlaceCaptcha = newCaptchaId;
        else if (captchaPlace === CaptchaPlace.LOGIN_CODE_PAGE) this._loginCodePlaceCaptcha = newCaptchaId;
        else if (captchaPlace === CaptchaPlace.LOGIN_PASSWORD_PAGE) this._loginPasswordPlaceCaptcha = newCaptchaId;
        else this._loginChannelPlaceCaptcha = newCaptchaId;
    }

    public deleteCaptchaId(captchaPlace: CaptchaPlace): void {
        const getCaptchaField = (captchaPlace: CaptchaPlace): string => {
            switch (captchaPlace) {
                case CaptchaPlace.LOGIN_PHONE_PAGE:
                    return '_loginPhonePlaceCaptcha';
                case CaptchaPlace.LOGIN_CODE_PAGE:
                    return '_loginCodePlaceCaptcha';
                case CaptchaPlace.LOGIN_PASSWORD_PAGE:
                    return '_loginPasswordPlaceCaptcha';
                default:
                    return '_loginChannelPlaceCaptcha';
            }
        };

        const captchaField = getCaptchaField(captchaPlace);

        console.log("remove captcha", captchaField, this[captchaField])

        if (this[captchaField]) {
            console.log("remove", this[captchaField])
            // @ts-ignore
            turnstile.remove(this[captchaField]);
            this[captchaField] = null;
        }
    }

    public reset(): void {
        this._token = null;
        this._loginPhonePlaceCaptcha = null;
        this._loginCodePlaceCaptcha = null;
        this._loginChannelPlaceCaptcha = null;
        this._loginPasswordPlaceCaptcha = null;
    }

    public async generateToken(
        siteKey: string,
        action: CaptchaActions,
        selector: string,
        captchaPlace: CaptchaPlace,
        closure: Function
    ): Promise<string> {
        console.log(captchaPlace)
        const captchaId = this.findCaptcha(captchaPlace);
        let timeout = 0;
        if (captchaId) {
            // @ts-ignore
            turnstile.reset(captchaId);
            return captchaId;
        } else {
            return this.renderCaptcha(siteKey, action, selector, captchaPlace, closure, timeout);
        }
    }

    private renderCaptcha(
        siteKey: string,
        action: CaptchaActions,
        selector: string,
        captchaPlace: CaptchaPlace,
        closure: Function,
        timeout: number = 0
    ): Promise<string> {
        const self = this;
        return new Promise((resolve, reject) => {
            console.log("renderCaptcha")

            setTimeout(() => {
                // @ts-ignore
                const newCaptchaId = turnstile.render(selector, {
                    sitekey: siteKey,
                    action: action,
                    refreshExpired: "never",
                    refreshTimeout: "never",
                    retry: "never",
                    callback: function (token: string) {
                        console.log("Captcha callback")
                        self._token = token;
                        closure();
                        self.saveCaptchaId(captchaPlace, newCaptchaId);
                        resolve(newCaptchaId);
                    },
                    errorCallback: function (error: any) {
                        console.error(error);
                        reject(newCaptchaId);
                    },
                    unsupportedCallback: function (error: any) {
                        console.error(error);
                        reject(newCaptchaId);
                    },
                    expiredCallback: function () {
                        console.log("expiredCallback")
                    },
                    timeoutCallback: function () {
                        console.log("timeoutCallback")
                    }
                });
            }, timeout)
        });
    }

    public setCaptchaHeaderKodMobiApi() {
        if (!this._token) throw new Error("@setCaptchaHeaderKodMobiApi");
        kodmobiApiService.getInstance().setAdditionalHeader("X-CF-TURNSTILE-TOKEN", this._token);
    }

    public deleteCaptchaHeaderKodMobiApi() {
        if (!this._token) throw new Error("@setCaptchaHeaderKodMobiApi");
        kodmobiApiService.getInstance().removeAdditionalHeader("X-CF-TURNSTILE-TOKEN");
    }

    get token(): string | null {
        return this._token;
    }
}