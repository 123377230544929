// @ts-ignore
import store from "@target/core/store/store";
// @ts-ignore
import routes from "@target/core/routes";
// @ts-ignore
import Api from "@target/services/project/projectApiService";
import MainApiService from "@/services/api-service/MainApiService";
import LangService from "@/services/LangService";
import {ref, Ref, ToRef} from "vue";
import SocketService from "@/services/SockerService";
import Account from "@/entities/Account";
import PaymentService from "@/services/operations/payment/PaymentService";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";
import RouterService from "@/services/RouterService";
import {f7, useStore} from "framework7-vue";
import {getDevice} from "framework7/shared/get-device";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import LogService from "@/services/log-service/LogService";
import BaseAppController from "@/controllers/app/BaseAppController";
import AuthDto from "@/targets/main/components/App/ts/dto/AuthDto";
import AuthorizationService from "@/services/authorization/AuthorizationService";
import FirebaseFcmToken from "@models/firebase/FirebaseFcmToken";
import generateHash from "@/helpers/hash-function";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import DocumentOthers from "@/services/firebase/firestore/documents/DocumentOthers";
import ProjectApiService from "@/targets/main/services/project/projectApiService";
import kodmobiApiService from "@/targets/main/services/project/kodmobiApiService";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";

type PaymentRequestData = { amount: number, psystem_id: number, currency_id?: number, type?: 'purchase' | 'sale' }

export default class AppController extends BaseAppController {
    private static instance: AppController | null = null;
    private _authorizationService: AuthorizationService = new AuthorizationService();
    readonly config: object;
    private _isAuth: Ref<Boolean> = ref(false);
    private _isReady: Ref<Boolean> = ref(false);
    public socketIsConnecting: Ref<boolean> = ref(false);
    private _paymentRequestData: ToRef<PaymentRequestData | null>;
    private _paymentService: PaymentService;
    private _withdrawalService: WithdrawalService;
    private _routerService: RouterService;

    constructor() {
        super();
        this.config = {
            name: 'app',
            theme: 'ios',
            store: store,
            routes: routes,
            iosTranslucentBars: false,
            input: {
                scrollIntoViewOnFocus: true,
                scrollIntoViewCentered: true,
            },
            statusbar: {
                iosOverlaysWebView: false,
                androidOverlaysWebView: false,
            },
        }
        const locale = localStorage.getItem('lang');
        if (locale) {
            LangService.getInstance().set(locale);
        }
        this._paymentRequestData = ref(null);
        this._paymentService = new PaymentService();
        this._withdrawalService = new WithdrawalService();
        this._routerService = new RouterService();
    }

    public async auth(token: string, phone: string) {
        localStorage.setItem("token", token);
        const response: any = await this._authorizationService.authorization(this.buildAuthDto(token, phone));
        localStorage.setItem("ltoken", response.data.token);
        localStorage.setItem("lrefreshToken", response.data.refreshToken);
        localStorage.setItem("csp", response.data.csp);

        this.setToken(token);
        MainApiService.getInstance().updateUpdateHeadersWithXToken();
        await this.init();
    }

    private buildAuthDto(token: string, phone: string) {
        const platform = !getDevice().cordova
            ? "web"
            : getDevice().os;
        const bundleId = platform === "web"
            ? null
            : "com.bromoney.app";
        const deviceId = getDevice().cordova
            // @ts-ignore
            ? device.uuid
            : generateHash(`${window.navigator.userAgent}:${phone}`);

        const userAgent = window.navigator.userAgent
            ? window.navigator.userAgent
            : null;
        const result = new AuthDto(
            token,
            deviceId,
            platform,
            bundleId,
            userAgent,
            import.meta.env.VITE_VERSION,
            []
        );
        const fcmToken = FirebaseService.of().notificationPluginService.fcmToken !== null
            ? new FirebaseFcmToken(FirebaseService.of().notificationPluginService.fcmToken!)
            : null;
        if (fcmToken) result.tokens.push(fcmToken);
        return result;
    }

    get routerService(): RouterService {
        return this._routerService;
    }

    get paymentService(): PaymentService {
        return this._paymentService;
    }

    get withdrawalService(): WithdrawalService {
        return this._withdrawalService;
    }

    get paymentRequestData() {
        return this._paymentRequestData;
    }

    get isAuth() {
        return this._isAuth;
    }

    get isReady() {
        return this._isReady;
    }

    public setPaymentRequestData(payload: PaymentRequestData | null) {
        this._paymentRequestData.value = payload;
    }

    public getPaymentRequestData() {
        return this._paymentRequestData.value;
    }

    public async logout(isNotifyServer: boolean = false) {
        if (isNotifyServer) await this.logoutNotifyServer();

        this._isAuth.value = false;
        await store.dispatch('reset');
        SocketService.getInstance().disconnect();
        this.setToken(null);

        this.removeAlertFlagWithdrawalAlert();
    }

    private removeAlertFlagWithdrawalAlert() {
        localStorage.removeItem("is_showed_withdrawal_delay_alert")
    }

    private async logoutNotifyServer() {
        try {
            const account = useStore('account');
            if (account) {
                const deviceId = getDevice().cordova
                    // @ts-ignore
                    ? device.uuid
                    : generateHash(`${window.navigator.userAgent}:${account.value.contact.phone}`);

                await this._authorizationService.logout(deviceId);
            }
        } catch (e: any) {
            LogService.of().log("AppController@logout", e.message);
        }
    }

    public async init() {
        try {
            let account: Account | null = null;
            let pusher = {
                host: import.meta.env.VITE_API_URL.replace('https://', ''),
                key: import.meta.env.VITE_PUSHER_KEY,
                authEndpoint: import.meta.env.VITE_API_URL + "/broadcasting/auth"
            };

            if (this.checkAuth()) this._isAuth.value = true;

            await FirebaseService.of().init(async () => {
                const config = await this.fetchFirebaseMainConfigs();
                if (config) {
                    if (config.pusherKey) pusher.key = config.pusherKey;
                    if (config.mainApiUrl) {
                        pusher.host = config.mainApiUrl.replace('https://', '');
                        pusher.authEndpoint = config.mainApiUrl + "/broadcasting/auth"
                    }
                }

                if (FirebaseService.of().firestoreService) {
                    this._paymentService.timerService.updateStatusLiveTimerValues(
                        FirebaseService.of().firestoreService!.parser.makeMapForPurchaseOperationLiveTimers()
                    );
                    this._withdrawalService.timerService.updateStatusLiveTimerValues(
                        FirebaseService.of().firestoreService!.parser.makeMapForWithdrawalOperationLiveTimers()
                    );
                }
            });

            if (this.checkAuth()) {
                const bromoneyAccountData: any = await store.dispatch('fetchClientProfile', null);
                account = await store.dispatch('fetchAccount', null);

                this.checkSetPassword(bromoneyAccountData);

                if (account) {
                    SocketService.getInstance().init(account.id, pusher).connect();
                }
            } else {
                localStorage.removeItem("token");
                localStorage.removeItem("ltoken");
                localStorage.removeItem("lrefreshToken");
                localStorage.removeItem("csp");
            }

            await FirebaseService.of().initPushNotifications();
            LogService.of().log("AppController@init", "initialized");
        } catch (e: any) {
            AppController.getInstance().setToken(null);
            LogService.of().error("AppController@init", "didn't initialized");
        } finally {
            this._isReady.value = true;
        }
    }

    private async fetchFirebaseMainConfigs() {
        try {
            const doc = FirebaseService.of().firestoreService!.getDocument(DocumentTypes.OTHERS) as DocumentOthers;
            const mainApiUrl = doc.mainApiUrl;
            const projectApiUrl = doc.projectApiUrl;
            const kodmobiApiUrl = doc.kodmobiApiUrl;
            const kodmobiApiKey = doc.kodmobiApiKey;
            const pusherKey = doc.pusherKey;

            if (mainApiUrl) {
                MainApiService.getInstance().setConfigDomain(mainApiUrl);
                ServiceMainApi.of().setConfigDomain(mainApiUrl);
            }
            if (projectApiUrl) ProjectApiService.getInstance().setConfigDomain(projectApiUrl);
            if (kodmobiApiUrl) kodmobiApiService.getInstance().setConfigDomain(kodmobiApiUrl);
            if (kodmobiApiKey) kodmobiApiService.getInstance().setHeaderXApiKey(kodmobiApiKey);

            return {
                mainApiUrl,
                pusherKey
            }
        } catch (e) {}
    }

    private getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    private checkSetPassword(account: any) {
        if (account.passwordIsSet) return;

        if (typeof this.getCookie('password_notification') === "undefined") {
            const now = new Date();
            const time = now.getTime();
            const expireTime = time + 1000 * 60 * 60 * 24;
            now.setTime(expireTime);
            document.cookie = 'password_notification=exists;expires=' + now.toUTCString() + ';path=/';
            f7.views.current.router.navigate('/settings/set-password', {
                openIn: 'popup'
            });
        }
    }

    private checkAuth(): boolean {
        if (localStorage.getItem('token') !== null && localStorage.getItem('ltoken') !== null) {
            return true;
        }
        return false;
    }

    private getToken(): string | null {
        const token = localStorage.getItem('token');
        if (token) {
            return token;
        }
        return null;
    }

    public static getInstance() {
        if (AppController.instance === null) {
            AppController.instance = new AppController()
        }
        return AppController.instance
    }
}
