<template>
    <f7-page
        ref="self"
        :page-content="false"
        class="select-psystem-page"
        :no-navbar="openIn == 'popup'"
        :class="{'without-navbar': openIn == 'popup'}"
    >
        <f7-navbar :hidden="openIn === PageOpenIn.POPUP">
            <f7-nav-left>
                <a href="#" class="link icon-only back">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                {{ $t('select-psystem.navbar-title') }}
            </f7-nav-title>
            <f7-nav-right></f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <popup-navbar
                :title="$t('select-psystem.navbar-title')"
                v-if="openIn === PageOpenIn.POPUP"
                @on-left-click="routerService.toBack"
            />
            <div class="psystems-content">
                <div class="search__wrapper">
                    <f7-list inset class="languages list-dividers list-strong">
                        <f7-list-input
                            type="text"
                            :placeholder="t('select-psystem.search-placeholder')"
                            clear-button
                            @input="filterPsystem"
                        >
                        </f7-list-input>
                    </f7-list>
                </div>

                <f7-list class="psystems-list list-outline list-strong list-dividers">
                    <template v-for="psystem in localPsystems" :key="'psystem_' + psystem.id">
                        <f7-list-item :title="psystem.name" link="#" @click="selectPSystem(psystem)">
                            <template #media>
                                <img :src="psystem.imgPath">
                            </template>
                        </f7-list-item>
                    </template>
                </f7-list>
            </div>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts" setup>
import {ref} from "@vue/runtime-core";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {f7} from "framework7-vue";
import {useI18n} from 'vue-i18n'
import RequisitePsystemPageController from "@/views/requisites-group/select-psystem/RequisitePsystemPageController";
import RequisitePsystem from "@models/requisite/RequisitePsystem";
import {PageOpenIn} from "@enums/PageOpenIn";
import PopupNavbar from "@components/popup/popup-navbar/PopupNavbar.vue";
import RouterService from "@/services/RouterService";

const controller = RequisitePsystemPageController.of();
const {psystems, openIn} = controller;
const localPsystems = ref([...psystems.value]);
const {t} = useI18n({useScope: 'global'});
const routerService: RouterService = AppController.getInstance().routerService;

function back() {
    f7.views.current.router.back();
}

function filterPsystem(e: Event) {
    const value = (e.target as HTMLInputElement).value;
    const regex = new RegExp(value, 'g');
    localPsystems.value = psystems.value!.filter((sys: any) => sys.name.match(regex))
}

function selectPSystem(psystem: RequisitePsystem) {
    controller.requisitePageController!._requisite.value!.psystem = psystem;
    f7.view.current.router.back();
}
</script>

<style lang="scss">
.select-psystem-page {
    &.without-navbar {
        --f7-page-navbar-offset: 0px;
        --f7-navbar-height: 0px;
        --f7-page-content-extra-padding-top: 24px;

        .popup-navbar {
            margin-bottom: 16px;
        }
    }

    .page-content {
        &::-webkit-scrollbar {
            display: none;
        }

        padding-bottom: 16px;

        .title-wrapper {
            margin: 24px 16px;
            display: flex;
            align-items: center;
            gap: 16px;

            .title {
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 120%;
                letter-spacing: 0.337647px;
                color: #000000;
            }

            .icon-back-svg {
                cursor: pointer;
                width: 32px;
                height: 32px;
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z' fill='white'/%3E%3Cpath d='M11.3262 16.1863C11.3337 16.4524 11.4314 16.6805 11.6343 16.8858L17.4818 22.6721C17.6546 22.8394 17.8576 22.9307 18.1056 22.9307C18.6092 22.9307 19 22.5353 19 22.0258C19 21.7825 18.9023 21.5544 18.7294 21.3795L13.4682 16.1863L18.7294 10.993C18.9023 10.8181 19 10.5976 19 10.3467C19 9.83723 18.6092 9.44184 18.1056 9.44184C17.8651 9.44184 17.6546 9.53308 17.4818 9.70036L11.6343 15.4943C11.4239 15.692 11.3262 15.9201 11.3262 16.1863Z' fill='%23818E95'/%3E%3C/svg%3E%0A");
            }
        }

        .psystems-content {
            // padding: 16px 0px;
            background-color: var(--white1);

            .search__wrapper {
                padding-top: 16px;
                padding-bottom: 16px;

                .list {
                    .item-content {
                        border-radius: 8px;
                        background-color: var(--grey5);
                    }
                }
            }

            .psystems-list {
                --f7-list-item-min-height: 48px;

                ul {
                    &:before {
                        content: none;
                    }

                    &:after {
                        content: none;
                    }

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            .list {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }
}
</style>
