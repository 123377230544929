<template>
    <div class="withdrawal-meta" v-if="withdrawal">
        <div class="withdrawal-meta__block">
            <p class="withdrawal-meta__text">{{ $t('g.operation.meta.createdAt') }}</p>
            <p class="withdrawal-meta__text">{{ withdrawal.getHumanCreateAt() }}</p>
        </div>
        <div class="withdrawal-meta__block">
            <p class="withdrawal-meta__text">{{ $t('g.operation.meta.number') }}</p>
            <p class="withdrawal-meta__text">{{ withdrawal.id }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import {PropType, ToRef, toRef} from "vue";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";

const props = defineProps({
    withdrawal: {
        type: Object as PropType<WithdrawalOperation>,
        required: true
    },
});

const withdrawal: ToRef<WithdrawalOperation> = toRef(props, 'withdrawal');

</script>

<style lang="scss">
.withdrawal-meta {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    padding: 0;

    &__block {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: self-start;

        &:nth-child(2) {
            align-items: self-end;
        }
    }

    &__text {
        @include text(12px, 400, #ACB4BE, 14.4px);
    }
}
</style>