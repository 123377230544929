<template>
    <f7-block class="requisites-page_requisites-filters mt-0 mb-3">
        <f7-chip
            v-for="filter in requisitesTypeFilterHandler.filters"
            :key="filter.type"
            :text="filter.text"
            outline
            class="requisites-page_requisite-filter"
            :class="[{'requisite-filter__active': filter.active}]"
            @click="requisitesTypeFilterHandler.change(filter)"
        />
    </f7-block>
</template>

<script setup lang="ts">
import {useI18n} from "vue-i18n";
import {RequisitesTypeFilter} from "@/views/requisites-group/requisites/types/RequisitesTypeFilter";
import {reactive} from "vue";
import {RequisitePsystemTargetType} from "@enums/RequisitePsystemTargetType";

const {t} = useI18n({useScope: 'global'});
const requisitesTypeFilterHandler: {
    filters: RequisitesTypeFilter[],
    change: (filter: RequisitesTypeFilter) => void
} = {
    filters: reactive([
        {
            text: t("requisites.requisites-filters.all"),
            active: true,
            type: null,
        },
        {
            text: t("requisites.requisites-filters.p2p"),
            active: false,
            type: RequisitePsystemTargetType.P2P,
        },
        {
            text: t("requisites.requisites-filters.wallet"),
            active: false,
            type: RequisitePsystemTargetType.WALLET,
        },
    ]),
    change: function (filter: RequisitesTypeFilter) {
        this.filters.map((el: RequisitesTypeFilter) => {
            el.active = el.type === filter.type;
        });
        emits("onUpdate", filter);
    }
}
const emits = defineEmits([
    "onUpdate"
]);
</script>

<style scoped lang="scss">
.requisites-page {
    &_requisites-filters {
        display: flex;
        gap: 8px;
    }

    &_requisite-filter {
        cursor: pointer;
        background-color: #FFF;
        border: 1px solid transparent;

        &.requisite-filter__active {
            border-color: var(--blue1);
        }
    }
}

</style>