<template>
    <div class="list-item-with-title" ref="self">
        <f7-block-title>{{ title }}</f7-block-title>
        <f7-list dividers-ios strong-ios outline-ios>
            <f7-list-item :disabled="loading" @click="handleItemClick" :title="text" :link="link">
                <template #media>
                    <img :src="media" alt="">
                </template>
                <template #after>
                    <f7-preloader :size="16" v-if="loading" />
                </template>
            </f7-list-item>
        </f7-list>
    </div>
</template>

<script setup lang="ts">
import {PropType, ref, toRef} from "vue";
import RequisiteTargetTypesController from "@/views/requisites-group/requisite/types/RequisiteTargetTypesController";

const props = defineProps({
    link: {
        type: Object as PropType<string | boolean>,
        required: false,
        default: "#"
    },
    title: {
        type: Object as PropType<string>,
        required: true
    },
    text: {
        type: Object as PropType<string>,
        required: true
    },
    loading: {
        type: Object as PropType<boolean>,
        required: false
    },
    media: {
        type: Object as PropType<string>,
        required: false
    }
});
const self = ref(null);
const title = props.title;
const link = props.link;
const text = toRef(props, "text");
const loading = toRef(props, "loading");
const emits = defineEmits(["onItemClick"]);
function handleItemClick() {
    emits("onItemClick");
}
</script>

<style lang="scss">
.list-item-with-title {
    .block-title {
        text-transform: uppercase;
        @include text(12px, 400, var(--grey2), 14.4px);
    }
    .item-title {
        line-height: 16px !important;
    }
    --f7-list-item-media-margin: 8px;
    .item-media {
        img {
            width: 24px;
            height: 24px;
        }
    }
}
</style>