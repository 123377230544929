// @ts-ignore
import Api from "@target/services/project/projectApiService";
import AuthDto from "@/targets/main/components/App/ts/dto/AuthDto";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
// import MainApiService from "@/services/api-service/MainApiService";

export default class AuthorizationService {
    async authorization(authDto: AuthDto): Promise<any> {
        const {deviceId, bundleId, userAgent, appV, platform, tokens} = authDto;
        const payload: any = {
            "platform": platform,
            "app_v": appV,
            "tokens": tokens
        }
        if (deviceId) payload["device_id"] = deviceId;
        if (bundleId) payload["bundle_id"] = bundleId;
        if (userAgent) payload["user-agent"] = userAgent;
        if (tokens.length > 0) payload["tokens"] = tokens;
        return await Api.getInstance().post(`/api/v2/user/lanpay/auth`, payload);
    }

    async logout(deviceId: string): Promise<any> {
        // return await MainApiService.getInstance().post(`/api/mobile/client/logout`, {
        return await ServiceMainApi.of().post(`/api/mobile/client/logout`, {
            "device_id": String(deviceId)
        });
    };
}
