import { ServiceApiRequestConfig } from "@/services/v2/service-api/types";

interface ServerErrorData {
    success: boolean;
    error: string;
    code?: number;
}

export class ServiceApiResponseError extends Error {
    private readonly _config: ServiceApiRequestConfig;
    private readonly _code: number;
    private readonly _data?: ServerErrorData;
    private readonly _originalResponse: Response;

    constructor(originalResponse: Response, config: ServiceApiRequestConfig, errorData?: ServerErrorData) {
        super(errorData?.error || 'ResponseError');

        this._originalResponse = originalResponse;
        this._config = config;
        this._code = errorData?.code || 500;
        this._data = errorData;
    }

    public get code() {
        return this._code;
    }

    public get originalResponse() {
        return this._originalResponse;
    }

    public get config() {
        return this._config;
    }

    public get data() {
        return this._data;
    }
}
