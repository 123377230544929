<template>
  <div class="symbols-row" ref="rootEl">
    <input @change="setError(null)" id="symbol-1" type="tel" @focus="onFocusInInput" @input="onKeyInInput"
           @keyup.delete="onKeyInInput" pattern="[0-9]+">
    <hr>
    <input @change="setError(null)" id="symbol-2" type="tel" @focus="onFocusInInput" @input="onKeyInInput"
           @keyup.delete="onKeyInInput" pattern="[0-9]+">
    <hr>
    <input @change="setError(null)" id="symbol-3" type="tel" @focus="onFocusInInput" @input="onKeyInInput"
           @keyup.delete="onKeyInInput" pattern="[0-9]+">
    <hr>
    <input @change="setError(null)" id="symbol-4" type="tel" @focus="onFocusInInput" @input="onKeyInInput"
           @keyup.delete="onKeyInInput" pattern="[0-9]+">
  </div>
</template>

<script setup lang="ts">
import {InputCodeErrorMessage} from "@/targets/main/views/login/types/InputCodeErrorMessage";
import {PropType, ref, toRef} from "vue";

const props = defineProps({
  error: {
    type: Object as PropType<any>,
    required: true
  },
});
const error = toRef(props, "error");
const rootEl = ref(null);
const setError = (value: null | InputCodeErrorMessage) => {
  emits('setError', value);
};

const clearCode = () => {
  const parent = rootEl.value! as HTMLDivElement;

  Array.from(parent.children as HTMLCollection).forEach((input, key) => {
    (input as HTMLInputElement).value = ''
  })
}

const onFocusInInput = () => {
  if (error.value != null) {
    setError(null);
    const parent = rootEl.value! as HTMLDivElement;
    (parent.querySelector('#symbol-3') as HTMLInputElement).value = "";
    (parent.querySelector('#symbol-4') as HTMLInputElement).value = "";
    (parent.querySelector('#symbol-2') as HTMLInputElement).value = "";
    (parent.querySelector('#symbol-1') as HTMLInputElement).value = "";
    (parent.querySelector('#symbol-1') as HTMLInputElement).focus();
  }
};
const extractCode = () => {
  let code = ""
  const parent = rootEl.value! as HTMLDivElement;

  Array.from(parent.children as HTMLCollection).map((input, key) => {
    if ([0, 2, 4, 6].includes(key)) {
      code += (input as HTMLInputElement).value
    }
  })

  return code;
}
const onKeyInInput = (event: Event) => {
  event.preventDefault();
  const parent = rootEl.value! as HTMLDivElement;
  // @ts-ignore
  if (event.target.value.length === 4) {
    // @ts-ignore
    const code = event.target.value;
    (parent.children[0] as HTMLInputElement).value = code[0];
    (parent.children[2] as HTMLInputElement).value = code[1];
    (parent.children[4] as HTMLInputElement).value = code[2];
    (parent.children[6] as HTMLInputElement).value = code[3];

    (parent.querySelector('#symbol-1') as HTMLInputElement).blur();
    emits("onCheckCode");
    return;
  }

  const target = event.target as HTMLInputElement;
  const value = (event as InputEvent).data || ''

  switch (target.id) {
    case "symbol-1":
      if ((parent.querySelector('#symbol-1') as HTMLInputElement).value !== "") {
        (parent.querySelector('#symbol-2') as HTMLInputElement).focus()
      }

      (parent.querySelector('#symbol-1') as HTMLInputElement).value = value
      break;
    case "symbol-2":
      if ((parent.querySelector('#symbol-2') as HTMLInputElement).value === "") {
        (parent.querySelector('#symbol-1') as HTMLInputElement).value = "";
        (parent.querySelector('#symbol-1') as HTMLInputElement).focus()
      } else {
        (parent.querySelector('#symbol-3') as HTMLInputElement).focus()
      }

      (parent.querySelector('#symbol-2') as HTMLInputElement).value = value
      break;
    case "symbol-3":
      if ((parent.querySelector('#symbol-3') as HTMLInputElement).value === "") {
        (parent.querySelector('#symbol-2') as HTMLInputElement).value = "";
        (parent.querySelector('#symbol-2') as HTMLInputElement).focus()
      } else {
        (parent.querySelector('#symbol-4') as HTMLInputElement).focus()
      }

      (parent.querySelector('#symbol-3') as HTMLInputElement).value = value

      break;
    case "symbol-4":
      if (error.value) {
        (parent.querySelector('#symbol-3') as HTMLInputElement).value = "";
        (parent.querySelector('#symbol-2') as HTMLInputElement).value = "";
        (parent.querySelector('#symbol-1') as HTMLInputElement).value = "";
        (parent.querySelector('#symbol-1') as HTMLInputElement).focus();
        return;
      }

      (parent.querySelector('#symbol-4') as HTMLInputElement).value = value

      if ((parent.querySelector('#symbol-4') as HTMLInputElement).value === "") {
        (parent.querySelector('#symbol-3') as HTMLInputElement).value = "";
        (parent.querySelector('#symbol-3') as HTMLInputElement).focus()
      } else {
        (parent.querySelector('#symbol-4') as HTMLInputElement).blur()
        emits("onCheckCode");
      }
      break;
  }
};

defineExpose({
  rootEl,
  extractCode,
  clearCode,
});
const emits = defineEmits([
  "setError",
  "onCheckCode"
]);
</script>

<style scoped lang="scss">

</style>
