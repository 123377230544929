<template>
    <f7-messages class="messages-block">
        <template v-if="messagesData.length > 0">
            <template v-for="(block, index) in messagesData" :key="index">
                <f7-messages-title><b>{{ (block as any).humanDate }}</b> {{ (block as any).humanTime }}
                </f7-messages-title>
                <f7-message
                    v-for="(message, idx) in block.messages" :key="message.id"
                    :type="message.type"
                    :name="message.name"
                    :class="{'message_with-text': (message.text && message.text.length > 0)}"
                    :first="isFirstMessage(message, +index, +idx)"
                    :last="isLastMessage(message, +index, +idx)"
                    :tail="isTailMessage(message, +index, +idx)"
                >
                    <template #image v-if="message.thumb">
                        <ChatMessagePDF v-if="checkIsPDF(message.mimeType)" :chatMessage="message"/>
                        <img v-else @click="emits('imageClick', message)" :src="message.thumb">
                    </template>
                    <template #avatar v-if="message.type === 'received'">
                        <div class="avatar" :class="[
                                    message.senderType,
                                    message.senderType !== 'client' ? chatData!.agent.sex : ''
                                ]"></div>
                    </template>

                    <template #text v-if="message.senderType === 'client'">
                        <span v-if="message.text && message.text.length > 0">{{ message.text }}</span>
                        <span class="read-status" v-if="message.type === 'sent'">
                                    <i class="icon check-svg" :class="{'was-read': message.wasRead}"></i>
                                </span>
                    </template>
                    <template #text
                              v-else-if="message.senderType !== 'client' && message.text && message.text.length > 0">
                        <span v-if="message.text && message.text.length > 0">{{ message.text }}</span>
                    </template>
                </f7-message>
            </template>
        </template>
        <template v-else>
            <NoMessages/>
        </template>
    </f7-messages>
</template>

<script setup lang="ts">
import NoMessages from "@/views/chat/components/NoMessages.vue";
import {PropType, toRef} from "vue";
import Message from "@/entities/Message";
import {ChatMessage} from "@/entities/chat/ChatPayload";
import {UIMessage} from "@/controllers/ChatDataController";
import ChatMessagePDF from "@components/chat/ChatMessagePDF.vue";

const props = defineProps({
    messagesData: {
        type: Object as PropType<any>,
        required: true
    },
    chatData: {
        type: Object as PropType<any>,
        required: true
    }
});
const messagesData = toRef(props, 'messagesData');
const chatData = toRef(props, 'chatData');
const emits = defineEmits(['imageClick']);

const checkIsPDF = (type: string) => {
    return !!type.match('.*pdf.*');
}

const isFirstMessage = (message: UIMessage, idx1: number, idx2: number) => {
    const previousMessage = messagesData.value[idx1].messages[idx2 - 1];
    return !previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name;

}
const isLastMessage = (message: UIMessage, idx1: number, idx2: number) => {
    const nextMessage = messagesData.value[idx1].messages[idx2 + 1];
    return !nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name;
}
const isTailMessage = (message: UIMessage, idx1: number, idx2: number) => {
    const nextMessage = messagesData.value[idx1].messages[idx2 + 1];
    return !nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name;
}
</script>

<style lang="scss">
.message_with-text {
    .message-image {
        &:has(.chat-message-pdf) {
            margin-bottom: 6px !important;
        }
    }
}
</style>