import Operation from "@models/operations/Operation";
import {Expose, Type} from "class-transformer";
import ProjectOperation from "@models/operations/components/ProjectOperation";
import Agent from "@models/operations/components/Agent";
import CurrencyOperation from "@models/operations/components/CurrencyOperation";
import RequisitesOperation from "@models/operations/components/RequisitesOperation";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import {OperationType_N} from "@enums/OperationType_N";
import {P_WITHDRAWAL_STATUS} from "@/entities/enums/PWithdrawalStatus";
import PaymentSystemOperation from "@models/operations/components/PaymentSystemOperation";
import {
    WithdrawalWalletOperationOperationStatus
} from "@models/operations/withdrawal/wallet/WithdrawalWalletOperationStatus";
import {value} from "dom7";
import {id} from "framework7/shared/utils";
import {currency} from "maz-ui";
import WithdrawalWalletOperationPayData from "@models/operations/withdrawal/wallet/WithdrawalWalletOperationPayData";

export default class WithdrawalWalletOperation extends Operation {
    @Expose({name: 'id'})
    private _id: number;

    @Expose({name: 'msid'})
    private _msid: string;

    @Expose({name: 'amount'})
    private _amount: number;

    @Expose({name: 'amountFiat'})
    private _amountFiat: number;

    @Expose({name: 'fee'})
    private _fee: number;

    @Expose({name: 'feeFiat'})
    private _feeFiat: number;

    @Expose({name: 'amountTotal'})
    private _amountTotal: number;

    @Expose({name: 'amountFiatTotal'})
    private _amountFiatTotal: number;

    @Expose({name: 'currency'})
    @Type(() => CurrencyOperation)
    private _currency: CurrencyOperation;

    @Expose({name: 'currencyFiat'})
    @Type(() => CurrencyOperation)
    private _currencyFiat: CurrencyOperation;

    @Expose({name: 'psystem'})
    @Type(() => PaymentSystemOperation)
    private _psystem: PaymentSystemOperation;

    @Expose({name: 'payData'})
    @Type(() => WithdrawalWalletOperationPayData)
    private _payData: WithdrawalWalletOperationPayData;

    @Expose({name: 'status'})
    private _status: WithdrawalWalletOperationOperationStatus;

    @Expose({name: 'completedAt'})
    private _completedAt: string;

    constructor(
        id: number,
        type: OperationType_N,
        msid: string,
        amount: number,
        amountFiat: number,
        fee: number,
        feeFiat: number,
        amountTotal: number,
        amountFiatTotal: number,
        currency: CurrencyOperation,
        currencyFiat: CurrencyOperation,
        psystem: PaymentSystemOperation,
        payData: WithdrawalWalletOperationPayData,
        status: WithdrawalWalletOperationOperationStatus,
        completedAt: string,
        createdAt: string
    ) {
        super(type, createdAt);
        this._id = id;
        this._msid = msid;
        this._amount = amount;
        this._amountFiat = amountFiat;
        this._fee = fee;
        this._feeFiat = feeFiat;
        this._amountTotal = amountTotal;
        this._amountFiatTotal = amountFiatTotal;
        this._currency = currency;
        this._currencyFiat = currencyFiat;
        this._psystem = psystem;
        this._payData = payData;
        this._status = status;
        this._completedAt = completedAt;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get msid(): string {
        return this._msid;
    }

    set msid(value: string) {
        this._msid = value;
    }

    get amount(): number {
        return this._amount;
    }

    set amount(value: number) {
        this._amount = value;
    }

    get amountFiat(): number {
        return this._amountFiat;
    }

    set amountFiat(value: number) {
        this._amountFiat = value;
    }

    get fee(): number {
        return this._fee;
    }

    set fee(value: number) {
        this._fee = value;
    }

    get amountTotal(): number {
        return this._amountTotal;
    }

    set amountTotal(value: number) {
        this._amountTotal = value;
    }

    get feeFiat(): number {
        return this._feeFiat;
    }

    set feeFiat(value: number) {
        this._feeFiat = value;
    }

    get amountFiatTotal(): number {
        return this._amountFiatTotal;
    }

    set amountFiatTotal(value: number) {
        this._amountFiatTotal = value;
    }

    get currency(): CurrencyOperation {
        return this._currency;
    }

    set currency(value: CurrencyOperation) {
        this._currency = value;
    }

    get currencyFiat(): CurrencyOperation {
        return this._currencyFiat;
    }

    set currencyFiat(value: CurrencyOperation) {
        this._currencyFiat = value;
    }

    get psystem(): PaymentSystemOperation {
        return this._psystem;
    }

    set psystem(value: PaymentSystemOperation) {
        this._psystem = value;
    }

    get payData(): WithdrawalWalletOperationPayData {
        return this._payData;
    }

    set payData(value: WithdrawalWalletOperationPayData) {
        this._payData = value;
    }

    get status(): WithdrawalWalletOperationOperationStatus {
        return this._status;
    }

    set status(value: WithdrawalWalletOperationOperationStatus) {
        this._status = value;
    }

    get completedAt(): string {
        return this._completedAt;
    }

    set completedAt(value: string) {
        this._completedAt = value;
    }
}