<template>
<div
    :id="instanceId"
    class="m-phone-number-input"
    :class="{
      '--no-flags': noFlags,
    }"
>
    <template v-if="countryCode && countryFlag">
        <div class="m-phone-number-input__country-flag" @click="openPopupWithCountries">
            <span>{{ countryFlag }}</span>
            <i class="icon i-phone-number-select m-select-chevron"></i>
        </div>
    </template>

    <MazInput
        :id="id"
        ref="PhoneNumberInput"
        :model-value="formattedNumber"
        :label="inputPlaceholder"
        :disabled="disabled"
        :color="color"
        :error="error || (!!formattedNumber && !results.isValid)"
        v-bind="$attrs"
        placeholder="+7 000 00000000"
        :size="size"
        icon-name="phone"
        type="tel"
        clearable
        class="m-phone-number-input__input maz-flex-1"
        :class="{
        '--border-radius': !noCountrySelector,
        '--error': error || !results.isValid,
        '--focused': inputFocused,
      }"
        @focus="inputFocused = true"
        @blur="onBlur"
        @update:model-value="emitsValueAndResults($event)"
        @keydown="onKeydown($event)"
    />
</div>
<p ref="errorTextRef" :class="{'hide': !error}" class="error-text">{{ $t('g.login.step1.error') }}</p>
<f7-popup
    v-model:opened="popupCountriesIsOpened"
    class="popup-with-countries"
    swipe-to-close="to-bottom"
    swipe-handler=".header, .search-country"
>
    <div class="page">
        <div class="page-content">
            <div class="header">
                <span class="tint"></span>
                <p class="title">{{ $t('g.login.step1.popup.title') }}</p>
            </div>
            <div class="body">
                <div class="search-country">
                    <f7-input
                        type="text"
                        :placeholder="$t('g.login.step1.popup.search-placeholder')"
                        clear-button
                        v-model:value="searchQuery"
                    />
                </div>
                <f7-list class="countries" strong>
                    <template v-for="option in optionsList" :key="option.name">
                        <f7-list-item class="country" link="#" no-chevron @click="selectCountry(option)">
                            <template #inner>
                                <div class="left-side">
                                    <div class="option-flag">
                                        <span>{{ (option as any).flag }}</span>
                                    </div>
                                    <div class="option-name">
                                        <span>{{ (option as any).name }}</span>
                                    </div>
                                </div>
                                <div class="option-dial-code">
                                    <span>{{ (option as any).dialCode }}</span>
                                </div>
                            </template>
                        </f7-list-item>
                    </template>
                </f7-list>
            </div>
        </div>
    </div>
</f7-popup>
</template>

<script lang="ts">
export type {Color, Size, Position} from '../types'
export type {CountryCode} from 'libphonenumber-js'
export type {Result, Translations} from './MazPhoneNumberInput/types'
</script>

<script lang="ts" setup>
import type {CountryCode} from 'libphonenumber-js'
import type {Result, Translations} from './MazPhoneNumberInput/types'
import type {Color, Position, Size} from '../types'

import {
    fetchCountryCode,
    browserLocale,
    getResultsFromPhoneNumber,
    getAsYouTypeFormat,
    isCountryAvailable,
    getCountriesList,
    getExamplePhoneNumber,
    sanitizePhoneNumber,
    loadPhoneNumberExamplesFile, getCountryFlag, getCountryDialCode, tryFindCountryCode,
} from './MazPhoneNumberInput/utils'
import {truthyFilter} from '../helpers'
import {useInstanceUniqId} from '../composables/instance-uniq-id.composable'

import locales from './MazPhoneNumberInput/constantes/locales'

import {
    computed,
    onBeforeMount,
    onMounted,
    type PropType,
    ref,
    watch,
    getCurrentInstance,
} from 'vue'

import MazInput from './MazInput.vue'
import MazSelect from './MazSelect.vue'
import {ModelValueSimple} from "../types";
import Animations from "@/helpers/animations";

const emits = defineEmits(['update', 'update:model-value', 'country-code', 'update-error'])
const props = defineProps({
    modelValue: {
        type: String,
        validator: (prop: string) => {
            return typeof prop === 'string' || prop === undefined
        },
        default: undefined,
    },
    id: {type: String, default: undefined},
    placeholder: {type: String, default: undefined},
    defaultPhoneNumber: {type: String, default: undefined},
    defaultCountryCode: {
        type: String as PropType<CountryCode | string>,
        default: undefined,
        validator: (code: string) => isCountryAvailable(code),
    },
    preferredCountries: {
        type: Array as PropType<CountryCode[]>,
        default: undefined,
    },
    ignoredCountries: {
        type: Array as PropType<CountryCode[]>,
        default: undefined,
    },
    onlyCountries: {
        type: Array as PropType<CountryCode[]>,
        default: undefined,
    },
    translations: {
        type: Object as PropType<Translations>,
        default: undefined,
    },
    listPosition: {
        type: String as PropType<Position>,
        default: 'bottom left',
        validator: (value: Position) => {
            return ['top', 'top right', 'top left', 'bottom', 'bottom right', 'bottom left'].includes(
                value,
            )
        },
    },
    color: {
        type: String as PropType<Color>,
        default: 'primary',
        validator: (value: string) => {
            return [
                'primary',
                'secondary',
                'warning',
                'danger',
                'info',
                'success',
                'white',
                'black',
            ].includes(value)
        },
    },
    size: {
        type: String as PropType<Size>,
        default: 'md',
        validator: (value: string) => {
            return ['mini', 'xs', 'sm', 'md', 'lg', 'xl'].includes(value)
        },
    },
    noFlags: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    noExample: {type: Boolean, default: false},
    noSearch: {type: Boolean, default: false},
    noUseBrowserLocale: {type: Boolean, default: false},
    fetchCountry: {type: Boolean, default: false},
    noCountrySelector: {type: Boolean, default: false},
    showCodeOnList: {type: Boolean, default: false},
    error: {type: Boolean, default: false},
    customCountriesList: {
        type: Object as PropType<Record<CountryCode, string>>,
        default: undefined,
    },
})
const instance = getCurrentInstance()
const {instanceId} = useInstanceUniqId({
    componentName: 'MazPhoneNumberInput',
    instance,
    providedId: props.id,
})
const results = ref<Partial<Result>>({})
const countryCode = ref<CountryCode>()
const countryFlag = ref<string>()
const formattedNumber = ref<string>()
const cursorPosition = ref<number | null>()
const examplesFileLoaded = ref(false)
const inputFocused = ref(false)
const lastKeyPressed = ref<KeyboardEvent['key']>()
const CountrySelector = ref<typeof MazSelect>()
const PhoneNumberInput = ref<typeof MazInput>()
const popupCountriesIsOpened = ref(false);
const searchQuery = ref<string>()
const errorTextRef = ref(null)

defineExpose({
    results
})

onBeforeMount(async () => {
    try {
        formattedNumber.value = props.modelValue ?? props.defaultPhoneNumber

        if (props.defaultCountryCode) {
            setCountryCode(props.defaultCountryCode)
            countryFlag.value = getCountryFlag(props.defaultCountryCode);
        }

        if (props.fetchCountry) {
            const locale = await fetchCountryCode()
            if (locale) {
                setCountryCode(locale)
                countryFlag.value = getCountryFlag(locale);
            }
        }

        getAndEmitResults(formattedNumber.value)
    } catch (error) {
        throw new Error(`[MazPhoneNumberInput](onBeforeMount) ${error}`)
    }

    try {
        if (!props.noExample && !examplesFileLoaded.value) {
            await loadPhoneNumberExamplesFile()
            examplesFileLoaded.value = true
        }
    } catch {
        throw new Error(
            '[MazPhoneNumberInput](onBeforeMount) while loading phone number examples file',
        )
    }
})

onMounted(() => {
    try {
        if (!props.defaultCountryCode && !props.noUseBrowserLocale && !countryCode.value) {
            const locale = browserLocale()
            // if (locale) {
            //     setCountryCode(locale)
            //     countryFlag.value = getCountryFlag(locale);
            //     formattedNumber.value = getCountryDialCode(locale);
            // } else {
            //     setCountryCode("RU")
            //     countryFlag.value = getCountryFlag("RU");
            //     formattedNumber.value = getCountryDialCode("RU");
            // }
            setCountryCode("RU")
            countryFlag.value = getCountryFlag("RU");
            formattedNumber.value = getCountryDialCode("RU");
        }

        if (props.defaultCountryCode && props.fetchCountry) {
            throw String(
                "Do not use 'fetch-country' and 'default-country-code' options in the same time",
            )
        }
        if (props.defaultCountryCode && props.noUseBrowserLocale) {
            throw String(
                "If you use a 'default-country-code', do not use 'no-use-browser-locale' options",
            )
        }
    } catch (error) {
        console.warn(`[MazPhoneNumberInput](mounted) ${error}`)
    }
})

const countries = computed(() => getCountriesList(props.customCountriesList))

const optionsList = computed(() => {
    return searchQuery.value
        ? countryOptions.value!.filter((option) => {
            return (
                searchInValue(option.name, searchQuery.value) ||
                searchInValue(option.dialCode, searchQuery.value)
            )
        })
        : countryOptions!.value
})

const searchInValue = (value?: ModelValueSimple, query?: string) => {
    return query && value?.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase())
}

const t = computed(() => ({
    ...locales,
    ...props.translations,
}))

const isValid = computed(() => {
    return results.value?.isValid
})

const countriesList = computed(() => {
    return countries.value?.filter((item) => !props.ignoredCountries?.includes(item.iso2))
})

const countriesFiltered = computed(() => {
    const countries = props.onlyCountries || props.preferredCountries
    return countries?.map((country) =>
        countriesList.value?.find((item) => item.iso2.includes(country)),
    )
})

const otherCountries = computed(() => {
    return countriesList.value?.filter((item) => !props.preferredCountries?.includes(item.iso2))
})

const countriesSorted = computed(() => {
    return props.preferredCountries
        ? [...(countriesFiltered.value ?? []), ...(otherCountries.value ?? [])]
        : props.onlyCountries
            ? countriesFiltered.value
            : countriesList.value
})

const countryOptions = computed(() => {
    return countriesSorted.value
        ?.map((country) => {
            return country
                ? {
                    ...country,
                    dialCode: `+${country.dialCode}`,
                }
                : undefined
        })
        .filter(truthyFilter)
})

const inputPlaceholder = computed(() => {
    if (props.placeholder) {
        return props.placeholder
    }

    const defaultPlaceholder = t.value.phoneInput.placeholder

    if (props.noExample || !examplesFileLoaded.value) {
        return defaultPlaceholder
    } else {
        const example = getPhoneNumberExample()
        return isValid.value || !example
            ? defaultPlaceholder
            : `${t.value.phoneInput.example} ${example}`
    }
})

watch(
    () => props.modelValue,
    (phoneNumber, oldPhoneNumber) => {
        if (phoneNumber !== oldPhoneNumber) {
            emitsValueAndResults(phoneNumber)
        }
    },
)

watch(
    () => props.error,
    (newError, oldNewError) => {
        if (newError) {
            Animations.shakeAnimation(errorTextRef.value!);
        }
    },
)

watch(
    () => props.defaultPhoneNumber,
    (phoneNumber, oldPhoneNumber) => {
        if (phoneNumber !== oldPhoneNumber) {
            emitsValueAndResults(phoneNumber)
        }
    },
)

watch(
    () => props.defaultCountryCode,
    (countryCode, oldCountryCode) => {
        if (countryCode && countryCode !== oldCountryCode) {
            setCountryCode(countryCode)
            countryFlag.value = getCountryFlag(countryCode);
            emitsValueAndResults()
        }
    },
)

const onKeydown = (event: KeyboardEvent) => {
    lastKeyPressed.value = event.key

    const target = event.target as HTMLInputElement | undefined

    // if (target && target.value === '+' && [8].includes(event.keyCode)) {
    //     event.preventDefault();
    // }

    cursorPosition.value = target?.selectionStart
}

const selectCountry = (option: any) => {
    setCountryCode(option.iso2)
    countryFlag.value = option.flag;
    formattedNumber.value = option.dialCode;
    popupCountriesIsOpened.value = false;
    ((PhoneNumberInput.value as any).input as HTMLInputElement).focus();
}

// @update:flag="setCountryFlag($event)"-->
//     <!--        @update:model-value="setCountryCode($event, true)"-->

const getPhoneNumberExample = () => {
    try {
        const phoneNumber = countryCode.value ? getExamplePhoneNumber(countryCode.value) : undefined
        return phoneNumber ? phoneNumber.formatNational() : undefined
    } catch (error) {
        throw new Error(`[MazPhoneNumberInput](getPhoneNumberExample) ${error}`)
    }
}

const autoUpdateCountryCodeFromPhoneNumber = () => {
    if (
        results.value &&
        results.value.countryCode &&
        countryCode.value !== results.value.countryCode
    ) {
        setCountryCode(results.value.countryCode)
        countryFlag.value = getCountryFlag(results.value.countryCode);
    }
}

const sanitizeNumber = (phoneNumber?: string) => {
    phoneNumber = sanitizePhoneNumber(phoneNumber)

    const backSpacePressed = lastKeyPressed.value === 'Backspace'

    const lastCharacOfPhoneNumber = phoneNumber ? phoneNumber.charAt(phoneNumber.length - 1) : ''
    const lastCharIsParanthese = lastCharacOfPhoneNumber === ')'

    if (backSpacePressed && lastCharIsParanthese) {
        phoneNumber = phoneNumber?.trim().slice(0, -2)
    }

    return phoneNumber
}

const openPopupWithCountries = () => {
    popupCountriesIsOpened.value = true
    searchQuery.value = ''
}

/**
 * Обработка ввода номер
 * @param phoneNumber
 * @param noAutoUpdateCountryCode
 */
const getAndEmitResults = (phoneNumber?: string, noAutoUpdateCountryCode?: boolean) => {
    // 1. Попытка определить по первым 4 символам страну
    if (phoneNumber) {
        const countryCode = tryFindCountryCode(phoneNumber.slice(0, 5));
        if (countryCode) {
            setCountryCode(countryCode)
            countryFlag.value = getCountryFlag(countryCode);
        }
    }
    results.value = getResultsFromPhoneNumber(countryCode.value, phoneNumber)

    if (!noAutoUpdateCountryCode) {
        autoUpdateCountryCodeFromPhoneNumber()
    }

    emits('update', results.value)
}

const emitsValueAndResults = (
    phoneNumber = props.modelValue,
    noAutoUpdateCountryCode?: boolean,
) => {
    try {
        getAndEmitResults(phoneNumber, noAutoUpdateCountryCode)

        emitValue(phoneNumber)
    } catch (error) {
        throw new Error(`[MazPhoneNumberInput](emitsValueAndResults) ${error}`)
    }
}

const emitValue = (phoneNumber?: string) => {
    formattedNumber.value = sanitizeNumber(phoneNumber)

    const {isValid, e164, formatNational} = getResultsFromPhoneNumber(
        countryCode.value,
        phoneNumber,
    )

    const hasDeletedCharac =
        formattedNumber.value && phoneNumber && formattedNumber.value?.length > phoneNumber?.length

    const cursorIsAtEnd =
        phoneNumber && cursorPosition.value ? cursorPosition.value + 1 >= phoneNumber.length : true

    const shouldUseAsYoutType = (!hasDeletedCharac && cursorIsAtEnd) || isValid

    if (countryCode.value) {
        formattedNumber.value = getAsYouTypeFormat(countryCode.value, formattedNumber.value)
    }
    if (phoneNumber && phoneNumber[0] != '+') {
        phoneNumber = phoneNumber.replaceAll('+', '');
        formattedNumber.value = '+' + phoneNumber;
    }

    emits('update-error', false);
}

const onBlur = () => {
    inputFocused.value = true

    if (countryCode.value) {
        formattedNumber.value = getAsYouTypeFormat(countryCode.value, formattedNumber.value)
    }
}

const setCountryFlag = (flag: string | undefined) => {
    try {
        if (flag) {
            countryFlag.value = flag;
        }
    } catch (error) {
        throw new Error(`[MazPhoneNumberInput](setCountryFlag) ${error}`)
    }
}
const setCountryCode = (selectedCountryCode: string, autoFocusInput = false) => {
    try {
        const countryAvailable = isCountryAvailable(selectedCountryCode)

        if (countryAvailable) {
            countryCode.value = selectedCountryCode as CountryCode
            emits('country-code', selectedCountryCode)
            emitsValueAndResults(props.modelValue, true)
        }

        if (autoFocusInput) {
            focusPhoneNumberInput()
            formattedNumber.value = getCountryDialCode(selectedCountryCode);
            // if (formattedNumber.value && formattedNumber.value.includes('+')) {
            //     formattedNumber.value = undefined
            // }
        }
    } catch (error) {
        throw new Error(`[MazPhoneNumberInput](setCountryCode) ${error}`)
    }
}

const focusCountrySelector = () => {
    try {
        CountrySelector.value.$el.querySelector('.input').click();
        // CountrySelector.value?.$el.querySelector('input')?.focus()
    } catch (error) {
        throw new Error(`[MazPhoneNumberInput](focusCountrySelector) ${error}`)
    }
}

const focusPhoneNumberInput = () => {
    try {
        PhoneNumberInput.value?.$el.querySelector('input')?.focus()
    } catch (error) {
        throw new Error(`[MazPhoneNumberInput](focusPhoneNumberInput) ${error}`)
    }
}
</script>

<style lang="scss">
@import './MazPhoneNumberInput/css/flags.css';

.error-text {
    &.hide {
        opacity: 0;
    }

    margin-top: 8px !important;
    padding-left: 8px;
    @include text(12px, 400, #FF3B30, 14.4px);
}

.popup-with-countries {
    overflow: scroll;
    height: calc(100% - 44px);
    bottom: 0;
    top: unset;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;

    &::-webkit-scrollbar {
        display: none;
    }
    .page-content {
        //height: 100vh;
        background-color: #FFF;
        width: 100%;

        &::-webkit-scrollbar {
            display: none;
        }

        .header {
            padding: 28px 16px 16px;
            position: sticky;
            top: 0px;
            z-index: 999;
            background-color: #FFF;
            .tint {
                position: absolute;
                display: block;
                transition: all .3s;
                width: 36px;
                height: 4px;
                top: 6px;
                z-index: 9999;
                background: #818E95;
                border-radius: 4px;
                left: 50%;
                transform: translate(-50%);
            }

            .title {
                @include text(24px, 700, #423636, 24px);
            }

            &:before {
                content: "";
                position: absolute;
                background-color: var(--grey4);
                display: block;
                z-index: 15;
                top: auto;
                right: 0;
                bottom: 0;
                left: auto;
                height: 1px;
                width: 100%;
                transform-origin: 50% 100%;
                transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
            }
        }

        .body {
            .search-country {
                position: sticky;
                top: 68px;
                z-index: 999;
                background-color: #FFF;
                padding: 16px;

                input {
                    height: 38px;
                    background: #EFEFF4;
                    border-radius: 8px;
                    padding: 0px 12px;
                    @include text(14px, 400, #423636, 16.8px);
                }

                .input-clear-button {
                    right: 8px;
                }
            }

            .countries {
                margin: 0;

                ul {
                    &::-webkit-scrollbar {
                        display: none;
                    }

                    overflow: auto;
                    //height: calc(100vh - 70px - 72px - var(--f7-safe-area-bottom));
                }

                .country {
                    .item-inner {
                        padding-top: 10px;
                        padding-bottom: 10px;

                        .left-side {
                            width: calc(100% - 32px - 80px);
                            display: flex;
                            gap: 8px;
                            align-items: center;
                        }

                        .option {
                            &-flag {
                                font-size: 20px;
                            }

                            &-name {
                                display: flex;
                                font-size: 16px;
                                font-weight: 400;
                                letter-spacing: 0px;
                                line-height: 19.2px;
                                color: #423636;
                                width: 100%;
                                flex: 1;
                                @include text(16px, 400, #423636, 19.2px);

                                span {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }

                            &-dial-code {
                                @include text(16px, 700, #423636, 19.2px);
                            }
                        }

                        //justify-content: left;
                    }
                }
            }
        }
    }
}

//.device-ios {
//  .popup-with-countries {
//    ul {
//      overflow: auto;
//      height: calc(100vh - 70px - 186px - var(--f7-safe-area-bottom)) !important;
//    }
//  }
//}
//.device-android {
//  .popup-with-countries {
//    ul {
//      overflow: auto;
//      height: calc(100vh - 70px - 186px - var(--f7-safe-area-bottom)) !important;
//    }
//  }
//}
.m-phone-number-input {
    display: flex;
    position: relative;
    height: 48px;

    &__country-flag {
        //position: absolute;
        height: 48px;
        padding-left: 8px;
        z-index: 4;
        outline: none;
        border: none;
        //padding: 0;
        margin: 0;
        cursor: pointer;
        width: fit-content;
        display: flex;
        align-items: center;

        i {
            margin-left: 4px;
            margin-top: 3px;
        }

        span {
            margin-top: 3px;
            font-size: 20px;
        }
    }

    .m-input {
        .input {
            height: inherit;
            display: flex;
            align-items: center;
            margin-left: 32px;

            p {
                @include text(20px, 500, #423636, 25px);
            }
        }
    }

    &__input.--border-radius:deep(.m-input-wrapper) {
        margin-left: -2px;
    }

    .m-phone-number-input__select {
        height: inherit;

        .input {
            button {
                margin-top: 3px;
                padding: 0;
            }
        }

        .m-select-list {
            position: absolute;
        }
    }

    .m-phone-number-input__input {
        position: relative;
        height: inherit;

        .m-input-wrapper {
            height: inherit;

            .m-input-wrapper-input {
                height: inherit;

                .m-phone-number-input__input {
                    height: inherit;
                    @include text(20px, 500, #423636, 24px);
                    width: 100%;
                    padding-left: 8px;
                    padding-right: 8px;

                    &::placeholder {
                        color: #D5D7D8;
                    }
                }
            }
        }

        //&:after {
        //  border-left: 1px solid var(--grey4);
        //  content: "";
        //  display: block;
        //  height: 32px;
        //  left: 0px;
        //  position: absolute;
        //  top: 8px;
        //  width: 1px;
        //  z-index: 0;
        //}
    }

    .m-select-list {
        top: -70px;
        background-color: #FFF;
        width: 100%;
        border-radius: 10px;
        border: 1px solid #D5D7D8;
        z-index: 999;
        box-shadow: 0px 10px 10px -3px rgba(15, 23, 42, 0.1);

        @media (min-width: 426px) {
            top: 52px;
        }

        .m-select-list__search-wrapper {
            padding: 8px 16px;

            .m-select-list__search-input {
                .m-input-wrapper {
                    display: flex;
                    height: 38px;
                    background: #EFEFF4;
                    align-items: center;
                    padding: 8px;

                    input {
                        @include text(14px, 500, #423636, 24px);
                        margin-left: 4px;
                    }
                }
            }
        }

        .m-select-list__scroll-wrapper {
            padding: 0 8px;
            overflow: auto;
            max-height: 180px;

            .m-select-list-item {
                height: 32px;

                .m-phone-number-input__select__item {
                    text-align: left;
                    display: flex;
                    gap: 8px;
                    align-items: baseline;

                    .phone-code {
                        @include text(16px, 700, #423636);
                        margin-right: 8px;
                    }

                    .country-name {
                        @include text(16px, 400, #423636);
                    }
                }
            }
        }

        .m-select-list__no-results {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 24px 0px;
        }
    }

    &__input {
        &.--error,
        &.--focused {

        }
    }

    &:before {
        content: "";
        position: absolute;
        background-color: var(--grey4);
        display: block;
        z-index: 15;
        top: auto;
        right: 0px;
        bottom: 0;
        left: auto;
        height: 1px;
        width: 100%;
        transform-origin: 50% 100%;
        transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    }
}
</style>
