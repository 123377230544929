// function ServerResponseError(message = "", code = 500) {
//     this.name = "ServerResponseError";
//     this.message = message;
//     this.code = code;
// }
// ServerResponseError.prototype = Error.prototype;

function InnerDataError(message = "") {
    this.name = "InnerDataError";
    this.message = message;
}

InnerDataError.prototype = Error.prototype;

export {
    // ServerResponseError,
    InnerDataError
}

export class ServerResponseError extends Error {
    private readonly code: number;
    protected readonly type: string = 'SERVER_RESPONSE_ERROR';

    constructor(message: string, code: number = 500) {
        super(message);

        this.code = code;

        Object.setPrototypeOf(this, ServerResponseError.prototype);
    }

    public getCode() {
        return this.code;
    }
}

export class ServerAuthError extends Error {
    private readonly code: number;
    private readonly type: string = 'ServerAuthError';

    constructor(message: string, code: number) {
        super(message);

        this.code = code;

        Object.setPrototypeOf(this, ServerResponseError.prototype);
    }

    public getCode() {
        return this.code;
    }
}

export class KodMobiError extends Error {
    private readonly code: number;
    private readonly sysMessage: string;
    private readonly type: string = 'KodMobiError';

    constructor(message: string, sysMessage: string, code: number) {
        super(message);

        this.code = code;
        this.sysMessage = sysMessage;

        Object.setPrototypeOf(this, ServerResponseError.prototype);
    }

    public getCode() {
        return this.code;
    }
}

export class AccountBlockedError extends Error {
    private readonly code: number;
    private readonly type: string = 'ACCOUNT_BLOCKED_ERROR';

    constructor(message: string, code: number) {
        super(message);

        this.code = code;

        Object.setPrototypeOf(this, ServerResponseError.prototype);
    }
}

export class AgentNotFoundError extends Error {
    private readonly type: string = 'AGENT_NOT_FOUND_ERROR';

    constructor(message: string) {
        super(message);

        Object.setPrototypeOf(this, AgentNotFoundError.prototype);
    }
}
