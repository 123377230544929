export default class {
    private _file: File;
    private _localBlobUrl: string;
    private _isPDF: boolean;

    constructor(file: File, localBlobUrl: string, isPDF: boolean) {
        this._file = file;
        this._localBlobUrl = localBlobUrl;
        this._isPDF = isPDF;
    }

    get localBlobUrl(): string {
        return this._localBlobUrl;
    }

    get file(): File {
        return this._file;
    }

    get isPDF(): boolean {
        return this._isPDF;
    }
}