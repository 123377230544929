<template>
    <a @click="toLink" href="#" class="home-url external">
        <i class="icon i-svg-cross"></i>
        {{ $t('g.close') }}
    </a>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Account from "@/entities/Account";
import {useStore} from "framework7-vue";
import {AccountProjectSlug} from "@/entities/account/AccountProject";
import {getDevice} from "framework7/shared/get-device";

export default defineComponent({
    computed: {
        AccountProjectSlug() {
            return AccountProjectSlug
        }
    },
    setup() {
        const account: Account = useStore("account");
        return {
            account
        }
    },
    methods: {
        toLink() {
            try {
                if (getDevice().ios) {
                    // @ts-ignore
                    window.webkit.messageHandlers.iosListener.postMessage('close');
                    setTimeout(() => {
                        window.open(this.account.settings.homeUrl);
                    }, 700);
                } else if (getDevice().android) {
                    // @ts-ignore
                    window.webInterface.onMessage('close');
                    setTimeout(() => {
                        window.open(this.account.settings.homeUrl);
                    }, 700);
                } else {
                    this.safeTryingSendMessageToAndroid();
                    this.safeTryingSendMessageToIos();
                    setTimeout(() => {
                        window.open(this.account.settings.homeUrl);
                    }, 700);
                }
            } catch (e) {
                this.safeTryingSendMessageToAndroid();
                this.safeTryingSendMessageToIos();
                setTimeout(() => {
                    window.open(this.account.settings.homeUrl);
                }, 700);
            }
        },
        safeTryingSendMessageToAndroid() {
            try {
                // @ts-ignore
                window.webInterface.onMessage('close');
            } catch (e) {
            }
        },
        safeTryingSendMessageToIos() {
            try {
                // @ts-ignore
                window.webkit.messageHandlers.iosListener.postMessage('close');
            } catch (e) {
            }
        },
        CLOSE() {
            switch (this.account.settings.homeUrlType) {
                case 'webview':
                    window.open(this.account.settings.homeUrl, '_self');
                    break;
                case 'deeplink':
                    try {
                        if (getDevice().android) { // window.webInterface
                            // @ts-ignore
                            window.webInterface.onMessage(JSON.stringify({
                                "deeplink": this.account.settings.homeUrl
                            }));
                        }
                        if (getDevice().ios) { // window.webkit
                            window.webkit.messageHandlers.iosListener.postMessage(JSON.stringify({
                                "deeplink": this.account.settings.homeUrl
                            }));
                        }
                    } catch (e) {
                        // console.log(e);
                    }
                    break;
                default:
                    break;
            }
        },
        prepareLinkName(link: string) {
            return link.replace(/(http)s?:\/\//, '').replace(/\..+/, '');
        },
    }
})
</script>
