import PurchaseOperation from "@models/operations/PurchaseOperation";
import MainApiService from "@/services/api-service/MainApiService";
import {plainToInstance} from "class-transformer";
import {validateOrReject} from "class-validator";
import {ToRef} from "@vue/runtime-core";
import {ref} from "vue";
import PaymentTimerService from "@/services/operations/payment/PaymentTimerService";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import humps from "lodash-humps-ts";
import LightPaymentPidResponseError from "@models/responses/light-payment-pid/LightPaymentPidResponseError";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import {FirebaseEvents} from "@/services/firebase/analytics/FirebaseEvents";
import FirebaseEventBuilder from "@/services/firebase/analytics/FirebaseEventBuilder";
import PaymentCheckImage from "@models/operations/payment/PaymentCheckImage";
import {ChatMessage} from "@/entities/chat/ChatPayload";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";

export default class PaymentService {
    private _payment: ToRef<PurchaseOperation | null> = ref(null);
    private _timerService: PaymentTimerService = new PaymentTimerService();
    private _isExternalCreated: ToRef<boolean> = ref(false);
    private _MOCK_STATUS: BuyingOperationStatus | null = null;
    private _paymentError: ToRef<LightPaymentPidResponseError | null> = ref(null);

    public async uploadCheckImages(checkImages: PaymentCheckImage[]) {
        await Promise.all(checkImages.map(async (checkImage: PaymentCheckImage) => {
            const payloadToSendMessage = {
                msid: this._payment.value.msid,
                message: "",
                wait_files: 1
            };
            // const responseMessage: ChatMessage = await MainApiService.getInstance().post('/api/mobile/client/chat', payloadToSendMessage);
            const { data: responseMessage } = await ServiceMainApi.of().post<{data: ChatMessage}>('/api/mobile/client/chat', payloadToSendMessage);
            const formData = new FormData();
            formData.append('chat_id', responseMessage.chatId.toString());
            formData.append('message_id', responseMessage.id.toString());
            formData.append('file', checkImage.file);
            // await MainApiService.getInstance().post('/api/mobile/client/chat/message/file', formData, true);
            await ServiceMainApi.of().post('/api/mobile/client/chat/message/file', formData);
        }));
    }

    get paymentError(): ToRef<LightPaymentPidResponseError | null> {
        return this._paymentError;
    }

    set paymentError(value: LightPaymentPidResponseError | null) {
        this._paymentError.value = value;
    }

    public paymentFactory(msid: string) {
        this._payment.value = plainToInstance(PurchaseOperation, new PurchaseOperation(), {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        this._payment.value.msid = msid;
    }

    public async createPayment(payload: createPaymentPayload) {
        // const response = await MainApiService.getInstance().post('/api/mobile/client/payments/create', payload);
        const response = await ServiceMainApi.of().post<{ data: any }>('/api/mobile/client/payments/create', payload);
        const payment: PurchaseOperation = plainToInstance(PurchaseOperation, response.data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        await validateOrReject(payment);
        this._payment.value = payment;
        return payment;
    }

    public async recreatePayment() {
        this.checkCreatePayload();
        const payload: createPaymentPayload = {
            amount: this._payment.value!.amount!,
            // psystem_id: this._payment.value!.psystemObj?.id!,
            psystem_id: null,
            currency_id: this._payment.value!.currency?.id!,
            retry_for: this._payment.value!.msid!
        }
        await this.createPayment(payload);
    }

    // public async cancelOldPayments() {
    //     await ApiService.getInstance().patch('/api/main/client/payments/cancelOldCreated');
    // }

    public async updatePayment() {
        this.checkMSID();
        let response: any;

        if (this._MOCK_STATUS !== null) {
            response = this.retrieveMockWithStatus(this._MOCK_STATUS);
        } else {
            // response = await MainApiService.getInstance().get(`/api/mobile/client/payments/get/${this._payment.value!.msid}`);
            response = await ServiceMainApi.of().get<{ data: any }>(`/api/mobile/client/payments/get/${this._payment.value!.msid}`);
        }

        const payment: PurchaseOperation = plainToInstance(PurchaseOperation, response.data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        await validateOrReject(payment);
        this._payment.value = payment;
    }

    public async toAppealPayment() {
        this.checkMSID();

        // const response = await MainApiService.getInstance().patch(`/api/mobile/client/payments/appeal`, {
        //     msid: this._payment.value!.msid
        // });
        const { data: response } = await ServiceMainApi.of().patch<{ data: any }>(`/api/mobile/client/payments/appeal`, {
            msid: this._payment.value!.msid
        });
        const payment: PurchaseOperation = plainToInstance(PurchaseOperation, response, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        await validateOrReject(payment);
        this._payment.value = payment;
    }

    public async fetchPayment(payload: fetchPaymentPayload) {
        let response: any;

        if (this._MOCK_STATUS !== null) {
            response = this.retrieveMockWithStatus(this._MOCK_STATUS);
        } else {
            // response = await MainApiService.getInstance().get(`/api/mobile/client/payments/get/${payload.msid}`);
            response = await ServiceMainApi.of().get<{ data: any }>(`/api/mobile/client/payments/get/${payload.msid}`);
        }
        const payment: PurchaseOperation = plainToInstance(PurchaseOperation, response.data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        await validateOrReject(payment);
        this._payment.value = payment;
    }

    public async cancelPayment() {
        this.checkMSID();

        // await MainApiService.getInstance().patch(`/api/mobile/client/payments/cancel`, {
        //     msid: this._payment.value!.msid
        // });
        await ServiceMainApi.of().patch(`/api/mobile/client/payments/cancel`, {
            msid: this._payment.value!.msid
        });
        FirebaseService.of().analyticsEvent(
            FirebaseEvents.PAYMENT_CANCEL,
            FirebaseEventBuilder.of().build()
        );
    }

    public async confirmPayment() {
        this.checkMSID();

        // const response: object = await MainApiService.getInstance().post(`/api/mobile/client/payments/confirm`, {
        //     msid: this._payment.value!.msid
        // });
        const response = await ServiceMainApi.of().post<{ data: any }>(`/api/mobile/client/payments/confirm`, {
            msid: this._payment.value!.msid
        });

        const payment: PurchaseOperation = plainToInstance(PurchaseOperation, response.data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        await validateOrReject(payment);
        this._payment.value = payment;
    }

    public reset() {
        this._payment.value = null;
        this._timerService.stopIntervals();
    }

    set payment(value: PurchaseOperation | null) {
        this._payment.value = value;
    }

    get payment(): ToRef<PurchaseOperation | null> {
        return this._payment;
    }

    get timerService(): PaymentTimerService {
        return this._timerService;
    }

    get isExternalCreated(): ToRef<boolean> {
        return this._isExternalCreated;
    }

    // set isExternalCreated(value: ToRef<boolean>) {
    //     this._isExternalCreated = value;
    // }

    private checkMSID() {
        if (this._payment.value === null) throw new Error(`[${this.constructor.name}]: payment is null`);
        if (this._payment.value.msid === null) throw new Error(`[${this.constructor.name}]: payment msid is null`);
    }

    private checkCreatePayload() {
        if (this._payment.value === null) throw new Error(`[${this.constructor.name}]: payment is null`);
        if (this._payment.value.msid === null) throw new Error(`[${this.constructor.name}]: payment msid is null`);
        // if (this._payment.value.psystemObj === null) throw new Error(`[${this.constructor.name}]: payment psystemObj is null`);
        // if (this._payment.value.psystemObj.id === null) throw new Error(`[${this.constructor.name}]: payment psystemObj.id is null`);
        if (this._payment.value.currency === null) throw new Error(`[${this.constructor.name}]: payment currency is null`);
        if (this._payment.value.currency.id === null) throw new Error(`[${this.constructor.name}]: payment currency.id is null`);
    }

    private retrieveMockWithStatus(status: BuyingOperationStatus) {
        let result = null;
        // switch (status) {
        //     case BuyingOperationStatus.CANCELED:
        //         result = humps(PAYMENT_MOCK_CANCELED);
        //         break;
        //     case BuyingOperationStatus.TMP_NO_AGENT_EXPIRED:
        //         result = humps(PAYMENT_MOCK_TMP_NO_AGENT_EXPIRED);
        //         break;
        //     case BuyingOperationStatus.EXPIRED:
        //         result = humps(PAYMENT_MOCK_EXPIRED);
        //         break;
        // }
        return result;
    }
}

export type createPaymentPayload = {
    amount: number,
    psystem_id?: number | null,
    account_ids?: number[],
    currency_id?: number,
    retry_for?: string,
    msid?: string
}

export type fetchPaymentPayload = {
    msid: string
}
