<template>
    <div class="message-success display-flex align-items-center justify-content-center flex-direction-column fade-in">
        <div class="success-svg display-block"></div>
        <span class="title">{{ title }}</span>
        <span class="text">{{ text }}</span>
        <f7-button class="close-btn" @click="close">Закрыть</f7-button>
    </div>
</template>

<script lang="ts">
import Message from "@/entities/Message";
import {defineComponent, PropType} from "vue";
import {f7} from "framework7-vue";

export default defineComponent({
    props: {
        message: {
            type: Object as PropType<Message>,
            required: true
        }
    },
    setup(props) {
        const {title, text} = props.message;
        return {
            title, text
        }
    },
    methods: {
        close() {
            // @ts-ignore
            if (typeof(window.webInterface) !== "undefined") {
                // @ts-ignore
                window.webInterface.onMessage('close');
            }
            // @ts-ignore
            if (window.webkit) {
                // @ts-ignore
                window.webkit.messageHandlers.iosListener.postMessage("close");
            }
        }
    }
})
</script>

<style lang="scss" src="./index.scss"></style>
