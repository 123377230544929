<template>
    <div class="payment__status rejected">
        <div class="status-image"></div>
        <PaymentStatusText
            :title="$t('light-payment.status.rejected.title')"
            :text="$t('light-payment.status.rejected.text')"
        />
        <PaymentViewRequisites :payment="payment"/>
    </div>
</template>

<script setup lang="ts">
import PaymentStatusText from "../atomic/PaymentStatusText.vue";
import PaymentViewRequisites from "@/views/payment/components/PaymentViewRequisites.vue";
import {PropType, toRef} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";

const props = defineProps({
    payment: {
        type: Object as PropType<PurchaseOperation>,
        required: true
    },
});

const payment = toRef(props, 'payment');
</script>

<style src="./index.scss"></style>
<style lang="scss">
.payment__status {
    &.rejected {
        display: flex;
        align-items: center;
        margin-top: 56px;
        flex-direction: column;

        .payment__status-timer {
            height: 100px;
        }

        .status-image {
            width: 100px;
            height: 100px;
            background-image: url("data:image/svg+xml,%3Csvg width='101' height='100' viewBox='0 0 101 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4372_198879)'%3E%3Cpath d='M50.3242 43.3779L59.6797 34.1787C61.5156 32.3623 64.4844 32.3623 66.1445 34.1787C68.1367 36.0146 68.1367 38.9834 66.1445 40.6436L57.1211 49.8232L66.1445 59.1787C68.1367 61.0147 68.1367 63.9834 66.1445 65.6436C64.4844 67.6357 61.5156 67.6357 59.6797 65.6436L50.3242 56.6201L41.1445 65.6436C39.4844 67.6357 36.5156 67.6357 34.6797 65.6436C32.8633 63.9834 32.8633 61.0147 34.6797 59.1787L43.8789 49.8232L34.6797 40.6436C32.8633 38.9834 32.8633 36.0146 34.6797 34.1787C36.5156 32.3623 39.4844 32.3623 41.1445 34.1787L50.3242 43.3779Z' fill='%23FF3B30'/%3E%3Cpath opacity='0.4' d='M0.5 50C0.5 22.3828 22.8828 0 50.5 0C78.1172 0 100.5 22.3828 100.5 50C100.5 77.6172 78.1172 100 50.5 100C22.8828 100 0.5 77.6172 0.5 50ZM34.6797 40.6445L43.8789 49.8242L34.6797 59.1797C32.8633 61.0156 32.8633 63.9844 34.6797 65.6445C36.5156 67.6367 39.4844 67.6367 41.1445 65.6445L50.3242 56.6211L59.6797 65.6445C61.5156 67.6367 64.4844 67.6367 66.1445 65.6445C68.1367 63.9844 68.1367 61.0156 66.1445 59.1797L57.1211 49.8242L66.1445 40.6445C68.1367 38.9844 68.1367 36.0156 66.1445 34.1797C64.4844 32.3633 61.5156 32.3633 59.6797 34.1797L50.3242 43.3789L41.1445 34.1797C39.4844 32.3633 36.5156 32.3633 34.6797 34.1797C32.8633 36.0156 32.8633 38.9844 34.6797 40.6445Z' fill='%23FF3B30'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4372_198879'%3E%3Crect width='100' height='100' fill='white' transform='translate(0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }
    }
}
</style>