<template>
    <div class="hunting-agents-toast toast toast-bottom toast-horizontal-left">
        <div class="toast-content">
            <div class="toast-content_line">
                <p>{{ $t("toasts.hunting-agents.text") }}</p>
            </div>
            <div class="toast-content_line external" @click="toTelegram">
                <p class="external">{{ $t("toasts.hunting-agents.more") }}</p>
                <i class="icon svg-arrow-right svg-filter-blue1"></i>
            </div>
        </div>
        <div class="toast-right" @click="HuntingAgentsService.of().forceHideToast()">
            <i class="icon close-svg svg-filter-AAADB3"></i>
        </div>
    </div>
</template>

<script setup lang="ts">
import {f7} from "framework7-vue";
import {getDevice} from "framework7/shared/get-device";
import {useI18n} from "vue-i18n";
import HuntingAgentsService from "@/services/others/HuntingAgentsService";

const {t} = useI18n({useScope: 'global'});

function toTelegram() {
    try {
        const url = "https://t.me/usdt24bot?start=olimp";
        if (getDevice().ios) {
            // @ts-ignore
            window.webkit.messageHandlers.iosListener.postMessage(JSON.stringify({
                "deeplink": "https://t.me/usdt24bot?start=olimp"
            }));
        } else if (getDevice().android) {
            // @ts-ignore
            window.webInterface.onMessage(JSON.stringify({
                "deeplink": "https://t.me/usdt24bot?start=olimp"
            }));
        } else {
            window.open(url, "_target");
        }
    } catch (e) {
        window.open('https://t.me/usdt24bot?start=olimp', "_target");
    }
}
</script>

<style lang="scss">
.hunting-agents-toast {
    background: #F7F7F8 !important;
    color: #000;
    border-radius: 18px !important;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px 0px;

    .toast-right {
        padding: 12px 16px 0 0;
        display: flex;

        .close-svg {
            cursor: pointer;
            width: 9px;
            height: 9px;
        }
    }

    .toast-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        border-radius: inherit !important;
        padding: 12px 16px !important;

        .toast-content_line {
            display: flex;
            align-items: center;
            gap: 4px;

            @include text(14px, 400, #000, 16.8px);

            &.external {
                cursor: pointer;
                color: var(--blue1);

                .svg-arrow-right {
                    width: 14px;
                    height: 14px;
                }
            }

            .external {

            }
        }
    }
}
</style>