import PaymentWithdrawal from "@/entities/PaymentWithdrawal";
import {P_WITHDRAWAL_STATUS} from "@/entities/enums/PWithdrawalStatus";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

class GPWithdrawalController {
    public getStatusMM(paymentWithdrawal: PaymentWithdrawal): number {
        let updatedAt = dayjs(paymentWithdrawal.updatedAt).utc();
        let ss = 0;
        // Set time for calculate view time
        switch (paymentWithdrawal.status) {
            case P_WITHDRAWAL_STATUS.CREATED:
                ss = 120000; // 2 min
                break;
            case P_WITHDRAWAL_STATUS.ACCEPTED:
                ss = 1200000; // 20 min
                break;
            case P_WITHDRAWAL_STATUS.CASHED:
                ss = 600000; // 10 min
                break;
            default:
        }

        updatedAt = updatedAt.add(ss, "millisecond");
        let result = updatedAt.diff(dayjs().utc(), "millisecond");
        if (result <= 0) result = 0;
        return result;
    }
}

export default new GPWithdrawalController();
