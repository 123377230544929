import {f7} from "framework7-vue";
import ViewController from "@/interfaces/ViewController";
import {AgentNotFoundError, ServerResponseError} from "@/interfaces/errors";
// @ts-ignore
import store from "@target/core/store/store";

export function catchErrors(target: ViewController, _2: any, descriptor: any) {
    let fn = descriptor.value;

    // @ts-ignore
    if (typeof this === 'undefined') {
        return workWithoutContext(fn, descriptor);
    } else {
        return workWithContext(fn, descriptor);
    }
}

function workWithoutContext(fn: any, descriptor: any) {
    descriptor.value = async function () {
        try {
            await fn.bind(this)();
        } catch (e: any) {
            handlerError(this, e);
        } finally {
            f7.preloader.hide();
        }
    }
    return descriptor;
}

function workWithContext(fn: any, descriptor: any) {
    descriptor.value = async function () {
        try {
            await fn();
        } catch (e: any) {
            handlerError(this, e);
        } finally {
            f7.preloader.hide();
        }
    }
    return descriptor;
}

function handlerError(context: any, error: any) {
    context.destructor();
    let text = 'Что-то пошло не так';

    if (typeof error.type !== 'undefined') {
        switch (error.type) {
            case 'SERVER_RESPONSE_ERROR':
                switch (error.code) {
                    case 10030:
                        text = 'Нет свободных агентов';
                        const el: HTMLDivElement = document.querySelector('.no-agents-sheet')!;
                        const sheet = f7.sheet.create({
                            el,
                        });
                        sheet.open();
                        break;
                }
                break;
        }
        return;
    }
    // f7.dialog.alert(text, 'Упс!', () => {});
}
