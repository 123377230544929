<template>
    <div class="payment-income__status confirmed">
        <div class="wrapper-elements">
            <div class="status-image"></div>
            <OperationStatusText
                :title="`+${payment.amount} $`"
                :text="$t('payment-income.statuses.confirmed.subtitle')"
            />
        </div>
        <PaymentIncomeViewRequisites :payment="payment" />
    </div>
</template>

<script setup lang="ts">
import OperationMeta from "@components/operation/OperationMeta.vue";
import OperationStatusText from "@components/operation/OperationStatusText.vue";
import {PropType, ToRef, toRef} from "vue";
import PaymentIncomeOperation from "@models/operations/PaymentIncomeOperation";
import PaymentIncomeViewRequisites from "@/views/payment-income/components/PaymentIncomeViewRequisites.vue";

const props = defineProps({
    payment: {
        type: Object as PropType<PaymentIncomeOperation>,
        required: true
    }
});
const payment: ToRef<PaymentIncomeOperation> = toRef(props, 'payment');
</script>

<style lang="scss">
.payment-income__status {
    box-sizing: border-box;
    width: 100%;

    .wrapper-elements {
        display: flex;
        align-items: center;
        margin-top: 56px;
        flex-direction: column;
        padding: 0 16px;
    }

    &.confirmed {
        .operation-status-text__title {
            @include text(28px, 700, var(--brown1), 33.6px);
        }

        .operation-status-text {
            padding: 0 16px;
        }

        .info-block {
            & + .button {
                margin-top: 32px;
            }
        }

        .status-image {
            & + .operation-status-text {
                margin-top: 32px;
            }
        }

        .status-image {
            width: 100px;
            height: 100px;
            background-image: url("data:image/svg+xml,%3Csvg width='101' height='100' viewBox='0 0 101 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4372_197603)'%3E%3Cpath d='M73.1177 33.6318C75.2466 35.7607 75.2466 39.2373 73.1177 41.3662L48.1177 66.3662C45.9888 68.4951 42.5122 68.4951 40.3833 66.3662L27.8833 53.8662C25.7544 51.7373 25.7544 48.2607 27.8833 46.1318C30.0122 44.0029 33.4888 44.0029 35.6177 46.1318L44.2505 54.7646L65.3833 33.6318C67.5122 31.5029 70.9888 31.5029 73.1177 33.6318Z' fill='%2332BF61'/%3E%3Cpath opacity='0.4' d='M0.5 50C0.5 22.3828 22.8828 0 50.5 0C78.1172 0 100.5 22.3828 100.5 50C100.5 77.6172 78.1172 100 50.5 100C22.8828 100 0.5 77.6172 0.5 50ZM73.1172 41.3672C75.2461 39.2383 75.2461 35.7617 73.1172 33.6328C70.9883 31.5039 67.5117 31.5039 65.3828 33.6328L44.25 54.7656L35.6172 46.1328C33.4883 44.0039 30.0117 44.0039 27.8828 46.1328C25.7539 48.2617 25.7539 51.7383 27.8828 53.8672L40.3828 66.3672C42.5117 68.4961 45.9883 68.4961 48.1172 66.3672L73.1172 41.3672Z' fill='%2332BF61'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4372_197603'%3E%3Crect width='100' height='100' fill='white' transform='translate(0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }
    }
}
</style>