import {OperationType_N} from "@enums/OperationType_N";
import dayjs from "dayjs";
import {Expose} from "class-transformer";

export default abstract class Operation {
    private readonly _operationType: OperationType_N;

    @Expose({name: 'createdAt'})
    private _createdAt: string | null = null;

    protected constructor(
        type: OperationType_N,
        createdAt: string
    ) {
        this._operationType = type;
        this._createdAt = createdAt;
    }

    get operationType(): OperationType_N {
        return this._operationType;
    }

    public getHumanCreateAt() {
        return dayjs(this._createdAt).format('DD.MM.YYYY HH:mm');
    }
}