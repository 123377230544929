import {Expose} from "class-transformer";

export default class KodmobiChallenge {
    @Expose({name: 'enabled'})
    private readonly _enabled: boolean;
    @Expose({name: 'apiKey'})
    private readonly _apiKey: string | null;

    constructor(enabled: boolean, apiKey: string | null) {
        this._enabled = enabled;
        this._apiKey = apiKey;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    get apiKey(): string | null {
        return this._apiKey;
    }
}
