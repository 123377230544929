<template>
    <f7-block class="requisites-page_hint my-0">
        <p class="text">{{ $t("requisites.hint1") }}</p>
    </f7-block>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.text {
    text-align: center;
    @include text(14px, 400, var(--grey2), 16.8px);
}
</style>