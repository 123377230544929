<template>
    <div class="payment" :class="'payment-' + payment.id" @click="toPayment(payment.msid)">
        <div class="body">
            <div class="header-wrap">
                <div class="amount">
                    <i class="icon" :class="iconPayment"></i>
                    <div class="wrapper-numbers">
                        <span class="main" v-format-number>{{ payment.amount }} {{ payment.currency.abbr }}</span>
                        <span class="convert">{{ getOperationTypeText() }} {{ payment.amountUsd }} $</span>
                    </div>
                </div>
                <div class="right-side">
                    <WithdrawalOperationStatus :payment="payment" />
                </div>
            </div>
            <div class="details-wrap">
                <p class="number">{{ $t('g.request') }} {{ payment.id }} <span
                    class="data">{{ convertDatetime(payment.createdAt) }}</span></p>

                <div class="wrapper-right">
                    <i v-if="payment.chat?.unread > 0" class="icon alert-message-svg"></i>
                    <p class="agent" v-if="payment.account">{{ payment.account }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
//@ts-ignore
import AppController from "@target/components/App/ts/AppController";
// @ts-ignore
import store from "@target/core/store/store";
import {useI18n} from "vue-i18n";
import {computed, Ref, toRef} from "vue";
import {type PropType} from 'vue'
import Account from "@/entities/Account";
import {f7, useStore} from "framework7-vue";
import PaymentWithdrawal from "@/entities/PaymentWithdrawal";
import dayjs from "dayjs";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";
import WithdrawalOperationStatus from "@components/atomic/operation-status/WithdrawalOperationStatus.vue";
import ErrorsService from "@/services/errors-service/ErrorsService";

const {t} = useI18n({useScope: 'global'});
const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;

const props = defineProps({
    payment: {
        type: Object as PropType<PaymentWithdrawal>,
        required: true,
    }
})
const payment: Ref<PaymentWithdrawal> = toRef(props, "payment");
const account: Ref<Account> = useStore("account");
const olimpSlugs = ["olimp", "olimp-casino"];
const iconPayment = computed(() => {
    if (olimpSlugs.includes(account.value.project.slug)) {
        if (payment.value.operationType == 'purchase') {
            return 'logo-round-olimp-com-svg';
        } else {
            return 'logo-round-minus-svg';
        }
    } else {
        if (payment.value.operationType == 'purchase') {
            return 'logo-round-plus-svg';
        } else {
            return 'logo-round-minus-svg';
        }
    }
})

async function toPayment(msid: string) {
    // @ts-ignore
    const el = window.event.target.closest('.payment');
    el.classList.add("active-state");
    setTimeout(() => {
        el.classList.remove("active-state");
    }, 100);

    try {
        f7.preloader.show();
        await withdrawalService.fetchWithdrawal({msid});
        // await store.dispatch("fetchPaymentWithdrwawal", {msid});
        f7.views.main.router.navigate('/withdrawal');
    } catch (e: any) {
        ErrorsService.of().handle(e);
    } finally {
        f7.preloader.hide();
    }
};

function getOperationTypeText() {
    switch (payment.value.operationType) {
        case "purchase":
            if (olimpSlugs.includes(account.value.project.slug)) {
                return t('payment.pay');
            }
            return t('payment.purchase');
        case "sale":
            return t('payment.sale');
    }
    return "";
};

function convertTuHumanStatus(status: string, operationType: string) {
    let result = 'Undefined';
    switch (status) {
        case 'CASHED':
            result = t("g.payments.statuses.withdrawal.CASHED");
            break;
        case 'ONAPPEAL':
            result = t("g.payments.statuses.ONAPPEAL");
            break;
        case 'CONFIRMED':
            result = t("g.payments.statuses.withdrawal.CONFIRMED");
            break;
        case 'CREATED':
            result = t("g.payments.statuses.CREATED");
            break;
        case 'ACCEPTED':
            result = t("g.payments.statuses.withdrawal.ACCEPTED");
            break;
        case 'REJECTED':
            result = t("g.payments.statuses.REJECTED");
            break;
        case 'EXPIRED':
            result = t("g.payments.statuses.EXPIRED");
            break;
        case 'CANCELED':
            result = t("g.payments.statuses.CANCELED");
            break;
    }
    return result;
}

function convertDatetime(dateString: string) {
    return dayjs(dateString).format('DD.MM.YYYY HH:mm');
};

</script>
