import {
  parsePhoneNumberFromString,
  AsYouType,
  type CountryCode,
  getCountries,
  getCountryCallingCode,
  getExampleNumber,
  isSupportedCountry,
  type Examples,
} from 'libphonenumber-js'
import { countriesNameListByIsoCode } from './countries-name-list-by-iso-code'
import type { Country, Result } from '../types'
import countryDialInfo from './country_dial_info';
import dialByLocales from "@/components/maz/components/MazPhoneNumberInput/utils/dial_by_countries";

export function getCountryName(
  code: CountryCode | string,
  customCountriesNameListByIsoCode?: Record<CountryCode, string>,
): string | undefined {
  return {
    ...countriesNameListByIsoCode,
    ...customCountriesNameListByIsoCode,
  }[code]
}

const PHONE_CHAR_REGEX = /^[\d ().-]+$/
const NON_ALPHA_REGEX = /^[^a-z]+$/i

let examples: Examples

export async function loadPhoneNumberExamplesFile() {
  const { default: data } = await import('libphonenumber-js/examples.mobile.json')

  examples = data
  return examples
}

export function getExamplePhoneNumber(countryCode: CountryCode) {
  return getExampleNumber(countryCode, examples)
}

export function sanitizePhoneNumber(input?: string) {
  if (!input) {
    return
  }

  const hasNonAlpha = NON_ALPHA_REGEX.test(input)
  const hasPhoneChar = PHONE_CHAR_REGEX.test(input)

  if (!hasNonAlpha && !hasPhoneChar) {
    return input.replace(/[^\d.]/g, '')
  }

  return input
}

export function getCountryFlag(locale: string): string | undefined {
  if (countryDialInfo.get(locale)) {
    return JSON.parse(countryDialInfo.get(locale)!).flag;
  }
  return undefined;
}
export function getCountrySimpleName(locale: string): string | undefined {
  if (countryDialInfo.get(locale)) {
    return JSON.parse(countryDialInfo.get(locale)!).name;
  }
  return undefined;
}

export function getCountryDialCode(locale: string): string | undefined {
  if (countryDialInfo.get(locale)) {
    return JSON.parse(countryDialInfo.get(locale)!).dialCode;
  }
  return undefined;
}
export function tryFindCountryCode(countryCode: string): string | undefined {
  return dialByLocales.get(countryCode);
}

export function getCountriesList(
  customCountriesNameListByIsoCode?: Record<CountryCode, string>,
): Country[] | undefined {
  let countriesList: Country[] = []
  const isoList = getCountries()

  for (const iso2 of isoList) {
    // const name = getCountryName(iso2, customCountriesNameListByIsoCode)
    const flag = getCountryFlag(iso2);
    const name = getCountrySimpleName(iso2);

    if (name && flag) {
      try {
        const dialCode = getCountryCallingCode(iso2)
        countriesList.push({
          flag,
          iso2,
          dialCode,
          name,
        })

        countriesList = countriesList.sort(function(a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        // console.log(countriesList)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`[MazPhoneNumberInput](getCountryCallingCode) ${error}`)
      }
    }
  }

  return countriesList
}

export function browserLocale() {
  try {
    if (typeof window === 'undefined') {
      return
    }

    const browserLocale = window.navigator.language

    if (!browserLocale) {
      return
    }

    let locale = browserLocale.slice(3, 7).toUpperCase()

    if (locale === '') {
      locale = browserLocale.slice(0, 2).toUpperCase()
    }

    if (locale === 'EN') {
      locale = 'US'
    }
    if (locale === 'JA') {
      locale = 'JP'
    }

    return locale
  } catch (error) {
    throw new Error(`[MazPhoneNumberInput](browserLocale) ${error}`)
  }
}

export function isCountryAvailable(locale: string) {
  try {
    const response = isSupportedCountry(locale)

    if (!response) {
      console.error(
        `[MazPhoneNumberInput](isCountryAvailable) The code country "${locale}" is not available`,
      )
    }

    return response
  } catch (error) {
    throw new Error(`[MazPhoneNumberInput](isCountryAvailable) ${error}`)
  }
}

export const getResultsFromPhoneNumber = (
  countryCode?: CountryCode,
  phoneNumber?: string,
): Result => {
  try {
    if (!phoneNumber) {
      return {
        isValid: false,
        countryCode,
      }
    }
    const parsing = parsePhoneNumberFromString(phoneNumber, countryCode)

    return {
      isValid: parsing?.isValid() ?? false,
      isPossible: parsing?.isPossible(),
      countryCode: parsing?.country,
      countryCallingCode: parsing?.countryCallingCode,
      nationalNumber: parsing?.nationalNumber,
      type: parsing?.getType(),
      formatInternational: parsing?.formatInternational(),
      formatNational: parsing?.formatNational(),
      uri: parsing?.getURI(),
      e164: parsing?.format('E.164'),
      rfc3966: parsing?.format('RFC3966'),
    }
  } catch (error) {
    throw new Error(`[MazPhoneNumberInput](getResultsFromPhoneNumber) ${error}`)
  }
}

export function getAsYouTypeFormat(countryCode: CountryCode, phoneNumber?: string) {
  try {
    if (!phoneNumber) {
      return
    }

    return countryCode ? new AsYouType(countryCode).input(phoneNumber) : phoneNumber
  } catch (error) {
    throw new Error(`[MazPhoneNumberInput](getAsYouTypeFormat) ${error}`)
  }
}

interface IpWhoResponse {
  ip: string
  success: true
  type?: string
  continent?: string
  continent_code?: string
  country?: string
  country_code?: string
  region?: string
  region_code?: string
  city?: string
  latitude?: number
  longitude?: number
  is_eu: true
  postal?: string
  calling_code?: string
  capital?: string
  borders?: string
  flag: {
    img?: string
    emoji?: string
    emoji_unicode?: string
  }
  connection: {
    asn?: number
    org?: string
    isp?: string
    domain?: string
  }
  timezone: {
    id?: string
    abbr?: string
    is_dst: false
    offset?: number
    utc?: string
    current_time?: string
  }
}

export async function fetchCountryCode() {
  try {
    const reponse = await fetch('https://ipwho.is')
    const { country_code } = (await reponse.json()) as IpWhoResponse

    return country_code
  } catch (error) {
    throw new Error(`[MazPhoneNumberInput](fetchCountryCode) ${error}`)
  }
}
