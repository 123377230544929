<template>
    <div class="my-payments">
        <div class="title-wrapper">
            <span class="title">{{ $t("payments.myPayments") }}</span>
        </div>
        <div class="payments-list">
            <template v-for="operation in operations">
                <template v-if="operation.operationType === OperationType_N.income">
                    <PaymentCardIncome
                        :key="(operation as PaymentIncome).status + '' + '' + (operation as PaymentIncome).id"
                        :payment="(operation as PaymentIncome)"
                    />
                </template>
                <template v-else-if="operation.operationType === OperationType_N.purchase">
                    <purchase-operation-preview-card
                        :key="(operation as PurchaseOperation).status + '' + (operation as PurchaseOperation).msid + '' + (operation as PurchaseOperation).chat?.unread"
                        :data="(operation as PurchaseOperation)"
                    />
                </template>
                <template v-else-if="operation.operationType === OperationType_N.sale">
                    <PaymentSellCard
                        :key="(operation as PaymentWithdrawal).status + '' + (operation as PaymentWithdrawal)?.msid + '' + (operation as PaymentWithdrawal).chat?.unread"
                        :payment="(operation as PaymentWithdrawal)"
                    />
                </template>
                <template v-else-if="operation.operationType === OperationType_N.CLIENT_TRANSFER">
                    <OperationClientTransfer
                        @on-click-item="toOperationPage"
                        :key="(operation as WithdrawalWalletOperation)?.msid"
                        :operation="(operation as WithdrawalWalletOperation)"
                    />
                </template>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
// @ts-ignore
import store from "@target/core/store/store";
import {useStore} from 'framework7-vue';
import PaymentWithdrawal from "@/entities/PaymentWithdrawal";
import PaymentIncome from "@/entities/PaymentIncome";
import PaymentSellCard from "@/components/payment-card/PaymentSellCard.vue";
import PurchaseOperationPreviewCard from "@/components/payment-card/PurchaseOperationPreviewCard.vue";
import PaymentCardIncome from "@/components/payment-card/PaymentCardIncome.vue";
import {OperationType_N} from "@enums/OperationType_N";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import Operation from "@models/operations/Operation";
import OperationClientTransfer from "@components/operation-cards/client-transfer/OperationClientTransfer.vue";
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";
import RouterService from "@/services/RouterService";
import CreateWalletWithdrawalDto from "@/services/operations/withdrawal/dto/CreateWalletWithdrawalDto";
import Requisite from "@models/requisite/Requisite";

const operations: Operation[] = useStore('myPayments');
const routerService: RouterService = AppController.getInstance().routerService;

function toOperationPage(operation: Operation) {
    switch (operation.operationType) {
        case OperationType_N.CLIENT_TRANSFER:
            routerService.toWithdrawalWalletPage((operation as WithdrawalWalletOperation));
            break;
    }

}
</script>

<style lang="scss">
.my-payments {
    .block-header {
        margin-top: 24px;
        margin-bottom: 8px;
    }

    .title-wrapper {
        align-items: center;
        margin: 0px 16px 5px;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        cursor: pointer;

        .title {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #818E95;
        }
    }

    margin-top: 27px;

    .payments-list {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: 0;
            right: auto;
            bottom: auto;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 0%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }

        &:after {
            content: '';
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 100%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
    }
}
</style>
