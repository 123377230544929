<template>
    <div class="payment" :class="'payment-' + purchase.id" @click="toPayment()">
        <div class="body">
            <div class="header-wrap">
                <div class="amount">
                    <template v-if="checkIsOlimpProject()">
                        <i class="icon round-bill-svg"></i>
                    </template>
                    <template v-else>
                        <i class="icon logo-round-plus-svg"></i>
                    </template>

                    <div class="wrapper-numbers">
                        <span class="main" v-format-number>{{ purchase.amount }} {{ purchase.currency.getABBR() }}</span>
                        <span class="convert">{{ getOperationTypeText() }} {{ purchase.amountUsd }} $</span>
                    </div>
                </div>
                <div class="right-side">
                    <PurchaseOperationStatus :payment="purchase" />
<!--                    <span class="status" :class="[purchase.status!.toLowerCase(), purchase.operationType]">{{-->
<!--                            convertToHumanStatus(purchase.status)-->
<!--                        }}</span>-->
                </div>
            </div>
            <div class="details-wrap">
                <p class="number">{{ $t('g.request') }} {{ purchase.id }}
                    <span class="data">{{ purchase.getHumanCreateAt() }}</span>
                </p>

                <div class="wrapper-right">
                    <i v-if="purchase.chat?.unread > 0" class="icon alert-message-svg"></i>
                    <p class="agent" v-if="purchase.account">{{ purchase.account }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
// @ts-ignore
import store from "@target/core/store/store";
import {useI18n} from "vue-i18n";
import {type PropType, Ref, toRef} from "vue";
import Account from "@/entities/Account";
import {f7, useStore} from "framework7-vue";
import dayjs from "dayjs";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {PurchaseOperationSubtype} from "@enums/PurchaseOperationSubtype";
import LightBuyingService from "@/services/light-buying/LightBuyingService";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {fetchPaymentPayload} from "@/services/operations/payment/PaymentService";
import PurchaseOperationStatus from "@components/atomic/operation-status/PurchaseOperationStatus.vue";

const {t} = useI18n({useScope: 'global'});

const props = defineProps({
    data: {
        type: Object as PropType<PurchaseOperation>,
        required: true,
    }
})
const purchase: Ref<PurchaseOperation> = toRef(props, "data");
const account: Ref<Account> = useStore("account");
const olimpSlugs = ["olimp", "olimp-casino"];

async function toPayment() {
    // @ts-ignore
    const el = window.event.target.closest('.payment');
    el.classList.add("active-state");
    setTimeout(() => {
        el.classList.remove("active-state");
    }, 100);

    try {
        f7.preloader.show();
        if (!purchase.value.msid) return;

        const payload: fetchPaymentPayload = {
            msid: purchase.value.msid
        }
        await AppController.getInstance().paymentService.fetchPayment(payload);
        f7.views.main.router.navigate('/payment');
        // if (purchase.value.subtype === PurchaseOperationSubtype.default) {
        //     await store.dispatch("fetchPayment", {msid: purchase.value.msid});
        //
        // } else {
        //     const appController = AppController.getInstance();
        //     const lightBuyingService = appController.lightBuyingService;
        //     lightBuyingService.init(purchase.value.msid!);
        //     await lightBuyingService.fetch();
        //     f7.views.main.router.navigate('/payment/light');
        // }
    } catch (e) {
        console.log(e)
        f7.dialog.alert(t("payments.alert.first"), t("payments.alert.second"));
    } finally {
        f7.preloader.hide();
    }
};

function getOperationTypeText() {
    switch (purchase.value.operationType) {
        case "purchase":
            if (olimpSlugs.includes(account.value.project.slug)) {
                return t('payment.pay');
            }
            return t('payment.purchase');
        case "sale":
            return t('payment.sale');
    }
    return "";
};

function convertToHumanStatus(status: string) {
    let result = 'Undefined';
    switch (status) {
        case 'CASHED':
            result = t("g.payments.statuses.CASHED");
            break;
        case 'CONFIRMED':
            result = t("g.payments.statuses.CONFIRMED");
            break;
        case 'ONAPPEAL':
            result = t("g.payments.statuses.ONAPPEAL");
            break;
        case 'CREATED':
            result = t("g.payments.statuses.CREATED");
            break;
        case 'ACCEPTED':
            result = t("g.payments.statuses.ACCEPTED");
            break;
        case 'REJECTED':
            result = t("g.payments.statuses.REJECTED");
            break;
        case 'EXPIRED':
            result = t("g.payments.statuses.EXPIRED");
            break;
        case 'CANCELED':
            result = t("g.payments.statuses.CANCELED");
            break;
    }
    return result;
}

function convertDatetime(dateString: string) {
    return dayjs(dateString).format('DD.MM.YYYY HH:mm');
};

function checkIsOlimpProject() {
    return olimpSlugs.includes(account.value.project.slug);
}

</script>

<style lang="scss">
</style>
