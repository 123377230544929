type OnTickCallback = (remainingTime: string | null) => void;

export class PersistentTimer {
    private lastHiddenTime: number | null = null;
    private intervalId: number | null = null;
    private readonly tickInterval: number = 1000;
    private countdownTarget: number | null = null;
    private startTime: number | null = null;
    private savedTime: number = 0;
    private _onTick: OnTickCallback = () => {};

    toTimestamp(timestamp: string) {
        const currentDate = new Date();
        const targetDate = new Date(timestamp);
        const millisToTarget = this.formatDateToUTC(targetDate) - this.formatDateToUTC(currentDate);
        this.countdownTarget = isNaN(millisToTarget) ? null : millisToTarget;
        return this;
    }

    fromSecondsNumber(numberOfSeconds: number) {
        this.countdownTarget = numberOfSeconds * 1000 + 1000;
        return this;
    }

    onTick(onTickCallback: OnTickCallback) {
        this._onTick = onTickCallback;
        return this
    }

    start() {
        this.startTime = Date.now();
        this.scheduleTick();
        this.triggerImmediateTick()
        window.addEventListener('visibilitychange', this.handleVisibilityChanged.bind(this));
        return this;
    }

    reset() {
        this.stop();
        this.startTime = 0;
        this.savedTime = 0;
        this.countdownTarget = null;
        this.triggerResetTick()
        return this;
    }

    private stop() {
        window.removeEventListener('visibilitychange', this.handleVisibilityChanged.bind(this));
        if (this.intervalId !== null) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
        if (this.startTime !== null) {
            this.savedTime += Date.now() - this.startTime;
            this.startTime = null;
        }
    }

    private getElapsedTime() {
        if (this.startTime === null) return this.savedTime;
        return this.savedTime + (Date.now() - this.startTime);
    }

    private getRemainingTime() {
        if (this.countdownTarget === null) return 0;
        return Math.max(0, this.countdownTarget - this.getElapsedTime());
    }

    private scheduleTick() {
        this.intervalId = setInterval(() => {
            const remainingTime = this.getRemainingTime();

            if (remainingTime <= 0) {
                this.reset();
                this._onTick(null)
                return
            }

            if (remainingTime > 0 || (remainingTime === 0 && this.startTime !== null)) {
                this._onTick(this.getFormattedTime(remainingTime));
            }
        }, this.tickInterval);
    }

    private handleVisibilityChanged() {
        if (document.visibilityState === "hidden") {
            this.lastHiddenTime = Date.now();
            this.stop();
            return;
        }

        if (this.lastHiddenTime === null || this.countdownTarget === null) {
            return;
        }

        const hiddenDuration = Date.now() - this.lastHiddenTime;
        this.savedTime += hiddenDuration;
        this.lastHiddenTime = null;
        this.start();
    }

    private formatDateToUTC(date: Date) {
        return Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds()
        );
    }

    private getFormattedTime(remainingTime: number): string {
        const minutes = Math.floor(remainingTime / 60000);
        const seconds = Math.floor((remainingTime % 60000) / 1000);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }

    private triggerImmediateTick() {
        this._onTick(this.getFormattedTime(this.getRemainingTime()))
    }

    private triggerResetTick() {
        this._onTick(null)
    }
}
