<template>
  <a @click="selectChannel(channel)" href="#" class="channel no-chevron"
     :class="{'selected': timerText}">
    <div class="metadata">
      <div class="media">
        <i class="icon" :class="`i-svg-${channel.type}`"></i>
      </div>
      <p class="name">{{ channel.name }}</p>
    </div>
    <div class="timer" v-if="timerText">
      <p>{{ timerText }}</p>
    </div>
  </a>
</template>

<script setup lang="ts">
import LoginController, {Channel} from "@/targets/main/views/login/LoginController";
import {computed, toRefs} from "vue";

const controller = LoginController.getInstance();

interface Emits {
  (e: 'select', channel: Channel): void
}

interface Props {
  channel: Channel;
}

const props = defineProps<Props>()
const {channel} = toRefs(props)
const emit = defineEmits<Emits>();

const timerText = computed(() => {
  return controller.getTimerTextByChannel(channel.value.type)
})

const selectChannel = async (channel: Channel) =>{
  if (timerText.value) {
    return
  }

  emit("select", channel)
}

</script>

<style lang="scss" scoped>
.channel {
  display: inline-flex;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:before, &:after {
    content: none;
  }

  &.selected {
    background-color: var(--grey6);

    .metadata, .timer {
      opacity: 0.55;
    }
  }

  .metadata {
    display: flex;
    align-items: center;

    .media {
      border-radius: 8px;

      i {
        border-radius: inherit;
      }

      & + .name {
        margin-left: 16px;
      }
    }

    .name {
      @include text(17px, 400, var(--grey1), 21px);
    }
  }

  .timer {
    p {
      @include text(17px, 500, var(--grey2), 21px);
    }
  }
}

</style>
