import FirebaseServiceFirestore from "@/services/firebase/firestore/FirebaseServiceFirestore";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import DocumentPurchaseOperationTimers from "@/services/firebase/firestore/documents/DocumentPurchaseOperationTimers";
import DocumentWithdrawalOperationTimers
    from "@/services/firebase/firestore/documents/DocumentWithdrawalOperationTimers";

export default class FirestoreParser {
    private _owner: FirebaseServiceFirestore;

    public constructor(owner: FirebaseServiceFirestore) {
        this._owner = owner;
    }

    public makeMapForPurchaseOperationLiveTimers() {
        let doc = this._owner.documents.get(DocumentTypes.PURCHASE_OPERATION_TIMERS) as DocumentPurchaseOperationTimers | null;
        if (!doc) return;
        const map: Map<string, number> = new Map<string, number>();
        if (doc.statusCreated) map.set("Created".toUpperCase(), doc.statusCreated)
        if (doc.statusAccepted) map.set("Accepted".toUpperCase(), doc.statusAccepted)
        if (doc.statusCashed) map.set("Cashed".toUpperCase(), doc.statusCashed)
        if (doc.statusEmpty) map.set("Empty".toUpperCase(), doc.statusEmpty)
        return map;
    }

    public makeMapForWithdrawalOperationLiveTimers() {
        let doc = this._owner.documents.get(DocumentTypes.WITHDRAWAL_OPERATION_TIMERS) as DocumentWithdrawalOperationTimers | null;
        if (!doc) return;
        const map: Map<string, number> = new Map<string, number>();
        if (doc.statusCreated) map.set("Created".toUpperCase(), doc.statusCreated)
        if (doc.statusAccepted) map.set("Accepted".toUpperCase(), doc.statusAccepted)
        if (doc.statusCashed) map.set("Cashed".toUpperCase(), doc.statusCashed)
        return map;
    }
}