import i18n from "@/langs/i18n"

export default class LangService {

  private static instance?: LangService;

  private constructor() {
  }

  static getInstance() {
      if (typeof LangService.instance === 'undefined') {
        LangService.instance = new LangService();
      }
      return LangService.instance;
  }

  public set(locale: string) {
    i18n.global.locale.value = locale;
    localStorage.setItem("lang", locale);
  }

  public get() {
    return i18n.global.locale.value;
  }

}
