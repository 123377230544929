import anime from "animejs";

export default class Animations {
    public static shakeAnimation(el: HTMLElement) {
        anime({
            // @ts-ignore
            targets: el,
            translateX: [
                { value: -4, duration: 130 },
                { value: 4, duration: 120},
                { value: -3, duration: 110 },
                { value: 2, duration: 100 },
                { value: -1, duration: 90 },
                { value: 0, duration: 80 },
            ],
            easing: 'cubicBezier(0.455, 0.030, 0.515, 0.955)'
        });
    }
    public static showStatusIcon(element: any) {
        anime({
            targets: element,
            keyframes: [
                {
                    opacity: 0,
                    scale: 0
                },
                {
                    opacity: 1,
                    scale: 1
                }
            ],
            duration: 1500
        });
    }
    public static showPlayersAnimation(elements: NodeList | Array<any>, onlyView: boolean = true) {
        if (onlyView) {
            const targets: any = [];
            const others: any = [];
            elements.forEach(el => {
                if (this.isElementVisible(el)) {
                    targets.push(el);
                }
                else others.push(el)
            })

            anime({
                targets: targets,
                delay: function(el, i) { return i * 50 },
                keyframes: [
                    {
                        opacity: 0,
                        translateX: -50
                    },
                    {
                        opacity: 1,
                        translateX: 0,
                    }
                ],
                easing: 'cubicBezier(0.390, 0.575, 0.565, 1.000)',
                duration: 350
            });
            anime({
                targets: others,
                opacity: 1,
                easing: 'linear',
                duration: 0
            });
        } else {
            anime({
                targets: elements,
                delay: function(el, i) { return i * 50 },
                keyframes: [
                    {
                        opacity: 0,
                        translateX: -50
                    },
                    {
                        opacity: 1,
                        translateX: 0,
                    }
                ],
                easing: 'cubicBezier(0.390, 0.575, 0.565, 1.000)',
                duration: 350
            });
        }
    }
    public static showElementsOpacity(elements: NodeList | Array<any>, onlyView: boolean = true) {
        if (onlyView) {
            const targets: any = [];
            const others: any = [];
            elements.forEach(el => {
                if (this.isElementVisible(el)) targets.push(el)
                else others.push(el)
            })
            anime({
                targets: targets,
                delay: function(el, i) { return i * 90 },
                keyframes: [
                    {
                        opacity: 0,
                    },
                    {
                        opacity: 1,
                    }
                ],
                easing: 'easeOutQuart',
                duration: 900
            });
            anime({
                targets: others,
                opacity: 1,
                easing: 'linear',
                duration: 0
            });
        } else {
            anime({
                targets: elements,
                delay: function(el, i) { return i * 90 },
                keyframes: [
                    {
                        opacity: 0,
                    },
                    {
                        opacity: 1,
                    }
                ],
                easing: 'easeOutQuart',
                duration: 1200
            });
        }
    }

    /**
     * Анимация плавного приподнимания элемента
     * @param elements
     * @param onlyView
     */
    public static fadeUpAnimation(elements: NodeList | Array<any>, onlyView: boolean = true) {
        if (onlyView) {
            const targets: any = [];
            const others: any = [];
            elements.forEach(el => {
                if (this.isElementVisible(el)) targets.push(el)
                else others.push(el)
            })
            anime({
                targets: targets,
                delay: function(el, i) { return i * 90 },
                keyframes: [
                    {
                        opacity: 0,
                        translateY: 75
                    },
                    {
                        opacity: 1,
                        translateY: 0
                    }
                ],
                easing: 'easeOutQuart',
                duration: 628
            });
            anime({
                targets: others,
                opacity: 1,
                easing: 'linear',
                duration: 0
            });
        } else {
            anime({
                targets: elements,
                delay: function(el, i) { return i * 90 },
                keyframes: [
                    {
                        opacity: 0,
                        translateY: 75
                    },
                    {
                        opacity: 1,
                        translateY: 0
                    }
                ],
                easing: 'easeOutQuart',
                duration: 628
            });
        }
    }

    public static showSlideBottomAnimation(elements: NodeList | Array<any>, onlyView: boolean = true) {
        if (onlyView) {
            const targets: any = [];
            const others: any = [];
            elements.forEach(el => {
                if (this.isElementVisible(el)) targets.push(el)
                else others.push(el)
            })
            anime({
                targets: targets,
                delay: function(el, i) { return i * 90 },
                keyframes: [
                    {
                        opacity: 0,
                        translateY: 75
                    },
                    {
                        opacity: 1,
                        translateY: 0,
                    }
                ],
                easing: 'easeOutQuart',
                duration: 900
            });
            anime({
                targets: others,
                opacity: 1,
                easing: 'linear',
                duration: 0
            });
        } else {
            anime({
                targets: elements,
                delay: function(el, i) { return i * 90 },
                keyframes: [
                    {
                        opacity: 0,
                        translateY: 75
                    },
                    {
                        opacity: 1,
                        translateY: 0,
                    }
                ],
                easing: 'easeOutQuart',
                duration: 900
            });
        }
    }

    public static showShowPlayersInFieldAnimation(elements: NodeList) {
        anime({
            targets: elements,
            delay: function(el, i) { return i * 90 },
            keyframes: [
                {
                    opacity: 0,
                    scale: 0
                },
                {
                    opacity: 1,
                    scale: 1
                }
            ],
            duration: 900
        });
    }
    public static showLeaderboardPlayersAnimation(elements: NodeList | Array<any>) {
        anime({
            targets: elements,
            delay: function(el, i) {
                return i * 100;
            },
            keyframes: [
                {
                    opacity: 1,
                    scale: 1
                }
            ],
            easing: 'easeOutQuart',
            duration: 1300
        });
    }

    public static isElementVisible(el: any) {
        var rect     = el.getBoundingClientRect(),
            vWidth   = window.innerWidth,
            vHeight  = window.innerHeight;
            // efp      = function (x: any, y: any) { return document.elementFromPoint(x, y) };

        if (rect.right < 0 || rect.bottom < 0
                || rect.left > vWidth || rect.top > vHeight)
            return false;

        return true

        // return (
        //       el.contains(efp(rect.left,  rect.top))
        //   ||  el.contains(efp(rect.right, rect.top))
        //   ||  el.contains(efp(rect.right, rect.bottom))
        //   ||  el.contains(efp(rect.left,  rect.bottom))
        // );
    }
}
