<template>
    <div
        class="requisite-page_requisite-target-type"
        @click="handleClickRequisiteType"
        :class="[
            {
                'requisite-target-type__active': requisiteTargetType.isActive,
                'requisite-target-type__disabled': requisiteTargetType.isDisabled
            }
        ]"
    >
        <p class="requisite-target-type_title">{{ requisiteTargetType.title }}</p>
        <p class="requisite-target-type_text">{{ requisiteTargetType.text }}</p>
    </div>
</template>

<script setup lang="ts">
import {PropType, toRef} from "vue";
import RequisiteTargetTypes from "@/views/requisites-group/requisite/types/RequisiteTargetTypes";

const props = defineProps({
    targetType: {
        type: Object as PropType<RequisiteTargetTypes>,
        required: true
    }
});
const emits = defineEmits(["onSelectType"]);
const requisiteTargetType = toRef(props, "targetType");

function handleClickRequisiteType() {
    emits("onSelectType", requisiteTargetType.value);
}
</script>

<style scoped lang="scss">
.requisite-page_requisite-target-type {
    flex: 1;
    display: flex;
    padding: 12px;
    background-color: var(--white1);
    border-radius: 8px;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    text-align: center;
    border: 1px solid var(--grey8);
    cursor: pointer;
    &.requisite-target-type__active {
        border-color: var(--blue1);
        //border-width: 2px;
    }
    &.requisite-target-type__disabled {
        cursor: none;
        opacity: 0.55 !important;
        pointer-events: none !important;
    }
    .requisite-target-type {
        &_title {
            @include text(16px, 500, var(--brown1), 19.2px);
        }
        &_text {
            @include text(12px, 400, var(--grey2), 14.4px);
        }
    }
}
</style>