<template>
    <p  class="payment-meta__amount">
        {{amount}}₽
        <span>({{ amountUSD }}$)</span>
    </p>
</template>
<script setup lang="ts">
import {PropType, toRef, UnwrapRef} from "vue";

const props = defineProps({
    amount: {
        type: Object as PropType<number | null>,
        required: true
    },
    amountUSD: {
        type: Object as PropType<number | null>,
        required: true
    }
});
const amount = toRef(props, 'amount');
const amountUSD = toRef(props, 'amountUSD');
console.log(amount.value)
console.log(amountUSD.value)
</script>

<style lang="scss">
.payment-meta__amount {
    font-size: 12px;
    line-height: 14.4px;
    font-weight: 500;
    color: var(--grey2);
    background: none !important;
}
</style>