<template>
    <f7-popup ref="withdrawal-popup" class="withdrawal-popup" @popup:open="open" @popup:opened="opened"
        @popup:close="eventClosePopup">
        <span class="tint"></span>
        <f7-view name="withdrawal-popup" url="/popup/withdrawal/create">
        </f7-view>
    </f7-popup>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, ref } from 'vue';
import { f7 } from "framework7-vue";
import WithdrawalPopupController from '../WithdrawalPopupController';
import AppController from "@target/components/App/ts/AppController";
import GlobalRequisitesController from "@/views/requisites-group/GlobalRequisitesController";
import RequisitesPageController from "@/views/requisites-group/requisites/RequisitesPageController";
// import FindAgentController from '../withdrawal-create/FindAgentController';

export default {
    // props: ['paymentSystems'],
    components: {},
    setup() {
        const wpc = WithdrawalPopupController.getInstance();
        const requisitePageController = RequisitesPageController.of();
        // const fac = FindAgentController.getInstance();

        return {
            wpc,
            requisitePageController
            // fac
        };
    },
    methods: {
        open() {},
        opened() {
            // f7.view.current.params.stackPages = true;
            const popupHTML = document.querySelector('.withdrawal-popup');
            (popupHTML as HTMLDivElement).classList.add('transition');
        },
        eventClosePopup(e: any) {
            this.close();
            AppController.getInstance().setPaymentRequestData(null);
        },
        async close() {
            const popupHTML = document.querySelector('.withdrawal-popup');
            (popupHTML as HTMLDivElement).classList.remove('transition');

            this.wpc.destructor();
            this.requisitePageController.destroy();
        }
    }
}
</script>

<style lang="scss">
.popup {
    &.withdrawal-popup {

        &.height-100 {
            height: calc(100% - var(--f7-safe-area-top)) !important;
        }

        &.transition {
            transition: 0.4s ease height;
        }

        height: calc(var(--f7-safe-area-bottom) + 391px) !important;
        bottom: 0px !important;
        top: unset !important;
        border-top-left-radius: 16px !important;
        border-top-right-radius: 16px !important;

        .tint {
            transition: all 0.3s;
            width: 36px;
            height: 4px;
            position: absolute;
            top: 6px;
            z-index: 9999;
            background: #818E95;
            border-radius: 4px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%)
        }
    }
}

//.sheet-modal-new-payment {
//    border-top-left-radius: 16px;
//    border-top-right-radius: 16px;
//
//    .sheet-modal-inner {
//        border-top-left-radius: inherit;
//        border-top-right-radius: inherit;
//    }
//
//    .title {
//        font-weight: 700;
//        font-size: 22px;
//        line-height: 120%;
//        letter-spacing: 0.337647px;
//        color: #000000;
//    }
//}
</style>
