const countryDialInfo: Map<string, string> = new Map();
countryDialInfo.set("AF", JSON.stringify({
    "name": "Afghanistan",
    "flag": "🇦🇫",
    "code": "AF",
    "dialCode": "+93"
}));
countryDialInfo.set("RU", JSON.stringify({
    "name": "Russia",
    "flag": "🇷🇺",
    "code": "RU",
    "dialCode": "+7"
}));
countryDialInfo.set("KZ", JSON.stringify( {
    "name": "Kazakhstan",
    "flag": "🇰🇿",
    "code": "KZ",
    "dialCode": "+7"
}));
countryDialInfo.set("DE", JSON.stringify(  {
    "name": "Germany",
    "flag": "🇩🇪",
    "code": "DE",
    "dialCode": "+49"
}));
countryDialInfo.set("AX", JSON.stringify({"name": "Åland Islands","flag": "🇦🇽","code": "AX","dialCode": "+358"}))
countryDialInfo.set("AL", JSON.stringify({"name": "Albania","flag": "🇦🇱","code": "AL","dialCode": "+355"}))
countryDialInfo.set("DZ", JSON.stringify({"name": "Algeria","flag": "🇩🇿","code": "DZ","dialCode": "+213"}))
countryDialInfo.set("AS", JSON.stringify({"name": "American Samoa","flag": "🇦🇸","code": "AS","dialCode": "+1684"}))
countryDialInfo.set("AD", JSON.stringify({"name": "Andorra","flag": "🇦🇩","code": "AD","dialCode": "+376"}))
countryDialInfo.set("AO", JSON.stringify({"name": "Angola","flag": "🇦🇴","code": "AO","dialCode": "+244"}))
countryDialInfo.set("AI", JSON.stringify({"name": "Anguilla","flag": "🇦🇮","code": "AI","dialCode": "+1264"}))
countryDialInfo.set("AQ", JSON.stringify({"name": "Antarctica","flag": "🇦🇶","code": "AQ","dialCode": "+672"}))
countryDialInfo.set("AG", JSON.stringify({"name": "Antigua and Barbuda","flag": "🇦🇬","code": "AG","dialCode": "+1268"}))
countryDialInfo.set("AR", JSON.stringify({"name": "Argentina","flag": "🇦🇷","code": "AR","dialCode": "+54"}))
countryDialInfo.set("AM", JSON.stringify({"name": "Armenia","flag": "🇦🇲","code": "AM","dialCode": "+374"}))
countryDialInfo.set("AW", JSON.stringify({"name": "Aruba","flag": "🇦🇼","code": "AW","dialCode": "+297"}))
countryDialInfo.set("AU", JSON.stringify({"name": "Australia","flag": "🇦🇺","code": "AU","dialCode": "+61"}))
countryDialInfo.set("AT", JSON.stringify({"name": "Austria","flag": "🇦🇹","code": "AT","dialCode": "+43"}))
countryDialInfo.set("AZ", JSON.stringify({"name": "Azerbaijan","flag": "🇦🇿","code": "AZ","dialCode": "+994"}))
countryDialInfo.set("BS", JSON.stringify({"name": "Bahamas","flag": "🇧🇸","code": "BS","dialCode": "+1242"}))
countryDialInfo.set("BH", JSON.stringify({"name": "Bahrain","flag": "🇧🇭","code": "BH","dialCode": "+973"}))
countryDialInfo.set("BD", JSON.stringify({"name": "Bangladesh","flag": "🇧🇩","code": "BD","dialCode": "+880"}))
countryDialInfo.set("BB", JSON.stringify({"name": "Barbados","flag": "🇧🇧","code": "BB","dialCode": "+1246"}))
countryDialInfo.set("BY", JSON.stringify({"name": "Belarus","flag": "🇧🇾","code": "BY","dialCode": "+375"}))
countryDialInfo.set("BE", JSON.stringify({"name": "Belgium","flag": "🇧🇪","code": "BE","dialCode": "+32"}))
countryDialInfo.set("BZ", JSON.stringify({"name": "Belize","flag": "🇧🇿","code": "BZ","dialCode": "+501"}))
countryDialInfo.set("BJ", JSON.stringify({"name": "Benin","flag": "🇧🇯","code": "BJ","dialCode": "+229"}))
countryDialInfo.set("BM", JSON.stringify({"name": "Bermuda","flag": "🇧🇲","code": "BM","dialCode": "+1441"}))
countryDialInfo.set("BT", JSON.stringify({"name": "Bhutan","flag": "🇧🇹","code": "BT","dialCode": "+975"}))
countryDialInfo.set("BO", JSON.stringify({"name": "Bolivia, Plurinational State of bolivia","flag": "🇧🇴","code": "BO","dialCode": "+591"}))
countryDialInfo.set("BA", JSON.stringify({"name": "Bosnia and Herzegovina","flag": "🇧🇦","code": "BA","dialCode": "+387"}))
countryDialInfo.set("BW", JSON.stringify({"name": "Botswana","flag": "🇧🇼","code": "BW","dialCode": "+267"}))
countryDialInfo.set("BV", JSON.stringify({"name": "Bouvet Island","flag": "🇧🇻","code": "BV","dialCode": "+47"}))
countryDialInfo.set("BR", JSON.stringify({"name": "Brazil","flag": "🇧🇷","code": "BR","dialCode": "+55"}))
countryDialInfo.set("IO", JSON.stringify({"name": "British Indian Ocean Territory","flag": "🇮🇴","code": "IO","dialCode": "+246"}))
countryDialInfo.set("BN", JSON.stringify({"name": "Brunei Darussalam","flag": "🇧🇳","code": "BN","dialCode": "+673"}))
countryDialInfo.set("BG", JSON.stringify({"name": "Bulgaria","flag": "🇧🇬","code": "BG","dialCode": "+359"}))
countryDialInfo.set("BF", JSON.stringify({"name": "Burkina Faso","flag": "🇧🇫","code": "BF","dialCode": "+226"}))
countryDialInfo.set("BI", JSON.stringify({"name": "Burundi","flag": "🇧🇮","code": "BI","dialCode": "+257"}))
countryDialInfo.set("KH", JSON.stringify({"name": "Cambodia","flag": "🇰🇭","code": "KH","dialCode": "+855"}))
countryDialInfo.set("CM", JSON.stringify({"name": "Cameroon","flag": "🇨🇲","code": "CM","dialCode": "+237"}))
countryDialInfo.set("CA", JSON.stringify({"name": "Canada","flag": "🇨🇦","code": "CA","dialCode": "+1"}))
countryDialInfo.set("CV", JSON.stringify({"name": "Cape Verde","flag": "🇨🇻","code": "CV","dialCode": "+238"}))
countryDialInfo.set("KY", JSON.stringify({"name": "Cayman Islands","flag": "🇰🇾","code": "KY","dialCode": "+345"}))
countryDialInfo.set("CF", JSON.stringify({"name": "Central African Republic","flag": "🇨🇫","code": "CF","dialCode": "+236"}))
countryDialInfo.set("TD", JSON.stringify({"name": "Chad","flag": "🇹🇩","code": "TD","dialCode": "+235"}))
countryDialInfo.set("CL", JSON.stringify({"name": "Chile","flag": "🇨🇱","code": "CL","dialCode": "+56"}))
countryDialInfo.set("CN", JSON.stringify({"name": "China","flag": "🇨🇳","code": "CN","dialCode": "+86"}))
countryDialInfo.set("CX", JSON.stringify({"name": "Christmas Island","flag": "🇨🇽","code": "CX","dialCode": "+61"}))
countryDialInfo.set("CC", JSON.stringify({"name": "Cocos (Keeling) Islands","flag": "🇨🇨","code": "CC","dialCode": "+61"}))
countryDialInfo.set("CO", JSON.stringify({"name": "Colombia","flag": "🇨🇴","code": "CO","dialCode": "+57"}))
countryDialInfo.set("KM", JSON.stringify({"name": "Comoros","flag": "🇰🇲","code": "KM","dialCode": "+269"}))
countryDialInfo.set("CG", JSON.stringify({"name": "Congo","flag": "🇨🇬","code": "CG","dialCode": "+242"}))
countryDialInfo.set("CD", JSON.stringify({"name": "Congo, The Democratic Republic of the Congo","flag": "🇨🇩","code": "CD","dialCode": "+243"}))
countryDialInfo.set("CK", JSON.stringify({"name": "Cook Islands","flag": "🇨🇰","code": "CK","dialCode": "+682"}))
countryDialInfo.set("CR", JSON.stringify({"name": "Costa Rica","flag": "🇨🇷","code": "CR","dialCode": "+506"}))
countryDialInfo.set("CI", JSON.stringify({"name": "Cote d'Ivoire","flag": "🇨🇮","code": "CI","dialCode": "+225"}))
countryDialInfo.set("HR", JSON.stringify({"name": "Croatia","flag": "🇭🇷","code": "HR","dialCode": "+385"}))
countryDialInfo.set("CU", JSON.stringify({"name": "Cuba","flag": "🇨🇺","code": "CU","dialCode": "+53"}))
countryDialInfo.set("CY", JSON.stringify({"name": "Cyprus","flag": "🇨🇾","code": "CY","dialCode": "+357"}))
countryDialInfo.set("CZ", JSON.stringify({"name": "Czech Republic","flag": "🇨🇿","code": "CZ","dialCode": "+420"}))
countryDialInfo.set("DK", JSON.stringify({"name": "Denmark","flag": "🇩🇰","code": "DK","dialCode": "+45"}))
countryDialInfo.set("DJ", JSON.stringify({"name": "Djibouti","flag": "🇩🇯","code": "DJ","dialCode": "+253"}))
countryDialInfo.set("DM", JSON.stringify({"name": "Dominica","flag": "🇩🇲","code": "DM","dialCode": "+1767"}))
countryDialInfo.set("DO", JSON.stringify({"name": "Dominican Republic","flag": "🇩🇴","code": "DO","dialCode": "+1849"}))
countryDialInfo.set("EC", JSON.stringify({"name": "Ecuador","flag": "🇪🇨","code": "EC","dialCode": "+593"}))
countryDialInfo.set("EG", JSON.stringify({"name": "Egypt","flag": "🇪🇬","code": "EG","dialCode": "+20"}))
countryDialInfo.set("SV", JSON.stringify({"name": "El Salvador","flag": "🇸🇻","code": "SV","dialCode": "+503"}))
countryDialInfo.set("GQ", JSON.stringify({"name": "Equatorial Guinea","flag": "🇬🇶","code": "GQ","dialCode": "+240"}))
countryDialInfo.set("ER", JSON.stringify({"name": "Eritrea","flag": "🇪🇷","code": "ER","dialCode": "+291"}))
countryDialInfo.set("EE", JSON.stringify({"name": "Estonia","flag": "🇪🇪","code": "EE","dialCode": "+372"}))
countryDialInfo.set("ET", JSON.stringify({"name": "Ethiopia","flag": "🇪🇹","code": "ET","dialCode": "+251"}))
countryDialInfo.set("FK", JSON.stringify({"name": "Falkland Islands (Malvinas)","flag": "🇫🇰","code": "FK","dialCode": "+500"}))
countryDialInfo.set("FO", JSON.stringify({"name": "Faroe Islands","flag": "🇫🇴","code": "FO","dialCode": "+298"}))
countryDialInfo.set("FJ", JSON.stringify({"name": "Fiji","flag": "🇫🇯","code": "FJ","dialCode": "+679"}))
countryDialInfo.set("FI", JSON.stringify({"name": "Finland","flag": "🇫🇮","code": "FI","dialCode": "+358"}))
countryDialInfo.set("FR", JSON.stringify({"name": "France","flag": "🇫🇷","code": "FR","dialCode": "+33"}))
countryDialInfo.set("GF", JSON.stringify({"name": "French Guiana","flag": "🇬🇫","code": "GF","dialCode": "+594"}))
countryDialInfo.set("PF", JSON.stringify({"name": "French Polynesia","flag": "🇵🇫","code": "PF","dialCode": "+689"}))
countryDialInfo.set("TF", JSON.stringify({"name": "French Southern Territories","flag": "🇹🇫","code": "TF","dialCode": "+262"}))
countryDialInfo.set("GA", JSON.stringify({"name": "Gabon","flag": "🇬🇦","code": "GA","dialCode": "+241"}))
countryDialInfo.set("GM", JSON.stringify({"name": "Gambia","flag": "🇬🇲","code": "GM","dialCode": "+220"}))
countryDialInfo.set("GE", JSON.stringify({"name": "Georgia","flag": "🇬🇪","code": "GE","dialCode": "+995"}))
countryDialInfo.set("GH", JSON.stringify({"name": "Ghana","flag": "🇬🇭","code": "GH","dialCode": "+233"}))
countryDialInfo.set("GI", JSON.stringify({"name": "Gibraltar","flag": "🇬🇮","code": "GI","dialCode": "+350"}))
countryDialInfo.set("GR", JSON.stringify({"name": "Greece","flag": "🇬🇷","code": "GR","dialCode": "+30"}))
countryDialInfo.set("GL", JSON.stringify({"name": "Greenland","flag": "🇬🇱","code": "GL","dialCode": "+299"}))
countryDialInfo.set("GD", JSON.stringify({"name": "Grenada","flag": "🇬🇩","code": "GD","dialCode": "+1473"}))
countryDialInfo.set("GP", JSON.stringify({"name": "Guadeloupe","flag": "🇬🇵","code": "GP","dialCode": "+590"}))
countryDialInfo.set("GU", JSON.stringify({"name": "Guam","flag": "🇬🇺","code": "GU","dialCode": "+1671"}))
countryDialInfo.set("GT", JSON.stringify({"name": "Guatemala","flag": "🇬🇹","code": "GT","dialCode": "+502"}))
countryDialInfo.set("GG", JSON.stringify({"name": "Guernsey","flag": "🇬🇬","code": "GG","dialCode": "+44"}))
countryDialInfo.set("GN", JSON.stringify({"name": "Guinea","flag": "🇬🇳","code": "GN","dialCode": "+224"}))
countryDialInfo.set("GW", JSON.stringify({"name": "Guinea-Bissau","flag": "🇬🇼","code": "GW","dialCode": "+245"}))
countryDialInfo.set("GY", JSON.stringify({"name": "Guyana","flag": "🇬🇾","code": "GY","dialCode": "+592"}))
countryDialInfo.set("HT", JSON.stringify({"name": "Haiti","flag": "🇭🇹","code": "HT","dialCode": "+509"}))
countryDialInfo.set("HM", JSON.stringify({"name": "Heard Island and Mcdonald Islands","flag": "🇭🇲","code": "HM","dialCode": "+672"}))
countryDialInfo.set("VA", JSON.stringify({"name": "Holy See (Vatican City State)","flag": "🇻🇦","code": "VA","dialCode": "+379"}))
countryDialInfo.set("HN", JSON.stringify({"name": "Honduras","flag": "🇭🇳","code": "HN","dialCode": "+504"}))
countryDialInfo.set("HK", JSON.stringify({"name": "Hong Kong","flag": "🇭🇰","code": "HK","dialCode": "+852"}))
countryDialInfo.set("HU", JSON.stringify({"name": "Hungary","flag": "🇭🇺","code": "HU","dialCode": "+36"}))
countryDialInfo.set("IS", JSON.stringify({"name": "Iceland","flag": "🇮🇸","code": "IS","dialCode": "+354"}))
countryDialInfo.set("IN", JSON.stringify({"name": "India","flag": "🇮🇳","code": "IN","dialCode": "+91"}))
countryDialInfo.set("ID", JSON.stringify({"name": "Indonesia","flag": "🇮🇩","code": "ID","dialCode": "+62"}))
countryDialInfo.set("IR", JSON.stringify({"name": "Iran, Islamic Republic of Persian Gulf","flag": "🇮🇷","code": "IR","dialCode": "+98"}))
countryDialInfo.set("IQ", JSON.stringify({"name": "Iraq","flag": "🇮🇶","code": "IQ","dialCode": "+964"}))
countryDialInfo.set("IE", JSON.stringify({"name": "Ireland","flag": "🇮🇪","code": "IE","dialCode": "+353"}))
countryDialInfo.set("IM", JSON.stringify({"name": "Isle of Man","flag": "🇮🇲","code": "IM","dialCode": "+44"}))
countryDialInfo.set("IL", JSON.stringify({"name": "Israel","flag": "🇮🇱","code": "IL","dialCode": "+972"}))
countryDialInfo.set("IT", JSON.stringify({"name": "Italy","flag": "🇮🇹","code": "IT","dialCode": "+39"}))
countryDialInfo.set("JM", JSON.stringify({"name": "Jamaica","flag": "🇯🇲","code": "JM","dialCode": "+1876"}))
countryDialInfo.set("JP", JSON.stringify({"name": "Japan","flag": "🇯🇵","code": "JP","dialCode": "+81"}))
countryDialInfo.set("JE", JSON.stringify({"name": "Jersey","flag": "🇯🇪","code": "JE","dialCode": "+44"}))
countryDialInfo.set("JO", JSON.stringify({"name": "Jordan","flag": "🇯🇴","code": "JO","dialCode": "+962"}))
countryDialInfo.set("KE", JSON.stringify({"name": "Kenya","flag": "🇰🇪","code": "KE","dialCode": "+254"}))
countryDialInfo.set("KI", JSON.stringify({"name": "Kiribati","flag": "🇰🇮","code": "KI","dialCode": "+686"}))
countryDialInfo.set("KP", JSON.stringify({"name": "Korea, Democratic People's Republic of Korea","flag": "🇰🇵","code": "KP","dialCode": "+850"}))
countryDialInfo.set("KR", JSON.stringify({"name": "Korea, Republic of South Korea","flag": "🇰🇷","code": "KR","dialCode": "+82"}))
countryDialInfo.set("XK", JSON.stringify({"name": "Kosovo","flag": "🇽🇰","code": "XK","dialCode": "+383"}))
countryDialInfo.set("KW", JSON.stringify({"name": "Kuwait","flag": "🇰🇼","code": "KW","dialCode": "+965"}))
countryDialInfo.set("KG", JSON.stringify({"name": "Kyrgyzstan","flag": "🇰🇬","code": "KG","dialCode": "+996"}))
countryDialInfo.set("LA", JSON.stringify({"name": "Laos","flag": "🇱🇦","code": "LA","dialCode": "+856"}))
countryDialInfo.set("LV", JSON.stringify({"name": "Latvia","flag": "🇱🇻","code": "LV","dialCode": "+371"}))
countryDialInfo.set("LB", JSON.stringify({"name": "Lebanon","flag": "🇱🇧","code": "LB","dialCode": "+961"}))
countryDialInfo.set("LS", JSON.stringify({"name": "Lesotho","flag": "🇱🇸","code": "LS","dialCode": "+266"}))
countryDialInfo.set("LR", JSON.stringify({"name": "Liberia","flag": "🇱🇷","code": "LR","dialCode": "+231"}))
countryDialInfo.set("LY", JSON.stringify({"name": "Libyan Arab Jamahiriya","flag": "🇱🇾","code": "LY","dialCode": "+218"}))
countryDialInfo.set("LI", JSON.stringify({"name": "Liechtenstein","flag": "🇱🇮","code": "LI","dialCode": "+423"}))
countryDialInfo.set("LT", JSON.stringify({"name": "Lithuania","flag": "🇱🇹","code": "LT","dialCode": "+370"}))
countryDialInfo.set("LU", JSON.stringify({"name": "Luxembourg","flag": "🇱🇺","code": "LU","dialCode": "+352"}))
countryDialInfo.set("MO", JSON.stringify({"name": "Macao","flag": "🇲🇴","code": "MO","dialCode": "+853"}))
countryDialInfo.set("MK", JSON.stringify({"name": "Macedonia","flag": "🇲🇰","code": "MK","dialCode": "+389"}))
countryDialInfo.set("MG", JSON.stringify({"name": "Madagascar","flag": "🇲🇬","code": "MG","dialCode": "+261"}))
countryDialInfo.set("MW", JSON.stringify({"name": "Malawi","flag": "🇲🇼","code": "MW","dialCode": "+265"}))
countryDialInfo.set("MY", JSON.stringify({"name": "Malaysia","flag": "🇲🇾","code": "MY","dialCode": "+60"}))
countryDialInfo.set("MV", JSON.stringify({"name": "Maldives","flag": "🇲🇻","code": "MV","dialCode": "+960"}))
countryDialInfo.set("ML", JSON.stringify({"name": "Mali","flag": "🇲🇱","code": "ML","dialCode": "+223"}))
countryDialInfo.set("MT", JSON.stringify({"name": "Malta","flag": "🇲🇹","code": "MT","dialCode": "+356"}))
countryDialInfo.set("MH", JSON.stringify({"name": "Marshall Islands","flag": "🇲🇭","code": "MH","dialCode": "+692"}))
countryDialInfo.set("MQ", JSON.stringify({"name": "Martinique","flag": "🇲🇶","code": "MQ","dialCode": "+596"}))
countryDialInfo.set("MR", JSON.stringify({"name": "Mauritania","flag": "🇲🇷","code": "MR","dialCode": "+222"}))
countryDialInfo.set("MU", JSON.stringify({"name": "Mauritius","flag": "🇲🇺","code": "MU","dialCode": "+230"}))
countryDialInfo.set("YT", JSON.stringify({"name": "Mayotte","flag": "🇾🇹","code": "YT","dialCode": "+262"}))
countryDialInfo.set("MX", JSON.stringify({"name": "Mexico","flag": "🇲🇽","code": "MX","dialCode": "+52"}))
countryDialInfo.set("FM", JSON.stringify({"name": "Micronesia, Federated States of Micronesia","flag": "🇫🇲","code": "FM","dialCode": "+691"}))
countryDialInfo.set("MD", JSON.stringify({"name": "Moldova","flag": "🇲🇩","code": "MD","dialCode": "+373"}))
countryDialInfo.set("MC", JSON.stringify({"name": "Monaco","flag": "🇲🇨","code": "MC","dialCode": "+377"}))
countryDialInfo.set("MN", JSON.stringify({"name": "Mongolia","flag": "🇲🇳","code": "MN","dialCode": "+976"}))
countryDialInfo.set("ME", JSON.stringify({"name": "Montenegro","flag": "🇲🇪","code": "ME","dialCode": "+382"}))
countryDialInfo.set("MS", JSON.stringify({"name": "Montserrat","flag": "🇲🇸","code": "MS","dialCode": "+1664"}))
countryDialInfo.set("MA", JSON.stringify({"name": "Morocco","flag": "🇲🇦","code": "MA","dialCode": "+212"}))
countryDialInfo.set("MZ", JSON.stringify({"name": "Mozambique","flag": "🇲🇿","code": "MZ","dialCode": "+258"}))
countryDialInfo.set("MM", JSON.stringify({"name": "Myanmar","flag": "🇲🇲","code": "MM","dialCode": "+95"}))
countryDialInfo.set("NA", JSON.stringify({"name": "Namibia","flag": "🇳🇦","code": "NA","dialCode": "+264"}))
countryDialInfo.set("NR", JSON.stringify({"name": "Nauru","flag": "🇳🇷","code": "NR","dialCode": "+674"}))
countryDialInfo.set("NP", JSON.stringify({"name": "Nepal","flag": "🇳🇵","code": "NP","dialCode": "+977"}))
countryDialInfo.set("NL", JSON.stringify({"name": "Netherlands","flag": "🇳🇱","code": "NL","dialCode": "+31"}))
countryDialInfo.set("AN", JSON.stringify({"name": "Netherlands Antilles","flag": "","code": "AN","dialCode": "+599"}))
countryDialInfo.set("NC", JSON.stringify({"name": "New Caledonia","flag": "🇳🇨","code": "NC","dialCode": "+687"}))
countryDialInfo.set("NZ", JSON.stringify({"name": "New Zealand","flag": "🇳🇿","code": "NZ","dialCode": "+64"}))
countryDialInfo.set("NI", JSON.stringify({"name": "Nicaragua","flag": "🇳🇮","code": "NI","dialCode": "+505"}))
countryDialInfo.set("NE", JSON.stringify({"name": "Niger","flag": "🇳🇪","code": "NE","dialCode": "+227"}))
countryDialInfo.set("NG", JSON.stringify({"name": "Nigeria","flag": "🇳🇬","code": "NG","dialCode": "+234"}))
countryDialInfo.set("NU", JSON.stringify({"name": "Niue","flag": "🇳🇺","code": "NU","dialCode": "+683"}))
countryDialInfo.set("NF", JSON.stringify({"name": "Norfolk Island","flag": "🇳🇫","code": "NF","dialCode": "+672"}))
countryDialInfo.set("MP", JSON.stringify({"name": "Northern Mariana Islands","flag": "🇲🇵","code": "MP","dialCode": "+1670"}))
countryDialInfo.set("NO", JSON.stringify({"name": "Norway","flag": "🇳🇴","code": "NO","dialCode": "+47"}))
countryDialInfo.set("OM", JSON.stringify({"name": "Oman","flag": "🇴🇲","code": "OM","dialCode": "+968"}))
countryDialInfo.set("PK", JSON.stringify({"name": "Pakistan","flag": "🇵🇰","code": "PK","dialCode": "+92"}))
countryDialInfo.set("PW", JSON.stringify({"name": "Palau","flag": "🇵🇼","code": "PW","dialCode": "+680"}))
countryDialInfo.set("PS", JSON.stringify({"name": "Palestinian Territory, Occupied","flag": "🇵🇸","code": "PS","dialCode": "+970"}))
countryDialInfo.set("PA", JSON.stringify({"name": "Panama","flag": "🇵🇦","code": "PA","dialCode": "+507"}))
countryDialInfo.set("PG", JSON.stringify({"name": "Papua New Guinea","flag": "🇵🇬","code": "PG","dialCode": "+675"}))
countryDialInfo.set("PY", JSON.stringify({"name": "Paraguay","flag": "🇵🇾","code": "PY","dialCode": "+595"}))
countryDialInfo.set("PE", JSON.stringify({"name": "Peru","flag": "🇵🇪","code": "PE","dialCode": "+51"}))
countryDialInfo.set("PH", JSON.stringify({"name": "Philippines","flag": "🇵🇭","code": "PH","dialCode": "+63"}))
countryDialInfo.set("PN", JSON.stringify({"name": "Pitcairn","flag": "🇵🇳","code": "PN","dialCode": "+64"}))
countryDialInfo.set("PL", JSON.stringify({"name": "Poland","flag": "🇵🇱","code": "PL","dialCode": "+48"}))
countryDialInfo.set("PT", JSON.stringify({"name": "Portugal","flag": "🇵🇹","code": "PT","dialCode": "+351"}))
countryDialInfo.set("PR", JSON.stringify({"name": "Puerto Rico","flag": "🇵🇷","code": "PR","dialCode": "+1939"}))
countryDialInfo.set("QA", JSON.stringify({"name": "Qatar","flag": "🇶🇦","code": "QA","dialCode": "+974"}))
countryDialInfo.set("RO", JSON.stringify({"name": "Romania","flag": "🇷🇴","code": "RO","dialCode": "+40"}))
countryDialInfo.set("RW", JSON.stringify({"name": "Rwanda","flag": "🇷🇼","code": "RW","dialCode": "+250"}))
countryDialInfo.set("RE", JSON.stringify({"name": "Reunion","flag": "🇷🇪","code": "RE","dialCode": "+262"}))
countryDialInfo.set("BL", JSON.stringify({"name": "Saint Barthelemy","flag": "🇧🇱","code": "BL","dialCode": "+590"}))
countryDialInfo.set("SH", JSON.stringify({"name": "Saint Helena, Ascension and Tristan Da Cunha","flag": "🇸🇭","code": "SH","dialCode": "+290"}))
countryDialInfo.set("KN", JSON.stringify({"name": "Saint Kitts and Nevis","flag": "🇰🇳","code": "KN","dialCode": "+1869"}))
countryDialInfo.set("LC", JSON.stringify({"name": "Saint Lucia","flag": "🇱🇨","code": "LC","dialCode": "+1758"}))
countryDialInfo.set("MF", JSON.stringify({"name": "Saint Martin","flag": "🇲🇫","code": "MF","dialCode": "+590"}))
countryDialInfo.set("PM", JSON.stringify({"name": "Saint Pierre and Miquelon","flag": "🇵🇲","code": "PM","dialCode": "+508"}))
countryDialInfo.set("VC", JSON.stringify({"name": "Saint Vincent and the Grenadines","flag": "🇻🇨","code": "VC","dialCode": "+1784"}))
countryDialInfo.set("WS", JSON.stringify({"name": "Samoa","flag": "🇼🇸","code": "WS","dialCode": "+685"}))
countryDialInfo.set("SM", JSON.stringify({"name": "San Marino","flag": "🇸🇲","code": "SM","dialCode": "+378"}))
countryDialInfo.set("ST", JSON.stringify({"name": "Sao Tome and Principe","flag": "🇸🇹","code": "ST","dialCode": "+239"}))
countryDialInfo.set("SA", JSON.stringify({"name": "Saudi Arabia","flag": "🇸🇦","code": "SA","dialCode": "+966"}))
countryDialInfo.set("SN", JSON.stringify({"name": "Senegal","flag": "🇸🇳","code": "SN","dialCode": "+221"}))
countryDialInfo.set("RS", JSON.stringify({"name": "Serbia","flag": "🇷🇸","code": "RS","dialCode": "+381"}))
countryDialInfo.set("SC", JSON.stringify({"name": "Seychelles","flag": "🇸🇨","code": "SC","dialCode": "+248"}))
countryDialInfo.set("SL", JSON.stringify({"name": "Sierra Leone","flag": "🇸🇱","code": "SL","dialCode": "+232"}))
countryDialInfo.set("SG", JSON.stringify({"name": "Singapore","flag": "🇸🇬","code": "SG","dialCode": "+65"}))
countryDialInfo.set("SK", JSON.stringify({"name": "Slovakia","flag": "🇸🇰","code": "SK","dialCode": "+421"}))
countryDialInfo.set("SI", JSON.stringify({"name": "Slovenia","flag": "🇸🇮","code": "SI","dialCode": "+386"}))
countryDialInfo.set("SB", JSON.stringify({"name": "Solomon Islands","flag": "🇸🇧","code": "SB","dialCode": "+677"}))
countryDialInfo.set("SO", JSON.stringify({"name": "Somalia","flag": "🇸🇴","code": "SO","dialCode": "+252"}))
countryDialInfo.set("ZA", JSON.stringify({"name": "South Africa","flag": "🇿🇦","code": "ZA","dialCode": "+27"}))
countryDialInfo.set("SS", JSON.stringify({"name": "South Sudan","flag": "🇸🇸","code": "SS","dialCode": "+211"}))
countryDialInfo.set("GS", JSON.stringify({"name": "South Georgia and the South Sandwich Islands","flag": "🇬🇸","code": "GS","dialCode": "+500"}))
countryDialInfo.set("ES", JSON.stringify({"name": "Spain","flag": "🇪🇸","code": "ES","dialCode": "+34"}))
countryDialInfo.set("LK", JSON.stringify({"name": "Sri Lanka","flag": "🇱🇰","code": "LK","dialCode": "+94"}))
countryDialInfo.set("SD", JSON.stringify({"name": "Sudan","flag": "🇸🇩","code": "SD","dialCode": "+249"}))
countryDialInfo.set("SR", JSON.stringify({"name": "Suriname","flag": "🇸🇷","code": "SR","dialCode": "+597"}))
countryDialInfo.set("SJ", JSON.stringify({"name": "Svalbard and Jan Mayen","flag": "🇸🇯","code": "SJ","dialCode": "+47"}))
countryDialInfo.set("SZ", JSON.stringify({"name": "Eswatini","flag": "🇸🇿","code": "SZ","dialCode": "+268"}))
countryDialInfo.set("SE", JSON.stringify({"name": "Sweden","flag": "🇸🇪","code": "SE","dialCode": "+46"}))
countryDialInfo.set("CH", JSON.stringify({"name": "Switzerland","flag": "🇨🇭","code": "CH","dialCode": "+41"}))
countryDialInfo.set("SY", JSON.stringify({"name": "Syrian Arab Republic","flag": "🇸🇾","code": "SY","dialCode": "+963"}))
countryDialInfo.set("TW", JSON.stringify({"name": "Taiwan","flag": "🇹🇼","code": "TW","dialCode": "+886"}))
countryDialInfo.set("TJ", JSON.stringify({"name": "Tajikistan","flag": "🇹🇯","code": "TJ","dialCode": "+992"}))
countryDialInfo.set("TZ", JSON.stringify({"name": "Tanzania, United Republic of Tanzania","flag": "🇹🇿","code": "TZ","dialCode": "+255"}))
countryDialInfo.set("TH", JSON.stringify({"name": "Thailand","flag": "🇹🇭","code": "TH","dialCode": "+66"}))
countryDialInfo.set("TL", JSON.stringify({"name": "Timor-Leste","flag": "🇹🇱","code": "TL","dialCode": "+670"}))
countryDialInfo.set("TG", JSON.stringify({"name": "Togo","flag": "🇹🇬","code": "TG","dialCode": "+228"}))
countryDialInfo.set("TK", JSON.stringify({"name": "Tokelau","flag": "🇹🇰","code": "TK","dialCode": "+690"}))
countryDialInfo.set("TO", JSON.stringify({"name": "Tonga","flag": "🇹🇴","code": "TO","dialCode": "+676"}))
countryDialInfo.set("TT", JSON.stringify({"name": "Trinidad and Tobago","flag": "🇹🇹","code": "TT","dialCode": "+1868"}))
countryDialInfo.set("TN", JSON.stringify({"name": "Tunisia","flag": "🇹🇳","code": "TN","dialCode": "+216"}))
countryDialInfo.set("TR", JSON.stringify({"name": "Turkey","flag": "🇹🇷","code": "TR","dialCode": "+90"}))
countryDialInfo.set("TM", JSON.stringify({"name": "Turkmenistan","flag": "🇹🇲","code": "TM","dialCode": "+993"}))
countryDialInfo.set("TC", JSON.stringify({"name": "Turks and Caicos Islands","flag": "🇹🇨","code": "TC","dialCode": "+1649"}))
countryDialInfo.set("TV", JSON.stringify({"name": "Tuvalu","flag": "🇹🇻","code": "TV","dialCode": "+688"}))
countryDialInfo.set("UG", JSON.stringify({"name": "Uganda","flag": "🇺🇬","code": "UG","dialCode": "+256"}))
countryDialInfo.set("UA", JSON.stringify({"name": "Ukraine","flag": "🇺🇦","code": "UA","dialCode": "+380"}))
countryDialInfo.set("AE", JSON.stringify({"name": "United Arab Emirates","flag": "🇦🇪","code": "AE","dialCode": "+971"}))
countryDialInfo.set("GB", JSON.stringify({"name": "United Kingdom","flag": "🇬🇧","code": "GB","dialCode": "+44"}))
countryDialInfo.set("US", JSON.stringify({"name": "United States","flag": "🇺🇸","code": "US","dialCode": "+1"}))
countryDialInfo.set("UY", JSON.stringify({"name": "Uruguay","flag": "🇺🇾","code": "UY","dialCode": "+598"}))
countryDialInfo.set("UZ", JSON.stringify({"name": "Uzbekistan","flag": "🇺🇿","code": "UZ","dialCode": "+998"}))
countryDialInfo.set("VU", JSON.stringify({"name": "Vanuatu","flag": "🇻🇺","code": "VU","dialCode": "+678"}))
countryDialInfo.set("VE", JSON.stringify({"name": "Venezuela, Bolivarian Republic of Venezuela","flag": "🇻🇪","code": "VE","dialCode": "+58"}))
countryDialInfo.set("VN", JSON.stringify({"name": "Vietnam","flag": "🇻🇳","code": "VN","dialCode": "+84"}))
countryDialInfo.set("VG", JSON.stringify({"name": "Virgin Islands, British","flag": "🇻🇬","code": "VG","dialCode": "+1284"}))
countryDialInfo.set("VI", JSON.stringify({"name": "Virgin Islands, U.S.","flag": "🇻🇮","code": "VI","dialCode": "+1340"}))
countryDialInfo.set("WF", JSON.stringify({"name": "Wallis and Futuna","flag": "🇼🇫","code": "WF","dialCode": "+681"}))
countryDialInfo.set("YE", JSON.stringify({"name": "Yemen","flag": "🇾🇪","code": "YE","dialCode": "+967"}))
countryDialInfo.set("ZM", JSON.stringify({"name": "Zambia","flag": "🇿🇲","code": "ZM","dialCode": "+260"}))
countryDialInfo.set("ZW", JSON.stringify({"name": "Zimbabwe","flag": "🇿🇼","code": "ZW","dialCode": "+263"}))

export default countryDialInfo;

