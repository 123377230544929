<template>
  <div class="input-code_wrapper" ref="rootEl">
    <input class="input-code" v-model="code" :placeholder="$t('g.login.input-code')">
  </div>
</template>

<script setup lang="ts">
import {ref, watch} from "vue";
import LoginController from "@/targets/main/views/login/LoginController";

const controller = LoginController.getInstance();
const code = ref("");
const rootEl = ref<HTMLInputElement | null>(null);
const extractCode = () => {
  return code.value;
}

const clearCode = () => {
  if (!rootEl.value) {
    return
  }

  rootEl.value.value = ''
}

watch(code, (value: string) => {
  if (value.length >= controller.kodmobiSettings!.code.length) {
    emits("onCheckCode");
  }
})

defineExpose({
  rootEl,
  extractCode,
  clearCode
});
const emits = defineEmits([
  "onCheckCode"
]);
</script>

<style scoped lang="scss">
.input-code_wrapper {
  margin: 0px;
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: var(--grey1);
  position: relative;

  .input-code {
    line-height: 20px;
    padding: 12px 8px 12px 16px;
    width: 100%;

    &::placeholder {
      color: #ACB4BE;
    }
  }

  &::after {
    content: "";
    position: absolute;
    background-color: var(--grey4);
    display: block;
    z-index: 15;
    top: auto;
    right: 0px;
    bottom: 0;
    left: auto;
    height: 1px;
    width: 100%;
    transform-origin: 50% 100%;
    transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
  }
}
</style>
