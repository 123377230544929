import AgentsRepository from "@/repositories/agents-repository/AgentsRepository";
import Agent from "@models/agents/Agent";
import {ref, ToRef} from "vue";
import {value} from "dom7";
import LightPaymentPidResponseError from "@models/responses/light-payment-pid/LightPaymentPidResponseError";

export default class ViewPaymentCreateAgentsController {
    private static _instance: ViewPaymentCreateAgentsController | null = null;
    private _repositoryAgents: AgentsRepository = new AgentsRepository();
    private _agents: ToRef<Agent[]> = ref([]);
    private _agent: ToRef<Agent | null> = ref(null);
    private _amount: ToRef<number | null> = ref(null);
    private _externalSetAgentFunc: Function | null = null;
    private _paymentError: ToRef<LightPaymentPidResponseError | null> = ref(null);
    private _paymentErrorWasShowed: ToRef<boolean> = ref(false);
    public static of() {
        if (this._instance === null) this._instance = new ViewPaymentCreateAgentsController();
        return this._instance;
    }

    get paymentErrorWasShowed(): ToRef<boolean> {
        return this._paymentErrorWasShowed;
    }

    set paymentErrorWasShowed(value: ToRef<boolean>) {
        this._paymentErrorWasShowed = value;
    }

    get paymentError(): ToRef<LightPaymentPidResponseError | null> {
        return this._paymentError;
    }

    set paymentError(value: ToRef<LightPaymentPidResponseError | null>) {
        this._paymentError = value;
    }

    get amount(): ToRef<number | null> {
        return this._amount;
    }

    set amount(value: ToRef<number | null>) {
        this._amount = value;
    }

    get agent(): ToRef<Agent | null> {
        return this._agent;
    }

    set agent(value: ToRef<Agent | null>) {
        this._agent = value;
    }

    get agents(): ToRef<Agent[]> {
        return this._agents;
    }

    setExternalSetAgentFunc(func: Function | null) {
        this._externalSetAgentFunc = func;
    }

    selectAgent(agent: Agent | null) {
        if (this._externalSetAgentFunc) this._externalSetAgentFunc(agent);
    }

    async fetchAndSetAgents() {
        this._agents.value = await this._repositoryAgents.getAgentsAvailableForPurchase();
    }
}