<template>
    <f7-view id="view-login" main url="/auth/login"></f7-view>
</template>

<style lang="scss">
#view-login {
    --f7-navbar-bg-color: var(--white2);
    --f7-page-bg-color: var(--white2);

    p {
        margin: 0;
    }
}
</style>
