import ChatPayload, {ChatMessage} from "@/entities/chat/ChatPayload";
import i18n from "@/langs/i18n";
import humps from "lodash-humps-ts";
import {f7} from "framework7-vue";
import {ToRef, nextTick} from "vue";
import MainApiService from "@/services/api-service/MainApiService";
// @ts-ignore
import store from "@target/core/store/store";
import PaymentWithdrawal from "@/entities/PaymentWithdrawal";
import Payment from "@/entities/Payment";
import Animations from "@/helpers/animations";
import dayjs from "dayjs";
import SocketService from "@/services/SockerService";
import LightBuyingService from "@/services/light-buying/LightBuyingService";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import PaymentService from "@/services/operations/payment/PaymentService";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";

declare const window: Window & typeof globalThis & { Pusher: any, Echo: any }

type UIMessageBlock = {
    createdAt: string,
    humanDate: string,
    humanTime: string,
    messages: UIMessage[],
    unix: number
}
export type UIMessage = {
    id: number,
    name: string
    type: string
    text: string,
    senderType: string
    wasRead: boolean
}
type MessagePayload = {
    storeMessages: UIMessageBlock[],
    callback?: Function
}
type MessageWasRead = {
    "chat": {
        "id": number,
        "paymentId": number,
        "theme": "sale" | "purchase"
    },
    "messageId": number
}

export default class ChatDataController {
    private static _instance?: ChatDataController;
    private _subscribe?: MessagePayload | null = null;

    public static getInstance() {
        if (typeof this._instance === "undefined") this._instance = new ChatDataController();
        return this._instance;
    }

    public socketConnect() {
        try {
            SocketService.getInstance().gate
                .listen('.NewChatMessage', async (data: ChatPayload) => {
                    data = humps(data);

                    if (data.message.senderType === "system") return;
                    let {url} = f7.view.current.router.currentRoute;

                    url = url.split('?')[0];
                    switch (url) {
                        case "/chat-withdrawal":
                            if (!this.isCheckCurrentWithdrawal(data.chat.paymentId)) break;
                            await this.produceMessages(data);
                            await this.markMessageAsRead(data);
                            break;
                        case "/chat":
                            if (!this.isCheckCurrentPayment(data.chat.paymentId)) break;
                            await this.produceMessages(data);
                            await this.markMessageAsRead(data);
                            break;
                        case "/payments":
                        case "/withdrawal":
                        case "/payment":
                            this.incrementPaymentChatCount(data);
                            break;
                        default:
                    }
                })
                .listen('.ChatMessagesRead', async (data: ChatPayload) => {
                    data = humps(data);

                    const {url} = f7.view.current.router.currentRoute;
                    switch (url) {
                        case "/payments":
                            this.resetPaymentChatCount(data.chat.paymentId);
                            break;
                        case "/chat-withdrawal":
                            this._subscribe?.storeMessages.map(el => {
                                el.messages.map((v: UIMessage) => {
                                    if (!v.wasRead) v.wasRead = true;
                                });
                            });
                            break;
                        case "/chat":
                            this._subscribe?.storeMessages.map(el => {
                                el.messages.map((v: UIMessage) => {
                                    if (!v.wasRead) v.wasRead = true;
                                });
                            });
                            break;
                        case "/withdrawal":
                            break;
                        case "/payment":

                            break;
                        default:
                    }
                })
                .listen('.ChatMessageRead', (data: MessageWasRead) => {
                    data = humps(data);
                    this._subscribe?.storeMessages.map(el => {
                        let start = 0, end = el.messages.length - 1;
                        while (start <= end) {
                            let mid = Math.floor((start + end) / 2);
                            if (el.messages[mid].id === data.messageId) {
                                el.messages[mid].wasRead = true;
                                break;
                            } else if (el.messages[mid].id < data.messageId)
                                start = mid + 1;
                            else
                                end = mid - 1;
                        }
                    })
                });
        } catch (e) {
            console.info('socketConnect error')
        }
    }

    private isCheckCurrentPayment(messagePaymentId: number) {
        const paymentService: PaymentService = AppController.getInstance().paymentService;
        let {payment} = paymentService;
        return !!(payment.value && payment.value.id === messagePaymentId);
    }

    private isCheckCurrentWithdrawal(messagePaymentId: number) {
        const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;
        let {withdrawal} = withdrawalService;
        return !!(withdrawal.value && withdrawal.value.id === messagePaymentId);
    }

    public incrementPaymentChatCount(data: ChatPayload) {
        let {url} = f7.view.current.router.currentRoute;
        url = url.split('?')[0];
        switch (url) {
            case "/withdrawal":
                const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;
                const localWithdrawal: WithdrawalOperation | null = withdrawalService.withdrawal.value;
                localWithdrawal?.incrementChatUnreadCount();

                nextTick(() => {
                    const el = document.querySelector(`.withdrawal-view__subnavbar .subnavbar-chat__mark`) as HTMLElement;
                    Animations.shakeAnimation(el);
                });
                break;
            case "/payment":
                const paymentService: PaymentService = AppController.getInstance().paymentService;
                const localPayment: PurchaseOperation | null = paymentService.payment.value;
                localPayment?.incrementChatUnreadCount();

                nextTick(() => {
                    const el = document.querySelector(`.payment-view__subnavbar .subnavbar-chat__mark`) as HTMLElement;
                    Animations.shakeAnimation(el);
                });
                // const payment: Payment = {...store.getters['payment'].value};
                // payment.chat.unread += 1;
                // store.dispatch("setPayment", payment);
                //
                // nextTick(() => {
                //     const el = document.querySelector(`.payment-view__navbar .alert-message-svg`) as HTMLElement;
                //     Animations.shakeAnimation(el);
                // });
                break;
            // case "/payment/light":
            //     const appController = AppController.getInstance();
            //     (appController.lightBuyingService as LightBuyingService).buying.value?.incrementChatUnreadCount();
            //
            //     nextTick(() => {
            //         const el = document.querySelector(`.light-payment__navbar .subnavbar-chat__mark`) as HTMLElement;
            //         Animations.shakeAnimation(el);
            //     });
            //     break;
            default:
        }

        if (store.getters['myPayments'].value) {
            const payments: PaymentWithdrawal[] | Payment[] = [...store.getters['myPayments'].value];
            const idx = payments.findIndex(el => el.id === data.chat.paymentId);
            if (idx != -1) {
                payments[idx].chat.unread += 1;
                store.dispatch("manuallyUpdateMyPayments", payments);
                nextTick(() => {
                    const el = document.querySelector(`.payments-view .my-payments .payment-${data.chat.paymentId} .alert-message-svg`) as HTMLElement;
                    Animations.shakeAnimation(el);
                });
            }
        } else {
            // console.log(123)
        }
    }

    public resetPaymentChatCount(paymentId: number) {
        const appController = AppController.getInstance();

        let {url} = f7.view.current.router.currentRoute;
        url = url.split('?')[0];
        switch (url) {
            case "/chat-withdrawal":
            case "/withdrawal":
                const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;
                const localWithdrawal: WithdrawalOperation | null = withdrawalService.withdrawal.value;
                localWithdrawal?.resetChatUnreadCount();
                break;
            case "/chat":
            case "/payment":
                const paymentService: PaymentService = AppController.getInstance().paymentService;
                const localPayment: PurchaseOperation | null = paymentService.payment.value;
                localPayment?.resetChatUnreadCount();
                break;
            default:
        }

        const payments: PaymentWithdrawal[] | Payment[] = store.getters['myPayments'].value;
        if (payments) {
            const idx = payments.findIndex(el => el.id === paymentId);
            if (idx != -1) {
                payments[idx].chat.unread = 0;
                store.dispatch("manuallyUpdateMyPayments", payments);
            }
        }
    }

    public subscribeToMessageStream(v: UIMessageBlock[], callback?: Function) {
        this._subscribe = {
            storeMessages: v,
            callback
        }
    }

    public unsubscribeMessageStream(chatId: number) {
        this._subscribe = null;
    }

    public async markMessageAsRead(payload: ChatPayload) {
        // await MainApiService.getInstance().patch("/api/mobile/client/chat/read", {
        await ServiceMainApi.of().patch("/api/mobile/client/chat/read", {
            chat_id: payload.chat.id,
            message_ids: [payload.message.id]
        });
    }

    public produceMessages(payload: ChatPayload) {
        return new Promise((resolve) => {
            if (!this._subscribe) resolve(null);

            this._subscribe?.storeMessages[this._subscribe?.storeMessages.length - 1].messages.push(this.buildMessage(payload.message));
            if (this._subscribe?.callback) this._subscribe?.callback();
            resolve(null);
        })
    }

    public appendMessage(message: ChatMessage) {
        return new Promise((resolve) => {
            this._subscribe?.storeMessages[this._subscribe?.storeMessages.length - 1].messages.push(this.buildMessage(message));
            resolve(null);
        })
    }

    public buildMessage(message: ChatMessage) {
        // @ts-ignore
        const name = message.senderName === "admin" ? i18n.global.t("g.admin") : message.senderName;
        const result = {
            id: message.id,
            name,
            type: ["agent", "admin"].includes(message.senderType) ? 'received' : 'sent',
            text: this.filterMessageText(message.message),
            senderType: message.senderType,
            wasRead: message.isRead,
            clientFilename: message.attachments.length > 0 ? message.attachments[0].clientFilename : null,
            fileSize: message.attachments.length > 0 ? message.attachments[0].fileSize : null,
            image: message.attachments.length > 0 ? message.attachments[0].filePath : "",
            mimeType: message.attachments.length > 0 ? message.attachments[0].mimeType : null,
            thumb: message.attachments.length > 0 ? message.attachments[0].thumbPath : "",
            createdAtUnix: dayjs(message.createdAt).unix()
        }
        return result
    }

    private filterMessageText(text: string): string {
        const wordsForExclusion: string[] = ["[attachments]"];
        wordsForExclusion.map(word => {
            text = text.replaceAll(word, '')
        });
        return text;
    }
}
