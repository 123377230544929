<template>
  <f7-page ref="self" :page-content="false" class="login-password-page" @page:beforein="PAGE_BEFORE_IN"
           @page:afterin="PAGE_AFTER_IN">
    <f7-navbar class="login-password-navbar">
      <f7-nav-left back-link></f7-nav-left>
      <f7-nav-title>
        <i class="icon sloth-logo-m-svg"/>
        <i class="icon logo-small-svg"/>
      </f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <f7-page-content>
      <f7-block>
        <PageHeadline :title="headline.title" :text="headline.text"/>
        <div class="password-form" :class="{'error': error}">
          <div class="password-input-wrapper">
            <input ref="input-password" class="input-password" v-model="password"
                   :placeholder="$t('g.login.password.placeholder')"
                   :type="passwordInputType" @keyup.enter="SUBMIT"
                   @focusin="INPUT_PHONE_FOCUS" @keydown="INPUT_PHONE_KEYDOWN">
            <div class="item-media" @click="changePasswordInputType">
              <i v-if="passwordInputType === 'password'" class="icon eye-closed-icon"></i>
              <i v-else class="icon eye-opened-icon"></i>
            </div>
          </div>
          <div class="error-block">
            <p class="error-message" v-if="error">{{ error.message }}</p>
          </div>
          <f7-button @click="SUBMIT" class="theme-type" :loading="submitState.loading"
                     :disabled="submitState.disable" preloader>{{ $t("g.login.password.submit") }}
          </f7-button>
        </div>
        <p @click="toLoginCodePage" class="to-login-code" :class="{'disabled': loadingCreateSession}">
          {{ $t("g.login.password.code") }}
          <template v-if="loadingCreateSession">
            <f7-preloader/>
          </template>
        </p>
        <div id="login-password-page_cf-turnstile"></div>
      </f7-block>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts">
import {useI18n} from 'vue-i18n'
import {defineComponent, Ref, ref} from "@vue/runtime-core";
import LoginController, {Channel} from "../LoginController";
import PageHeadline from '@/components/atomic/page-headline/PageHeadline.vue';
import Animations from "@/helpers/animations";
import {f7} from "framework7-vue";
import LoginEnterCodePage from './LoginEnterCodePage.vue';
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {CaptchaActions} from "@/targets/main/views/login/types/CaptchaActions";
import {CaptchaPlace} from "@/targets/main/views/login/types/CaptchaPlace";
import {onUnmounted} from "vue";
import AuthDto from "@/targets/main/components/App/ts/dto/AuthDto";
import FirebaseFcmToken from "@models/firebase/FirebaseFcmToken";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import TelegramPage from "@/targets/main/views/login/subpages/TelegramPage.vue";

type ErrorMessage = {
  message: string
}
export default defineComponent({
  components: {
    PageHeadline
  },
  setup() {
    const {t} = useI18n({useScope: 'global'});
    const controller = LoginController.getInstance();
    const error: Ref<ErrorMessage | null> = ref(null);
    const passwordInputType: 'password' | 'text' = ref('password')
    const phoneText = controller.result.value.formatInternational;

    const headline = {
      title: t("g.login.password.headline.title"),
      text: t("g.login.password.headline.text", {phone: phoneText})
    };
    const submitState = ref({
      loading: false,
      disable: true
    });
    const loadingCreateSession = ref(false);
    return {
      passwordInputType,
      t,
      loadingCreateSession,
      password: controller.password,
      controller,
      error,
      submitState,
      headline,
    }
  },
  watch: {
    'password'(newValue: string) {
      this.setError(null);
      if (newValue.length > 0) this.submitState.disable = false;
      else this.submitState.disable = true;
    }
  },
  methods: {
    changePasswordInputType() {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
    },
    checkTelegram(channel: Channel) {
      if (channel.type === 'telegram' && !channel.isActive) {
        if (typeof f7.views.current.routes.find((r) => r.name === 'detect-telegram') === 'undefined') {
          f7.views.current.routes.push({
            name: 'detect-telegram',
            path: '/auth/login/detect-telegram',
            component: TelegramPage,
          });
        }
        f7.views.current.router.navigate('/auth/login/detect-telegram');
        return false;
      }
      return true;
    },
    async toLoginCodePage() {
      try {
        this.setError(null)

        this.loadingCreateSession = true;
        try {
          await this.controller.generateCaptchaIfEnabled(
              CaptchaActions.CREATE,
              '#login-password-page_cf-turnstile',
              CaptchaPlace.LOGIN_PASSWORD_PAGE,
          );

          await this.controller.requestCreateSession();
          const firstActiveChannel = this.controller.getFirstActiveChannel()
          if (!this.checkTelegram(firstActiveChannel)) {
            return
          }

          await this.controller.generateCaptchaIfEnabled(
              CaptchaActions.SEND,
              '#login-password-page_cf-turnstile',
              CaptchaPlace.LOGIN_PASSWORD_PAGE,
          )

          await this.controller.requestManuallySendCode(firstActiveChannel.type);

          if (typeof f7.views.current.routes.find((r) => r.name === 'login-code') === 'undefined') {
            f7.views.current.routes.push({
              name: 'login-code',
              path: '/auth/login/code',
              component: LoginEnterCodePage,
            });
          }
          f7.views.current.router.navigate('/auth/login/code');
          setTimeout(() => {
            this.controller.captchaController?.deleteCaptchaId(CaptchaPlace.LOGIN_PASSWORD_PAGE);
          }, 400)
        } catch (e: any) {
          if (e?.error) {
            this.setError({message: e.error})
            return
          }
          f7.dialog.alert(e.message, this.t('g.errors.alert.default-title'));
        }
      } catch (e: any) {
        f7.dialog.alert(this.t('errors.70001'), this.t('g.errors.alert.default-title'));
      } finally {
        setTimeout(() => {
          this.loadingCreateSession = false;
        }, 700);
      }
    },
    PAGE_BEFORE_IN() {
    },
    async PAGE_AFTER_IN() {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs['input-password'].focus();
        this.controller.captchaController?.deleteCaptchaId(CaptchaPlace.LOGIN_CODE_PAGE);
        this.controller.captchaController?.deleteCaptchaId(CaptchaPlace.LOGIN_CHANNEL_PAGE);
      });
    },
    async SUBMIT() {
      try {
        this.setError(null)
        this.submitState.loading = true;
        const data = await this.controller.requestCheckPassword();
        AppController.getInstance().auth(data.token, this.controller.phone);
        setTimeout(() => {
          this.submitState.loading = false;
        }, 1000)
      } catch (e: any) {
        this.submitState.loading = false;
        this.setError({message: e.message});
      }
    },
    INPUT_PHONE_FOCUS() {
    },
    INPUT_PHONE_KEYDOWN() {
      this.setError(null)
    },
    setError(value: null | ErrorMessage) {
      this.error = value;
      if (value) {
        const page = this.$el;
        const inputWrapper = page.querySelector('.error-block');
        Animations.shakeAnimation(inputWrapper);
      }
    },
  },
  unmounted() {
    this.controller.password.value = '';
    this.setError(null);
    this.controller.captchaController?.deleteCaptchaId(CaptchaPlace.LOGIN_PASSWORD_PAGE);
    if (AppController.getInstance().isAuth.value) {
      this.controller.destroy();
    }
  }
});
</script>
<style lang="scss">
.login-password-navbar {
  --f7-navbar-inner-padding-left: 16px;
  --f7-navbar-inner-padding-right: 16px;

  .navbar-bg {
    &:after {
      content: none;
    }
  }

  .title {
    display: flex;
    align-items: center;
  }

  .right {
    // a {
    //     background: rgba(116, 116, 128, 0.08);
    //     border-radius: 50%;
    //     width: 30px !important;
    //     height: 30px;

    //     i {
    //         width: 12px;
    //         height: 12px;
    //         background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.271767 10.3446C-0.0881408 10.7045 -0.0954858 11.3435 0.279112 11.7181C0.661056 12.0927 1.30008 12.0854 1.65264 11.7328L6.00092 7.38454L10.3419 11.7255C10.7091 12.0927 11.3408 12.0927 11.7154 11.7181C12.09 11.3362 12.09 10.7119 11.7227 10.3446L7.38179 6.00367L11.7227 1.6554C12.09 1.28815 12.0973 0.656471 11.7154 0.281873C11.3408 -0.0927245 10.7091 -0.0927245 10.3419 0.274528L6.00092 4.61546L1.65264 0.274528C1.30008 -0.0853795 0.653711 -0.10007 0.279112 0.281873C-0.0954858 0.656471 -0.0881408 1.30284 0.271767 1.6554L4.6127 6.00367L0.271767 10.3446Z' fill='%233C3C43' fill-opacity='0.6'/%3E%3C/svg%3E%0A");
    //     }
    // }
  }

}

.login-password-page {
  display: block;

  .block {
    margin: 0px;
    padding: 0px;

    .password-form {
      padding: 0 24px;

      .password-input-wrapper {
        margin: 0px;
        display: flex;
        justify-content: flex-start;
        @include text(20px, 400, var(--grey1), 24px);
        position: relative;

        &::after {
          content: '';
          position: absolute;
          background-color: var(--grey4);
          display: block;
          z-index: 15;
          top: auto;
          right: 0px;
          bottom: 0;
          left: auto;
          height: 1px;
          width: 100%;
          transform-origin: 50% 100%;
          transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }

        .item-media {
          display: flex;
          align-items: center;
          color: var(--grey3);
          cursor: pointer;
          flex: 1;
          margin-right: 8px;

          .icon {
            width: 18px;
            height: 18px;
            filter: invert(84%) sepia(14%) saturate(188%) hue-rotate(173deg) brightness(84%) contrast(89%);
          }
        }

        .password-code {
          position: relative;
          padding: 12px 16px 12px;

          &::after {
            content: '';
            position: absolute;
            background-color: var(--grey4);
            display: block;
            z-index: 15;
            top: 7px;
            right: 0px;
            bottom: auto;
            left: auto;
            height: 32px;
            width: 1px;
            transform-origin: 100% 50%;
            transform: scaleX(calc(1 / var(--f7-device-pixel-ratio)));
          }
        }

        .input-password {
          line-height: 20px;
          padding: 12px 8px 12px 16px;
          width: 100%;

          &::placeholder {
            color: #ACB4BE;
          }
        }
      }

      .error-block {
        margin-top: 8px;
        height: 12px;

        .error-message {
          text-align: center;
          @include text(12px, 400, var(--red), 14px);
        }
      }

      .button {
        margin-top: 26px;
      }

      &.error {
        .phone-input-wrapper {

          &::after,
          &::before {
            background-color: var(--red);
          }
        }
      }

      & + .to-login-code {
        margin-top: 28px !important;
      }
    }

    .to-login-code {
      cursor: pointer;
      text-align: center;
      @include text(17px, 500, var(--blue1), 21px);

      & + #login-password-page_cf-turnstile {
        margin-top: 32px;
      }

      &.disabled {
        opacity: 0.55;
        --f7-preloader-color: var(--blue1);

        .preloader {
          margin-left: 4px;
        }
      }
    }
  }
}
</style>
