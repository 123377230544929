import {doc, getDoc, collection, getDocs, getFirestore} from "firebase/firestore";
import AbstractDocument from "@/services/firebase/firestore/documents/AbstractDocument";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import {Expose, plainToInstance} from "class-transformer";
import Requisite from "@models/requisite/Requisite";
import DocumentOthers from "@/services/firebase/firestore/documents/DocumentOthers";
import humps from "lodash-humps-ts";
import DocumentPurchaseOperationTimers from "@/services/firebase/firestore/documents/DocumentPurchaseOperationTimers";
import FirestoreParser from "@/services/firebase/firestore/parsers/FirestoreParser";
import DocumentWithdrawalOperationTimers
    from "@/services/firebase/firestore/documents/DocumentWithdrawalOperationTimers";
import {Exception} from "sass";
import DocumentPurchase from "@/services/firebase/firestore/documents/DocumentPurchase";
import FirebaseWrongRangeSuccessMetric from "@/services/firebase/firestore/metrics/FirebaseWrongRangeSuccessMetric";
import FirebaseEmptyRangeSuccessMetric from "@/services/firebase/firestore/metrics/FirebaseEmptyRangeSuccessMetric";

export default class FirebaseServiceFirestore {
    private readonly _firestore: any = null;
    private _VERSION_DATABASE_PATH = `mobile/${import.meta.env.VITE_APP_TYPE}-version/${import.meta.env.VITE_APP_PROJECT}`;
    private _GLOBAL_DATABASE_PATH = `mobile/global-settings`;
    private _documents: Map<string, AbstractDocument> = new Map();
    private _parser: FirestoreParser = new FirestoreParser(this);

    public constructor(app: any) {
        this._firestore = getFirestore(app);
    }

    public async fetchVersionDatabaseDocuments() {
        const documents = ["others", "purchase"];
        await Promise.all(documents.map(async (el: string) => {
            const collection = await getDoc(doc(this.firestore, this._VERSION_DATABASE_PATH, el));
            if (!collection.exists()) return false;
            switch (el) {
                case DocumentTypes.OTHERS.toString():
                    this._documents.set(el, plainToInstance(DocumentOthers, humps(collection.data())) as DocumentOthers);
                    break;
                case DocumentTypes.PURCHASE.toString():
                    this._documents.set(el, plainToInstance(DocumentPurchase, humps(collection.data())) as DocumentPurchase);
                    break;
                default:
                    return false;
            }
        }))
    }

    public async fetchGlobalDatabaseDocuments() {
        const documents = ["purchase-operation/timers", "withdrawal-operation/timers"];
        await Promise.all(documents.map(async (el: string) => {
            const collection = await getDoc(doc(this.firestore, this._GLOBAL_DATABASE_PATH, el));
            if (!collection.exists()) return false;
            switch (el) {
                case DocumentTypes.PURCHASE_OPERATION_TIMERS.toString():
                    this._documents.set(el, plainToInstance(DocumentPurchaseOperationTimers, humps(collection.data())) as DocumentPurchaseOperationTimers);
                    break;
                case DocumentTypes.WITHDRAWAL_OPERATION_TIMERS.toString():
                    this._documents.set(el, plainToInstance(DocumentWithdrawalOperationTimers, humps(collection.data())) as DocumentWithdrawalOperationTimers);
                    break;
                default:
                    return false;
            }
        }))
    }

    get documents(): Map<string, AbstractDocument> {
        return this._documents;
    }

    public getDocument(key: DocumentTypes): AbstractDocument | null {
        if (this._documents.has(key.toString())) return this._documents.get(key.toString())!;
        return null;
    }

    get parser(): FirestoreParser {
        return this._parser;
    }

    get firestore(): any {
        return this._firestore;
    }
}