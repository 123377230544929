export enum BuyingOperationStatus {
    EMPTY = "EMPTY",
    CANCELED = "CANCELED",
    CREATED = "CREATED",
    ACCEPTED = "ACCEPTED",
    CASHED = "CASHED",
    CONFIRMED = "CONFIRMED",
    ONAPPEAL = "ONAPPEAL",
    REJECTED = "REJECTED",
    EXPIRED = "EXPIRED",
    TMP_NO_AGENT_EXPIRED = "TMP_NO_AGENT_EXPIRED"
}