import {computed, ref, Ref} from "vue";
import {f7, f7ready} from "framework7-vue";
import ViewController from "@/interfaces/ViewController";
import {catchErrors} from "@/decorators/catch-errors";
// @ts-ignore
import store from "@target/core/store/store";
import Message, {MessageType} from "@/entities/Message";
import Payment from "@/entities/Payment";
import {autobind} from "@/decorators/autobind";
import humps from "lodash-humps-ts";
import { nextTick } from "framework7/shared/utils";
import i18n from "@/langs/i18n";

declare const window: Window & typeof globalThis & { Pusher: any, Echo: any }

const defaultValues = {
    time: 60000,
}

export default class PaymentViewController implements ViewController {
    private static instance?: PaymentViewController;
    interval?: ReturnType<typeof setInterval>;
    title: Ref<string>;
    subtitle: Ref<string>;
    hint: Ref<string>;
    screenFile: Ref<File | null> = ref(null);
    fileToUploadPath: Ref<string | null> = ref(null);

    constructor() {
        this.title = ref("");
        this.subtitle = ref("");
        this.hint = ref("");
    }
    configure() {
        const payment: Payment = computed((): Payment => store.getters.payment.value).value;
    }

    public configurateTextOnView() {
        // @ts-ignore
        this.title.value = i18n.global.t("payment.income.title");
        this.subtitle.value = i18n.global.t("payment.income.subtitle");
    };

    static getInstance(): PaymentViewController {
        if (typeof this.instance === 'undefined') {
            this.instance = new PaymentViewController();
        }
        return this.instance;
    }

    destructor() {
        clearInterval(this.interval);
        PaymentViewController.instance = undefined;
    }

    @autobind
    @catchErrors
    public async cancelPaymentWithBack() {
        f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
    }
}
