<template>
    <div class="loader-block">
        <f7-preloader size="28"/>
    </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.loader-block {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>