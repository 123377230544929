import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";
import {getRemoteConfig} from "firebase/remote-config";
import {getValue} from "firebase/remote-config";
import {fetchAndActivate} from "firebase/remote-config";
import {getFirestore} from "firebase/firestore";
import {collection, getDocs} from "firebase/firestore";
import FirebaseServiceFirestore from "@/services/firebase/firestore/FirebaseServiceFirestore";
import LogService from "@/services/log-service/LogService";
import FirebaseNotificationCordovaPluginService from "@/services/firebase/FirebaseNotificationCordovaPluginService";
import FirebaseWrongRangeSuccessMetric from "@/services/firebase/firestore/metrics/FirebaseWrongRangeSuccessMetric";
import FirebaseEmptyRangeSuccessMetric from "@/services/firebase/firestore/metrics/FirebaseEmptyRangeSuccessMetric";
import {f7} from "framework7-vue";

export class FirebaseService {
    private static instance?: FirebaseService;
    private _notificationPluginService: FirebaseNotificationCordovaPluginService = new FirebaseNotificationCordovaPluginService();
    private app: any = null;
    private analytics: any = null;
    private remoteConfig: any = null;
    public firestoreService: FirebaseServiceFirestore | null = null;
    private initialized: boolean = false;
    private firebaseConfig: object = {
        apiKey: "AIzaSyBHEBql3D3-803D5ZR5JcNwZcqouspJexM",
        authDomain: "bromoney-5c177.firebaseapp.com",
        projectId: "bromoney-5c177",
        storageBucket: "bromoney-5c177.appspot.com",
        messagingSenderId: "163936832478",
        appId: "1:163936832478:web:e21d6c0f2465f4265eeb4f"
    };
    private readonly _wrongRangeMetricSuccess: FirebaseWrongRangeSuccessMetric;
    private readonly _emptyRangeMetricSuccess: FirebaseEmptyRangeSuccessMetric;

    static of() {
        if (typeof FirebaseService.instance === 'undefined') FirebaseService.instance = new FirebaseService();
        return FirebaseService.instance;
    }

    public constructor() {
        this._wrongRangeMetricSuccess = new FirebaseWrongRangeSuccessMetric(this);
        this._emptyRangeMetricSuccess = new FirebaseEmptyRangeSuccessMetric(this);
    }

    get wrongRangeMetricSuccess(): FirebaseWrongRangeSuccessMetric {
        return this._wrongRangeMetricSuccess;
    }

    get emptyRangeMetricSuccess(): FirebaseEmptyRangeSuccessMetric {
        return this._emptyRangeMetricSuccess;
    }

    get notificationPluginService(): FirebaseNotificationCordovaPluginService {
        return this._notificationPluginService;
    }

    public isInitialized(): boolean {
        return this.initialized;
    }

    public async init(callback: Function | null = null) {
        try {
            this.app = initializeApp(this.firebaseConfig);
            this.analytics = getAnalytics(this.app);
            await this.initRemoteConfig();
            await this.initFirestore();
            this.initialized = true;
            if (callback && typeof callback === "function") callback();

            LogService.of().log("FirebaseService@init", "initialized");
        } catch (e: any) {
            LogService.of().error("FirebaseService@init", e.message);
        }
    }

    public async initPushNotifications() {
        await this._notificationPluginService.initPushNotifications();
    }

    public getFromRemoteConfig(key: string) {
        try {
            if (this.initialized) return getValue(this.remoteConfig, key);
        } catch (e: any) {
            console.log(e)
        }
    }

    public getFromFirestore(key: string) {
        try {
            if (this.initialized) return getValue(this.remoteConfig, key);
        } catch (e: any) {
            console.log(e)
        }
    }

    private async initRemoteConfig() {
        this.remoteConfig = getRemoteConfig(this.app);
        this.remoteConfig.settings.minimumFetchIntervalMillis = 0;
        await fetchAndActivate(this.remoteConfig);
    }

    private async initFirestore() {
        this.firestoreService = new FirebaseServiceFirestore(this.app);
        await this.firestoreService.fetchGlobalDatabaseDocuments();
        await this.firestoreService.fetchVersionDatabaseDocuments();
    }

    public analyticsEvent(eventName: string, payload: object = {}) {
        try {
            LogService.of().log("FirebaseService@analyticsEvent", {
                eventName,
                payload
            });
            if (this.initialized && !import.meta.env.DEV) logEvent(this.analytics, eventName, payload);
        } catch (e: any) {
            LogService.of().error("FirebaseService@analyticsEvent", e);
        }
    }
}