import {Expose} from "class-transformer";

export default class CurrencyOperation {
    @Expose({name: 'id'})
    private _id: number | null = null;

    @Expose({name: 'name'})
    private _name: string | null = null;

    @Expose({name: 'abbr'})
    private _abbr: string | null = null;

    @Expose({name: 'desc'})
    private _desc: string | null = null;

    getABBR(): string | null {
        return this._abbr;
    }

    get abbr(): string | null {
        return this._abbr;
    }

    get id(): number | null {
        return this._id;
    }
}