<template>
    <f7-navbar class="payment-view__navbar">
        <f7-nav-left>
            <a href="#" class="link icon-only" @click.prevent="clickBack">
                <i class="icon-back icon"></i>
            </a>
        </f7-nav-left>
        <f7-nav-title>
            <template v-if="payment && payment.id">
                <div class="buying-meta">
                    <p class="buying-meta__id">{{ $t('g.request') }} {{ payment.id }}</p>
                    <PurchaseOperationStatus :payment="payment" />
                </div>
            </template>
        </f7-nav-title>
        <f7-nav-right>
            <p v-if="showPaymentTimes" class="payment-view__navbar_status-timer">{{percent > 0 ? timeText : '0:00'}}</p>
        </f7-nav-right>
    </f7-navbar>
</template>

<script setup lang="ts">
import {computed, PropType, toRef, UnwrapRef} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import {ReactiveBuyingOperation} from "@/services/operations/AbstractBuyingService";
import PurchaseOperationStatus from "@components/atomic/operation-status/PurchaseOperationStatus.vue";
import PaymentTimerService, {
    ReactivePaymentPercent,
    ReactivePaymentTimeText
} from "@/services/operations/payment/PaymentTimerService";

const props = defineProps({
    payment: {
        type: Object as PropType<UnwrapRef<PurchaseOperation> | null>,
        required: true
    },
    timerService: {
        type: Object as PropType<PaymentTimerService>,
        required: true
    }
});
const payment: ReactiveBuyingOperation = toRef(props, 'payment');
const emits = defineEmits(['closeView', 'openPaymentDetailsPopup']);
const timerService: PaymentTimerService = props.timerService;
const percent: ReactivePaymentPercent = timerService.getPercent;
const timeText: ReactivePaymentTimeText = timerService.getTimeText;
const showPaymentTimes = computed(() => {
    return !!(payment.value &&
        payment.value.status && [
            BuyingOperationStatus.ACCEPTED
        ].includes(payment.value.status));
});

function clickBack() {
    emits('closeView');
}

function openPaymentDetailsPopup() {
    emits('openPaymentDetailsPopup');
}

</script>

<style lang="scss">
.payment-view__navbar {
    //--f7-navbar-inner-padding-left: 0;
    //--f7-navbar-inner-padding-right: 0;
    --f7-subnavbar-bg-color: #fff;
    --f7-navbar-bg-color: #fff;

    &_status-timer {
        @include text(16px, 500, var(--grey2), 19.2px);
    }

    &.with-custom-subnavbar {
        .navbar-bg {
            &::after {
                transform: translate3d(0, 0, 0);
                will-change: transform;
                transition: 0.5s background-color cubic-bezier(0.250, 0.460, 0.450, 0.940);
                background-color: unset !important;
            }
        }
    }

    .right {
        min-width: 44px;

        .chat-svg {
            width: 24px;
            height: 24px;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.92072 21C7.11848 21 9.53985 19.8698 11.3667 18.6056C17.4502 18.7144 22 15.2986 22 10.8112C22 6.48279 17.545 3 11.9957 3C6.44636 3 2 6.48279 2 10.8112C2 13.5656 3.75786 16.0186 6.45498 17.2995C6.08445 17.9777 5.42956 18.907 5.05903 19.3926C4.50754 20.1042 4.88669 21 5.92072 21ZM6.96338 19.267C6.85136 19.3088 6.80827 19.2502 6.88583 19.1581C7.32529 18.6391 7.97156 17.8102 8.29039 17.2242C8.5489 16.7637 8.46273 16.3619 7.91124 16.1107C5.28307 14.9135 3.75786 12.9963 3.75786 10.8112C3.75786 7.44558 7.41146 4.70791 11.9957 4.70791C16.5799 4.70791 20.2335 7.44558 20.2335 10.8112C20.2335 14.1767 16.5799 16.9228 11.9957 16.9228C11.8406 16.9228 11.5993 16.9144 11.3063 16.9144C10.9358 16.906 10.6601 17.0149 10.3154 17.2744C9.27273 18.0279 7.73029 18.9656 6.96338 19.267Z' fill='%23007AFF'/%3E%3Cline x1='8.4' y1='9.1' x2='15.6' y2='9.1' stroke='%23007AFF' stroke-width='1.8' stroke-linecap='round'/%3E%3Cline x1='9.9' y1='12.6' x2='14.1' y2='12.6' stroke='%23007AFF' stroke-width='1.8' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        }
    }

    .buying-meta {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__id {
            @include text(16px, 500, var(--brown1), 19px);
        }
    }
}
</style>