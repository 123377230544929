// import RequisitePsystem from "@models/requisite/RequisitePsystem";
import {Expose, Type} from "class-transformer";
import {RequisitePsystemTargetType} from "@enums/RequisitePsystemTargetType";
import {RequisiteAddressType} from "@enums/RequisiteAddressType";
import PaymentSystemOperation from "@models/operations/components/PaymentSystemOperation";
// import {RequisitePsystemTargetType} from "@enums/RequisiteAddressType";

export default class Requisite {
    @Expose({name: "id"})
    private _id: number;
    @Expose({name: "name"})
    private _name: string;
    @Expose({name: "address"})
    private _address: string;
    @Expose({name: "addressTypeId"})
    private _addressTypeId: number;
    @Expose({name: "addressType"})
    private _addressType: RequisiteAddressType;
    @Expose({name: "comment"})
    private _comment: string;
    @Expose({name: "currencyId"})
    private _currencyId: number;
    @Expose({name: "isApproved"})
    private _isApproved: boolean;
    @Expose({name: "isDefault"})
    private _isDefault: boolean;
    @Expose({name: "createdAt"})
    private _createdAt: string;
    @Expose({name: "psystem"})
    @Type(() => PaymentSystemOperation)
    private _psystem: PaymentSystemOperation;

    constructor(
        id: number,
        name: string,
        address: string,
        addressTypeId: number,
        addressType: RequisiteAddressType,
        comment: string,
        currencyId: number,
        isApproved: boolean,
        isDefault: boolean,
        createdAt: string,
        psystem: PaymentSystemOperation,
    ) {
        this._id = id;
        this._name = name;
        this._address = address;
        this._addressTypeId = addressTypeId;
        this._addressType = addressType;
        this._comment = comment;
        this._currencyId = currencyId;
        this._isApproved = isApproved;
        this._isDefault = isDefault;
        this._createdAt = createdAt;
        this._psystem = psystem;
    }

    public isNew(): boolean {
        return this._id === -1;
    }

    public getType(): RequisitePsystemTargetType {
        return this.psystem.targetType;
    }

    public static build(psystem: PaymentSystemOperation) {
        return new Requisite(
            -1,
            "",
            "",
            psystem.addressTypes[0],
            RequisiteAddressType.CARD,
            "",
            1,
            false,
            false,
            "",
            psystem
        );
    }

    public clone(): Requisite {
        return new Requisite(
            this._id,
            this._name,
            this._address,
            this._addressTypeId,
            this._addressType,
            this._comment,
            this._currencyId,
            this._isApproved,
            this._isDefault,
            this._createdAt,
            this._psystem,
        );
    }

    get psystem(): PaymentSystemOperation {
        return this._psystem;
    }

    get createdAt(): string {
        return this._createdAt;
    }

    get isDefault(): boolean {
        return this._isDefault;
    }

    get isApproved(): boolean {
        return this._isApproved;
    }

    get comment(): string {
        return this._comment;
    }

    get currencyId(): number {
        return this._currencyId;
    }

    get addressType(): RequisiteAddressType {
        return this._addressType;
    }

    get addressTypeId(): number {
        return this._addressTypeId;
    }

    get address(): string {
        return this._address;
    }

    get name(): string {
        return this._name;
    }

    get id(): number {
        return this._id;
    }

    set psystem(value: PaymentSystemOperation) {
        this._psystem = value;
    }

    set createdAt(value: string) {
        this._createdAt = value;
    }

    set isDefault(value: boolean) {
        this._isDefault = value;
    }

    set isApproved(value: boolean) {
        this._isApproved = value;
    }

    set currencyId(value: number) {
        this._currencyId = value;
    }

    set comment(value: string) {
        this._comment = value;
    }

    set addressType(value: RequisiteAddressType) {
        this._addressType = value;
    }

    set addressTypeId(value: number) {
        this._addressTypeId = value;
    }

    set address(value: string) {
        this._address = value;
    }

    set name(value: string) {
        this._name = value;
    }

    set id(value: number) {
        this._id = value;
    }
}