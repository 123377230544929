import MainApiService from "@/services/api-service/MainApiService";
// @ts-ignore
import Api from "@target/services/project/projectApiService";
export default class BaseAppController {
    public async init() {
        throw new Error("Not implemented");
    }

    public setToken(token: string | null) {
        if (token) {
            localStorage.setItem("token", token);
        } else {
            localStorage.removeItem("token");
            localStorage.removeItem("ltoken");
            localStorage.removeItem("lrefreshToken");
            localStorage.removeItem("csp");
        }
    }
}