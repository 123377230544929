import ViewController from "@/interfaces/ViewController";
import {computed, ref, ToRef} from "vue";
// @ts-ignore
//@ts-ignore
import AppController from '@target/components/App/ts/AppController';
import i18n from "@/langs/i18n"
// @ts-ignore
import store from '@target/core/store/store';
import GlobalRequisitesController from "@/views/requisites-group/GlobalRequisitesController";
import {autobind} from "@/decorators/autobind";
import Rates from "@/entities/Rates";
import Account from "@/entities/Account";
import WithdrawalService, {createWithdrawalPayload} from "@/services/operations/withdrawal/WithdrawalService";
import Requisite from "@models/requisite/Requisite";
import MainApiService from "@/services/api-service/MainApiService";
import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";
import {ServerResponseError} from "@/interfaces/errors";

export default class WithdrawalPopupController implements ViewController {
    private static instance?: WithdrawalPopupController;
    public amount: ToRef<string>;
    public inputCurrency: ToRef<string>;
    private _amountErrors: ToRef<any> = ref({
        min: false,
        max: false,
        balance: false,
    });

    private constructor() {
        // const currencySign = computed(() => store.getters['currencySign'].value);
        // const currencySign = computed(() => store.getters['currencySign'].value);
        const currencySign = ref("$");
        AppController.getInstance().setPaymentRequestData(null);

        const requisitePs = GlobalRequisitesController.getInstance();
        let amount = 5;

        if (requisitePs.activeRequisiteAmountRange.value) {
            const {min, max} = requisitePs.activeRequisiteAmountRange.value!;

            if (max !== 0) {
                const rates: Rates = store.getters['rates'].value;
                amount = +(min * rates.sell).toFixed(0);
            }
        }

        this.amount = ref(`${amount} ${currencySign.value}`);
        this.inputCurrency = ref("RUB");
    }

    public extractAmount() {
        const rates: Rates = store.getters['rates'].value;
        if (this.inputCurrency.value === "USDT") {
            return +(
                (Math.ceil(
                    parseInt(this.amount.value.replaceAll(/\s+/g, '')
                    )) / rates.sell)
            ).toFixed(2);
        } else {
            return parseFloat(this.amount.value.replaceAll(/\s+/g, ''))
        }
    }

    get amountErrors(): ToRef<any> {
        return this._amountErrors;
    }

    set amountErrors(value: ToRef<any>) {
        this._amountErrors = value;
    }

    @autobind
    public async create2P2Withdrawal() {
        const requisitePs = GlobalRequisitesController.getInstance();
        const {min, max} = requisitePs.activeRequisiteAmountRange.value!;
        const rates: Rates = store.getters['rates'].value;

        const payload: createWithdrawalPayload = {
            requisite_id: requisitePs.activeRequisite.value!.id,
            amount: -1,
        };
        if (this.inputCurrency.value === "USDT") {
            payload.amount = +(
                (Math.ceil(
                    parseInt(this.amount.value.replaceAll(/\s+/g, '')
                    )) / rates.sell)
            ).toFixed(2);
        } else {
            payload.amount = parseFloat(this.amount.value.replaceAll(/\s+/g, ''))
        }

        const account = computed((): Account => store.getters.account.value);

        if (max === 0) {
            throw new ServerResponseError("Something went wrong", 70401);
        }

        // if (payload.amount > account.value.balance!.amount) {
        //     throw Error(`${i18n.global.t("g.errors.newPayment.no-enough")}`);
        // }
        //
        // if (payload.amount < min) {
        //     throw Error(`${i18n.global.t("g.errors.newPayment.minAmount")} ${min}$`);
        // }
        // if (payload.amount > max) {
        //     throw Error(`${i18n.global.t("g.errors.newPayment.maxAmount")} ${max}$`);
        // }

        const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;
        AppController.getInstance().setPaymentRequestData({...payload, type: 'sail'});

        await withdrawalService.create2P2Withdrawal(payload);
    }

    private createP2PWithdraw() {

    }

    static getInstance() {
        if (typeof this.instance === 'undefined') this.instance = new WithdrawalPopupController();
        return this.instance;
    }

    public async fetchRequisiteMinMax(requisite: Requisite) {
        const response = await MainApiService.getInstance().get("/api/mobile/client/withdrawals/amount_range", {requisite_id: requisite.id});
        return response.data as AgentsAmountRanges;
    }

    public updateAmount() {
        const currencySign = computed(() => store.getters['currencySign'].value);
        const requisitePs = GlobalRequisitesController.getInstance();
        let amount = 100;

        if (requisitePs.activeRequisiteAmountRange.value) {
            const {min, max} = requisitePs.activeRequisiteAmountRange.value!;

            if (max !== 0) {
                const rates: Rates = store.getters['rates'].value;
                amount = +(Math.ceil(min * rates.sell)).toFixed(0);
            }
        }

        this.amount.value = `${amount} ${currencySign.value}`;
    }

    destructor(): void {
        WithdrawalPopupController.instance = undefined;
    }
}
