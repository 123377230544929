<template>
    <div class="payment-card__attribute payment-card__psystem-operation">
        <p class="title">{{ $t('payment.ui-card.payment-system') }}</p>
        <div class="payment-card__text-wrapper">
            <p class="text">
                <img v-if="paymentSystem.imgPath" :src="paymentSystem.imgPath" alt="#">
                {{ paymentSystem.name }}
            </p>
            <template v-if="isPhone">
                <payment-system-operation-s-b-p />
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import {PropType, toRef} from "vue";
import PaymentSystemOperation from "@models/operations/components/PaymentSystemOperation";
import PaymentSystemOperationSBP from "@/views/payment/components/atomic/PaymentSystemOperationSBP.vue";

const props = defineProps({
    paymentSystem: {
        type: Object as PropType<PaymentSystemOperation>,
        required: true
    },
    isPhone: {
        type: Object as PropType<Boolean>,
        required: true
    }
});

const paymentSystem = toRef(props, "paymentSystem");
const isPhone = toRef(props, "isPhone");
</script>

<style scoped lang="scss">
.payment-card {
    &__attribute {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    .title {
        @include text(16px, 400, var(--grey2), 19.2px);
    }

    &__text-wrapper {
        display: flex;
        gap: 4px;
        flex-direction: unset;
        align-items: flex-start;
        @media (min-width: 375px) {
            align-items: center;
            flex-direction: unset;
        }
        img {
            width: 24px;
            height: 24px;
        }
    }
    .text {
        display: flex;
        align-items: center;
        gap: 4px;
        @include text(20px, 500, var(--brown1), 24px);
    }
}
</style>