import Requisite from "@models/requisite/Requisite";
import MainApiService from "@/services/api-service/MainApiService";
import CreateWalletWithdrawalDto from "@/services/operations/withdrawal/dto/CreateWalletWithdrawalDto";


export default class WithdrawalRepository {
    async createWallet(createWalletWithdrawalDto: CreateWalletWithdrawalDto) {
        const { amount, requisite } = createWalletWithdrawalDto;
        return await MainApiService.getInstance().post('/api/mobile/client/transfers/create', {
            amount_fiat: amount,
            requisite_id: requisite.id
        });
    }

    async find(msid: string) {
        const response = await MainApiService.getInstance().get(`/api/mobile/client/transfers/get/${msid}`);
        return response.data;
    }
}