<template>
    <div class="balance-block__skeleton">
        <div class="header-row">
            <f7-skeleton-block class="skeleton-effect-wave" />
        </div>
        <div class="actions-row">
            <f7-skeleton-block class="skeleton-effect-wave" />
            <f7-skeleton-block class="skeleton-effect-wave" />
        </div>
    </div>
</template>
<script setup lang="ts">
</script>

<style lang="scss">
.balance-block__skeleton {
    --skeleton-color: #DBDDE4;
    margin: 0 16px;
    .header-row {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        .skeleton-block {
            height: 74px;
            width: 165px;
            border-radius: 8px;
        }
    }
    .actions-row {
        display: flex;
        justify-content: center;
        margin-top: 32px;
        gap: 12px;
        .skeleton-block {
            height: 46px;
            width: 190px;
            border-radius: 8px;
        }
    }
}
</style>