<template>
    <div class="page-headline">
        <p class="title" v-html="title"></p>
        <p class="text" v-html="text"></p>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, Ref, ref, toRef } from "@vue/runtime-core";
export default defineComponent({
    props: {
        title: {
            type: Object as PropType<string>,
            required: true,
        },
        text: {
            type: Object as PropType<string>,
            required: true,
        }
    },
    setup(props) {
        return {
            title: toRef(props, 'title'),
            text: toRef(props, 'text'),
        }
    },
});
</script>

<style lang="scss">
.page-headline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 16px;
    margin: 32px 0px;
    padding: 0px 16px;
    .title {
        @include text(28px, 700, var(--grey1), 34px);
    }
    .text {
        @include text(15px, 400, var(--grey1), 18px);
        b {
            font-weight: 700;
        }
    }
}
</style>