import {Expose, Type} from "class-transformer";
import {RequisitePsystemTargetType} from "@enums/RequisitePsystemTargetType";
import {id} from "framework7/shared/utils";

export default class RequisitePsystem {
    @Expose({name: "id"})
    private readonly _id: number;
    @Expose({name: "targetType"})
    private readonly _targetType: RequisitePsystemTargetType;
    @Expose({name: "name"})
    private readonly _name: string;
    @Expose({name: "sysName"})
    private readonly _sysName: string;
    @Expose({name: "imgPath"})
    private readonly _imgPath: string;
    @Expose({name: "addressTypes"})
    private readonly _addressTypes: number[];

    constructor(
        id: number,
        name: string,
        targetType: RequisitePsystemTargetType,
        sysName: string,
        imgPath: string,
        addressTypes: number[]
    ) {
        this._id = id;
        this._name = name;
        this._targetType = targetType;
        this._sysName = sysName;
        this._imgPath = imgPath;
        this._addressTypes = addressTypes;
    }

    get imgPath(): string {
        return this._imgPath;
    }

    get sysName(): string {
        return this._sysName;
    }

    get addressTypes(): number[] {
        return this._addressTypes;
    }

    get name(): string {
        return this._name;
    }

    get targetType(): RequisitePsystemTargetType {
        return this._targetType;
    }

    get id(): number {
        return this._id;
    }
}