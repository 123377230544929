import FirebaseFcmToken from "@models/firebase/FirebaseFcmToken";

export default class AuthDto {
    public token: string;
    public deviceId: string | null;
    public platform: string;
    public userAgent: string | null;
    public bundleId: string | null;
    public appV: string;
    public tokens: FirebaseFcmToken[] = [];

    public constructor(
        token: string,
        deviceId: string | null,
        platform: string,
        bundleId: string | null,
        userAgent: string | null,
        appV: string,
        tokens: FirebaseFcmToken[]
    ) {
        this.token = token;
        this.deviceId = deviceId;
        this.platform = platform;
        this.bundleId = bundleId;
        this.userAgent = userAgent;
        this.appV = appV;
        this.tokens = tokens;
    }
}