export enum MessageType {
    'error',
    'success',
    'notFoundPayment'
}

export default interface Message {
    title: string;
    text: string;
    extra?: string;
    type: MessageType
}
