import {f7} from "framework7-vue";
import {ref, Ref} from "vue";
import {autobind} from "@/decorators/autobind";
import {catchErrors} from "@/decorators/catch-errors";
import store from "@target/core/store/store";
import ViewController from "@/interfaces/ViewController";
import Payment from "@/entities/Payment";

export default class MessageNotFoundPaymentController implements ViewController {
    private static instance?: MessageNotFoundPaymentController;
    public screenFile: Ref<File | null>;
    public screenFilePath: Ref<string | null>;
    public screenFileName: Ref<string | null>;
    public submitBtnLoading: Ref<boolean>;

    static getInstance(): MessageNotFoundPaymentController {
        if (typeof this.instance === 'undefined') {
            this.instance = new MessageNotFoundPaymentController();
        }
        return this.instance;
    }

    private constructor() {
        this.screenFile = ref(null);
        this.screenFilePath = ref(null);
        this.screenFileName = ref(null);
        this.submitBtnLoading = ref(false);
    }

    public resetScreenFile() {
        this.screenFile.value = null;
        this.screenFilePath.value = null;
        this.screenFileName.value = null;
    }

    public eventFileLoad(e: Event) {
        const file = (e.target as HTMLInputElement).files![0];
        const extension = file.type.split('/')[1];
        const fileSize = file.size / 1024 / 1024;

        if (fileSize > 10) {
            f7.dialog.alert('Максимальный размер файла 10mb', 'Упс!');
            return false;
        }
        if (!['jpeg', 'jpg', 'png', 'pdf'].includes(extension)) {
            f7.dialog.alert('Формат может быть: jpeg, jpg, png и pdf', 'Упс!');
            return false;
        }
        this.screenFile.value = file;
        this.screenFilePath.value = URL.createObjectURL(file);
        if (file.name.length > 20) this.screenFileName.value = '...' + file.name.slice(-20);
        else this.screenFileName.value = file.name.slice(-20);
    }

    @autobind
    @catchErrors
    public async submit() {
        this.submitBtnLoading.value = true;
        await store.dispatch('uploadFile', this.screenFile.value);
        const payment: Payment = store.getters.payment.value;
        await store.dispatch('fetchPayment', {msid: payment.msid});
        this.submitBtnLoading.value = false;
        f7.view.main.router.navigate('/payment');
        // f7.dialog.alert('Мы разбираемся в ситуации, статус можно уточнить в чате техподдержки', 'Отправлено', () => {
        //     f7.view.main.router.back('/', {clearPreviousHistory: true, force: true});
        // });
    }

    public destructor() {
        this.resetScreenFile();
        MessageNotFoundPaymentController.instance = undefined;
    }
}
