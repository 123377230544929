import Message, {MessageType} from "@/entities/Message";
import store from "@target/core/store/store";
import {f7} from "framework7-vue";
import ViewController from "@/interfaces/ViewController";

const defaultMessage: Message = {
    title: 'Что-то пошло не так',
    text: 'Попробуйте чуть-позже',
    type: MessageType.error
}

export default class MessageViewController implements ViewController {
    message: Message;

    constructor() {
        this.message = store.getters.message?.value ?? defaultMessage;
    }

    public toPayments() {
        store.dispatch('setPayment', undefined);
        f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
        // f7.view.main.router.back('/', {transition: 'f7-fade', force: true, clearPreviousHistory: true});
    }

    public destructor() {

    }
}
